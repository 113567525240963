import React from "react";

const DisplayMarkdown = ({ markdownValue }) => {
  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  return <div dangerouslySetInnerHTML={renderHTML(markdownValue)} />;
};

export default DisplayMarkdown;
