import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertIcon from "../../../../static/icons/alert-icon.svg";
import { Box, Button, IconButton, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import cms from "../../../../config/axiosConfig";

export default function PlaybookDeleteDialoge({ open, setOpen, row, refrashFunc }) {
    const handleClose = () => {
        setOpen(false);
    };

    // andle SubmitD

    const handleSubmit = async (e) => {
        try {
            e.target.disabled = true;
            await cms
                .delete(`/cms/api/case/template/${row._id}`)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("Template deleted successfuly.");
                        setOpen(false);
                        refrashFunc();
                    } else {
                        toast.error("Something is wrong");
                    }
                })
                .catch((res) => {
                    toast.error(res.response?.data?.message);
                });
        } catch (err) {
            toast.error("Something wents wrong", err);
        } finally {
            e.target.disabled = false;
        }
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="md"
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "822px",
                    borderRadius: "15px",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title" className="bg-danger">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight={700} color="#fff">
                            <Box component="img" src={AlertIcon} alt="text" mr={1} />
                            {`Are you sur you want to remove playbook (${row._id})`}
                        </Typography>
                        <Typography variant="caption" color="#fff">
                            Deleting playbook
                        </Typography>
                    </Box>

                    <IconButton onClick={handleClose}>
                        <CancelIcon fontSize="large" sx={{ color: "#fff" }} />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    p={5}
                >
                    <Typography paragraph noWrap>
                        Are you sure you want to delete the selected template?
                    </Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Box
                    pl="22px"
                    pr="22px"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    width="100%"
                >
                    <Button
                        startIcon={<DeleteForeverIcon />}
                        variant="contained"
                        color="error"
                        onClick={handleSubmit}
                        sx={{ fontSize: 20, height: 50 }}
                    >
                        Remove
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
