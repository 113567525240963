import {
  Box,
  Typography,
  Button,
  Grid,
  Chip,
  Collapse,
  Divider,
  LinearProgress,
  ButtonBase,
  InputBase,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { tlp_enum } from "./enums/enums";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import MarkDownTable from "../../components/Display/MarkDownTable/MarkDownTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { toast } from "react-toastify";
import StarIcon from "@mui/icons-material/Star";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import LinkIcon from "@mui/icons-material/Link";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReactJson from "react-json-view";
import CreateIcon from "@mui/icons-material/Create";
import RichTextEditor from "../../components/Field/RichTextEditor";
import DeleteObservable from "./components/DeleteObservable.js";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { connect } from "react-redux";
import { useData } from "../../DataContext.js";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CachedIcon from '@mui/icons-material/Cached';

const ObservablesDetail = ({ id, permissions, onClose, ...props }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({ loading: false, data: {} });
  const [analysers, setAnalysers] = useState({});
  const [editFields, setEditFields] = useState([]);
  const [editTags, setEditTags] = useState([]);
  const [deleteDialog, showdeleteDialog] = useState(false);
  const [editDescription, setDescription] = useState("");
  const [analyserCollap, setAnalyserCollap] = useState("");
  const [analyzerReport, setAnalyserReport] = useState({});
  const [analyzerLoader, setAnalyzerLoader] = useState(false);

  const [runableAnalyzers, setRunableAnalyser] = useState([]);
  const reportRef = useRef(null)
  const { _data } = useData();

  const FetchAnalysers = async (dataType) => {
    let query = [
      {
        _name: "getObservable",
        idOrName: id,
      },
      {
        _name: "jobs",
      },
      {
        _name: "sort",
        _fields: [
          {
            startDate: "desc",
          },
        ],
      },
      {
        _name: "page",
        from: 0,
        to: 200,
      },
    ];

    try {
      const response = await axios.post(
        `/cms/api/v1/query`,
        { query: query },
        {
          params: {
            name: "case-by-tags-stats",
          },
        }
      );
      let d = response.data;

      const groupedData = {};
      const analyzerIds = []
      d.forEach((item) => {
        analyzerIds.push(item.analyzerId)
        const analyzerName = item.analyzerName;
        if (!groupedData[analyzerName]) {
          groupedData[analyzerName] = [];
        }
        groupedData[analyzerName].push(item);
      });
      setAnalysers(groupedData);
      FecthNonRuningAnalyzers(groupedData, dataType)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const FetchAnalyserReport = (analyser_id) => async () => {
    try {
      setAnalyzerLoader(true)
      setAnalyserReport({});
      const response = await axios.get(
        `/cms/api/connector/cortex/job/${analyser_id}`,
        {
          params: {
            nstats: true,
          },
        }
      );
      let d = response.data;
      setAnalyserReport(d);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setAnalyzerLoader(false)
    }
  };

  const ReRunAnalyzer = (analyser, refresh = false) => async () => {
    try {
      const payload = { analyzerId: analyser.analyzerDefinition, artifactId: id, cortexId: analyser.cortexId }

      const response = await axios.post(`/cms/api/connector/cortex/job`, payload);
      if (response.status > 199) {
        toast.dismiss()
        if (refresh) {
          FetchAnalysers(data.data?.dataType)
        }
        toast.success(`Analyzer ${payload.analyzerId} has been successfully started for observable: ${data.data?.dataType === "file" ? data.data?.attachment?.name : data.data?.data}`)
      }

    } catch (error) {
      toast.error(error.response?.data?.message)
      console.log("Error runing analyzer:", error);
    }
  };
  const FetchObservable = async () => {
    setData((pre) => ({ ...pre, loading: true }));
    try {
      const response = await axios.get(`/cms/api/case/artifact/${id}`);
      let d = response.data;
      setData((pre) => ({ ...pre, data: d }));
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("observable", d._id);
      // Use the navigate function to update the URL with the new search parameters
      navigate(`?${newSearchParams.toString()}`);
      FetchAnalysers(d.dataType);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setData((pre) => ({ ...pre, loading: false }));
    }
  };
  const updateField = async (filed_name, value) => {
    if (!permissions.mObs) {
      toast.error("permission denied");
      return;
    }
    try {
      const response = await axios.patch(`/cms/api/case/artifact/${id}`, {
        [filed_name]: value,
      });

      let d = response.data;
      setData((pre) => ({ ...pre, data: d }));
      toast.success(`Successfuly updated "${filed_name}"`);
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };
  const toggleEditField = (value) => () => {
    if (editFields.includes(value)) {
      setEditFields(editFields.filter((item) => item !== value));
    } else {
      setEditFields([...editFields, value]);
    }
  };
  const removeCasesTask = (tagToRemove) => {
    setEditTags((pre) => pre.filter((tag) => tag !== tagToRemove));
  };
  const handleTagsKeyDown = (e) => {
    const value = e.target.value;
    if (e.key === "Enter" && value.length > 2 && !editTags.includes(value)) {
      setEditTags((pre) => [...pre, value]);
      e.target.value = "";
    }
  };

  useEffect(() => {
    FetchObservable();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (analyzerReport.id && reportRef.current) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [analyzerReport.id]);

  const FecthNonRuningAnalyzers = async (_analysers, dataType) => {
    // Fetch all none runing analyzers
    let none_runing = props.analyzers;
    if (none_runing && none_runing.length > 0) {
      none_runing = none_runing.filter(ana => ana.dataTypeList.includes(dataType) && !Object.keys(_analysers).includes(ana.name))
      setRunableAnalyser(none_runing)
    }

  }
  useEffect(() => {
    const CheckForChange = () => {
      if (_data?.length > 0) {
        const summary = _data[0].summary;
        const _analyzer = _data[0].base?.details

        if (_analyzer?.objectId === data.data?._id) {
          if (summary.hasOwnProperty("case_artifact_job")) {
            const _analyzer = _data[0].base?.details
            FetchAnalysers(data.data.dataType);
            toast.dismiss()
            toast.success(`Analyzer ${_analyzer?.analyzerName ? _analyzer.analyzerName : _data[0].base?.object?.analyzerName} has been successfully started for observable: ${data.data?.dataType === "file" ? data.data?.attachment?.name : data.data?.data}`)
          }

        }
      }

    }
    CheckForChange()
  }, [_data])

  if (data.loading) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }
  return (
    <Grid container p="18px 20px" spacing={5.75}>
      <Grid item xs={12} mt={2} textAlign="right">
        {permissions.mObs && (
          <Button
            startIcon={<DeleteForeverIcon fontSize="small" />}
            variant={"contained"}
            color="error"
            onClick={() => {
              showdeleteDialog(true);
            }}
            sx={{
              height: "max-content",
              minWidth: 120,
              borderRadius: "5px",
            }}
          >
            Remove
          </Button>
        )}
        <DeleteObservable
          setOpen={showdeleteDialog}
          open={deleteDialog}
          selectedObj={{ _id: id }}
          refrashFunc={onClose}
        />
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Box display="flex" gap={1}>
            <Typography fontSize={20} fontWeight="bold" color="black">
              {`[${data.data?.dataType}] :`}
            </Typography>
            {data.data?.dataType === "file" ? (
              <Tooltip title={`Zip are protected with password "malware"`}>
                <Typography
                  fontSize={20}
                  fontWeight="bold"
                  component="a"
                  target="_blank"
                  href={
                    window.location.origin +
                    "/cms/api/datastorezip/" +
                    data.data?.attachment?.hashes[0] +
                    `?name=${data.data?.attachment?.name}`
                  }
                >
                  {data.data?.attachment?.name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography fontSize={20} fontWeight="bold" color="black">
                {data.data?.data}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Stack
            direction="row"
            width="fit-content"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2.25}
          >
            <Tooltip title="is IOC">
              <Typography
                sx={{ textAlign: "center" }}
                variant="body2"
                fontWeight="bold"
                color="#000"
              >
                IOC
              </Typography>
              <IconButton
                size="large"
                disabled={!permissions.mObs}
                onClick={() => updateField("ioc", !data.data.ioc)}
              >
                <StarIcon
                  fontSize="large"
                  sx={{ color: !data.data?.ioc ? "#ECF0F4" : "#003272" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Has been sighted">
              <Typography
                sx={{ textAlign: "center" }}
                variant="body2"
                fontWeight="bold"
                color="#000"
              >
                Sighted
              </Typography>
              <IconButton
                size="large"
                disabled={!permissions.mObs}
                onClick={() => updateField("sighted", !data.data.sighted)}
              >
                <ToggleOnIcon
                  fontSize="large"
                  sx={{ color: !data.data?.sighted ? "#ECF0F4" : "#1173FF" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ignored for similarity">
              <Typography
                sx={{ textAlign: "center" }}
                variant="body2"
                fontWeight="bold"
                color="#000"
              >
                Similarity
              </Typography>
              <IconButton
                size="large"
                disabled={!permissions.mObs}
                onClick={() =>
                  updateField("ignoreSimilarity", !data.data.ignoreSimilarity)
                }
              >
                <LinkIcon
                  fontSize="large"
                  sx={{
                    color: !data.data?.ignoreSimilarity ? "#ECF0F4" : "#f0ad4e",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Grid container alignItems="center" item spacing={1.25}>
        <Grid item xs={2}>
          <Typography variant="body2" fontWeight="bold" color="#000">
            TLP
          </Typography>
        </Grid>

        <Grid item xs>
          <ButtonBase
            disabled={!permissions.mObs}
            sx={{ display: editFields.includes("tlp") && "none" }}
            onClick={toggleEditField("tlp")}
            key={tlp_enum.find((t) => t.value === data.data?.tlp)?.title}
          >
            <Chip
              sx={{
                minWidth: "82px",
                bgcolor: tlp_enum.find((t) => t.value === data.data?.tlp)
                  ?.bgColor,
                color: tlp_enum.find((t) => t.value === data.data?.tlp)?.value,
              }}
              icon={
                <CircleIcon
                  fontSize="small"
                  style={{
                    color: tlp_enum.find((t) => t.value === data.data?.tlp)
                      ?.color,
                  }}
                />
              }
              label={tlp_enum.find((t) => t.value === data.data?.tlp)?.title}
            />
          </ButtonBase>
          <Stack
            direction="row"
            spacing={1.25}
            sx={{ display: !editFields.includes("tlp") && "none" }}
          >
            {tlp_enum.map((item) => (
              <ButtonBase
                key={item.title}
                onClick={() => {
                  updateField("tlp", item.value);
                  toggleEditField("tlp")();
                }}
              >
                <Chip
                  sx={{
                    minWidth: "82px",
                    justifyContent: item.bgColor,
                    backgroundColor: data.data?.tlp === item.value && "#003272",
                    color: data.data?.tlp === item.value && "#fff",
                  }}
                  icon={
                    <CircleIcon
                      fontSize="small"
                      style={{ color: item.color }}
                    />
                  }
                  label={item.title}
                />
              </ButtonBase>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Grid container alignItems="center" item spacing={1.25}>
        <Grid item xs={2}>
          <Typography variant="body2" color="#000">
            Date added
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography variant="body2" fontWeight="bold" color="#000">
            {new Date(data.data?.createdAt).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" item spacing={1.25}>
        <Grid item xs={2}>
          <Typography variant="body2" color="#000">
            Tags
          </Typography>
        </Grid>

        <Grid container spacing={1.25} item xs>
          {!editFields.includes("tags") && (
            <ButtonBase
              disabled={!permissions.mObs}
              sx={{ display: "flex", gap: "4px" }}
              onClick={() => {
                toggleEditField("tags")();
                setEditTags(data.data?.tags);
              }}
            >
              {data.data?.tags?.length > 0 ? (
                data.data?.tags.map((tag, i) => (
                  <Grid item key={tag}>
                    <Box
                      sx={{
                        height: "20px",
                        p: "5px",
                        borderRadius: "2px 0px 0px 2px",
                        borderLeft: "5px solid #13D38E",
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="4px"
                      bgcolor={"#ECF0F4"}
                    >
                      {tag}
                    </Box>
                  </Grid>
                ))
              ) : (
                <Typography variant="caption" component="li" color="grey">
                  No tag
                </Typography>
              )}
            </ButtonBase>
          )}
          {/* EditField */}
          {editFields.includes("tags") && (
            <Grid
              sx={{
                position: "relative",
                border: editFields.includes("tags") && "1px solid #EAF2FF",
                p: "7px",
              }}
              item
              container
              spacing={1.25}
            >
              {editTags.map((tag, i) => (
                <Grid item key={tag}>
                  <Box
                    sx={{
                      height: "20px",
                      p: "5px",
                      borderRadius: "2px 0px 0px 2px",
                      borderLeft: "5px solid #13D38E",
                    }}
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    bgcolor={"#ECF0F4"}
                  >
                    {tag}

                    <IconButton
                      size="small"
                      onClick={() => removeCasesTask(tag)}
                    >
                      <CancelIcon sx={{ fontSize: "15px" }} />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
              <Grid item xs={12}>
                <InputBase
                  fullWidth
                  autoFocus
                  id="basic_tags"
                  onKeyDown={handleTagsKeyDown}
                  placeholder="Enter tags"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "primary.light",
                  }}
                />
              </Grid>
              <Box
                display="flex"
                gap="5px"
                position="absolute"
                sx={{ bottom: -40, right: 0 }}
                className="editButton"
              >
                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={() => {
                    updateField("tags", editTags);
                    toggleEditField("tags")();
                    // setEditTags([]);
                  }}
                >
                  <CheckCircleIcon sx={{ color: "primary.light" }} />
                </IconButton>

                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={toggleEditField("tags")}
                >
                  <CancelIcon sx={{ color: "error.main" }} />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      {data?.data?.message && (
        <Grid container direction="column" item spacing={1.25}>
          <Grid item>
            <ButtonBase
              sx={{
                "&:hover": { "& .edit": { display: "inline-flex !important" } },
              }}
              onClick={toggleEditField("message")}
            >
              <Typography fontSize={20} fontWeight="bold" color="#000">
                Description
                <CreateIcon className="edit" sx={{ ml: 2, display: "none" }} />
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs>
            {!editFields.includes("message") ? (
              <MarkDownTable>{data.data?.message}</MarkDownTable>
            ) : (
              <Box
                sx={{
                  position: "relative",
                  border: editFields.includes("message") && "1px solid #EAF2FF",
                  p: "7px",
                }}
              >
                <RichTextEditor
                  setValue={setDescription}
                  defaultValue={data.data.message}
                />
                <Box
                  display="flex"
                  gap="5px"
                  position="absolute"
                  sx={{ bottom: -40, right: 0 }}
                  className="editButton"
                >
                  <IconButton
                    sx={{ borderRadius: "5px" }}
                    onClick={() => {
                      updateField("message", editDescription);
                      toggleEditField("message")();
                      // setEditTags([]);
                    }}
                  >
                    <CheckCircleIcon sx={{ color: "primary.light" }} />
                  </IconButton>

                  <IconButton
                    sx={{ borderRadius: "5px" }}
                    onClick={toggleEditField("message")}
                  >
                    <CancelIcon sx={{ color: "error.main" }} />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      {/* {data.data?.reports && Object.keys(data.data?.reports).length > 0 && ( */}
      {(Object.keys(analysers).length > 0 || runableAnalyzers.length > 0) && (
        <Grid container direction="column" item spacing={1.25}>
          <Grid item>
            <Typography fontSize={20} fontWeight="bold" color="#000">
              Analysis
            </Typography>
          </Grid>
          <Grid item>

            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              {/* Headers */}
              <thead>
                <tr style={{ fontSize: 14, fontWeight: 700 }}>
                  <th style={{ border: "none", padding: "12px" }}>
                    Analyzer
                  </th>
                  <th style={{ border: "none", padding: "12px" }}>
                    Last analysis
                  </th>
                  <th style={{ border: "none", padding: "12px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Runing Analyzers */}
                {/* {Object.keys(data.data.reports).map((res, index) => ( */}
                {Object.keys(analysers).map((res, index) => (
                  <tr
                    style={{
                      border: "1px solid #ECF0F4",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <td
                      style={{ padding: "12px", verticalAlign: "text-top" }}
                    >
                      <IconButton
                        size="small"
                        onClick={() =>
                          setAnalyserCollap((pre) => {
                            if (pre !== res) {
                              return res;
                            } else {
                              return "";
                            }
                          })
                        }
                      >
                        {analyserCollap === res ? <ExpandLessIcon color="grey" /> : <ExpandMoreIcon color="grey" />}
                      </IconButton>
                      {res}
                    </td>
                    <td style={{ padding: "12px" }}>
                      <Collapse
                        in={analyserCollap === res}
                        collapsedSize={23}
                      >
                        {analysers[res]?.map((any) => {
                          const date = new Date(
                            any.startDate
                          ).toLocaleString();
                          return (
                            <Typography
                              component={ButtonBase}
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              onClick={FetchAnalyserReport(any.id)}
                              fontSize={14}
                              color="primary.light"
                            >
                              <Box display="flex" gap={1} alignItems="center">
                                {any.status === "Waiting" && <Tooltip title={any.status}> <HourglassBottomIcon fontSize="small" color="secondary" /> </Tooltip>}
                                {any.status === "Success" && <Tooltip title={any.status}> <DoneAllIcon fontSize="small" color="success" /> </Tooltip>}
                                {any.status === "InProgress" && <Tooltip title={any.status}> <CachedIcon fontSize="small" color="primary" /> </Tooltip>}
                                {any.status === "Failure" && <Tooltip title={any.status}> <WarningAmberIcon fontSize="small" color="warning" /> </Tooltip>}
                                {any.status === "Deleted" && <Tooltip title={any.status}> <RemoveCircleOutlineIcon fontSize="small" color="error" /> </Tooltip>}
                                {date}
                                <span style={{ color: "#000" }}>
                                  ({any.cortexId})
                                </span>
                              </Box>
                            </Typography>
                          );
                        })}
                      </Collapse>
                    </td>
                    <td style={{ padding: "12px", verticalAlign: "top" }}>
                      <IconButton
                        size="small"
                        sx={{
                          bgcolor: "warning.main",
                          "& svg:hover": { color: "warning.main" },
                        }}
                        onClick={ReRunAnalyzer(analysers[res][0])}
                      >
                        <RefreshIcon
                          fontSize="small"
                          sx={{ color: "#fff" }}
                        />
                      </IconButton>
                    </td>
                  </tr>


                ))}
                {/* None Runing Analyzers */}
                {runableAnalyzers.map(analyser => (
                  <tr
                    style={{
                      border: "1px solid #ECF0F4",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <td
                      style={{ padding: "12px", verticalAlign: "text-top" }}
                    >

                      {analyser.name}
                    </td>

                    <td style={{ padding: "12px", verticalAlign: "top" }}>
                      <Typography component="i" variant="caption">None</Typography>
                    </td>
                    <td style={{ padding: "12px", verticalAlign: "top" }}>
                      <IconButton
                        size="small"
                        sx={{
                          bgcolor: "primary.main",
                          "& svg:hover": { color: "error.dark" },
                        }}
                        onClick={ReRunAnalyzer({ analyzerDefinition: analyser.name, cortexId: analyser.cortexIds[0] }, true)}
                      >
                        <LocalFireDepartmentIcon
                          fontSize="small"
                          color="error"
                        />
                      </IconButton>
                    </td>
                  </tr>
                ))}
                {/* Reports */}
                {analyzerLoader && <Box width="100%"><LinearProgress /></Box>}
                {analyzerReport.id && !analyzerLoader && analyzerReport.report?.full &&
                  < tr
                    style={{
                      marginTop: "15px",
                    }}
                    ref={reportRef}
                  >
                    <td component={"td"} colSpan={3}>
                      <Typography variant="h6" fontWeight={700}>Report</Typography>
                    </td>
                  </tr>}
                {analyzerReport.id && !analyzerLoader && analyzerReport.report?.full &&
                  <tr
                    style={{
                      marginTop: "10px",
                      display: analyzerReport.id ? "table-row" : "none",
                    }}
                  >
                    <Collapse
                      component={"td"}
                      colSpan={3}
                      unmountOnExit
                      in={analyzerReport.id}


                    >
                      <Box
                        sx={{ "& .react-json-view": { borderRadius: 1 } }}
                      >
                        <ReactJson
                          collapsed={false}
                          src={analyzerReport.report?.full}
                          theme="monokai"
                          displayDataTypes={false}
                          enableClipboard
                        />
                      </Box>
                    </Collapse>
                  </tr>}
              </tbody>
            </table>

          </Grid>
        </Grid>
      )
      }
    </Grid >
  );
};

const mapStateToProps = (state) => {
  return {
    analyzers: state.analyzers,

  };
};
export default connect(mapStateToProps, null)(ObservablesDetail);
