import * as actionTypes from "../actions/actionTypes";
// import { SET_DF_VERISON } from "../actions/actions";
import { updateObject } from "../utils";


const initialPermissions = {
  mOrg: true,
  mPrf: true,
  mCases: true,
  mObs: true,
  mAlerts: true,
  mUsers: true,
  mTasks: true,
  mAnlys: true,
};
export const user_enums = {
  ADMIN: "org-admin",
  READONLY: "read-only",
  ANALYST: "analyst"
}
const initialStates = {
  loading: false,
  error: null,
  mode: "light",
  DF_version: {},
  casesCount: 0,
  tasksCount: 0,
  alertsCount: 0,
  ams_user: null,
  cms_user: null,
  permissions: initialPermissions,
  enableOtp: false,
  brand: {},
  vulnerabilities: false,
  dashboards: false,
  userType: null,
  analyzers: []
};

export const setCaseCount = (state, action) => {
  return updateObject(state, {
    casesCount: action.count,
  });
};
export const setTasksCount = (state, action) => {
  return updateObject(state, {
    tasksCount: action.count,
  });
};
export const setAlertsCount = (state, action) => {
  return updateObject(state, {
    alertsCount: action.count,
  });
};
export const setBrandDetails = (state, action) => {
  const screens = action.brand?.modules;
  localStorage.setItem("brand", JSON.stringify(action.brand));

  let vulnerability = false;
  let dashboards = false;
  if (Array.isArray(screens)) {
    vulnerability = screens.find(screen => screen.toLowerCase() === "vulnerabilities") ? true : false;
    dashboards = screens.find(screen => screen.toLowerCase() === "dashboards") ? true : false;
  }
  return updateObject(state, {
    brand: action.brand,
    vulnerabilities: vulnerability,
    dashboards: true
  });
};
export const setMeta = (state, action) => {
  return updateObject(state, {
    analyzers: action.analyzers,
  });
};
export const authStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};
export const setPermissions = (state, action) => {
  return updateObject(state, {
    permissions: action.permissions,
  });
};
export const enableOTP = (state, action) => {
  return updateObject(state, {
    loading: false,
    enableOtp: action.enableOtp,
  });
};

export const authSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    ams_user: action.ams_user,
    cms_user: action.cms_user,
  });
};
export const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    ams_user: null,
    cms_user: null,
  });
};
export const authLogout = (state, action) => {
  return updateObject(state, {
    ams_user: null,
    cms_user: null,
  });
};
export const setMode = (state) => {
  return updateObject(state, {
    mode: state.mode === "light" ? "dark" : "light",
  });
};
export const setWazuhVersion = (state, action) => {
  localStorage.setItem("DF_version", JSON.stringify(action.DF_version));
  return updateObject(state, {
    DF_version: action.DF_version,
  });
};
const reducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.SET_META:
      return setMeta(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_BRAND:
      return setBrandDetails(state, action);
    case actionTypes.SET_PERMISSIONS:
      return setPermissions(state, action);
    case actionTypes.SET_MODE:
      return setMode(state);
    case actionTypes.SET_DF_VERISON:
      return setWazuhVersion(state, action);
    case actionTypes.SET_CASES_COUNT:
      return setCaseCount(state, action);
    case actionTypes.SET_TASKS_COUNT:
      return setTasksCount(state, action);
    case actionTypes.SET_ALERTS_COUNT:
      return setAlertsCount(state, action);
    case actionTypes.ENABLE_OTP:
      return enableOTP(state, action);
    default:
      return initialStates;
  }
};
export default reducer;
