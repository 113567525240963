import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import InputField from "../../components/Field/BootStrap/InputField";
import { Badge } from "react-bootstrap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import AppleIcon from "@mui/icons-material/Apple";
import WindowIcon from "@mui/icons-material/Window";
import AndroidIcon from "@mui/icons-material/Android";
import cms from "../../config/axiosConfig";
import { useState } from "react";
import { useEffect } from "react";
import noProfileImage from "../../static/image/noProfile.png";
import CancelIcon from "@mui/icons-material/Cancel";
import userapi from "../../config/userConfig";
import { PasswordValidator } from "../../utils/utils";

function Settings() {
  const permissionPill_colors = [
    "#90C8FF",
    "#B0FFF6",
    "#CB98FF",
    "#F6C1FF",
    "#FFCB9B",
    "#FFD464",
  ];
  const CheckPasswordModify = () => {
    const currentpassword = document.getElementById("currentPassword")?.value;
    const password = document.getElementById("password")?.value;
    const confirmpassword = document.getElementById("confirmpassword")?.value;
    if (currentpassword && password && confirmpassword) {
      setIsPasswordModified(true);
    } else {
      setIsPasswordModified(false);
    }
  };

  const [data, setData] = useState({ data: {}, loading: false });
  const [userData, setUserData] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [isPasswordModified, setIsPasswordModified] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  // const [apiKey, setApikey] = useState("");

  const initial_MFAFields = {
    data: { secret: "", url: "" },
    loading: false,
    open: false,
  };
  const [MFAAuth, setMFA] = useState(initial_MFAFields);

  function handleUserDataChange(event) {
    const { name, value } = event.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
    // Check if value is different from original data
    setIsModified(value !== data.data[name]);
  }

  function handleProfileChange(event) {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileSizeKB = selectedFile.size / 1024; // Convert bytes to kilobytes
      console.log(fileSizeKB)
      // Check if the file size is greater than 500 KB
      if (fileSizeKB > 500) {
        toast.error("File size exceeds the allowed limit of 500 KB.");
        return;
      }

      // Check if the file type is PNG
      if (selectedFile.type === "image/png") {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
              const jpegFile = new File([blob], "avatar.jpg", { type: "image/jpeg" });
              handleConvertedFile(jpegFile);
            }, "image/jpeg");
          };
          img.src = e.target.result;
        };

        reader.readAsDataURL(selectedFile);
      } else if (selectedFile.type === "image/jpeg") {
        handleConvertedFile(selectedFile);
      } else {
        toast.error("Only PNG or JPEG images are allowed.");
      }
    }
  }

  function handleConvertedFile(file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64String = e.target.result;
      setSelectedAvatar(base64String);
    };

    reader.readAsDataURL(file);
    setIsModified(true);
  }

  function ClearProfile(event) {
    setSelectedAvatar(null);
    setIsModified(userData.avatar && true);
    const _data = data.data;
    delete _data["avatar"];
    setData((pre) => ({ ...pre, data: _data }));
  }
  function handleRevertModification() {
    setUserData(data.data);
    setIsModified(false);
    setSelectedAvatar(null);
  }

  async function FetchCurrentUser() {
    setData((pre) => ({ ...pre, loading: true }));
    await userapi
      .get("/getuserdata")
      .then((res) => {
        const _user = res.data?.data?.cms_user;
        setData((pre) => ({ ...pre, data: _user }));
        setUserData(_user);
      })
      .catch((error) => {
        toast.error(error);
        setData((pre) => ({ ...pre, loading: false }));
      });
  }
  async function GetMFAAuth() {
    setMFA((pre) => ({ ...pre, loading: true, open: false }));
    await cms
      .post("/cms/api/v1/auth/totp/set", {})
      .then((res) => {
        setMFA((pre) => ({
          ...pre,
          data: res.data,
          loading: false,
          open: true,
        }));
      })
      .catch((error) => {
        toast.error(error);
        setMFA((pre) => ({ ...pre, loading: false }));
      });
  }
  async function MFARequest(code, action = "set") {
    setMFA((pre) => ({ ...pre, loading: true, open: false }));
    let _data = {};
    if (code) {
      _data["code"] = code;
    }
    await cms
      .post(`/cms/api/v1/auth/totp/${action}`, _data)
      .then((res) => {
        if (res.status === 204 || res.status === 200) {
          toast.success("MFA authentication is active");
          FetchCurrentUser();
        }

        setMFA((pre) => ({ ...pre, loading: false }));
      })
      .catch((error) => {
        toast.error(error);
        setMFA((pre) => ({ ...pre, loading: false }));
      });
  }
  const UpdateField = async (_fields, reload_page = false) => {
    // .patch(`/cms/api/v1/user/${data.data._id}`, _fields)
    await userapi
      .patch(`/updateuser/${data.data._id}`, _fields)
      .then((res) => {
        if (res.status === 204) {
          toast.success("Profile updated successfuly");
          if (reload_page) {
            window.location.reload();
          }
        }
        setIsModified(false);
        FetchCurrentUser();
      })
      .catch((error) => {
        toast.error(error.response?.data?.error);
      });
  };
  const handleBasicFieldUpdate = () => {
    let _data = { name: userData.name };
    if (selectedAvatar) {
      _data["avatar"] = selectedAvatar.replace("data:image/jpeg;base64,", "");
    }
    UpdateField(_data, selectedAvatar);
  };
  const handlePasswodUpdate = () => {
    if (isPasswordModified) {
      const currentpassword = document.getElementById("currentPassword")?.value;
      const password = document.getElementById("password")?.value;
      const confirmpassword = document.getElementById("confirmpassword")?.value;
      if ((password !== confirmpassword && password.length <= 8)) {
        toast.error("Confirm password not math or too short.");
      } else {
        console.log(PasswordValidator(password))
        if (!PasswordValidator(password)) {
          toast.error("The password must contain at least one upper and lower case letter, a number and a symbol, and should be minimum 8 characters long.");
          return;
        }
        UpdateField({ currentPassword: currentpassword, password: password });
      }
    }
  };
  // const createKey = (id) => {
  //   cms.post(`/cms/api/v1/user/${id}/key/renew`).then((res) => {
  //     if (res.status === 200) {
  //       toast.info("key created successfully");
  //       FetchCurrentUser();
  //     }
  //   });
  // };
  // const revealKey = (id) => {
  //   cms.get(`/cms/api/v1/user/${id}/key`).then((res) => {
  //     if (res.status === 200) {
  //       setApikey(res.data);
  //     }
  //   });
  // };
  // const revokeKey = (id) => {
  //   cms.delete(`/cms/api/v1/user/${id}/key`).then((res) => {
  //     toast.info("key revoked");
  //     FetchCurrentUser();
  //   });
  // };

  useEffect(() => {
    FetchCurrentUser();
  }, []);

  return (
    <Container>
      <Box>
        <Typography variant="h5" fontWeight="bold">
          {"Account Settings"}
        </Typography>
        <Typography color="#A7BBCE" variant="caption" paragraph>
          {"Dashboard > Task Listing"}
        </Typography>
        {/* Update basic information */}
        <Box
          display="flex"
          flexDirection="column"
          component={Paper}
          p="29px 22px"
          sx={{ borderRadius: "10px" }}
        >
          <Typography variant="h6" fontWeight="bold">
            {"Update basic information"}
          </Typography>
          {/* Image Box */}
          <Box display="flex" mt={5.25} gap={2.5} alignItems="center">
            <Box
              borderRadius="17px"
              width={88}
              bgcolor="#D6DEE5"
              position="relative"
            >
              <IconButton
                onClick={ClearProfile}
                sx={{ zIndex: 1, position: "absolute", top: -18, left: -20 }}
              >
                <CancelIcon color="error" fontSize="small" />
              </IconButton>
              <label htmlFor="avatarInput" className="w-100 h-100">
                <input
                  id="avatarInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleProfileChange}
                />
                <Box
                  component="img"
                  borderRadius="10px"
                  src={
                    selectedAvatar
                      ? selectedAvatar
                      : userData.avatar
                        ? window.location.origin + `/cms${userData.avatar}`
                        : noProfileImage
                  }
                  alt="profile pic"
                  className="w-100"
                />
              </label>
            </Box>
            <Box>
              <ButtonBase component="label">
                <Box
                  type="file"
                  p="9px 16px"
                  color="#fff"
                  fontWeight={500}
                  sx={{ borderRadius: "55px", mr: 1, bgcolor: "success.dark" }}
                >
                  Edit Image
                </Box>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleProfileChange}
                />
              </ButtonBase>
              <Tooltip title="Add profile pic">
                <InfoRoundedIcon fontSize="small" sx={{ color: "#D6DEE5" }} />
              </Tooltip>
            </Box>
          </Box>
          <Grid container mt={2.5} spacing={2.5}>
            <Grid item container xs={6} spacing={1.25} direction="column">
              <Grid item xs>
                <Typography fontSize={"14px"} fontWeight="bold">
                  Full Name*
                </Typography>
              </Grid>
              <Grid item xs>
                <InputField
                  name="name"
                  value={userData.name || ""}
                  onChange={handleUserDataChange}
                  fullWidth
                  placeholder="Type Full name here..."
                />
              </Grid>
            </Grid>
            <Grid item container xs={6} spacing={1.25} direction="column">
              <Grid item xs>
                <Typography fontSize={"14px"} fontWeight="bold">
                  Username*
                </Typography>
              </Grid>
              <Grid item xs>
                <InputField
                  readOnly
                  fullWidth
                  sx={{ "& input": { cursor: "no-drop" } }}
                  name="login"
                  value={userData.login || ""}
                  onChange={handleUserDataChange}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3.75}>
              <Grid item>
                <Typography fontSize={"14px"} fontWeight="bold">
                  Account States
                </Typography>
              </Grid>
              <Grid item xs container spacing={1.25}>
                <Grid item xs={12}>
                  <Typography fontSize={"14px"}>{data.data.profile}</Typography>
                </Grid>
                {data.data?.permissions?.map((p, i) => (
                  <Grid item key={p}>
                    <Box
                      component={Badge}
                      pill
                      text="dark"
                      sx={{
                        backgroundColor: `${permissionPill_colors[i > 5 ? Math.ceil(i / 2) : i]
                          } !important`,
                        fontSize: 10,
                        fontWeight: 400,
                      }}
                    >
                      {p}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {/* Footer Buttons */}
          <Box display="flex" justifyContent="space-between" mt={7.5}>
            <Button
              variant="outlined"
              disabled={!isModified}
              sx={{ minWidth: 88 }}
              onClick={handleRevertModification}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!isModified}
              color="primary"
              sx={{ minWidth: 88 }}
              onClick={handleBasicFieldUpdate}
            >
              Save
            </Button>
          </Box>
        </Box>
        {/* Update Password */}
        <Box
          display="flex"
          flexDirection="column"
          component={Paper}
          p="29px 22px"
          mt={3.75}
          sx={{ borderRadius: "10px" }}
        >
          <Typography variant="h6" fontWeight="bold">
            {"Update password"}
          </Typography>

          <Grid
            container
            width={{ md: "50%", xs: "100%" }}
            direction="column"
            mt={2.5}
            spacing={1.5}
          >
            <Grid item xs>
              <Typography fontSize={"14px"} fontWeight="bold">
                Old password*
              </Typography>
            </Grid>
            <Grid item xs>
              <InputField
                fullWidth
                onChange={CheckPasswordModify}
                type="password"
                placeholder="Current Password"
                inputProps={{ id: "currentPassword" }}
              />
            </Grid>
            <Grid item xs>
              <Typography fontSize={"14px"} fontWeight="bold">
                New password*
              </Typography>
            </Grid>
            <Grid item xs>
              <InputField
                onChange={CheckPasswordModify}
                fullWidth
                placeholder="New Password"
                inputProps={{ id: "password" }}
              />
            </Grid>
            <Grid item xs>
              <Typography fontSize={"14px"} fontWeight="bold">
                Confirm new password*
              </Typography>
            </Grid>
            <Grid item xs>
              <InputField
                onChange={CheckPasswordModify}
                fullWidth
                placeholder="Confirm Password"
                inputProps={{ id: "confirmpassword" }}
              />
            </Grid>
          </Grid>
          {/* Footer Buttons */}
          <Box display="flex" justifyContent="space-between" mt={7.5}>
            <Button variant="outlined" sx={{ minWidth: 88 }}>
              Cancel
            </Button>
            <Button
              disabled={!isPasswordModified}
              variant="contained"
              color="primary"
              sx={{ minWidth: 88 }}
              onClick={handlePasswodUpdate}
            >
              Update Password
            </Button>
          </Box>
        </Box>
        {/* API Key */}
        {/* <Box
          display="flex"
          flexDirection="column"
          component={Paper}
          p="29px 22px"
          mt={3.75}
          sx={{ borderRadius: "10px" }}
        >
          <Typography variant="h6" fontWeight="bold" paragraph>
            {"API Key"}
          </Typography>
          {data.data.hasKey && (
            <Typography color="#A7BBCE" variant="caption">
              {"You have an API key defined. You can renew it if needed."}
            </Typography>
          )} */}
        {/* {data.data.hasKey && (
            <InputGroup className="mb-3 mt-3 rounded">
              <Box
                component={InputGroup.Text}
                sx={{ color: "primary.main" }}
                className="bg-transparent "
                id="inputGroup-sizing-default"
              >
                <ButtonBase sx={{ borderRadius: 0 }}>Renew</ButtonBase>
              </Box>
              <Form.Control
                value={"lv1Cx1MFBvn1gJargsCGbts4E/2L7K/F"}
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
              />
              <CopyToClipboard
                text={"1v1Cx1MFBvn1gJargsCGbts4E/2L7K/F"}
                onCopy={() =>
                  toast.info("1v1Cx1MFBvn1gJargsCGbts4E/2L7K/F is copied!")
                }
              >
                <Box
                  component={InputGroup.Text}
                  sx={{ bgcolor: "primary.main", cursor: "pointer" }}
                  className="text-light"
                  id="inputGroup-sizing-default"
                >
                  <ContentCopyIcon fontSize="small" />
                  Copy
                </Box>
              </CopyToClipboard>
            </InputGroup>
          )} */}
        {/* {data.data?.hasKey ? (
            <ButtonGroup
              className="mb-3 mt-3"
              variant="outlined"
              color="primary"
            >
              <Button
                color="secondary"
                onClick={() => {
                  createKey(data.data?._id);
                }}
              >
                Renew
              </Button>
              {!apiKey ? (
                <Button
                  onClick={() => {
                    revealKey(data.data?._id);
                  }}
                >
                  Reveal
                </Button>
              ) : (
                <TextField
                  size="small"
                  value={apiKey}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                        <CopyToClipboard
                          text={apiKey}
                          onCopy={() => {
                            toast.info("key copied");
                            setApikey("");
                          }}
                        >
                          <ContentCopyIcon color="primary" fontSize="small" />
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ButtonGroup>
          ) : (
            <Button
              onClick={() => {
                createKey(data.data._id);
              }}
              sx={{ bgcolor: "#ECF0F4", color: "primary.main", height: "26px" }}
            >
              Create API Key
            </Button>
          )}
        </Box> */}
        {/* 2 Fector authentication */}
        <Box
          display="flex"
          flexDirection="column"
          component={Paper}
          p="29px 22px"
          mt={3.75}
          sx={{ borderRadius: "10px" }}
        >
          <Typography variant="h6" fontWeight="bold" paragraph>
            <Checkbox
              disabled={data.data?.hasMFA}
              checked={MFAAuth.open || data.data?.hasMFA}
              onClick={(e) => {
                setMFA((pre) => ({ ...pre, open: !pre.open }));
                if (e.target.checked) {
                  GetMFAAuth();
                }
              }}
            />
            {"Enable Multi-Factor Authentication"}
          </Typography>
          {MFAAuth.loading ? (
            <CircularProgress />
          ) : !data.data?.hasMFA ? (
            <Collapse in={MFAAuth.data.secret && MFAAuth.open} unmountOnExit>
              <Box>
                <Typography color="#A7BBCE" variant="caption">
                  {
                    "Your are going to enable Multi-Factor Authentication. Use the QRCode or the Secret to generate a MFA code and submit it."
                  }
                </Typography>
                <Grid container gap={2.5} mt={2.5}>
                  <Grid item>
                    <Box
                      width={320}
                      height={320}
                      p={5}
                      bgcolor="#D6DEE5"
                      borderRadius={"10px"}
                    >
                      <QRCode
                        size={320}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                          background: "transparent",
                        }}
                        value={MFAAuth.data.uri}
                        viewBox={`0 0 320 320`}
                      />
                    </Box>
                  </Grid>
                  <Grid item container xs direction="column" spacing={"22px"}>
                    <Grid item>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="h5" fontWeight={400}>
                          {MFAAuth.data.secret}
                        </Typography>
                        <CopyToClipboard
                          text={MFAAuth.data.secret}
                          onCopy={() =>
                            toast.info(`${MFAAuth.data.secret} is copied!`)
                          }
                        >
                          <Button
                            variant="contained"
                            size="small"
                            sx={{ bgcolor: "#000000", ml: 1.25 }}
                            startIcon={<ContentCopyIcon fontSize="small" />}
                          >
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </Box>
                    </Grid>
                    <Grid item container direction="column" spacing={1.25}>
                      <Grid item>
                        <Typography fontSize={"14px"} fontWeight="bold">
                          Code
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <InputField
                          type="number"
                          fullWidth
                          placeholder="code"
                          id="mfaCode"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              MFARequest(parseInt(e.target.value), "set");
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* Download App */}
                    <Grid item container>
                      <Grid
                        container
                        item
                        direction="column"
                        bgcolor={"#F9FAFA"}
                        borderRadius="12px"
                        p="12px 17px"
                        spacing={1.25}
                      >
                        <Grid item>
                          <Typography variant="caption">
                            Need a two-step authenticator app? Download one of
                            the following:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box display="flex" gap={1}>
                            <AppleIcon />
                            <Typography variant="caption" fontWeight={500}>
                              {"iOS devices:"}
                            </Typography>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ bgcolor: "primary.light", height: 24 }}
                            >
                              Authy
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box display="flex" gap={1}>
                            <AndroidIcon />
                            <Typography variant="caption" fontWeight={500}>
                              Android devices:
                            </Typography>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ bgcolor: "primary.light", height: 24 }}
                            >
                              Authy
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box display="flex" gap={1}>
                            <WindowIcon />
                            <Typography variant="caption" fontWeight={500}>
                              Windows devices:
                            </Typography>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ bgcolor: "primary.light", height: 24 }}
                            >
                              Authy
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Box display="flex" alignItems="end" className="h-100">
                      <Button
                        onClick={() => {
                          MFARequest(
                            parseInt(document.getElementById("mfaCode")?.value),
                            "set"
                          );
                        }}
                        variant="contained"
                        color="primary"
                        sx={{ minWidth: 88 }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          ) : (
            <Collapse in={data.data?.hasMFA} unmountOnExit>
              <Box
                display="flex"
                flexDirection="column"
                gap={2.5}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="caption">
                  Your have enabled Multi-Factor Authentication, do you want to
                  disabled it?
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    MFARequest(false, "unset");
                  }}
                >
                  Disable MFA
                </Button>
              </Box>
            </Collapse>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default Settings;
