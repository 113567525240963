import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  Snackbar,
  Typography,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactScrollbar from "react-scrollbar";
import { severity_enum } from "../../Cases/enums/enums";
import { useState } from "react";
import numberIcon from "../../../static/icons/numberIcon.svg";
import calenderIcon from "../../../static/icons/calenderIcon.svg";
import tagIcon from "../../../static/icons/tagIcon.svg";
import chainIcon from "../../../static/icons/chainIcon.svg";
import folderIcon from "../../../static/icons/folderIcon.svg";
import MarkDownTable from "../../../components/Display/MarkDownTable/MarkDownTable";
import PestControlIcon from "@mui/icons-material/PestControl";
import CustomObservablesRow from "../../Cases/components/CustomObservablesRow";
import CustomTable from "../../../components/Display/CustomTable";
import InputField from "../../../components/Field/BootStrap/InputField";
import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CallMergeIcon from "@mui/icons-material/CallMerge";

import DeleteIcon from "@mui/icons-material/Delete";
import { formatCreatedAt } from "../../../utils/utils";
import DeleteAlertDialog from "./DeleteAlertDialog";
import AlertMergeDialog from "./AlertMergeDialog";
import { useNavigate } from "react-router-dom";

const ObservablesTab = ({ data, onPageChange, pagination, count, details }) => {
  const columns = [
    { id: "flag", label: "Flags", sortable: false },
    { id: "dataType", label: "Type", sortable: true },
    { id: "data", label: "Value/Filename", sortable: true },
    { id: "_createdAt", label: "Dates", sortable: true },
  ];
  const navigation = useNavigate();
  return (
    <Box mt={5}>
      <Typography variant="h6" fontSize={700} paragraph>
        List of observables ({data.length} of {count})
      </Typography>
      <CustomTable
        columns={columns}
        checkboxies={false}
        data={data}
        CustomTableRow={CustomObservablesRow}
        onDetailOpen={(id, data) => {
          navigation(
            `/case-detail/${details.caseId.replace(
              "~",
              ""
            )}?page=2&observable=${id}`
          );
        }}
        loading={false}
        onPageChange={onPageChange}
        page={pagination.page}
        total_count={Math.ceil(count / pagination.per_page)}
      />
    </Box>
  );
};

export default function AlertDetaildialog({
  open,
  setOpen,
  data,
  obervables,
  createCaseFromAlert,
  followORunfollowAlert,
  readOrunReadAlert,
  deleteDialog,
  openDeleteDialog,
  refrashFunc,
  permissions,
  searchParams,
  setSearchParams,
  navigator
}) {
  const handleClose = () => {
    setOpen(false);

    navigator(window.location.pathname)
  };
  const initialPagination = { page: 1, per_page: 15 };
  const [pagination, setPagination] = useState(initialPagination);
  const [mergeBox, showMergeBox] = useState(false);
  const onPageChange = (e, new_page) => {
    setPagination((pre) => ({ ...pre, page: new_page }));
  };
  const [page, setPage] = useState(0);
  const handlePage = (p) => () => {
    setPage(p);
  };
  const [alert, setAlert] = useState({
    show: false,
    severity: "success",
    text: "",
  });

  // andle Submit

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

  }, [open]);
  React.useEffect(() => {
    const preview = searchParams.get("preview", null);
    if (open && preview !== data._id) {
      let _updatedParams = searchParams;
      setSearchParams((params) => {
        const updatedParams = new URLSearchParams(params);
        updatedParams.set("preview", data._id);
        _updatedParams = updatedParams;
        return updatedParams;
      });
      navigator(`?${_updatedParams.toString()}`)
    }
  }, [data._id])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          //   maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight={700}>
            Alert Preview
          </Typography>
          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "80vh" }}>
        <DialogContent>
          <Snackbar
            open={alert.show}
            autoHideDuration={6000}
            onClose={() => {
              setAlert((pre) => ({ ...pre, show: false }));
            }}
          >
            <Alert
              onClose={() => {
                setAlert((pre) => ({ ...pre, show: false }));
              }}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {alert.text}
            </Alert>
          </Snackbar>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" fontWeight={500}>
                {/* <Box
                  component="img"
                  src={severity_enum[data?.severity]?.icon}
                  alt="priority"
                  width={20}
                  height={20}
                  mr={"5px"}
                /> */}
                {typeof severity_enum[data?.severity]?.icon === "string" ? (
                  <img
                    src={severity_enum[data?.severity]?.icon}
                    alt={`Icon for ${severity_enum[data?.severity].value}`}
                    width={20}
                    height={20}
                  />
                ) : (
                  severity_enum[data?.severity]?.icon
                )}
                {severity_enum[data?.severity]?.value}
              </Typography>
              <Typography fontSize={19} fontWeight={600} color="#000" mt={2}>
                {data?.title}
              </Typography>
              {/* states */}
              <Box
                mt={2.15}
                display="flex"
                className="w-100"
                borderRadius={"10px"}
                height="50px"
                sx={{ border: "1px solid #ECF0F4" }}
                justifyContent="space-around"
                alignItems="center"
                p="16px 20px"
              >
                <Box display="flex" alignItems="center" gap={"5px"}>
                  <Box
                    component="img"
                    src={numberIcon}
                    width={18}
                    height={18}
                    alt="id"
                  />
                  <Typography
                    variant="caption"
                    color="#869EB6"
                    fontWeight={500}
                  >
                    ID:
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      sx={{ color: "primary.light" }}
                    >
                      {data._id}
                    </Typography>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={"5px"}>
                  <Box
                    component="img"
                    src={calenderIcon}
                    width={18}
                    height={18}
                    alt="id"
                  />
                  <Typography
                    variant="caption"
                    color="#869EB6"
                    fontWeight={500}
                  >
                    Date:{" "}
                    <Typography
                      variant="caption"
                      fontWeight={700}
                      sx={{ color: "#000" }}
                    >
                      {formatCreatedAt(data._createdAt)}
                    </Typography>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={"5px"}>
                  <Box
                    component="img"
                    src={tagIcon}
                    width={18}
                    height={18}
                    alt="id"
                  />
                  <Typography
                    variant="caption"
                    color="#869EB6"
                    fontWeight={500}
                  >
                    Type:{" "}
                    <Typography
                      variant="caption"
                      fontWeight={700}
                      sx={{ color: "#000" }}
                    >
                      {data.type}
                    </Typography>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={"5px"}>
                  <Box
                    component="img"
                    src={chainIcon}
                    width={18}
                    height={18}
                    alt="id"
                  />
                  <Typography
                    variant="caption"
                    color="#869EB6"
                    fontWeight={500}
                  >
                    Reference:{" "}
                    <Typography
                      variant="caption"
                      fontWeight={700}
                      sx={{ color: "#000" }}
                    >
                      {data.sourceRef}
                    </Typography>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={"5px"}>
                  <Box
                    component="img"
                    src={folderIcon}
                    width={18}
                    height={18}
                    alt="id"
                  />
                  <Typography
                    variant="caption"
                    color="#869EB6"
                    fontWeight={500}
                  >
                    Source:
                    <Typography
                      variant="caption"
                      fontWeight={700}
                      sx={{ color: "#000" }}
                    >
                      {data.source}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              {/* Basic Information */}
              <Box display="flex" flexDirection="column" mt={5} gap={1.25}>
                <Typography variant="h6" fontWeight="bold" color="#000">
                  Basic Information
                </Typography>
                <Box>
                  <Typography
                    fontWeight={400}
                    fontSize="1.125rem"
                    color="#A7BBCE"
                  >
                    Tags
                  </Typography>

                  <Grid mt={1.25} container spacing={1.25} maxWidth={"100%"}>
                    {data?.tags?.map((tag, i) => (
                      <Grid item key={tag + i}>
                        <Box
                          sx={{
                            height: "20px",
                            borderRadius: "2px 0px 0px 2px",
                            borderLeft: "5px solid #13D38E",
                            width: "fit-content",
                          }}
                          display="flex"
                          alignItems="center"
                          p="10px"
                          gap="4px"
                          bgcolor={"#ECF0F4"}
                        >
                          {tag}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
              {/* Tables */}
              <Typography variant="h6" fontWeight={700} color="#000" mt={5}>
                Description
              </Typography>
              <Box mt={1.25}>
                <MarkDownTable maxWidth={467}>{data.description}</MarkDownTable>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={2.5}>
                <Box
                  display="flex"
                  gap={1.25}
                  sx={{
                    width: "446px",
                    p: "5px",
                    backgroundColor: "#ECF0F4",
                    borderRadius: "55px",
                    "& .MuiButtonBase-root": {
                      borderRadius: "52px",
                      color: "#000",
                    },
                  }}
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    variant="text"
                    sx={
                      page === 0 && {
                        bgcolor: "#003272",
                        color: "#fff !important",
                        "&:hover": {
                          bgcolor: "#1173FF",
                        },
                      }
                    }
                    fullWidth
                    disableFocusRipple
                    disableTouchRipple
                    onClick={handlePage(0)}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="space-around"
                    >
                      <Box>
                        <PestControlIcon fontSize="small" sx={{ mr: 1.25 }} />
                        <span>Observables</span>
                      </Box>
                      <Chip
                        label={data.observableCount}
                        size="small"
                        sx={{
                          bgcolor: "#FF3E3E",
                          color: "#fff",
                          height: "22px",
                          minWidth: "34px",
                        }}
                      />
                    </Box>
                  </Button>
                  <Button
                    variant="text"
                    fullWidth
                    sx={
                      page === 1 && {
                        bgcolor: "#003272",
                        color: "#fff !important",
                        "&:hover": {
                          bgcolor: "#1173FF",
                        },
                      }
                    }
                    disableFocusRipple
                    disableTouchRipple
                    onClick={handlePage(1)}
                  >
                    <Box>
                      <Box
                        component="img"
                        src={chainIcon}
                        alt="any"
                        width={18}
                        height={18}
                      />
                      <span>Similar Cases</span>
                    </Box>
                  </Button>
                </Box>
                <Box>
                  <Typography
                    mr={1.25}
                    variant="caption"
                    fontWeight={500}
                    color="textPrimary"
                  >
                    Per Pages
                  </Typography>

                  <InputField
                    type="number"
                    value={pagination.per_page}
                    onChange={(e) =>
                      setPagination((pre) => ({
                        ...pre,
                        per_page: e.target.value,
                      }))
                    }
                    sx={{ maxWidth: 121 }}
                  />
                </Box>
              </Box>
              <Collapse in={page === 0}>
                <ObservablesTab
                  onPageChange={onPageChange}
                  pagination={pagination}
                  data={obervables}
                  details={data}
                  count={data.observableCount}
                />
              </Collapse>
            </Box>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>

      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          // display={data.caseId && !permissions.mAlerts ? "none" : "flex"}
          display={!permissions.mAlerts || data.caseId ? "none" : "flex"}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" gap={2}>
            <Button
              startIcon={
                data.read ? (
                  <DraftsIcon fontSize="small" />
                ) : (
                  <MailIcon fontSize="small" />
                )
              }
              variant={"primary"}
              id="flag_btn"
              onClick={() => {
                readOrunReadAlert(
                  data._id,
                  data.read ? "markAsUnread" : "markAsRead"
                );
              }}
              sx={{
                color: "#fff",
                borderRadius: "5px",
                bgcolor: "#13D38E",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              {data.read ? "Make as unRead" : "Mark as read"}
            </Button>
            <Button
              startIcon={
                data.follow ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <VisibilityIcon fontSize="small" />
                )
              }
              variant={"primary"}
              id="flag_btn"
              onClick={() => {
                followORunfollowAlert(
                  data._id,
                  data.follow ? "unfollow" : "follow"
                );
              }}
              sx={{
                borderRadius: "5px",
                color: "#fff",
                bgcolor: "#FF6B00",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              {data.follow ? "Ignore new update" : "get new updates"}
            </Button>
            <Button
              onClick={() => showMergeBox(true)}
              startIcon={<CallMergeIcon fontSize="small" />}
              variant={"primary"}
              id="flag_btn"
              sx={{
                borderRadius: "5px",
                color: "#fff",
                bgcolor: "#0D2A56",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              {"Merge into case"}
            </Button>
            <Button
              onClick={() => openDeleteDialog(true)}
              startIcon={<DeleteIcon fontSize="small" />}
              variant={"primary"}
              id="flag_btn"
              sx={{
                borderRadius: "5px",
                color: "#fff",
                bgcolor: "error.main",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              {"Delete"}
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            flexGrow={1}
            alignItems="center"
            gap={0.5}
          >
            <Button
              variant={"primary"}
              id="flag_btn"
              onClick={() => {
                createCaseFromAlert(data._id);
              }}
              sx={{
                minWidth: 120,
                borderRadius: "5px",
                color: "#fff",
                bgcolor: "primary.main",
                "&:hover": {
                  color: "black",
                },
              }}
            >
              {"Import as Case"}
            </Button>
          </Box>
        </Box>
      </DialogActions>
      {/* Other Dialogs */}
      <AlertMergeDialog
        open={mergeBox}
        setOpen={showMergeBox}
        alertId={data._id}
        alertName={data.title}
      />
      {/* Delete  */}
      <DeleteAlertDialog
        open={deleteDialog}
        setOpen={openDeleteDialog}
        alertid={data._id}
        refrashFunc={refrashFunc}
      />
    </Dialog>
  );
}
