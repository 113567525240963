import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";
import InputField from "../../../components/Field/BootStrap/InputField";

import Btn from "../../../components/Buttons/BootStrap/Btn";
import PlusIcon from "../../../static/icons/plus-icon.svg";
import ReactScrollbar from "react-scrollbar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CreateOrganizationDialog({
  open,
  setOpen,
  initial_fields = { name: "", description: "" },
  or_name = "",
  update = false,
  resetFields,
}) {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const [fields, setFields] = useState(initial_fields);

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields((pre) => ({ ...pre, [name]: value }));
  };

  // andle Submit
  const validate = () => {
    let valid = true;
    let message = "";
    if (!fields.description) {
      message = "Write description is required";
      valid = false;
    } else if (!fields.name) {
      message = "Write title is required";
      valid = false;
    }
    if (!valid) {
      toast.error(message);
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    if (validate()) {
      try {
        await axios.post("/cms/api/organisation", fields).then((res) => {
          if (res.status === 201) {
            toast.success("Case created successfuly.");
            navigate(`/organizations`);
            setOpen(false);
          }
        });
      } catch {
        toast.error("something went wrong.");
      }
    }
  };
  const handleUpdate = async (e) => {
    if (validate()) {
      try {
        await axios
          .patch(`/cms/api/organisation/${or_name}`, fields)
          .then((res) => {
            if (res.status === 204) {
              toast.success("organization updated successfuly.");
              navigate(`/organizations`);
              setOpen(false);
              resetFields();
            }
          });
      } catch {
        toast.error("Something went wrong.");
      }
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h5" fontWeight={700}>
          {update ? "Edit Organization" : "Create organization"}
        </Typography>
        <Typography variant="caption" color="#A7BBCE">
          Case details
        </Typography>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
            <Grid container direction="column" item spacing={1.25}>
              <Grid item>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Organization Title*
                </Typography>
              </Grid>
              <Grid item>
                <InputField
                  placeholder="Type case title here..."
                  fullWidth
                  readOnly={or_name !== "admin"}
                  name="name"
                  className={or_name !== "admin" && "text-muted"}
                  value={fields.name}
                  onChange={handleFieldChange}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Organization Description*
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  component={"textarea"}
                  sx={{
                    backgroundColor: "transparent",
                    minHeight: "80px",
                    fontSize: "14px",
                    padding: "8px",
                    border: "1px solid #ECF0F4",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  name="description"
                  value={fields.description}
                  onChange={handleFieldChange}
                  placeholder="Type case description here..."
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="body2" fontWeight={400} color="#000">
            * Required field
          </Typography>
          <Btn
            onClick={update ? handleUpdate : handleSubmit}
            text="Create Organization"
            style={{ backgroundColor: "#003272" }}
            startIcon={PlusIcon}
            height={50}
            fontSize={20}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
