import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Table({
  title = "Agent",
  rows,
  columns,
  CustomToolbar,
  loading = false,
  shourt_note,
}) {
  const navigator = useNavigate();
  const getRowClassName = (params) => {
    return "custom-row";
  };
  return (
    <Paper elevation={3} sx={{ p: 2.5 }}>
      <Typography variant="h5" fontWeight="bold" mt={2.5}>
        {title}
      </Typography>
      {shourt_note && (
        <Typography variant="caption" paragraph mt={2.5}>
          {shourt_note}
        </Typography>
      )}

      <Box height={420} width={"100%"}>
        {loading && <LinearProgress />}
        <DataGrid
          sx={{
            "& .custom-row": {
              fontWeight: 300,
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none!important",
            },
          }}
          getRowClassName={getRowClassName}
          rows={rows}
          columns={columns}
          disableColumnSelector
          autoPageSize
          slots={{
            toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          onRowClick={(r) => {
            navigator(`/agents-preview?id=${r.id}`);
          }}
        />
      </Box>
    </Paper>
  );
}
