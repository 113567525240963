import { Box, LinearProgress, Paper, Typography, linearProgressClasses } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import GridLayout from "react-grid-layout";
import cms from '../../../config/axiosConfig';
import { severity_enum, tlp_enum } from '../../Cases/enums/enums';
import SimplePieChart from '../../../components/charts/PieChart';
import GenderBarChart from '../../../components/charts/BarChart';
import styled from 'styled-components';
import CasesTable from '../components/CasesTable';
import { convertToEpochMillis, getRandomColor } from '../../../utils/utils';
import "./T2Dashboard.css"

const BorderLinearProgress = styled(LinearProgress)(({ theme, Ccolor }) => ({
    height: 20,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: Ccolor,
    },
}));

const TlpProgressBars = ({ data }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: 2 }}>
            {data.map((item, index) => (
                <Box key={index}>
                    <Typography variant='subtitle2'>{item.name} tlp</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <BorderLinearProgress variant="determinate" value={item.value} Ccolor={item.color} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">{item.value}</Typography>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default function T2Dashboard({ layout, setLayout, dateRange, interval }) {
    const getUniqueRandomColors = (len = 10) => {
        const colorSet = new Set();
        while (colorSet.size < len) {
            colorSet.add(getRandomColor());
        }
        return Array.from(colorSet);
    };
    const title_styles = {
        fontSize: "71.6667px",
        fontWeight: 500,
        lineHeight: 1.2,
        textAlign: "center"
    }
    const containerRef = useRef(null);
    const [cases, setCases] = useState({ top10: [], by_status: [], by_severirty: [], tlps: [], loading: true, colors: { top10: getUniqueRandomColors(), by_status: getUniqueRandomColors(), by_severirty: getUniqueRandomColors(), tlps: getUniqueRandomColors() } })
    const [caseByResolution, setCaseByResolution] = useState({ data: [], loading: true, colors: getUniqueRandomColors() })
    const [caseImpactStatus, setCaseImpactStatus] = useState({ withImpact: null, withoutImpact: null, loading: true })
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);


    const onLayoutChange = (layout) => {
        localStorage.setItem("tier2-layout", JSON.stringify(layout));
        setLayout(layout);
    };



    const FetchCaseByResolution = async () => {
        try {
            setCaseByResolution((pre) => ({ ...pre, loading: true }));
            const response = await cms.post(
                "/cms/api/v1/query",
                {
                    query: [
                        { _name: "listCase" },
                        { _name: "filter", _between: { _field: "_createdAt", _from: convertToEpochMillis(dateRange.from_date), _to: convertToEpochMillis(dateRange.to_date) } },
                        {
                            _name: "aggregation",
                            _agg: "field",
                            _field: "resolutionStatus",
                            _select: [{ _agg: "count" }],
                        },
                    ],
                },
                {
                    params: { name: "case-by-resolutionStatu-stats" },
                }
            );
            let d = response.data;
            console.log(d);
            let _statuses = [];

            let defult_data = ["FalsePositive", "TruePositive", "Other"];
            for (let i in d) {
                defult_data = defult_data.filter(
                    (d) => d.toLowerCase() !== i.toLowerCase()
                );
                _statuses.push({
                    name: i,
                    value: d[i].count,
                    // color: resolution_enum[i.toLowerCase()],
                    color: caseByResolution.colors[i],
                });
            }
            for (let row in defult_data) {
                _statuses.push({
                    name: defult_data[row],
                    value: 0,
                    // color: resolution_enum[defult_data[row].toLocaleLowerCase()],
                    color: getRandomColor(),
                });
            }
            setCaseByResolution((pre) => ({ ...pre, data: _statuses }));
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setCaseByResolution((pre) => ({ ...pre, loading: false }));

        }
    };
    const FetchImpactStatus = async (withImpact = false) => {
        try {
            setCaseImpactStatus((pre) => ({ ...pre, loading: true }));
            const response = await cms.post(
                "/cms/api/v1/query",
                {
                    query: [
                        {
                            "_name": "listCase"
                        },
                        {
                            "_name": "filter",
                            "_field": "impactStatus",
                            "_value": withImpact ? "WithImpact" : "NotApplicable"
                        },
                        {
                            "_name": "limitedCount"
                        }
                    ]
                },
                {
                    params: { name: "case.count" },
                }
            );
            let d = response.data;
            if (withImpact) {
                setCaseImpactStatus(pre => ({ ...pre, withImpact: d }))
            }
            else {
                setCaseImpactStatus(pre => ({ ...pre, withoutImpact: d }))
            }


        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setCaseImpactStatus((pre) => ({ ...pre, loading: false }));

        }
    };

    const FetchCases = async () => {
        try {
            setCases((pre) => ({ ...pre, loading: true }));

            let query = [
                {
                    _name: "listCase",
                },
                { _name: "filter", _between: { _field: "_createdAt", _from: convertToEpochMillis(dateRange.from_date), _to: convertToEpochMillis(dateRange.to_date) } },
                {
                    _name: "sort",
                    _fields: [{ _createdAt: "desc" }],
                },
                {
                    _name: "page",
                    from: 0,
                    to: 2 ^ 53,

                },
            ];

            const response = await cms.post(
                "/cms/api/v1/query",
                {
                    query: query,
                },
                {
                    params: { name: "cases" },
                }
            );

            let res = response.data;

            // Initialize counts with all TLP values set to 0
            const initialTlpCounts = tlp_enum.reduce((acc, tlp) => {
                acc[tlp.title] = 0;
                return acc;
            }, {});

            const counts = res.reduce((acc, caseItem) => {
                // Count statuses
                if (acc.status[caseItem.status]) {
                    acc.status[caseItem.status]++;
                } else {
                    acc.status[caseItem.status] = 1;
                }

                // Count severities
                if (acc.severity[severity_enum[caseItem.severity].value]) {
                    acc.severity[severity_enum[caseItem.severity].value]++;
                } else {
                    acc.severity[severity_enum[caseItem.severity].value] = 1;
                }
                // Count TLPs
                const tlpValue = tlp_enum.find(tlp => tlp.value === caseItem.tlp)?.title || 'White';
                acc.tlp[tlpValue]++;


                return acc;
            }, { status: {}, severity: {}, tlp: { ...initialTlpCounts } });
            const severities = Object.keys(counts.severity).map((name, i) => ({
                name,
                value: counts.severity[name],
                // color: severity_enum[counts.severity[name]]?.color
                color: cases.colors.by_severirty[i],
            }));

            const statuses = Object.keys(counts.status).map((name, i) => ({
                name,
                value: counts.status[name],
                color: cases.colors.by_status[i],
            }));

            const tlpCounts = tlp_enum.map(tlp => ({
                name: tlp.title,
                value: counts.tlp[tlp.title] || 0,
                color: tlp.color,
            }));
            setCases(pre => ({ ...pre, top10: res?.slice(0, 10), by_status: statuses, by_severirty: severities, tlps: tlpCounts }))


        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            setCases((pre) => ({ ...pre, loading: false }));
        }
    };

    useEffect(() => {
        const fetchAllData = () => {
            FetchCaseByResolution();
            FetchCases();
            FetchImpactStatus(true);
            FetchImpactStatus(false);
        };
        // Initial fetch
        fetchAllData();

        // Set up interval for fetching data
        const intervalId = setInterval(fetchAllData, interval * 60000);

        // Clear interval on unmount
        return () => clearInterval(intervalId);
    }, [dateRange.from_date, dateRange.to_date, interval]);

    return (
        <Box ref={containerRef} height="100vh">
            <GridLayout
                className="custom-grid-layout"
                layout={layout}
                cols={12}
                rowHeight={Math.floor(dimensions.height / 5.25)}
                width={dimensions.width}
                isDraggable={true}
                isResizable={true}
                onLayoutChange={onLayoutChange}
            >
                <Box key="1"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {cases.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle2">Case by stage</Typography>
                    <SimplePieChart  data={cases.by_status} innerRadius={90} />
                </Box>
                <Box key="2"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {caseByResolution.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle2">Case by Resolution</Typography>
                    <GenderBarChart data={caseByResolution.data} innerRadius={90} />

                </Box>
                <Box key="3"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {cases.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle2">Case by severity</Typography>
                    <SimplePieChart data={cases.by_severirty} innerRadius={90} />
                </Box>
                <Box key="4"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {caseImpactStatus.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle2">Cases with-impact</Typography>
                    <Typography variant="h2" textAlign="center" fontWeight={500} color={"error"} sx={title_styles}>{caseImpactStatus.withImpact}</Typography>
                </Box>
                <Box key="5"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {caseImpactStatus.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle2">Cases No-impact</Typography>
                    <Typography variant="h2" textAlign="center" fontWeight={500} color="green" sx={title_styles}>{caseImpactStatus.withoutImpact}</Typography>
                </Box>
                <Box key="6"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {cases.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle2">Top 10 Cases</Typography>
                    <CasesTable data={cases.top10} />
                </Box>
                <Box key="7"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {cases.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle2">Case by TLP</Typography>
                    <TlpProgressBars data={cases.tlps} />
                </Box>
            </GridLayout>
        </Box>
    )
}

