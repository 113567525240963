import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Pagination,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

export const CustomTableCell = ({
  isLast = false,
  verticalAlign = "top",
  sx,
  children,
  ...other
}) => (
  <TableCell
    sx={{
      borderColor: "#ECF0F4",
      borderRadius: isLast ? "0px 4px 4px 0px" : "0",
      borderWidth: isLast ? "1px 1px 1px 0" : "1px 0 1px 0px",
      fontSize: 12,
      verticalAlign: "top",
      ...sx,
    }}
    {...other}
  >
    {children}
  </TableCell>
);

const CustomTable = ({
  columns,
  data,
  CustomTableRow,
  loading = false,
  refrashFunc = false,
  total_count = 0,
  page = 0,
  onPageChange,
  onDetailOpen = false,
  checkboxies = false,
  SelectRows = () => {},
  _selectedRows = [],
  ...other
}) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedRows, setSelectedRows] = useState(_selectedRows);

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortDirection("asc");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = data.map((row) => row._id);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };
  useEffect(() => {
    SelectRows(selectedRows);
  }, [selectedRows]);

  const isSelected = (rowId) => selectedRows.indexOf(rowId) !== -1;

  const sortedData = sortBy
    ? [...data].sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];
        if (sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
      })
    : data;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {total_count > 0 && (
        <Pagination
          count={total_count}
          page={page}
          onChange={onPageChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 2,
            marginBottom: "auto",
          }}
        />
      )}
      {data.length < 1 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={100}
        >
          <Typography color="grey" variant="h5">
            No Data
          </Typography>
        </Box>
      ) : (
        <Table
          sx={{
            border: "none",
            borderCollapse: "separate",
            borderSpacing: "0px 15px",
            position: "relative",
          }}
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {checkboxies && (
                <TableCell sx={{ borderBottom: "none" }}>
                  <Checkbox
                    size="small"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < data.length
                    }
                    checked={selectedRows.length === data.length}
                    onChange={handleSelectAllClick}
                  />
                  {selectedRows.length > 0 && (
                    <Typography display="block" noWrap variant="caption">
                      {selectedRows.length} Selected
                    </Typography>
                  )}
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    fontSize: 12,
                    fontWeight: "400",
                    borderBottom: "none",
                    ...(column.sortable && {
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }),
                  }}
                  onClick={() => column.sortable && handleSort(column.id)}
                >
                  {column.label}
                  {sortBy === column.id && (
                    <span sx={{ fontSize: "0.8em", paddingLeft: "4px" }}>
                      {sortDirection === "asc" ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>

            <LinearProgress
              sx={{
                width: "100%",
                display: !loading && "none",
                position: "absolute",
                color: "#1173FF",
              }}
            />
          </TableHead>

          <TableBody>
            {sortedData.map((row) => (
              <CustomTableRow
                key={row._id}
                row={row}
                isSelected={isSelected(row._id)}
                onSelect={handleRowSelect}
                refrashFunc={refrashFunc}
                onDetailOpen={onDetailOpen}
                loading={loading}
                checkboxies={checkboxies}
                {...other}
              />
            ))}
          </TableBody>
        </Table>
      )}
      {total_count > 0 && (
        <Pagination
          count={total_count}
          page={page}
          onChange={onPageChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
          }}
        />
      )}
    </div>
  );
};

export default React.memo(CustomTable);
