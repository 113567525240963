import { Box, Button } from "@mui/material";
import React from "react";

function FotterButtons({ disabled = true, onApply }) {
  return (
    <Box mt={1} display="flex" width="100%" justifyContent="flex-end" gap={1}>
      <Button
        onClick={onApply}
        disabled={disabled}
        sx={{ minWidth: 200 }}
        variant="contained"
      >
        Apply Filter
      </Button>
      <Button
        sx={{ minWidth: 100, bgcolor: "grey.300", color: "black" }}
        variant="contained"
      >
        Cancel
      </Button>
    </Box>
  );
}

export default FotterButtons;
