import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Link,
    Paper,
    Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { formatCreatedAt } from "../../../../utils/utils.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputField from "../../../../components/Field/BootStrap/InputField.js";
import CustomTable from "../../../../components/Display/CustomTable.js";
import CustomUsersRow from "../../components/CustomUsersRow.js";
import CreateUserDialog from "../../components/CreateUserDialog.js";
import cms from "../../../../config/axiosConfig.js";
import DeleteUserDialog from "../../components/DeleteUserDialog.js";

function Users({ orgName, organization }) {
    const columns = [
        { id: "locked", label: "Status", sortable: true },
        { id: "login", label: "Log in", sortable: true },
        { id: "name", label: "Full name", sortable: true },
        { id: "profile", label: "Profile", sortable: true },
        { id: "_id", label: "Password", sortable: false },
        // { id: "_id", label: "API Key", sortable: false },
        { id: "hasMFA", label: "MFA", sortable: true },
        { id: "_createdAt", label: "Date", sortable: true },
        { id: "action", label: "", sortable: true },
    ];
    const initialPagination = { page: 1, per_page: 15 };
    const [pagination, setPagination] = useState(initialPagination);
    const [userDialog, showUserDialog] = useState(false);
    const [confirmDelete, showConfirmDelete] = useState(false);
    const initial_user_fields = {
        id: "",
        login: "",
        name: "",
        organisation: "",
        profile: "",
        password: "",
        confim_password: "",
    };

    const [selectedUser, setSelectedUser] = useState(initial_user_fields);

    const [users, setUsers] = useState({
        data: [],
        count: 0,
        loading: false,
    });
    const fetchUsers = async (get_count = false) => {
        try {
            if (!get_count) {
                setUsers((pre) => ({ ...pre, loading: true }));
            }
            let query = [
                {
                    _name: "getOrganisation",
                    idOrName: orgName,
                },
                {
                    _name: "users",
                },
                {
                    _name: "sort",
                    _fields: [
                        {
                            login: "asc",
                        },
                    ],
                },
                {
                    _name: "page",
                    from: (pagination.page - 1) * pagination.per_page,
                    to: pagination.page * pagination.per_page,
                    organisation: "admin",
                },
            ];

            if (get_count) {
                query.pop();
                query.pop();
                query.push({ _name: "count" });
            }
            const response = await cms.post(
                "/cms/api/v1/query",
                {
                    query: query,
                },
                {
                    params: {
                        name: get_count ? "organisation-users.count" : "organisation-users",
                    },
                }
            );
            let d = response.data;
            if (get_count) {
                setUsers((pre) => ({ ...pre, count: d }));
            } else {
                setUsers((pre) => ({ ...pre, loading: false, data: d }));
                fetchUsers(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const onPageChange = (e, new_page) => {
        setPagination((pre) => ({ ...pre, page: new_page }));
    };
    const CreateUserBox = useCallback(() => {
        return (
            <CreateUserDialog
                open={userDialog}
                fetchUsers={fetchUsers}
                setOpen={showUserDialog}
                organization={orgName}
                refrashFunc={fetchUsers}
                initial_fields={selectedUser}
                update={selectedUser.name}
                user_name={selectedUser.name}
                resetField={() => {
                    setSelectedUser(initial_user_fields);
                    showUserDialog(false);
                }}
            />
        );
        // eslint-disable-next-line
    }, [userDialog, selectedUser]);

    const DeleteUserBox = useCallback(
        () => (
            <DeleteUserDialog
                open={confirmDelete}
                setOpen={showConfirmDelete}
                refrashFunc={fetchUsers}
                user={selectedUser}
            />
        ),
        // eslint-disable-next-line
        [confirmDelete, selectedUser]
    );

    const updateField = async (id, _name, _value) => {
        try {
            await cms
                // .patch(`/cms/api/v1/user/${id}`, { [_name]: _value })
                .patch(`/api/updateuser/${id}`, { [_name]: _value })
                .then((res) => {
                    if (res.status === 204) {
                        toast.success("User updated successfuly.");
                        fetchUsers();
                        // setOpen(false);
                    }
                });
        } catch (err) {
            toast.error(err);
        }
    };

    const updatePassword = async (id, _value) => {
        try {
            await cms
                .patch(`/api/updateuser/${id}`, { password: _value })
                .then((res) => {
                    if (res.status === 204) {
                        toast.success("Password updated successfuly.");
                        fetchUsers();
                        // setOpen(false);
                    }
                });
        } catch (err) {
            toast.error(err);
        }
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line
    }, [orgName]);

    return (
        <Box display="flex" flexDirection="column">
            {/* <Box display="flex" flexDirection={"column"}>
                <Typography fontSize="1.5rem" fontWeight="bold">
                    <Typography
                        component={"span"}
                        fontSize="1.5rem"
                        fontWeight="bold"
                        sx={{ color: "primary.light" }}
                    >
                        [{organization.data?._type}]
                    </Typography>{" "}
                    {organization.data?.name?.toUpperCase()}
                </Typography>

                <Typography variant="caption" color="#A7BBCE">
                    {"Dashboard > Organisation for Administration"}
                </Typography>
            </Box> */}
            <Box
                display="flex"
                flexDirection="column"
                mt={1.5}
                gap={3.125}
                component={Paper}
                elevation={0}
                p="12px 20px"
                borderRadius={"10px 10px 0px 0px"}
            >
                {/* <Breadcrumbs separator="" aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        sx={{ display: "flex", alignItems: "center" }}
                        color="inherit"
                        href="/"
                    >
                        <Avatar
                            src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFblndSZeUbaKJTXoUSPh0tpT_VhOLo_UnoQ&usqp=CAU"
                            }
                            sx={{ width: "26px", height: "26px", mr: 0.5 }}
                            fontSize="inherit"
                        />
                        {organization.data?.name}
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: "flex", alignItems: "center" }}
                        color="inherit"
                        href="/material-ui/getting-started/installation/"
                    >
                        <CalendarMonthIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {formatCreatedAt(organization.data?.createdAt)}
                    </Link>
                </Breadcrumbs> */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" gap={1.25} height={36}>
                        <Button
                            startIcon={<AddCircleIcon fontSize="small" />}
                            variant={"primary"}
                            sx={{
                                borderRadius: "5px",
                                borderColor: "#003272",
                                color: "#fff",
                                bgcolor: "#13D38E",
                                "&:hover": {
                                    color: "#0D2A56",
                                },
                            }}
                            onClick={() => {
                                showUserDialog(true);
                                setSelectedUser(initial_user_fields);
                            }}
                        >
                            Create new user
                        </Button>
                        <CreateUserBox />

                        {/* )} */}
                        {/* <BTN
                text="Filter"
                disabled
                startIcon={FilterIcon}
                // onClick={hanldeScreens("filter")}
                variant={"primary"}
                sx={{
                  borderColor: "#003272",
                  color: "#fff",
                  bgcolor: "#003272",
                }}
              /> */}
                    </Box>
                    <Box>
                        <Typography
                            mr={1.25}
                            variant="caption"
                            fontWeight={500}
                            color="textPrimary"
                        >
                            Per Pages
                        </Typography>

                        <InputField
                            type="number"
                            value={pagination.per_page}
                            onChange={(e) =>
                                setPagination((pre) => ({
                                    ...pre,
                                    per_page: e.target.value,
                                }))
                            }
                            sx={{ maxWidth: 121 }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                component={Paper}
                elevation={0}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                p={2.5}
                sx={{
                    maxWidth: "100%",
                    overflowX: "auto",
                }}
                height="100%"
            >
                <Typography variant="h5" fontWeight="bold">
                    {`List of Users ( ${users.data?.length} of ${users.count || 0} )`}
                </Typography>
                <CustomTable
                    columns={columns}
                    data={users.data}
                    CustomTableRow={CustomUsersRow}
                    loading={users.loading}
                    onPageChange={onPageChange}
                    page={pagination.page}
                    total_count={Math.ceil(users.count / pagination.per_page)}
                    onEdit={setSelectedUser}
                    openEditBox={showUserDialog}
                    updateField={updateField}
                    updatePassword={updatePassword}
                    refrashFunc={fetchUsers}
                    onDelete={(user) => {
                        showConfirmDelete(true);
                        setSelectedUser(user);
                    }}
                />
                <DeleteUserBox />
            </Box>
        </Box>
    );
}

export default Users;
