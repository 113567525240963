import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { FetchAgents } from "../../../utils/agents/AgentUtils";
import { FetchGroups } from "../../../utils/Groups/GroupUtils";

const ServerAddress = ({
  onServerAdressChange,
  serverAdress,
  agent,
  onAgentChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [erros, setErrors] = useState([]);

  const handleGroupChange = (event, value) => {
    setSelectedGroups(value);
    onAgentChange("groups", value);
  };

  const handleChipDelete = (chipToDelete) => {
    setSelectedGroups((prevSelected) =>
      prevSelected.filter((group) => group !== chipToDelete)
    );
    onAgentChange("groups", []);
  };

  const validServerAdress = () => {
    const ipPattern = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
    const fqdnPattern = /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/;
    if (ipPattern.test(serverAdress) || fqdnPattern.test(serverAdress)) {
      const new_errors = erros.filter((err) => err !== "serverAdress");
      setErrors(new_errors);
    } else {
      setErrors((preError) => [...preError, "serverAdress"]);
    }
  };

  const CheckAgents = async () => {
    if (agent.length > 3) {
      setLoading(true);
      await FetchAgents("GET", { q: `name=${agent}` }).then((res) => {
        const exist = res.length > 0;
        if (exist) {
          setErrors((preError) => [...preError, "agent"]);
        } else {
          const new_errors = erros.filter((err) => err !== "agent");
          setErrors(new_errors);
        }
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchgroupslist = async () => {
      await FetchGroups("GET").then((res) => {
        setGroups(res);
      });
    };
    fetchgroupslist();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Typography variant="h6">Server Address</Typography>
      <Typography variant="subtitle1" paragraph>
        This is the address the agent uses to communicate with the defencefusion server.
        It can be an IP address or a fully qualified domain name (FQDN).
      </Typography>
      <TextField
        size="small"
        fullWidth
        error={erros.find((err) => err === "serverAdress")}
        variant="outlined"
        value={serverAdress}
        onChange={onServerAdressChange}
        onBlur={validServerAdress}
        placeholder="Server Address i.e 127.0.0.1"
      />
      <Divider sx={{ mb: 1 }} flexItem variant="middle" />
      <Typography variant="h6">Settings</Typography>
      <Typography variant="subtitle1">
        The deployment sets the endpoint hostname as the agent name by default.
        Optionally, you can set the agent name below.
      </Typography>

      <TextField
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Assign Name"
        label="Assign an agent name"
        onBlur={CheckAgents}
        onChange={(e) => {
          onAgentChange("agent", e.target.value);
        }}
        disabled={loading}
        InputProps={{
          endAdornment: loading && (
            <InputAdornment position="end">
              <CircularProgress color="inherit" size={20} />
            </InputAdornment>
          ),
        }}
        error={erros.find((err) => err === "agent")}
      />

      <Alert severity="warning">
        {" "}
        The agent name must be unique. It can’t be changed once the agent has
        been enrolled.
      </Alert>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          id="groups"
          size="small"
          options={groups.map((group) => group.name)}
          value={selectedGroups}
          onChange={handleGroupChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                size="small"
                label={option}
                onDelete={() => handleChipDelete(option)}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label="Select one or more existing groups" />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default ServerAddress;
