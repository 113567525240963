export const permissions_enum = {
  admin: {
    manageOrganisation: "Manage organisations",
    manageProfile: "Manage profiles",
    manageUser: "Manage users",
    manageCustomField: "Manage custom fields",
    manageConfig: "Manage configurations",
    manageAnalyzerTemplate: "Manage analyzer templates",
    manageObservableTemplate: "Manage observable types",
    manageTaxonomy: "Manage taxonomies",
    managePattern: "Manage attack patterns",
    managePlatform: "Manage the platform",
  },
  org: {
    manageUser: "Manage users",
    manageCaseTemplate: "Manage case templates",
    manageTag: "Manage custom tags",
    manageAlert: "Manage alert",
    manageCase: "Manage case",
    manageShare: "Manage sharing",
    manageObservable: "Manage observables",
    manageTask: "Manage tasks",
    manageProcedure: "Manage TTPs",
    manageAction: "Run Cortex responders",
    manageAnalyse: "Run Cortex analyzer",
    accessTheHiveFS: "Access to TheHiveFS service",
  },
};
