import React, { createContext } from "react";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { connect } from "react-redux";
import SideDrawer from "../components/fixed/SideDrawer.js";

export const ThemeModeContext = createContext();
const Index = (props) => {
  let theme = createTheme({
    palette: {
      mode: props.mode,
      blackBg: {
        main: "#FFFFFF",
      },
      primary: {
        light: "#1173FF",
        main: "#003272",
        dark: "#003272",
      },
      error: {
        light: "#FFC2C2",
        main: "#FF3E3E",
        dark: "#FF3E3E",
      },
      warning:{
        light: "#68f2c1",
        main: "#FFA800",
        dark: "#FFA800",
      },
      success: {
        light: "#68f2c1",
        main: "#13D38E",
        dark: "#039c65",
      },
    },
    typography: {
      fontFamily: `Poppins`,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });
  theme = responsiveFontSizes(theme, {
    breakpoints: ["sm", "md", "lg", "xl"],
    disableAlign: true,
    factor: 2,
  });
  return (
    <ThemeProvider theme={theme}>
      <SideDrawer>{props.children}</SideDrawer>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    mode: state.mode,
  };
};

export default connect(mapStateToProps, null)(Index);
