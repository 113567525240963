import React, { useEffect, useState } from "react";
import StackedBarChart from "../../../components/charts/StackedBarChart.js";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import EventsTabs from "./EventsTabs.js";
import { alerts_events_query } from "../enums/enums.js";
import { wazuhIndex } from "../../../config/wazuh.js";
import HistoryIcon from "@mui/icons-material/History";
import DateRangeSelector from "../../../components/Field/DateRangeSeletor.js";
import { useLocation } from "react-router-dom";

const Event = ({ dateRange, setDateRange, setGraphs, setTableData }) => {
  const location = useLocation();
  const agentId =
    new URLSearchParams(location.search).get("agent_id") || undefined;
  const pageTab = new URLSearchParams(location.search).get("tab") || "";
  const inintialEvents = {
    events: {},
    loading: false,
    graphData: { labels: [], values: [] },
  };

  const [events, setEvents] = useState(inintialEvents);
  const report_graphs = [{
    id: "graph_events", label: "Events",
    component: <StackedBarChart data={events.graphData} />,
    type: "component",
    xs: 12,
    sx: {
      "& canvas": {
        height: "270px !important"
      }
    }
  }];


  const [query, setQuery] = useState(
    pageTab === "sca" || pageTab === "mitre" ? "rule.groups: sca" : ""
  );
  const [calendarInterval, setCalendarInterval] = useState("1d");

  const [pagination, setPagination] = React.useState({
    from: 0,
    size: 10,
  });

  const FetchEvent = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date,
    from = pagination.from,
    size = pagination.size
  ) => {
    setEvents((pre) => ({
      ...pre,
      graphData: inintialEvents.graphData,
      events: inintialEvents.events,
      loading: true,
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        alerts_events_query(agentId, query, from_date, to_date, from, size)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        const TimeStampToString = (stamp) => {
          const timestamp = stamp;
          const date = new Date(timestamp);
          // const hours = String(date.getUTCHours()).padStart(2, "0");
          // const minutes = String(date.getUTCMinutes()).padStart(2, "0");
          // return `${hours}:${minutes}`;
          return date.toLocaleDateString();
        };
        buckets.map((b) => {
          data.labels.push(TimeStampToString(b.key));
          data.values.push(b.doc_count);
          return "";
        });
        setEvents((pre) => ({
          ...pre,
          graphData: data,
          events: res.data,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const FetchEventGraph = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date,
    from = pagination.from,
    size = pagination.size,
    calendar_interval
  ) => {
    setEvents((pre) => ({
      ...pre,
      graphData: inintialEvents.graphData,
      loading: true,
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        alerts_events_query(
          agentId,
          query,
          from_date,
          to_date,
          from,
          size,
          calendar_interval
        )
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        const TimeStampToString = (stamp) => {
          const timestamp = stamp;
          const date = new Date(timestamp);
          return date.toLocaleDateString();
        };
        buckets.map((b) => {
          data.labels.push(TimeStampToString(b.key));
          data.values.push(b.doc_count);
          return "";
        });
        setEvents((pre) => ({
          ...pre,
          graphData: data,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (newFrom, newSize) => {
    setPagination({ from: newFrom, size: newSize });
    FetchEvent(undefined, undefined, newFrom, newSize);
  };

  useEffect(() => {

    FetchEvent();
    // eslint-disable-next-line
  }, [pagination.from, pagination.size]);
  useEffect(() => { setGraphs(report_graphs); }, [events])

  const handleSearch = (e) => {
    const dqlQuery = e.target.value;
    setQuery(dqlQuery);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      FetchEvent();
    }
  };
  useEffect(() => {
    if (calendarInterval) {
      FetchEventGraph(
        undefined,
        undefined,
        undefined,
        undefined,
        calendarInterval
      );
    }
    // eslint-disable-next-line
  }, [calendarInterval]);

  return (
    <Box mt={5}>
      {/* Filtes */}
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} lg={7}>
          {/* <FilterSelecter onSearch={() => {}} /> */}
          <TextField
            fullWidth
            size="small"
            placeholder="agent.id:000 AND agent.name:agent OR ip:127.0.0.1."
            value={query}
            onChange={handleSearch}
            onKeyDown={handleSearchKeyDown}
          />
        </Grid>
        <Grid item xs={10} lg={4}>
          {/* <DatTimeSelect /> */}
          <DateRangeSelector
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Grid>
        <Grid item xs={2} lg>
          <Button
            startIcon={<HistoryIcon fontSize="small" />}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => FetchEvent()}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      {events.events?.hits?.total.value > 1 ? (
        <Box
          component={Paper}
          elevation={3}
          gap={2}
          display="flex"
          justifyContent="center"
          flexDirection={"column"}
        >
          <Box
            mt={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {events.loading && (
              <Box width="100%">
                <LinearProgress />
              </Box>
            )}
            <Typography paragraph variant="subtitle1">
              {events.events?.hits?.total.value ? (
                <b>{events.events?.hits?.total.value}</b>
              ) : (
                <b>0</b>
              )}
              <span style={{ fontSize: 12 }}>hits</span>
            </Typography>
            <Box
              display="flex"
              gap={1.5}
              alignItems="center"
              justifyContent="center"
              p={{ xs: 0.625, lg: 0 }}
            >
              <FormControl
                sx={{ minWidth: 120, "& .MuiInputBase-root ": { height: 30 } }}
              >
                <Select
                  value={calendarInterval}
                  displayEmpty
                  onChange={(e) => {
                    setCalendarInterval(e.target.value);
                  }}
                  size="small"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {/* <MenuItem value={undefined} color="grey">
                    <i>Select Range</i>
                  </MenuItem> */}
                  <MenuItem value="1d">Auto</MenuItem>
                  <MenuItem value={"12h"}>Hour</MenuItem>
                  <MenuItem value={"1M"}>Month</MenuItem>
                  <MenuItem value={"1w"}>Week</MenuItem>
                  <MenuItem value={"1y"}>Year</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width="90%" height={300} id="graph_events">
              <StackedBarChart data={events.graphData} />
            </Box>
          </Box>
          <Divider variant="middle" />
          {events.events?.hits?.hits && (
            <EventsTabs
              loading={events.loading && events.events.length < 1}
              data={events.events}
              pagination={pagination}
              handlePageChange={handlePageChange}
              setTableData={setTableData}
            />
          )}
        </Box>
      ) : (
        <Box height="65vh">
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            There are no results for selected time range. -
            <strong>Try another one!</strong>
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default Event;
