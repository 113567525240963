import { CustomTableCell } from "../../../components/Display/CustomTable";

import React, { useEffect, useState } from "react";
import {
  TableRow,
  Box,
  Typography,
  Button,
  ButtonBase,
  Chip,
  Tooltip,
} from "@mui/material";
import { pap_enum, severity_enum, tlp_enum } from "../../Cases/enums/enums";
import CircleIcon from "@mui/icons-material/Circle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  calculateDaysFromTimestamp,
  formatCreatedAt,
} from "../../../utils/utils";
import { tasks_states } from "../enums/enums";
import { useNavigate } from "react-router-dom";

const CustomDetailRow = ({
  row,
  isSelected,
  TaskStatusUpdate,
  permissions,
  onSelect,
}) => {
  const navigation = useNavigate();
  const handleRowClick = () => {
    // onSelect(row._id);
    navigation(
      `/case-detail/${row.extraData.case._id.replace("~", "")}?task=${row._id}`
    );
  };
  const [statusUpdate, setStatusUpdate] = useState("");
  useEffect(() => {
    switch (row.status.toLocaleLowerCase()) {
      case "waiting":
        setStatusUpdate("Click to start");
        break;
      case "inprogress":
        setStatusUpdate("Click to complete");
        break;
      case "completed":
        setStatusUpdate("Click to start");
        break;
      default:
        setStatusUpdate("Click to delete Cancel");
        break;
    }
  }, [row.status]);

  return (
    <TableRow
      sx={{
        borderRadius: "4px",
        minHeight: 76,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        "& .MuiTableCell-root": {
          verticalAlign: "middle ",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),
      }}
      onClick={handleRowClick}
    >
      <CustomTableCell>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Typography variant="caption" fontWeight={500}>
            {row._id.replace("~", "#")}
          </Typography>
          <Box
            sx={{
              minHeight: "20px",
              p: "5px",
              borderRadius: "2px 0px 0px 2px",
              width: "fit-content",
            }}
            display="flex"
            alignItems="center"
            gap="4px"
            bgcolor={"#ECF0F4"}
            color="#FF3E3E"
          >
            <AccessTimeIcon fontSize={"12px"} sx={{ color: "#FF3E3E" }} />
            <Typography fontSize={10} fontWeight={400}>
              {calculateDaysFromTimestamp(row.startDate)} days
            </Typography>
          </Box>
        </Box>
      </CustomTableCell>
      <CustomTableCell width={200}>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Typography
            component={ButtonBase}
            textAlign="left"
            display={"block"}
            onClick={() => {
              if (row?.extraData?.case?._id) {
                navigation(
                  `/case-detail/${row.extraData.case._id.replace(
                    "~",
                    ""
                  )}?task=${row._id}`
                );
              }
            }}
            color="#1173FF"
            variant="caption"
            fontWeight={700}
          >
            {row.title}
          </Typography>

          <Typography color="#1173FF" variant="caption">
            #{row?.extraData?.case?.number} - {row.title}
          </Typography>
        </Box>
      </CustomTableCell>

      <CustomTableCell>
        <Tooltip title={statusUpdate}>
          <Button
            variant="contained"
            size="small"
            disabled={!permissions.mTasks}
            onClick={(e) => {
              e.stopPropagation();
              TaskStatusUpdate(row._id, row.status.toLocaleLowerCase())();
              e.target.disabled = true;
            }}
            style={{
              backgroundColor: tasks_states[row.status.toLocaleLowerCase()],
              height: "26px",
              fontSize: 12,
              fontWeight: 700,
              marginTop: -4,
            }}
            className=" shadow-none"
          >
            {row.status}
          </Button>
        </Tooltip>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <Typography
          variant="caption"
          color={!row.assignee && "grey"}
          fontWeight={500}
        >
          {row.assignee ? row.assignee : "Not assigneed"}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="caption" fontWeight={500}>
          {typeof severity_enum[row?.extraData.case.severity].icon ===
          "string" ? (
            <Box
              component="img"
              src={severity_enum[row?.extraData.case.severity]?.icon}
              alt="priority"
              width={20}
              height={20}
              mr={"5px"}
            />
          ) : (
            severity_enum[row?.extraData.case.severity].icon
          )}
          {severity_enum[row?.extraData.case.severity]?.value}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{ color: "#A5A5A5" }}
        >
          {formatCreatedAt(row._createdAt)}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <ButtonBase>
          <Chip
            sx={{
              minWidth: "82px",
              backgroundColor: tlp_enum[row?.extraData.case.tlp]?.bgColor,
              color: tlp_enum[row?.extraData.case.tlp]?.value,
            }}
            icon={
              <CircleIcon
                fontSize="small"
                style={{ color: tlp_enum[row?.extraData.case.tlp]?.color }}
              />
            }
            label={tlp_enum[row?.extraData.case.tlp]?.title}
          />
        </ButtonBase>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <ButtonBase>
          <Chip
            sx={{
              minWidth: "82px",
              backgroundColor: pap_enum[row?.extraData.case.pap]?.bgColor,
              color: pap_enum[row?.extraData.case.pap]?.value,
            }}
            icon={
              <CircleIcon
                fontSize="small"
                style={{ color: pap_enum[row?.extraData.case.pap]?.color }}
              />
            }
            label={pap_enum[row?.extraData.case.pap]?.title}
          />
        </ButtonBase>
      </CustomTableCell>
    </TableRow>
  );
};

export default CustomDetailRow;
