import axios from "axios";
import { authLogout } from "../store/actions/actions";
import { store } from "../index";

const cms = axios.create({
  baseURL: window.location.origin,
});

// Response interceptor
cms.interceptors.response.use(
  (response) => {
    let responseDataString = JSON.stringify(response.data);

    // const termsToReplace = {
    //   "thehive.local": "defencefusion.io",
    //   wazuh: "defence fusion",
    //   thehive: "DefenceFusion.io",
    // };

    // Object.entries(termsToReplace).forEach(([term, replacement]) => {
    //   const regex = new RegExp(term, "ig");
    //   responseDataString = responseDataString.replace(regex, replacement);
    // });

    response.data = JSON.parse(responseDataString);

    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      // toast.error("Session expired.");
      try {
        store.dispatch(authLogout());
      } catch {}
    }
    if (error.response && error.response.status === 401) {
      try {
        store.dispatch(authLogout());
      } catch {}
    }
    return Promise.reject(error);
  }
);

export default cms;
