import {
  Box,
  ButtonBase,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import Button from "../../components/Buttons/BootStrap/Btn.js";
import Selector from "../../components/Field/BootStrap/Selectcer.js";
import InputField from "../../components/Field/BootStrap/InputField.js";
import PieChart from "../../components/charts/PieChart.js";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CustomTable from "../../components/Display/CustomTable.js";
import GenderBarChart from "../../components/charts/BarChart.js";
import { useState, useEffect } from "react";
import CustomAlertsRow from "./components/CustomAlertsRow.js";
import { NotificationBell, insertElementAtIndex } from "../../utils/utils.js";
import PiecartIcon from "../../static/icons/piecart-icon.svg";
import { status_enum } from "../Cases/enums/enums.js";
import PiecartBlueIcon from "../../static/icons/piechart-blue-icon.svg";
import ListView from "../../components/Display/ListView.js";
import AlertDetaildialog from "./components/AlertDetaildialog.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import AlertRespondersDialog from "./components/AlertRespondersDialog.js";
import { connect } from "react-redux";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import TuneIcon from "@mui/icons-material/Tune";
import cms from "../../config/axiosConfig.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilterFields from "../../components/filters/FilterFields.js";
import { AlertFilterFields } from "../../utils/filters/fields.js";
import DeleteIcon from "@mui/icons-material/Delete";
import BulkAlertDeleteDialog from "./components/BulkAlertDeleteDialog.js";
import { useData } from "../../DataContext.js";
import { toast } from "react-toastify";


export const unFollow = async (id) => {
  try {
    const response = await cms.post(`/cms/api/alert/${id}/unfollow`);
    let d = response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const AlertsList = (props) => {
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const initialPagination = { page: 1, per_page: 15 };
  const [pagination, setPagination] = useState(initialPagination);
  const initialStatus = { by_status: [], by_type: [], by_tags: [] };
  const [responderDialog, openresponderDialog] = useState(false);
  const [deleteDialog, openDeleteDialog] = useState(false);
  const [detailDialog, openDetailDialog] = useState(false);
  const [responders, setResponders] = useState([]);
  const [statuses, setStatuses] = useState(initialStatus);
  const [filtersQuery, setFiltersQuery] = useState([]);
  const inital_filters = {
    sortBy: "",
    quickFilter: "",
  };
  const [filters, setFilters] = useState(inital_filters);
  const [alertData, setalertData] = useState({
    data: {},
    observables: [],
    observableCount: 0,
    loading: false,
  });
  const [quickFilterField, setQuickFilterFiled] = useState({});
  const initial_sortFields = [{ date: "desc" }];
  const [sortFields, setSortFields] = useState(initial_sortFields);
  const [screens, setScreens] = useState(["status", "filter"]);
  const [data, setData] = useState({ loading: false, data: [], count: 0 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkDeleteDialog, showDeleteDialog] = useState(false);
  const { _data, _setData } = useData();
  const [statsuViews, setStatusView] = useState({
    type_list: true,
    status_list: true,
    tags_list: true,
  });
  const sort_options = [
    {
      name: "Newest first",
      value: "newest_first",
      _field: [{ date: "desc" }],
    },
    {
      name: "Oldest first",
      value: "oldest_first",
      _field: [{ date: "asc" }],
    },
    {
      name: "Height Severity first",
      value: "height_severity_first",
      _field: [{ severity: "desc" }],
    },
    {
      name: "Low Severity first",
      value: "low_severity_first",
      _field: [{ severity: "asc" }],
    },
  ];
  const quick_filter_options = [
    {
      name: "Read events",
      value: "read_",
      _field: { _name: "filter", _field: "read", _value: true },
    },
    {
      name: "Unread events",
      value: "read",
      _field: { _name: "filter", _field: "read", _value: false },
    },
    {
      name: "Imported events",
      value: "read",
      _field: { _name: "filter", _field: "imported", _value: true },
    },
    {
      name: "Forwarded events",
      value: "read",
      _field: { _name: "filter", _field: "follow", _value: true },
    },
    {
      name: "Ignored events",
      value: "read",
      _field: { _name: "filter", _field: "follow", _value: false },
    },
  ];
  const columns = [
    { id: "_id", label: "# Number", sortable: true },
    { id: "pap", label: "PAP / TLP", sortable: true },
    { id: "severity", label: "Severity", sortable: true },
    { id: "read", label: "Read", sortable: true },
    { id: "title", label: "Title", sortable: false },
    { id: "extraData.caseNumber", label: "Case #", sortable: false },
    { id: "type", label: "Type", sortable: true },
    { id: "source", label: "Source", sortable: true },
    { id: "sourceRef", label: "Reference ", sortable: true },
    { id: "observableCount", label: "Observables", sortable: true },
    { id: "_createdAt", label: "Create", sortable: true },
    { id: "actions", label: "Actions", sortable: false },
  ];
  const handleActionSelect = (event) => {
    showDeleteDialog(true);
  };
  const handlePage = (event) => {
    if (event.keyCode === 13 && event.target.value) {
      fetchAlerts();
    }
  };
  const handleSort = (e) => {
    let value = e.target.value;
    setFilters((pre) => ({ ...pre, sortBy: value }));
    let ops = sort_options.find((s) => s.value === value);
    if (ops) {
      setSortFields(ops._field);
    } else {
      setSortFields(initial_sortFields);
    }
  };
  const handleQuickFilter = (e) => {
    let value = e.target.value;
    setFilters({ ...filters, quickFilter: value });
    let ops = quick_filter_options.find((s) => s.value === value);
    if (ops) {
      setQuickFilterFiled(ops._field);
    } else {
      setQuickFilterFiled({});
    }
  };
  const hanldeScreens = (scr) => () => {
    const index = screens.indexOf(scr);

    if (index !== -1) {
      const updatedScreens = [...screens];
      updatedScreens.splice(index, 1);
      setScreens(updatedScreens);
    } else {
      setScreens((prevScreens) => [...prevScreens, scr]);
    }
  };

  const fetchAlerts = async (filter_query = filtersQuery, get_count = true, sortonTop = null) => {
    try {
      setFiltersQuery(filter_query)
      if (!get_count) {
        setData((pre) => ({ ...pre, loading: true }));
      }
      let query = [
        {
          _name: "listAlert",
        },
        {
          _name: "sort",
          _fields: sortFields,
        },
        {
          _name: "filter",
          _not: { _like: { _field: "tags", _value: "agent_id=000" } },
        },
        {
          _name: "page",
          from: (pagination.page - 1) * pagination.per_page,
          to: pagination.page * pagination.per_page,
          extraData: ["importDate", "caseNumber"],
        },
      ];
      if (get_count) {
        if (filter_query && Array.isArray(filter_query)) {
          query.splice(1, 1);
        }
        query.pop();
        query.pop();
        query.push({ _name: "limitedCount" });
      }

      if (filter_query && Array.isArray(filter_query) && filter_query.length > 0) {
        if (filter_query.length < 2) {
          const element = {
            _name: "filter",
            _and: [
              { _not: { _like: { _field: "tags", _value: "agent_id=000" } } },
              ...filter_query,
            ],
          };
          query = insertElementAtIndex(query, element, 1);
        } else {
          const element = {
            _name: "filter",
            _and: [
              { _not: { _like: { _field: "tags", _value: "agent_id=000" } } },
              ...filter_query,
            ],
          };
          query = insertElementAtIndex(query, element, 1);
        }
      }
      if (Object.keys(quickFilterField).length > 0) {
        const indexToInsert = 2;
        const newObject = quickFilterField;
        const newArray = [...query];
        newArray.splice(indexToInsert, 0, newObject);
        query = newArray;
      }

      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: get_count ? "alerts.count" : "alerts" },
        }
      );
      let d = response.data;
      if (get_count) {
        setData((pre) => ({ ...pre, count: d }));
        fetchAlerts(filter_query, false, sortonTop);
      } else {
        if (sortonTop) {
          const _data = [...d];
          sortonTop.forEach(id => {
            const matchingIndex = _data.findIndex(_d => _d._id === id);
            if (matchingIndex !== -1) {
              // Move the matching item to the top
              const matchedItem = _data.splice(matchingIndex, 1)[0];
              matchedItem.updatedRow = true;
              _data.unshift(matchedItem);
            }
          });
          setData((pre) => ({ ...pre, data: _data, loading: false }));

        } else {
          setData((pre) => ({ ...pre, data: d, loading: false }));
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const fetchAlertDetail = async (id) => {
    setalertData((pre) => ({ ...pre, loading: true }));
    try {
      const response = await cms.get(`/cms/api/v1/alert/${id}`);
      let d = response.data;
      setalertData((pre) => ({ ...pre, data: d }));

      fetchObservablesDetail(d._id);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setalertData((pre) => ({ ...pre, loading: false }));
    }
  };
  const createCaseFromAlert = async (id) => {
    try {
      const response = await cms.post(`/cms/api/alert/${id}/createCase`, {});
      let d = response.data;

      openDetailDialog(false);
      navigator(`/case-detail/${d._id.replace("~", "")}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setalertData((pre) => ({ ...pre, loading: false }));
    }
  };
  const followORunfollowAlert = async (id, opration = "follow") => {
    try {
      const response = await cms.post(`/cms/api/alert/${id}/${opration}`);
      let d = response.data;

      fetchAlertDetail(d._id);
      fetchAlerts();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setalertData((pre) => ({ ...pre, loading: false }));
    }
  };
  const fetchAlertResponders = async (id) => {
    try {
      const response = await cms.get(
        `/cms/api/connector/cortex/responder/alert/${id}`
      );
      let d = response.data;
      if (d) {
        // plant alert id
        const data = [];
        d.map((obj) => {
          data.push({ ...obj, alertId: id });
          return "";
        });
        setResponders(data);
        openresponderDialog(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const readOrunReadAlert = async (id, opration = "markAsRead") => {
    try {
      const response = await cms.post(`/cms/api/alert/${id}/${opration}`);
      let d = response.data;
      fetchAlertDetail(d._id);
      fetchAlerts();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setalertData((pre) => ({ ...pre, loading: false }));
    }
  };
  const fetchObservablesDetail = async (caseId) => {
    try {
      let query = [
        {
          _name: "getAlert",
          idOrName: caseId,
        },
        {
          _name: "observables",
        },
        {
          _name: "sort",
          _fields: [
            {
              startDate: "desc",
            },
          ],
        },
        {
          _name: "page",
          from: 0,
          to: 15,
          extraData: ["seen"],
        },
      ];

      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: {
            name: "alert-observables",
          },
        }
      );

      let d = response.data;
      setalertData((pre) => ({ ...pre, observables: d }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAlertsByTags = async () => {
    try {
      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "listAlert",
            },
            {
              _name: "aggregation",
              _agg: "field",
              _field: "tags",
              _select: [
                {
                  _agg: "count",
                },
              ],
              _order: ["-count"],
              _size: 5,
            },
          ],
        },
        {
          params: { name: "alert-by-type-stats" },
        }
      );

      let d = response.data;
      let _statuses = [];
      const COLORS = [
        "#FFAF15",
        "#FF3E3E",
        "#13D38E",
        "#1173FF",
        "#003272",
        "#FFAF15",
        "#FF6B00",
        "#000000",
        "#ffffff",
      ];
      let index = 0;
      for (let i in d) {
        _statuses.push({ name: i, value: d[i].count, color: COLORS[index] });
        index += 1;
      }
      setStatuses((pre) => ({ ...pre, by_tags: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAlertsByStatus = async () => {
    try {
      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "listAlert",
            },
            {
              _name: "aggregation",
              _agg: "field",
              _field: "read",
              _select: [
                {
                  _agg: "count",
                },
              ],
            },
          ],
        },
        {
          params: { name: "alert-by-tags-stats" },
        }
      );
      let d = response.data;
      let _statuses = [];
      let defult_data = ["true", "false"];
      for (let i in d) {
        defult_data = defult_data.filter(
          (d) => d.toLowerCase() !== i.toLowerCase()
        );
        _statuses.push({
          name: i === "false" ? "Unread" : "Read",
          value: d[i].count,
          color: i.toLocaleLowerCase() === "true" ? "#FF6B00" : "#1173FF",
        });
      }
      for (let row in defult_data) {
        _statuses.push({
          name: defult_data[row] === "false" ? "UnRead" : "read",
          value: 0,
          color: status_enum[defult_data[row].toLocaleLowerCase()],
        });
      }
      setStatuses((pre) => ({ ...pre, by_status: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAlertsByTypes = async () => {
    try {
      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "listAlert",
            },
            {
              _name: "aggregation",
              _agg: "field",
              _field: "type",
              _select: [
                {
                  _agg: "count",
                },
              ],
              _order: ["-count"],
              _size: 5,
            },
          ],
        },
        {
          params: { name: "alert-by-type-stats" },
        }
      );
      let d = response.data;
      let _statuses = [];
      const COLORS = ["#1173FF", "#000000", "#FF3E3E"];

      let index = 0;
      for (let i in d) {
        _statuses.push({
          name: i,
          value: d[i].count,
          color: COLORS[index],
        });
        index += 1;
      }

      setStatuses((pre) => ({ ...pre, by_type: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onPageChange = (e, new_page) => {
    setPagination((pre) => ({ ...pre, page: new_page }));
  };

  useEffect(() => {
    const preview = searchParams.get("preview", null);
    if (preview) {
      fetchAlertDetail(preview);
      openDetailDialog(true);
    }
  }, [searchParams])

  useEffect(() => {
    const CheckForChange = () => {
      if (_data?.length > 0) {
        const alert_n_case_summaries = _data.filter(s => s.summary.hasOwnProperty("alert") && s.summary.hasOwnProperty("case"))
        const alert_summaries = _data.filter(s => s.summary.hasOwnProperty("alert"))
        if (alert_n_case_summaries.length > 0) {
          var cases = [];
          for (const _case of alert_n_case_summaries) {
            const case_ = _case.base?.object;
            const alert_ = _case.base?.details?.fromAlert;
            cases.push({ caseId: case_?.id, case: case_?.caseId, title: case_?.title, alert: alert_?._id })
          }
          // Check if Creattion 
          if (cases.length > 0) {
            // Check if alert present in current page 
            if (data.data.find(d => cases.map(c => c.alert).includes(d._id))) {
              fetchAlerts(filtersQuery, true, cases.map(a => a.alert));
              if (detailDialog && cases.map(a => a.alert).includes(alertData.data?._id)) {
                const alert_id = cases.find(a => a.alert === alertData.data?._id)
                fetchAlertDetail(alert_id)
              }
            }
            toast.info(<Typography component={ButtonBase} variant="subtitle1" sx={{ textDecoration: "underline" }} onClick={(e) => {
              e.stopPropagation()
              navigator(`/case-detail/${cases[0]?.caseId?.replace("~", "")}?page=0`)
            }} >Case genrated: {cases.map(a => `${a.case} with alert (${a.alert})`)}</Typography>);
          }
        }
        else if (alert_summaries.length > 0) {
          const alerts = [];
          for (const _alert of alert_summaries) {

            const alert = _alert.base?.object
            alerts.push({ id: _alert.base?.operation === "Delete" ? _alert.base?.objectId : alert?._id, title: alert?.title, operation: _alert.base?.operation, object: _alert.base?.object })
          }
          if (alerts.length > 0) {
            // Check if Creattion 
            // const created = alerts.filter(a => a.operation === "Creation")
            // if (created.length > 0) {
            //   const new_obj = created.map(obj => obj.object)
            //   for (const _obj of new_obj) {
            //     _obj.updatedRow = true;
            //   }
            //   const data_ = [...new_obj, ...data.data];
            //   setData((pre) => ({ ...pre, data: data_, loading: false }));
            //   NotificationBell()
            //   toast.info(<Typography component={ButtonBase} variant="subtitle1" sx={{ textDecoration: "underline" }} onClick={(e) => {
            //     e.stopPropagation()
            //     navigator("/alerts" + "?preview=" + created[0].id)
            //   }} >Alert: {created.map(a => a.title + ", ")}</Typography>);
            // }

            const updation = alerts.filter(a => a.operation === "Update")
            // Find alert if exist in current page event one
            if (data.data.find(d => updation.map(a => a.id).includes(d._id))) {
              fetchAlerts(filtersQuery, true, updation.map(a => a.id));

              if (detailDialog && updation.map(a => a.id).includes(alertData.data?._id)) {
                const alert_id = updation.find(a => a.id === alertData.data?._id)
                fetchAlertDetail(alert_id)
              }
              toast.info(<Typography component={ButtonBase} variant="subtitle1" sx={{ textDecoration: "underline" }} onClick={(e) => {
                e.stopPropagation()
                navigator("/alerts" + "?preview=" + updation[0].id)
              }} >Alert: {updation.map(a => a.title + ", ")}</Typography>);
            }
            // Check for Deleted 
            const deleted = alerts.filter(a => a.operation === "Delete")
            if (deleted.length > 0 && data.data.find(d => deleted.map(a => a.id).includes(d._id))) {
              fetchAlerts(filtersQuery, true);
              if (detailDialog && deleted.find(a => a.id === alertData.data?._id)) {
                openDetailDialog(false);
              }
              toast.info(<Typography variant="subtitle1" sx={{ textDecoration: "underline" }}  >Alert: {deleted.map(a => a.id + ", ")} deletd sucessfuly.</Typography>);
            }
          }

        }
        _setData(null);
      }
    }
    CheckForChange();
  }, [_data])

  const onSearch = (payload, getCount = false) => {
    setFiltersQuery(payload);
    fetchAlerts(payload, getCount);
  }

  useEffect(() => {
    fetchAlerts(filtersQuery, true);
    // eslint-disable-next-line
  }, [pagination.page, sortFields, quickFilterField]);

  useEffect(() => {
    if (screens.includes("status")) {
      fetchAlertsByStatus();
      fetchAlertsByTags();
      fetchAlertsByTypes();
    }
  }, [screens]);

  const BulkDeleteDialogBox = useCallback(() => {
    return (
      <BulkAlertDeleteDialog
        open={bulkDeleteDialog}
        setOpen={showDeleteDialog}
        alerts={data.data.filter((c) => selectedRows.includes(c._id))}
        refreshFunc={() => {
          fetchAlerts(filtersQuery, true);
          setSelectedRows([]);
        }}
      />
    );
  }, [selectedRows, bulkDeleteDialog]);

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={3.5}>
      {/* Title & screens btns */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h5" fontWeight="bold">
            <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIosIcon color="primary" />
            </IconButton>
            List of Alerts ({props.alertsCount ? data.data.length : 0} of{" "}
            {data.count < 0 ? props.alertsCount : data.count})
          </Typography>
          <Typography color="#A7BBCE" variant="caption">
            {"Dashboard > Alert List"}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1.25}>
          <Button
            text="Stats"
            muiSVG={<DataSaverOffIcon sx={{ mr: 1 }} fontSize="small" />}
            onClick={hanldeScreens("status")}
            variant={"primary"}
            sx={{
              borderColor: "#003272",
              color: !screens.includes("status") ? "primary.dark" : "#fff",
              bgcolor: screens.includes("status") ? "#003272" : "transparent",
            }}
          />
          <Button
            text="Filter"
            display
            muiSVG={<TuneIcon sx={{ mr: 1 }} fontSize="small" />}
            onClick={hanldeScreens("filter")}
            variant={"primary"}
            sx={{
              borderColor: "#003272",
              color: !screens.includes("filter") ? "primary.dark" : "#fff",
              bgcolor: screens.includes("filter") ? "#003272" : "transparent",
            }}
          />
          <Typography variant="caption" fontWeight={500} color="textPrimary">
            Per Pages
          </Typography>

          <InputField
            type="number"
            onKeyDown={handlePage}
            value={pagination.per_page}
            onChange={(e) =>
              setPagination((pre) => ({
                ...pre,
                per_page: parseInt(e.target.value),
              }))
            }
            sx={{ maxWidth: 121 }}
          />
        </Box>
      </Box>
      {/* Filter & sort Buttons */}
      <Box display="flex" gap={1.25} alignItems="center">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="delete-label">
            {selectedRows.length < 1
              ? `No alert selected`
              : `${selectedRows.length} alerts selected`}
          </InputLabel>
          <Select
            sx={{ minWidth: 180 }}
            color="error"
            labelId="delete-label"
            disabled={selectedRows.length < 1}
            label="Action"
            value=""
            onChange={handleActionSelect}
          >
            <MenuItem value="">
              <em>Select Action</em>
            </MenuItem>
            <MenuItem value={"delete"}>
              <DeleteIcon color="error" />
              Delete
            </MenuItem>
          </Select>
          <BulkDeleteDialogBox />
        </FormControl>
        <Selector
          title={"Quick Filter"}
          value={filters.quickFilter}
          handleChange={handleQuickFilter}
          options={quick_filter_options}
        />
        <Selector
          value={filters.sortBy}
          handleChange={handleSort}
          title={"Sort By"}
          options={sort_options}
        />
      </Box>
      {/* </Box> */}
      {/* Graphs */}
      <Collapse in={screens.find((s) => s === "status")}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6} xl={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={2.5}
              sx={{ borderRadius: 1.5 }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Top 5 Types</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, type_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.type_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.type_list ? PiecartIcon : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, type_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.type_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{ color: statsuViews.type_list ? "#1173FF" : "#fff" }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1" height="100%">
                {/* Adjusted height here */}
                {statuses.by_type.length > 0 ? (
                  !statsuViews.type_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_type} />
                    </Box>
                  ) : (
                    <PieChart customLegend data={statuses.by_type} />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={2.5}
              sx={{ borderRadius: 1.5 }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Alerts by Status</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, status_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.status_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.status_list ? PiecartIcon : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, status_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.status_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{
                        color: statsuViews.status_list ? "#1173FF" : "#fff",
                      }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1">
                {statuses.by_status.length > 0 ? (
                  !statsuViews.status_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_status} />
                    </Box>
                  ) : (
                    <GenderBarChart data={statuses.by_status} />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={2.5}
              sx={{ borderRadius: 1.5 }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Top 5 Tags</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, tags_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.tags_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.tags_list ? PiecartIcon : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, tags_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.tags_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{ color: statsuViews.tags_list ? "#1173FF" : "#fff" }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1">
                {statuses.by_tags.length > 0 ? (
                  !statsuViews.tags_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_tags} />
                    </Box>
                  ) : (
                    <PieChart data={statuses.by_tags} graphHeight="95%" />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      {/* Filter & sort Buttons */}
      <Collapse in={screens.includes("filter")}>
        {/* <FiltersAlertFields onSearch={() => fetchAlerts(undefined, true)} /> */}
        <FilterFields
          filterField={AlertFilterFields}
          onSearch={onSearch}
          _for="alerts"
        />
      </Collapse>
      {/* Table */}
      <Box
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={2.5}
        sx={{ borderRadius: 1.5, maxWidth: "100%", overflowX: "auto" }}
        height="100%"
      >
        <CustomTable
          permissions={props.permissions}
          columns={columns}
          data={data.data}
          CustomTableRow={CustomAlertsRow}
          loading={data.loading}
          onPageChange={onPageChange}
          page={pagination.page}
          total_count={Math.ceil(
            (data.count < 0 ? props.alertsCount : data.count) /
            pagination.per_page
          )}
          openDetailDialog={openDetailDialog}
          detailDialog={detailDialog}
          fetchAlertDetail={fetchAlertDetail}

          followORunfollowAlert={followORunfollowAlert}
          readOrunReadAlert={readOrunReadAlert}
          fetchAlertResponders={fetchAlertResponders}
          checkboxies
          SelectRows={setSelectedRows}
        />
      </Box>
      {/* Dialog */}
      <AlertDetaildialog
        permissions={props.permissions}
        setOpen={openDetailDialog}
        open={detailDialog}
        data={alertData.data}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        navigator={navigator}
        obervables={alertData.observables}
        createCaseFromAlert={createCaseFromAlert}
        followORunfollowAlert={followORunfollowAlert}
        readOrunReadAlert={readOrunReadAlert}
        deleteDialog={deleteDialog}
        openDeleteDialog={openDeleteDialog}
        refrashFunc={() => {
          fetchAlerts();
          openDetailDialog(false);
        }}
      />
      {/* responsder  */}
      <AlertRespondersDialog
        open={responderDialog}
        setOpen={openresponderDialog}
        data={responders}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions,
    alertsCount: state.alertsCount,
  };
};
export default connect(mapStateToProps, null)(AlertsList);
