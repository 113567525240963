import React from "react";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import DateRangeSelector from "../../../../components/Field/DateRangeSeletor";
import { useState } from "react";
import SynchronizedLineChart from "../../../../components/charts/SynchronizedLineChart";
import Polarchart from "../../../../components/charts/Polarchart.js";
import StackedBarChart from "../../../../components/charts/StackedBarChart2";
import { wazuhIndex } from "../../../../config/wazuh";
import {
  mitre_attac_by_tactics,
  mitre_evolution_query,
  mitre_rule_lvl_by_att,
  mitre_rule_lvl_by_tactics,
  mitre_top_tactics,
} from "../../enums/enums";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateForGraph, graphs_colors } from "../../../../utils/utils.js";
import DubblePieChart from "../../../../components/charts/DubblePieChart.js";

function Mitre_Attach({ agentId, dateRange, setDateRange, setGraphs }) {
  const navigation = useNavigate();
  const initiaialAlertsData = {
    loading: false,
    evolution: { labels: [], values: [] },
    top_tactics: { labels: [], values: [] },
    rule_lvl_by_att: [[], []],
    rule_lvl_by_tactic: [[], []],
    attack_by_tactic: [],

  };
  const [data, setData] = useState(initiaialAlertsData);

  const report_graphs = [
    {
      id: "graph_alts_evl_over_time",
      label: "Alerts evolution over time",
      xs: 12,
      sx: {
        "& .recharts-legend-wrapper": {
          right: "-40px !important"
        }
      }

    },
    {
      id: "ghp_mitr_att_by_tactic",
      label: "MITRE attacks by tactic",
      xs: 6

    },
    {
      id: "ghp_rul_lvl_by_attc",
      label: "MITRE attacks by tactic",
      xs: 6
    },
    {
      id: "ghp_rule_lvl_by_tactic",
      label: "MITRE attacks by tactic",
      xs: 6
    },
    {
      id: "ghp_tp_tactics",
      label: "Top tactics",
      component: <Polarchart data={data.top_tactics} />,
      type: "component",
      xs: 6
    },

  ];

  const [query, setQuery] = useState("");


  const handleSearch = (e) => {
    const dqlQuery = e.target.value;
    setQuery(dqlQuery);
  };
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      CallAPIS();
    }
  };
  const FetchLvlEvolution = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        mitre_evolution_query(agentId, query, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;

        const buckets = Object.values(aggregations)[0].buckets;
        // Create an empty object to hold the transformed data
        const transformedData = {};
        const uniqueSet = new Set();
        // Iterate over the API response to format the data
        buckets.forEach((item) => {
          const timestamp = new Date(item.key_as_string);
          // Format the timestamp as "HH:mm"
          // const formattedTime = timestamp.toLocaleTimeString("en-US", {
          //   hour: "2-digit",
          //   minute: "2-digit",
          // });
          const formattedTime = formatDateForGraph(timestamp);

          const buckets = item[3].buckets;

          // Create an object with the formatted time as the 'name' property
          const formattedItem = { name: formattedTime };

          // Iterate over the 'buckets' and add each key as a property to the formatted item
          buckets.forEach((bucket) => {
            formattedItem[bucket.key.toString()] = bucket.doc_count;
            uniqueSet.add(bucket.key.toString());
          });

          // Store the formatted item in the transformedData object
          transformedData[formattedTime] = formattedItem;
        });
        // Convert the transformed data into an array
        const chartData = Object.values(transformedData);
        setData((pre) => ({
          ...pre,
          evolution: { labels: Array.from(uniqueSet), values: chartData },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const FetchTopTactic = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        mitre_top_tactics(agentId, query, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setData((pre) => ({ ...pre, top_tactics: data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const FetchRuleLevelByAttacker = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        mitre_rule_lvl_by_att(agentId, query, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        const innerData = [];
        const outerData = [];
        // buckets.map((b) => {
        //   data.labels.push(b.key);
        //   data.values.push(b.doc_count);
        //   return "";
        // });
        let index = 0;
        const levels_set = {};
        for (const bucket of buckets) {
          const levels = Object.values(bucket).find(obj => typeof obj === "object");
          const level_bucket = levels?.buckets[0]
          innerData.push({ name: bucket.key, value: bucket.doc_count, fill: graphs_colors[index] })
          let _fill = "";
          if (!levels_set.hasOwnProperty(level_bucket?.key)) {
            levels_set[level_bucket?.key] = graphs_colors[index + 1]
            _fill = graphs_colors[index + 1]
          }
          else {
            _fill = levels_set[level_bucket?.key]
          }
          outerData.push({ name: `${level_bucket?.key}-${bucket.key}`, value: level_bucket?.doc_count, fill: _fill })
          index += 2;
        }
        setData((pre) => ({ ...pre, rule_lvl_by_att: [innerData, outerData] }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const FetchRuleLevelByTactic = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        mitre_rule_lvl_by_tactics(agentId, query, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        const innerData = [];
        const outerData = [];
        let index = 0;
        const levels_set = {};
        for (const bucket of buckets) {
          const levels = Object.values(bucket).find(obj => typeof obj === "object");
          const level_bucket = levels?.buckets[0]
          innerData.push({ name: bucket.key, value: bucket.doc_count, fill: graphs_colors[index] })
          let _fill = "";
          if (!levels_set.hasOwnProperty(level_bucket?.key)) {
            levels_set[level_bucket?.key] = graphs_colors[index + 1]
            _fill = graphs_colors[index + 1]
          }
          else {
            _fill = levels_set[level_bucket?.key]
          }
          outerData.push({ name: `${level_bucket?.key}-${bucket.key}`, value: level_bucket?.doc_count, fill: _fill })
          index += 2;
        }
        setData((pre) => ({ ...pre, rule_lvl_by_tactic: [innerData, outerData] }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const FetchAttackByactic = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        mitre_attac_by_tactics(agentId, query, from_date, to_date)
      )
      .then((res) => {
        const rawResponse = res.data;
        const legends = rawResponse.aggregations["2"].buckets.map(
          (bucket) => bucket.key
        );

        const data = legends.reduce((formattedData, legend) => {
          const legendData = rawResponse.aggregations["2"].buckets.find(
            (bucket) => bucket.key === legend
          );

          if (legendData) {
            const counts = legendData["3"].buckets.reduce(
              (countsArray, bucket) => {
                countsArray[bucket.key] = bucket.doc_count;
                return countsArray;
              },
              {}
            );

            formattedData.push({
              name: legend,
              ...counts,
            });
          }

          return formattedData;
        }, []);
        setData((pre) => ({ ...pre, attack_by_tactic: data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function CallAPIS() {
    FetchLvlEvolution();
    FetchTopTactic();
    FetchRuleLevelByAttacker();
    FetchRuleLevelByTactic();
    FetchAttackByactic();
  }
  useEffect(() => {
    if (agentId === undefined) {
      navigation(-1);
      return;
    }
    FetchLvlEvolution();
    FetchTopTactic();
    FetchRuleLevelByAttacker();
    FetchRuleLevelByTactic();
    FetchAttackByactic();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setGraphs(report_graphs);
  }, [data])


  return (
    <Box mt={5}>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} lg={7}>
          <TextField
            size="small"
            fullWidth
            placeholder="Search"
            value={query}
            onChange={handleSearch}
            onKeyDown={handleSearchKeyDown}
          />
        </Grid>
        <Grid item xs={10} sm lg={4}>
          {/* <DatTimeSelect /> */}
          <DateRangeSelector
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Grid>
        <Grid item xs={2} sm>
          <Button
            startIcon={<HistoryIcon fontSize="small" />}
            fullWidth
            variant="contained"
            color="primary"
            onClick={CallAPIS}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      {/* Graphs */}
      <Grid container spacing={2} mt={2}>
        {/* Alerts evolution over time */}
        <Grid item xs={12} md={8}>
          <Box
            display="flex"
            flexDirection="column"
            component={Paper}
            elevation={3}
            sx={{ p: 2 }}
          >
            <Typography variant="subtitle2">
              Alerts evolution over time
            </Typography>
            <Box
              height="90%"
              sx={{ height: 250 }}
              id="graph_alts_evl_over_time"
            >
              {data.evolution.labels.length > 0 ? (
                data.evolution.labels && (
                  <SynchronizedLineChart
                    data={data.evolution.values}
                    dataKey={data.evolution.labels}
                    height={250}
                  />
                )
              ) : (
                <Box
                  display="flex"
                  minHeight="105px"
                  justifyContent="center"
                  alignItems="center"
                  color="grey"
                >
                  No data
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        {/* Top tactics */}
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            className="h-100"
            flexDirection="column"
            component={Paper}
            elevation={3}
            sx={{ p: 2 }}
          >
            <Typography variant="subtitle2">Top tactics</Typography>
            <Box height="90%" sx={{ p: 2, minHeight: 250 }} id="ghp_tp_tactics">
              <Polarchart data={data.top_tactics} />
            </Box>
          </Box>
        </Grid>
        {/* Rule level by attack */}
        <Grid item xs={12} md={4} lg={4}>
          <Box
            display="flex"
            className="h-100"
            flexDirection="column"
            component={Paper}
            elevation={3}
            sx={{ p: 2 }}
          >
            <Typography variant="subtitle2">Rule level by attack</Typography>
            <Box height="90%" sx={{ height: 250 }} id="ghp_rul_lvl_by_attc">
              {/* <Polarchart data={data.rule_lvl_by_att} /> */}
              <DubblePieChart innerData={data.rule_lvl_by_att[0]} outerData={data.rule_lvl_by_att[1]} />
            </Box>
          </Box>
        </Grid>
        {/* MITRE attacks by tactic */}
        <Grid item xs={12} md={8} lg={4}>
          <Box
            display="flex"
            flexDirection="column"
            component={Paper}
            elevation={3}
            sx={{ p: 2 }}
            minHeight={"100%"}
          >
            <Typography variant="subtitle2">MITRE attacks by tactic</Typography>
            {data.attack_by_tactic.length > 0 ? (
              <Box height="90%" id="ghp_mitr_att_by_tactic">
                <StackedBarChart data={data.attack_by_tactic} height={250} />
              </Box>
            ) : (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            )}
          </Box>
        </Grid>
        {/* Rule level by tactic */}
        <Grid item xs={12} md={4} lg={4}>
          <Box
            display="flex"
            className="h-100"
            flexDirection="column"
            component={Paper}
            elevation={3}
            sx={{ p: 2 }}
          >
            <Typography variant="subtitle2">Rule level by tactic</Typography>
            <Box height="90%" sx={{ height: 250 }} id="ghp_rule_lvl_by_tactic">
              <DubblePieChart innerData={data.rule_lvl_by_tactic[0]} outerData={data.rule_lvl_by_tactic[1]} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Mitre_Attach;
