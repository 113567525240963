import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { Stack } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars-2";

const Item = ({ row }) => (
  <Box
    mt={0.5}
    height={36}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    sx={{ borderLeft: `5px solid ${row.color}` }}
  >
    <Typography variant="caption" sx={{ color: "#A5A5A5" }} ml={1}>
      {row.name}
    </Typography>
    <Chip
      label={row.value}
      sx={{
        minWidth: "34px",
        height: "22px",
        backgroundColor: "#FF3E3E",
        color: "#fff",
      }}
    />
  </Box>
);
const ListView = ({ data }) => {
  return (
    <Scrollbars autoHide height={175}>
      <Stack>
        {data.map((row) => (
          <Item row={row} />
        ))}
      </Stack>
    </Scrollbars>
  );
};

export default ListView;
