import axios from "axios";
import { toast } from "react-toastify";
import { store } from "..";
import { authLogout } from "../store/actions/actions";

var baseURL = window.location.origin + "/api";
const userapi = axios.create({
  baseURL: baseURL,
});

userapi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(authLogout());
    }
    return Promise.reject(error);
  }
);

export default userapi;
