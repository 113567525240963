import { Box, Button, Paper } from "@mui/material";
import React from "react";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const Body = ({
  hide,
  activeStep,
  handleStepChange,
  children,
  disableNext,
}) => {
  return (
    <Box
      display={!hide ? "none" : "block"}
      component={Paper}
      elevation={3}
      width={"100%"}
      p={"40px 40px 10px 40px"}
      maxWidth={805}
    >
      {children}
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button
          startIcon={<KeyboardReturnIcon />}
          onClick={handleStepChange(activeStep - 1)}
          variant="outlined"
          disabled={activeStep === 0}
        >
          Back
        </Button>
        {activeStep !== 3 && (
          <Button
            endIcon={<KeyboardTabIcon />}
            onClick={handleStepChange(activeStep + 1)}
            variant="outlined"
            disabled={disableNext}
          >
            {"Next"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Body;
