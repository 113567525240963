import { Box, Typography } from '@mui/material';
import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend } from 'recharts';
// Parse data for inner pie chart
const renderLegend = (props) => {
    const { payload } = props;

    return (
        <Box display="flex" flexDirection="column" gap={2.525}>
            {payload.map((entry, index) => {
                const color = entry.color;
                return (
                    <Box
                        key={entry.value}
                        bgcolor={"lightblue"}
                        p={0.75}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius={"14px"}
                        gap={2}
                        maxWidth={200}
                    >
                        <Box display="flex" gap={0.5} alignItems="center">
                            <svg width="18" height="18">
                                <circle cx="50%" cy="50%" r="50%" fill={color} />
                            </svg>
                            <Typography
                                sx={{
                                    overflowWrap: "break-word",
                                    wordWrap: "break-word",
                                    wordBreak: "break-word",
                                }}
                                variant="caption"
                                color="#000"
                            >
                                {entry.value}
                            </Typography>
                        </Box>
                        <Typography variant="body2">{entry.payload.value}</Typography>
                    </Box>
                );
            })}
        </Box>
    );
};
const renderLegend2 = (props) => {
    const { payload } = props;

    return (
        <Box
            component={"ul"}
            sx={{
                maxWidth: "250px",
                "& li": {
                    fontSize: "12px ",
                },
            }}
        >
            {payload.map((entry, index) => (
                <Box
                    key={index}
                    component="li"
                    sx={{
                        wordWrap: "break-word",
                        "&::marker": {
                            color: entry.color,
                        },
                    }}
                >
                    {entry.value}
                </Box>
            ))}
        </Box>
    );

};

const DubblePieChart = ({ innerData, outerData, customLegend = false, sx }) => {


    return (
        <Box
            component={ResponsiveContainer}
            width="100%"
            height="100%"
            sx={{
                "& .giveFocusOutlineNone": { outline: "none" },
                "& .recharts-surface": { height: "100%", overflow: "inherit" },
                ...sx,
            }}
        >
            <PieChart width={400} height={400}>
                <Pie data={innerData} dataKey="value" cx="50%" cy="50%" outerRadius={60} />
                <Pie data={outerData} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} label />
                <Tooltip />
                <Legend
                    verticalAlign="middle"
                    align="right"
                    layout="vertical"
                    iconType="circle"
                    content={customLegend ? renderLegend : renderLegend2}
                    margin={{ right: 0 }}
                />
            </PieChart>
        </Box>
    );
};

export default DubblePieChart;
