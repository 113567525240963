import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useTheme } from "@mui/material/styles";

export const Colors = [
  {
    stroke: "#6e9bbd",
    fill: "#8ac6d1",
  },
  {
    stroke: "#8caf6f",
    fill: "#b4d7a7",
  },
  {
    stroke: "#000",
    fill: "#8ac6d1",
  },
  { stroke: "#3b72aa", fill: "#73a9ce" },
  { stroke: "#9a8ad0", fill: "#c9b8e2" },
  { stroke: "#bc2c1a", fill: "#f37567" },
  { stroke: "#916a4b", fill: "#d1bfa9" },
  { stroke: "#41a68d", fill: "#75d4c2" },
  { stroke: "#c2953f", fill: "#e8c487" },
  { stroke: "#35424a", fill: "#657d87" },
  { stroke: "#5f82a0", fill: "#8cb0c5" },
  { stroke: "#be4c39", fill: "#f07866" },
  { stroke: "#327c6c", fill: "#68b1a0" },
];

const SynchronizedLineChart = ({
  data,
  dataKey = [],
  fill = true,
  strokeWidth = 2,
  legend = true,
  height = 300,
}) => {
  const theme = useTheme();
  const lg_down = useMediaQuery(theme.breakpoints.down("lg"));

  const [hiddenSeries, setHiddenSeries] = useState({});

  const handleLegendClick = (legendItem) => {
    const { dataKey: clickedDataKey } = legendItem;

    setHiddenSeries((prevHiddenSeries) => ({
      ...prevHiddenSeries,
      [clickedDataKey]: !prevHiddenSeries[clickedDataKey],
    }));
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      {dataKey && (
        <AreaChart
          width={"100%"}
          data={data}
          syncId="anyId"
          margin={
            lg_down
              ? {}
              : {
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" fontSize={12} />
          {!lg_down && <YAxis />}
          <Tooltip />
          {legend && (
            <Legend
              layout={lg_down ? "horizontal" : "vertical"}
              align={lg_down ? "center" : "right"}
              verticalAlign="middle"
              iconType="circle"
              wrapperStyle={{ top: 5, right: 0, maxWidth: "226px" }}
              onClick={handleLegendClick}
            />
          )}
          {dataKey.map((key, i) => (
            <Area
              key={key}
              type="monotone"
              dataKey={key}
              // stackId="0"
              stroke={Colors[i].stroke}
              fill={fill ? Colors[i].fill : "none"}
              strokeWidth={strokeWidth}
              hide={hiddenSeries[key]}
              // dot={<CustomDot color={Colors[i].stroke} />}
            />
          ))}
        </AreaChart>
      )}
    </ResponsiveContainer>
  );
};

export default SynchronizedLineChart;
