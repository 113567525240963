import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import TitleLogo from "../../static/logos/TitleLogo.svg";
import TitleLogo from "../../static/logos/logo.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Btn from "../../components/Buttons/BootStrap/Btn";
import { connect } from "react-redux";
import { authLogin } from "../../store/actions/actions";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(undefined);
  let brand = localStorage.getItem("brand");
  const [logo, setLogo] = useState(brand ? JSON.parse(brand)?.org_logo : TitleLogo);

  useEffect(() => {
    if (props.brand?.org_logo) {
      setLogo(props.brand?.org_logo);
    }
    else if (brand) {
      brand = JSON.parse(brand);
      setLogo(brand.org_logo);
    }
  }, [props.brand])

  const toggleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  // const validateUsername = (username) => {
  //   return /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{3,}$/.test(username);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    // if (!validateUsername(data.username)) {
    //   // Add logic to handle invalid username
    //   toast.warning(
    //     "Invalid username. Username should have a minimum length of 4 characters and contain only letters, numbers, and underscore."
    //   );
    //   return;
    // }
    props.login(
      data.username,
      data.password,
      props.enableOTP ? otp : props.enableOTP
    );
  };

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={2}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit}
      sx={{ background: "linear-gradient(0deg, #3485F9 40%, #ffffff 100%)" }}
    >
      <Box
        display="flex"
        width={"550px"}
        borderRadius={"10px"}
        bgcolor={"transparent"}
        // elevation={0}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="50px 58px"
        gap={2.5}
      >
        {/* <Box component="img" src={props.brand.org_logo || TitleLogo} maxWidth="160px" /> */}
        <Box component="img" width="95%" src={logo} minWidth="160px" />
        {/* Wrap the content in a form element */}

        <Box width="100%">
          <Typography variant="subtitle1" fontWeight={600} color="#fff">
            Username
          </Typography>
          <TextField
            required
            fullWidth
            size="small"
            name="username"
            sx={{
              color: "#fff",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#fff",
              },

              "& .MuiInputBase-root": {
                borderRadius: "10px",
                color: "#fff",
              },
              "& .MuiFormLabel-root": {
                color: "#fff",
              },
            }}
            placeholder={"example_123"}
            InputProps={{
              readOnly: props.enableOTP,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" disableRipple>
                    <AccountCircleIcon
                      sx={{ color: "#fff", width: 28, height: 28 }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box width="100%">
          <Collapse unmountOnExit={false} in={!props.enableOTP}>
            <Typography variant="subtitle1" fontWeight={600} color="#fff">
              Password
            </Typography>
            <TextField
              hiddenLabel
              required
              fullWidth
              size="small"
              autoComplete={"false"}
              type={showPassword ? "text" : "password"}
              name="password"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "& .MuiInputBase-root": {
                  color: "#fff",
                  borderRadius: "10px",
                },
                "& .MuiFormLabel-root": {
                  color: "#fff",
                },
              }}
              placeholder="••••••••••"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={toggleShowPassword}>
                      {!showPassword ? (
                        <RemoveRedEyeIcon
                          sx={{ color: "#fff", fontSize: 28 }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{ color: "#fff", fontSize: 28 }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Collapse>

          <Collapse unmountOnExit timeout={1 * 1000} in={props.enableOTP}>
            <Typography variant="subtitle1" fontWeight={600} color="#fff">
              OTP
            </Typography>
            <TextField
              hiddenLabel
              required
              fullWidth
              size="small"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              autoComplete={"false"}
              type={showPassword ? "text" : "password"}
              name="otp"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "& .MuiInputBase-root": {
                  color: "#fff",
                  borderRadius: "10px",
                },
                "& .MuiFormLabel-root": {
                  color: "#fff",
                },
              }}
              placeholder="••••••••••"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={toggleShowPassword}>
                      {!showPassword ? (
                        <RemoveRedEyeIcon
                          sx={{ color: "#fff", fontSize: 28 }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{ color: "#fff", fontSize: 28 }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Collapse>
        </Box>
        {props.loading ? (
          <CircularProgress sx={{ color: "#fff" }} />
        ) : (
          <Btn
            type="submit"
            text="Sign in"
            width="100%"
            disabled={props.loading}
            height={50}
            fontSize={20}
            mt={1.25}
            sx={{
              fontWeight: 700,
              borderRadius: "10px",
              bgcolor: "primary.dark",
            }}
          />
        )}
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    enableOTP: state.enableOtp,
    brand: state.brand
  };
};
const mapDispacthToProps = (dispacth) => {
  return {
    login: (username, password, otp) =>
      dispacth(authLogin(username, password, otp)),
  };
};
export default connect(mapStateToProps, mapDispacthToProps)(Login);
