import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { case_status_enum, severity_enum } from '../../Cases/enums/enums';
import Scrollbars from 'react-custom-scrollbars-2';





const CasesTable = ({ data }) => {

    return (
        <Scrollbars style={{ height: "100%" }}>
            <Table size='small' stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Severity</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Title</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{severity_enum[row.severity].value}</TableCell>
                            <TableCell>{case_status_enum.find(c => c.title === row.status)?.title}</TableCell>
                            <TableCell>{row.title}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Scrollbars>
    );
};

export default CasesTable;
