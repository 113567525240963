import React, { useEffect } from "react";
import {
  Box,
  Container,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Operatingsystem from "./DeployComponents/Operatingsystem";
import Body from "./Body";
import VersionSelect from "./DeployComponents/VersionSelect.js";
import ServerAddress from "./DeployComponents/ServerAddress.js";
import InstallAndStart from "./DeployComponents/InstallAndStart.js";
import {
  SystemStartCmd,
  versionsForOp,
  windowsArchitectures,
} from "../../static/wazuh-consts/wazuhconsts";

const steps = [
  {
    label: "Choose the operating system",
    description: null,
  },
  {
    label: "Version & Architechure",
    description: null,
  },

  {
    label: "DF server address",
    description:
      "This is the address the agent uses to communicate with the defencefusion server. It can be an IP address or a fully qualified domain name (FQDN).",
  },

  {
    label: "Install and start the agent",
    description: `You can use this command to install and enroll the defencefusion agent in one or more hosts.`,
  },
];

const VerticalStepper = (props) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const initialStates = {
    op: {},
    version: [],
    architecture: [],
    serverAdress: "",
    agent: "",
    groups: [],
  };

  const [states, setStats] = React.useState(initialStates);
  const [disableNext, nextDisable] = React.useState(true);
  const [versions, setVersions] = React.useState([]);
  const [architectures, setArchitecture] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [startCMD, setStartCmd] = React.useState("");

  const handleStepChange = (step) => () => {
    setActiveStep(step);
  };
  const Opchange = (obj) => {
    const versions = versionsForOp[obj.id];
    setVersions(versions);
    setStats((prevState) => ({ ...prevState, op: obj }));
  };
  const VersionChange = (obj) => {
    setStats((prevState) => ({ ...prevState, version: obj }));
    const arch = windowsArchitectures.find((item) => item.ids.includes(obj.id));
    setArchitecture(arch.arch);
  };

  const ArchitectureChange = (obj) => {
    setStats((prevState) => ({ ...prevState, architecture: obj }));
  };

  const validServerAdress = () => {
    const ipPattern = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
    const fqdnPattern = /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/;
    if (
      ipPattern.test(states.serverAdress) ||
      fqdnPattern.test(states.serverAdress)
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!props.open) {
      setStats(initialStates);
      nextDisable(true);
    }
    // eslint-disable-next-line
  }, [props.open]);

  useEffect(() => {
    if (states.version) {
      const cmd = SystemStartCmd.find((cmd) =>
        cmd.for.includes(states.version.id)
      );
      setStartCmd(cmd?.cmd);
    }
  }, [states.version]);

  useEffect(() => {
    nextDisable(true);
    if (activeStep === 0 && Object.keys(states.op).length === 0) {
    } else if (
      activeStep === 1 &&
      (Object.keys(states.version).length === 0 ||
        Object.keys(states.architecture).length === 0)
    ) {
      nextDisable(true);
    } else if (
      activeStep === 2 &&
      (!states.serverAdress ||
        !states.agent ||
        states.groups.length < 1 ||
        validServerAdress())
    ) {
      nextDisable(true);
    } else {
      nextDisable(false);
    }
    // eslint-disable-next-line
  }, [activeStep, states]);

  return (
    <Container sx={{ mt: 10 }} maxWidth="xl">
      <Stepper
        activeStep={activeStep}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            {/* onClick={handleStepChange(index)} */}
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Box
                display={{ xs: "flex", lg: "none" }}
                mt={10}
                justifyContent="center"
              >
                <Body
                  hide={activeStep === 0}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                >
                  <Operatingsystem onChange={Opchange} op={states.op} />
                </Body>

                <Body
                  disableNext={disableNext}
                  hide={activeStep === 1}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                >
                  <VersionSelect
                    versions={versions}
                    version={states.version}
                    onChange={VersionChange}
                    architecture={states.architecture}
                    architectures={architectures}
                    onArchitectureChange={ArchitectureChange}
                  />
                </Body>

                <Body
                  disableNext={disableNext}
                  hide={activeStep === 2}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                >
                  <ServerAddress
                    serverAdress={states.serverAdress}
                    onServerAdressChange={(e) => {
                      setStats((preSate) => ({
                        ...preSate,
                        serverAdress: e.target.value,
                      }));
                    }}
                    agent={states.agent}
                    onAgentChange={(name, value) => {
                      console.log(name, value);
                      setStats((preSate) => ({
                        ...preSate,
                        [name]: value,
                      }));
                    }}
                  />
                </Body>

                <Body
                  disableNext={disableNext}
                  hide={activeStep === 3}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                >
                  <InstallAndStart
                    selectedOS={states.op.id}
                    version={states.version.id}
                    architecture={states.architecture.id}
                    startCMD={startCMD}
                    agent={states.agent}
                    serverAdress={states.serverAdress}
                    selectedGroup={states.groups}
                  />
                </Body>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Box display={{ xs: "none", lg: "flex" }} mt={10} justifyContent="center">
        <Body
          disableNext={disableNext}
          hide={activeStep === 0}
          activeStep={activeStep}
          handleStepChange={handleStepChange}
        >
          <Operatingsystem onChange={Opchange} op={states.op} />
        </Body>

        <Body
          disableNext={disableNext}
          hide={activeStep === 1}
          activeStep={activeStep}
          handleStepChange={handleStepChange}
        >
          <VersionSelect
            versions={versions}
            version={states.version}
            onChange={VersionChange}
            architecture={states.architecture}
            architectures={architectures}
            onArchitectureChange={ArchitectureChange}
          />
        </Body>

        <Body
          disableNext={disableNext}
          hide={activeStep === 2}
          activeStep={activeStep}
          handleStepChange={handleStepChange}
        >
          <ServerAddress
            serverAdress={states.serverAdress}
            onServerAdressChange={(e) => {
              setStats((preSate) => ({
                ...preSate,
                serverAdress: e.target.value,
              }));
            }}
            agent={states.agent}
            onAgentChange={(name, value) => {
              setStats((preSate) => ({
                ...preSate,
                [name]: value,
              }));
            }}
          />
        </Body>

        <Body
          disableNext={disableNext}
          hide={activeStep === 3}
          activeStep={activeStep}
          handleStepChange={handleStepChange}
        >
          <InstallAndStart
            selectedOS={states.op.id}
            version={states.version.id}
            architecture={states.architecture.id}
            startCMD={startCMD}
            agent={states.agent}
            serverAdress={states.serverAdress}
            selectedGroup={states.groups}
          />
        </Body>
      </Box>
    </Container>
  );
};

export default VerticalStepper;
