import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Box, IconButton, Snackbar, Typography, CircularProgress, Button } from "@mui/material";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadIcon from '@mui/icons-material/Upload';
import cms from "../../../../config/axiosConfig";
import Btn from "../../../../components/Buttons/BootStrap/Btn";

export default function CaseTemplateUploadDialog({ open, setOpen, reFrashFunc }) {
    const handleClose = () => {
        setOpen(false);
    };

    const [alert, setAlert] = useState({
        show: false,
        severity: "success",
        text: "",
    });

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const validate = () => {
        let valid = true;
        if (!file) {
            setAlert({
                show: true,
                severity: "error",
                text: "Please upload a file.",
            });
            valid = false;
        }
        return valid;
    };

    const handleSubmit = async (e) => {
        if (validate()) {
            setUploading(true);

            const fileText = await file.text();
            const parsedData = JSON.parse(fileText);
            const payload = {
                "name": parsedData["name"],
                "description": parsedData["description"],
                "tlp": parsedData["tlp"],
                "pap": parsedData["pap"],
                "severity": parsedData["severity"],
                "tags": parsedData["tags"],
                "status": parsedData["status"],
                "titlePrefix": parsedData["titlePrefix"],
                "tasks": parsedData["tasks"]
            }

            cms.post("/cms/api/case/template", payload).then(response => {
                if (response.status === 201) {
                    setAlert({
                        show: true,
                        severity: "success",
                        text: "Created successfully",
                    });
                    reFrashFunc();
                    setOpen(false);
                    setFile(null);
                }
            }).catch(err => {
                if (err.response?.data?.message) {
                    setAlert({
                        show: true,
                        severity: "error",
                        text: err.response?.data?.message,
                    });
                }
                else {
                    setAlert({
                        show: true,
                        severity: "error",
                        text: "Failed uploading data",
                    });
                }
            }).finally(res => {
                setUploading(false);
            });


        }
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="md"
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "822px",
                    borderRadius: "15px",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" fontWeight={700}>
                        Import Playbook
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CancelIcon fontSize="large" />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Snackbar
                    open={alert.show}
                    autoHideDuration={6000}
                    onClose={() => {
                        setAlert((prev) => ({ ...prev, show: false }));
                    }}
                >
                    <Alert
                        onClose={() => {
                            setAlert((prev) => ({ ...prev, show: false }));
                        }}
                        severity={alert.severity}
                        sx={{ width: "100%" }}
                    >
                        {alert.text}
                    </Alert>
                </Snackbar>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Box {...getRootProps()} sx={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} p={2} border="2px dashed gray" borderRadius="10px" minHeight={150}>
                        <input {...getInputProps()} />
                        <Typography color={file ? "green" : "#000"} variant="body1" textAlign="center">
                            {file ? `File selected: ${file.name}` : "Drag 'n' drop a file here, or click to select a file"}
                        </Typography>
                    </Box>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Box display="flex" alignItems="center">
                    {uploading && <CircularProgress size={24} sx={{ marginRight: 2 }} />}
                    {/* <Button variant="contained" disabled={uploading} onClick={handleSubmit} size="large" startIcon={<UploadIcon />}>
                        Upload Playbook
                    </Button> */}
                    <Btn
                        display={uploading}
                        onClick={handleSubmit}
                        text={`Upload Playbook`}
                        muiSVG={<UploadIcon />}
                        height={50}
                        fontSize={20}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
}
