import * as React from "react";
import Menu from "@mui/material/Menu";
import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { datatypes_options } from "../../../utils/filters/utils";
import { useState } from "react";
import FooterButtons from "./fixed/FooterButtons";

export default function DateSelector({
  field,
  anchorEl,
  setAnchorEl,
  onChange,
}) {
  const open = Boolean(anchorEl);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onFilterApply = () => {
    onChange(selectedValue);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      dense
      onClose={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            minWidth: 280,
            minHeight: 300,
            borderRadius: 1,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ml: 1,
            p: 2,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        },
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <FormControl fullWidth>
        <FormLabel>{field.label}</FormLabel>
        <RadioGroup
          onChange={handleChange}
          name="radio-buttons-group"
          value={selectedValue}
        >
          {Object.values(datatypes_options.DATE.operators).map((op, index) =>
            op({
              field_name: field.name,
              selectedValue: selectedValue,
              setValue: setSelectedValue,
            })
          )}
        </RadioGroup>
      </FormControl>

      <FooterButtons disabled={false} onApply={onFilterApply} />
    </Menu>
  );
}
