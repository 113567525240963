import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Polarchart from "../../../components/charts/Polarchart.js";
import StackedBarChart from "../../../components/charts/StackedBarChart.js";
import EventsTabs from "./EventsTabs.js";
import SynchronizedLineChart from "../../../components/charts/SynchronizedLineChart.js";
import { useEffect } from "react";
import { FetchAlerts } from "../../../utils/events/EventsIndexs.js";
import HistoryIcon from "@mui/icons-material/History";
import {
  alert_authfail_query,
  alert_authsuccess_query,
  alert_evolution_query,
  alert_evolution_top_5,
  alert_level_12_or_above_query,
  alert_mitre_att_query,
  alert_top_5_query,
  alerts_data_query,
  total_alerts_query,
} from "../enums/enums.js";
import { wazuhIndex } from "../../../config/wazuh.js";
import DateRangeSeletor from "../../../components/Field/DateRangeSeletor.js";
import {
  convertToEpochMillis,
  formatDateForGraph,
} from "../../../utils/utils.js";

const DashBoard = ({ dateRange, setDateRange, setGraphs, setTableData }) => {
  const initiaialAlertsData = {
    loading: false,
    counts: {
      authentication_success: 0,
      authentication_fail: 0,
      total: 0,
      lvl_12_or_above: 0,
    },
    mitre_Att_Cks: { labels: [], values: [] },
    top_5: { labels: [], values: [] },
    evolution: { labels: [], values: [] },
    evolution_top_5: { labels: [], values: [] },
    data: {},
  };
  const [alerts, setAlerts] = useState(initiaialAlertsData);
  const report_graphs = [
    {
      id: "grap_alt_lvl_evl", label: "Alert Level Evolution",
      xs: 12
    },
    {
      id: "grap_alt_evl_tp_5",
      label: "Alert Evolution Top 5 Agents",
      component: <StackedBarChart data={alerts.evolution_top_5} />,
      type: "component",
      xs: 12,
      sx: {
        "& canvas": {
          height: "270px !important"
        }
      }
    },
    {
      id: "graph_tp_mtr_Att",
      label: "Top MITRE ATT&CKS",
      component: <Polarchart data={alerts.mitre_Att_Cks} />,
      type: "component",
      xs: 6,
      sx: {
        "& canvas": {
          height: "270px !important",
          width: "387px !important"
        }
      }
    },
    {
      id: "graph_tp_agents",
      label: "Alert Evolution Top 5 Agents",
      component: <Polarchart data={alerts.top_5} />,
      type: "component",
      xs: 6,
      sx: {
        "& canvas": {
          height: "270px !important",
          width: "387px !important"
        }
      }
    },
  ];
  const [query, setQuery] = useState("");

  const [pagination, setPagination] = React.useState({
    from: 0,
    size: 10,
  });

  const handlePageChange = (newFrom, newSize) => {
    setPagination({ from: newFrom, size: newSize });
    FetchAlertsRow(undefined, undefined, undefined, newFrom, newSize);
  };

  const FetchAlertsCount = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    let queries = [
      total_alerts_query,
      alert_level_12_or_above_query,
      alert_authsuccess_query,
      alert_authfail_query,
    ];
    let _counts = {
      authentication_success: 0,
      authentication_fail: 0,
      total: 0,
      lvl_12_or_above: 0,
    };
    try {
      for (let _query in queries) {
        await FetchAlerts(
          "POST",
          queries[_query](query, from_date, to_date)
        ).then((res) => {
          const value = res.total.value;
          switch (parseInt(_query)) {
            case 0:
              _counts.total = value;
              break;
            case 1:
              _counts.lvl_12_or_above = value;
              break;
            case 2:
              _counts.authentication_success = value;
              break;
            case 3:
              _counts.alert_authfail_query = value;
              break;
            default:
              _counts.total = value;
          }
        });
      }
    } catch { }
    setAlerts((pre) => ({ ...pre, counts: _counts }));
  };

  const FetchMITRE_ATT_CKS = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        alert_mitre_att_query(query, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setAlerts((pre) => ({ ...pre, mitre_Att_Cks: data }));
      });
  };
  const FetchTop5Agents = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    try {
      await wazuhIndex
        .post(
          `/idx/wazuh-alerts-*/_search/`,
          alert_top_5_query(query, from_date, to_date)
        )
        .then((res) => {
          const aggregations = res.data?.aggregations;
          const buckets = Object.values(aggregations)[0].buckets;
          let data = { labels: [], values: [] };
          buckets.map((b) => {
            data.labels.push(b.key);
            data.values.push(b.doc_count);
            return "";
          });
          setAlerts((pre) => ({ ...pre, top_5: data }));
        });
    } catch { }
  };
  const FetchLvlEvolution = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        alert_evolution_query(query, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        // Create an empty object to hold the transformed data
        const transformedData = {};
        const uniqueSet = new Set();
        // Iterate over the API response to format the data
        buckets.forEach((item) => {
          const timestamp = new Date(item.key_as_string);
          const formattedTime = formatDateForGraph(timestamp);

          const buckets = item[3].buckets;

          // Create an object with the formatted time as the 'name' property
          const formattedItem = { name: formattedTime };

          // Iterate over the 'buckets' and add each key as a property to the formatted item
          buckets.forEach((bucket) => {
            formattedItem[bucket.key.toString()] = bucket.doc_count;
            uniqueSet.add(bucket.key.toString());
          });

          // Store the formatted item in the transformedData object
          transformedData[formattedTime] = formattedItem;
        });
        // Convert the transformed data into an array
        const chartData = Object.values(transformedData);
        setAlerts((pre) => ({
          ...pre,
          evolution: { labels: Array.from(uniqueSet), values: chartData },
        }));
      });
  };
  const FetchLvlEvolutionTop5 = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        alert_evolution_top_5(query, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        const TimeStampToString = (stamp) => {
          // const date = new Date(timestamp);
          // const hours = String(date.getUTCHours()).padStart(2, "0");
          // const minutes = String(date.getUTCMinutes()).padStart(2, "0");
          // return `${hours}:${minutes}`;
          return new Date(stamp).toDateString();
        };
        buckets.map((b) => {
          data.labels.push(TimeStampToString(b.key));
          data.values.push(b.doc_count);
          return "";
        });

        setAlerts((pre) => ({ ...pre, evolution_top_5: data }));
      });
  };
  const FetchAlertsRow = async (
    _query = query,
    from_date = dateRange.from_date,
    to_date = dateRange.to_date,
    from = 0,
    size = 10
  ) => {
    setAlerts((pre) => ({ ...pre, loading: true }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        alerts_data_query(
          _query,
          convertToEpochMillis(from_date),
          convertToEpochMillis(to_date),
          from,
          size
        )
      )
      .then((res) => {
        const data = res.data;
        setAlerts((pre) => ({ ...pre, data: data }));
      })
      .finally((res) => {
        setAlerts((pre) => ({ ...pre, loading: false }));
      });
  };
  function CallAPIS() {
    FetchAlertsRow();
    FetchAlertsCount();
    FetchMITRE_ATT_CKS();
    FetchTop5Agents();
    FetchLvlEvolution();
    FetchLvlEvolutionTop5();
  }

  const handleSearch = (e) => {
    const dqlQuery = e.target.value;
    setQuery(dqlQuery);
  };
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      CallAPIS();
    }
  };
  useEffect(() => {
    FetchAlertsRow();
    FetchAlertsCount();
    FetchMITRE_ATT_CKS();
    FetchTop5Agents();
    FetchLvlEvolution();
    FetchLvlEvolutionTop5();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setGraphs(report_graphs);
  }, [alerts])

  return (
    <Box mt={5}>
      {/* Filtes */}
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} lg={7}>
          <TextField
            size="small"
            fullWidth
            placeholder="Search"
            value={query}
            onChange={handleSearch}
            onKeyDown={handleSearchKeyDown}
          />
        </Grid>
        <Grid item xs={10} sm lg={4}>
          {/* <DatTimeSelect /> */}
          <DateRangeSeletor dateRange={dateRange} setDateRange={setDateRange} />
        </Grid>
        <Grid item xs={2} sm>
          <Button
            startIcon={<HistoryIcon fontSize="small" />}
            fullWidth
            variant="contained"
            color="primary"
            onClick={CallAPIS}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      {alerts.data?.hits?.total.value > 1 ? (
        <>
          {/* states */}
          <Grid
            container
            spacing={{ xs: 2, lg: 0 }}
            justifyContent={{ xs: "space-between", lg: "center" }}
          >
            <Grid item xs={6} lg={2}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap={0.5}
              >
                <Typography variant="caption" align="center">
                  Total
                </Typography>
                <Typography variant="h4" fontWeight={300} color="primary">
                  {alerts.counts.total}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap={0.5}
              >
                <Typography variant="caption" align="center">
                  Level 12 or above alerts
                </Typography>
                <Typography variant="h4" fontWeight={300} color="error">
                  {alerts.counts.lvl_12_or_above}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap={0.5}
              >
                <Typography variant="caption" align="center">
                  Authentication failure
                </Typography>
                <Typography variant="h4" fontWeight={300} color="error">
                  {alerts.counts.authentication_fail}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap={0.5}
              >
                <Typography variant="caption" align="center">
                  Authentication success
                </Typography>
                <Typography variant="h4" fontWeight={300} color="success.main">
                  {alerts.counts.authentication_success}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} lg={8}>
              <Box
                display="flex"
                flexDirection="column"
                component={Paper}
                elevation={3}
                sx={{ p: 2, minHeight: 300 }}
              >
                <Typography variant="subtitle2">
                  Alert level evolution
                </Typography>
                <Box height="90%" id="grap_alt_lvl_evl">
                  {alerts.evolution.values && (
                    <SynchronizedLineChart
                      data={alerts.evolution.values}
                      dataKey={alerts.evolution.labels}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                display="flex"
                className="h-100"
                flexDirection="column"
                component={Paper}
                elevation={3}
                sx={{ p: 2, minHeight: 300 }}
              >
                <Typography variant="subtitle2">Top MITRE ATT&CKS</Typography>
                <Box height="90%" id="graph_tp_mtr_Att">
                  <Polarchart data={alerts.mitre_Att_Cks} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                component={Paper}
                elevation={3}
                sx={{ p: 2, height: 300 }}
              >
                <Typography variant="subtitle2">Top 5 agents</Typography>
                <Box height="90%" id="graph_tp_agents">
                  <Polarchart data={alerts.top_5} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box
                display="flex"
                flexDirection="column"
                component={Paper}
                elevation={3}
                sx={{ p: 2, height: 300 }}
              >
                <Typography variant="subtitle2">
                  Alerts evolution - Top 5 agents
                </Typography>
                <Box height="90%" id="grap_alt_evl_tp_5">
                  <StackedBarChart data={alerts.evolution_top_5} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {alerts.data?.hits?.hits && (
                <EventsTabs
                  loading={alerts.loading}
                  data={alerts.data}
                  pagination={pagination}
                  handlePageChange={handlePageChange}
                  setTableData={setTableData}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Box height="65vh">
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            There are no results for selected time range. -
            <strong>Try another one!</strong>
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default DashBoard;
