import { Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const VersionSelect = (props) => {
  const {
    versions,
    onChange,
    version,
    architecture,
    architectures,
    onArchitectureChange,
  } = props;

  const ButtonGrids = ({ value, onClick, obj }) => (
    <Grid item xs={4} key={value.id}>
      <Button
        size="small"
        fullWidth
        onClick={() => onClick(value)}
        variant={obj?.id === value?.id ? "contained" : "outlined"}
      >
        {value.label}
      </Button>
    </Grid>
  );

  return (
    <Grid width={"100%"} container spacing={1.5}>
      <Grid item xs={12}>
        <Typography variant="h6">Versions</Typography>
      </Grid>
      {versions.map((value) => (
        <ButtonGrids value={value} onClick={onChange} obj={version} />
      ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Architecture</Typography>
      </Grid>
      {architectures.map((value) => (
        <ButtonGrids
          value={value}
          onClick={onArchitectureChange}
          obj={architecture}
        />
      ))}
    </Grid>
  );
};

export default VersionSelect;
