import { Box, Button, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import Polarchart from "../../../../components/charts/Polarchart.js";
import React, { useCallback, useEffect, useState } from 'react'
import wazuh from '../../../../config/wazuh.js';
import SortTable from '../../../../components/Display/SortTable.js';
import { useNavigate } from 'react-router-dom';
import AutoCompleteFilters from '../../../../components/Field/AutoComplete/AutoCompleteFilters.js';
import filtersSuggestions from '../../../../static/vnlnerabilities/filterSugessions.js';
import SideCanvas from './components/SideCanvas.js';

const vulnerabilities_columns = [
    { id: "name", label: "Name" },
    { id: "version", label: "Version" },
    { id: "architecture", label: "Architecture" },
    { id: "severity", label: "Severity" },
    { id: "cve", label: "CVE" },
    { id: "cvss2_score", label: "CVSS2 Score" },
    { id: "cvss3_score", label: "CVSS2 Score" },
    { id: "detection_time", label: "Detection Time" },
];
function Dashboard({ agentId, setGraphs, setTableData }) {
    const navigate = useNavigate()
    const [data, setData] = useState({ vulnerabilities: { data: [], count: 0, loading: false }, severities: { data: {}, graphData: { values: [], labels: [] }, loading: true }, summary: { data: { values: [], labels: [] }, loading: false }, last_full_scan: null, last_partial_scan: null })
    const [selectedrow, setSelectedRow] = useState({})
    const [detailBox, showDetailBox] = useState(false)
    const [pagination, setPagination] = useState({
        from: 0,
        size: 10,
    });
    const FetchsSEVERITIES = async () => {
        setData(pre => ({ ...pre, loading: true, severities: { ...pre.severities, loading: true } }))
        wazuh
            .get(
                `/vulnerability/${agentId}/summary/severity`,
            )
            .then((res) => {
                const severities = res.data["data"]?.severity
                setData(pre => ({
                    ...pre, severities: {
                        data: severities,
                        graphData: { values: Object.values(severities), labels: Object.keys(severities) },
                        loading: false
                    }
                }))
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setData(pre => ({ ...pre, loading: true, severities: { ...pre.severities, loading: false } }))
            });
    };
    const FetchLastScan = async (
    ) => {
        wazuh
            .get(
                `/vulnerability/${agentId}/last_scan`,
            )
            .then((res) => {
                const affected_items = res.data?.data?.affected_items
                if (affected_items) {
                    setData(pre => ({ ...pre, last_full_scan: affected_items[0]?.last_full_scan, last_partial_scan: affected_items[0]?.last_partial_scan }))
                }

            }).catch(err => {
                console.log(err)
            })
    };
    const FetchSummary = async (
    ) => {
        wazuh
            .get(
                `/vulnerability/${agentId}/summary/name`,
            )
            .then((res) => {
                const names = res.data?.data?.name

                if (Object.keys(names).length > 0) {
                    setData(pre => ({ ...pre, summary: { data: { values: Object.values(names), labels: Object.keys(names) }, loading: false } }))
                }

            }).catch(err => {
                console.log(err)
            })
    };

    const FetchVulnerabilites = async (_params) => {
        setData(pre => ({ ...pre, vulnerabilities: { ...pre.vulnerabilities, loading: true } }))
        const { from, size } = pagination;
        wazuh
            .get(
                `/vulnerability/${agentId}`, {
                params: {
                    select: "cve,architecture,version,name,severity,cvss2_score,cvss3_score,detection_time,title,condition,updated,published,external_references",
                    limit: size,
                    offset: from,
                    ..._params
                }
            },
            )
            .then((res) => {
                const affected_items = res.data?.data?.affected_items;
                const total_affected_items = res.data?.data?.total_affected_items;
                if (affected_items) {
                    setData(pre => ({ ...pre, vulnerabilities: { ...pre.vulnerabilities, data: affected_items, count: total_affected_items } }))
                }
                const headers = vulnerabilities_columns.map(cl => cl.label)
                const body = []
                const rows = affected_items;
                for (const row of rows) {
                    const _row = []

                    for (const col of vulnerabilities_columns) {
                        _row.push(row[col.id])
                    }
                    body.push(_row)
                }
                setTableData({ headers: [headers], body: body })
            }).catch(error => {
                console.log(error)
            }).finally(res => {
                setData(pre => ({ ...pre, vulnerabilities: { ...pre.vulnerabilities, loading: false } }))
            })
    }

    const report_graphs = [
        {
            id: "graph_severities",
            label: "SEVERITY",
            component: <Polarchart data={data.severities?.graphData} />,
            type: "component",
            xs: 6,
            sx: {
                "& canvas": {
                    height: "260px !important"
                }
            }
        },
        {
            id: "graph_summary",
            label: "SUMMARY",
            component: <Polarchart data={data.summary?.data} />,
            type: "component",
            xs: 6,
            sx: {
                "& canvas": {
                    height: "250px !important"
                }
            }

        },
    ]
    useEffect(() => {
        FetchsSEVERITIES();
        FetchLastScan();
        FetchSummary();
    }, [])

    useEffect(() => {
        if (agentId === undefined) {
            navigate(-1);
            return;
        }
        FetchVulnerabilites();
        // eslint-disable-next-line
    }, [pagination.from, pagination.size, agentId]);

    useEffect(() => {
        setGraphs(report_graphs);
    }, [data.summary, data.severities?.graphData])

    const onSearch = (query) => {
        const new_query = query
            .replace(/\s/g, "")
            .replace("OR", ",")
            .replace("AND", ";");
        FetchVulnerabilites({ q: new_query });
    };
    const SideDetailBox = useCallback(() => {
        return <SideCanvas agentId={agentId}
            show={detailBox}
            setShow={() => { showDetailBox(false); setSelectedRow({}) }}
            row={selectedrow}
            last_full_scan={data.last_full_scan}
            last_partial_scan={data.last_partial_scan}
            placement="end" />
    }, [selectedrow, detailBox])
    return (
        <Box mt={5}>
            <Grid container spacing={2}>
                <Grid item container spacing={2} height="100%">
                    <Grid item xs={12} md={3}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            gap={3}
                            elevation={3}
                            width="100%"
                            height="100%"
                            sx={{ p: 2 }}

                        >
                            {/* title */}
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                height={"90%"}
                            >
                                <Typography variant="subtitle2">
                                    SEVERITY
                                </Typography>
                                <Box height="100%" id="graph_severities">
                                    <Polarchart data={data.severities?.graphData} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            gap={3}
                            elevation={3}
                            width="100%"
                            height="100%"
                            sx={{ p: 2 }}
                        >
                            {/* title */}
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="subtitle2" >
                                    DETAILS
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" gap={0.5} p={2} >
                                <Box display="flex" alignItems="center" flexDirection="column" gap={0.5}>
                                    <Typography variant='subtitle2' fontWeight={400} >
                                        Critical
                                    </Typography>
                                    <Typography variant='h5' color="error">
                                        {data.severities.data?.Critical || 0}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" flexDirection="column" gap={0.5}>
                                    <Typography variant='subtitle2' fontWeight={400} >
                                        High
                                    </Typography>
                                    <Typography variant='h5' color="warning">
                                        {data.severities.data?.High || 0}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" flexDirection="column" gap={0.5}>
                                    <Typography variant='subtitle2' fontWeight={400} >
                                        Medium
                                    </Typography>
                                    <Typography variant='h5' color="primary">
                                        {data.severities.data?.Medium || 0}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" flexDirection="column" gap={0.5}>
                                    <Typography variant='subtitle2' fontWeight={400} >
                                        Low
                                    </Typography>
                                    <Typography variant='h5' color="success">
                                        {data.severities.data?.Low || 0}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="space-between" gap={2} p={2}>
                                <Box display="flex" alignItems="center" flexDirection="column" gap={0.5}>
                                    <Typography variant='subtitle2' fontWeight={400}>
                                        Last full scan
                                    </Typography>
                                    <Typography variant='caption' color="primary" align="center">
                                        {data.last_full_scan ? `${new Date(data.last_full_scan).toDateString()} @ ${new Date(data.last_full_scan).toTimeString()}` : "-"}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" flexDirection="column" gap={0.5}>
                                    <Typography variant='subtitle2' fontWeight={400}>
                                        Last partial scan
                                    </Typography>
                                    <Typography variant='caption' color="primary" align="center">
                                        {data.last_partial_scan ? `${new Date(data.last_partial_scan).toDateString()} @ ${new Date(data.last_partial_scan).toTimeString()}` : "-"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box
                            component={Paper}
                            display="flex"
                            flexDirection="column"
                            gap={3}
                            elevation={3}
                            width="100%"
                            height="100%"
                            sx={{ p: 2 }}
                        >
                            {/* title */}
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                height={"90%"}
                            >
                                <Typography variant="subtitle2" >
                                    SUMMARY
                                </Typography>
                                <Box height="100%" id="graph_summary">
                                    <Polarchart data={data.summary?.data} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteFilters
                        filtersSuggestions={filtersSuggestions(agentId)}
                        onSearch={onSearch}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        {data.vulnerabilities.loading ? <Box width="100%"><LinearProgress /></Box> :
                            <SortTable
                                columns={vulnerabilities_columns}
                                rows={data.vulnerabilities?.data}
                                pagination={pagination}
                                setPagination={setPagination}
                                total={data.vulnerabilities.count}
                                onRowClick={(policy_id, row) => {
                                    console.log(row);
                                    setSelectedRow(row);
                                    showDetailBox(true);
                                }
                                }
                            />
                        }
                    </Paper>
                </Grid>
            </Grid>
            <SideDetailBox />
        </Box>
    )
}

export default Dashboard
