import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Box,
  ButtonBase,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InputField from "../../../components/Field/BootStrap/InputField";
import { tlp_enum } from "../enums/enums";
import CircleIcon from "@mui/icons-material/Circle";
import CancelIcon from "@mui/icons-material/Cancel";
import Btn from "../../../components/Buttons/BootStrap/Btn";
import PlusIcon from "../../../static/icons/plus-icon.svg";
import ReactScrollbar from "react-scrollbar";
import { useRef, useState } from "react";
import SwitchIOS from "../../../components/Buttons/SwitchIOS.js";
import cms from "../../../config/axiosConfig";
import FileUploadButton from "../../../components/Buttons/FileUploadButton";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { toast } from "react-toastify";
import PasswordIcon from "@mui/icons-material/Password";
import { sleep } from "../../../utils/utils.js";

export default function CreateorCaseObservables({
  open,
  setOpen,
  caseId,
  refreshFunc,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const initialFields = {
    dataType: "",
    ioc: false,
    sighted: false,
    ignoreSimilarity: false,
    tlp: 0,
    message: "",
    tags: [],
    data: "",
    zipPassword: "",
    attachment: "",
    isZip: false,
  };
  const [fields, setFields] = useState(initialFields);
  const [formate, setFormate] = useState("perline");
  const inputCasesTaskRef = useRef(null);
  const [alert, setAlert] = useState({
    show: false,
    severity: "success",
    text: "",
  });

  // Add  functions

  const addCasesTask = () => {
    const trimmedValue = inputCasesTaskRef.current.value.trim();
    if (trimmedValue && !fields.tags.includes(trimmedValue)) {
      setFields((pre) => ({ ...pre, tags: [...pre.tags, trimmedValue] }));
      inputCasesTaskRef.current.value = "";
    } else if (fields.tags.includes(trimmedValue)) {
      setAlert({
        show: true,
        severity: "warning",
        text: `${trimmedValue} already exist.`,
      });
      sleep(() => {
        setAlert();
      }, [1500]);
    }
  };
  const ADDTLP = (value) => {
    setFields({ ...fields, tlp: value });
  };

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields((pre) => ({ ...pre, [name]: value }));
  };
  const handleValueField = (e) => {
    const value = e.target.value;
    let _value = "";
    if (formate === "multiline") {
      _value = JSON.stringify(value);
    } else {
      _value = value.split("\n");
    }
    setFields((pre) => ({ ...pre, data: _value }));
  };
  // Remove  functions
  const removeCasesTask = (tagToRemove) => {
    setFields((pre) => ({
      ...pre,
      tags: pre.tags.filter((tag) => tag !== tagToRemove),
    }));
  };
  // andle Submit
  const validate = () => {
    let valid = true;
    let message = "";
    let value_field = null;
    try {
      value_field = document.getElementById("value_textfield").value;
    } catch {}

    if (!fields.dataType) {
      message = "Please select type";
      valid = false;
    } else if (fields.dataType === "file" && !fields.attachment) {
      message = "Attachment required";
      valid = false;
    } else if (value_field != null) {
      if (!value_field) {
        message = "Value is required";
        valid = false;
      }
    } else if (fields.tags.length < 1) {
      message = "Atlest one tag is required";
      valid = false;
    }

    if (!valid) {
      setAlert({ show: true, severity: "error", text: message });
    } else {
      setAlert({ show: false, severity: "error", text: "" });
    }

    return valid;
  };
  const handleAttachment = (file) => {
    // console.log(file)
    if (file) {
      if (fields.isZip && file.type !== "application/x-zip-compressed") {
        toast.error("Please select 'Zip' file");
        return;
      }
      setFields((pre) => ({ ...pre, attachment: file }));
    }
  };
  const handleSubmit = async (e) => {
    let formData = new FormData();
    let _fields = { ...fields };

    if (_fields.dataType === "file") {
      delete _fields["values"];
      delete _fields["data"];
      if (!fields.isZip) {
        delete _fields["zipPassword"];
      }
    }

    for (let key in _fields) {
      if (key !== "attachment") {
        formData.set(key, _fields[key]);
      }
    }
    if (_fields.dataType !== "file") {
      delete _fields["zipPassword"];
      delete _fields["isZip"];
    }

    let _data = {};
    if (_fields.attachment) {
      _data["attachment"] = _fields.attachment;
    }
    delete _fields["attachment"];
    _data["_json"] = JSON.stringify(_fields);
    if (validate()) {
      await cms
        .post(`/cms/api/case/~${caseId}/artifact`, _data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 201) {
            refreshFunc(false, true);
            setFields(initialFields);
            setOpen(false);
          }
          if (res.status === 207) {
            toast.warning("Observable already exists");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 500) {
            toast.error("Something wents wrong.");
            return;
          }
          toast.error(err.response?.data?.message);
        });
    }
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight={700}>
            Create new observable(s)
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
              setFields(initialFields);
            }}
          >
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "80vh" }}>
        <DialogContent>
          <Snackbar
            open={alert.show}
            autoHideDuration={6000}
            onClose={() => {
              setAlert((pre) => ({ ...pre, show: false }));
            }}
          >
            <Alert
              onClose={() => {
                setAlert((pre) => ({ ...pre, show: false }));
              }}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {alert.text}
            </Alert>
          </Snackbar>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container item spacing={2.5}>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" gap={1.25}>
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Type*
                  </Typography>
                  <FormControl fullWidth size="small">
                    <Typography variant="caption">
                      Select Observable Type
                    </Typography>
                    <Select
                      sx={{ maxWidth: "381px" }}
                      value={fields.dataType}
                      name="dataType"
                      onChange={handleFieldChange}
                    >
                      <MenuItem value="">
                        <em>Select Observable Type</em>
                      </MenuItem>
                      <MenuItem value={"autonomous-system"}>
                        autonomous-system
                      </MenuItem>
                      <MenuItem value={"domain"}>domain</MenuItem>
                      <MenuItem value={"file"}>file</MenuItem>
                      <MenuItem value={"filename"}>filename</MenuItem>
                      <MenuItem value={"fqdn"}>fqdn</MenuItem>
                      <MenuItem value={"hash"}>hash</MenuItem>
                      <MenuItem value={"hostname"}>hostname</MenuItem>
                      <MenuItem value={"ip"}>ip</MenuItem>
                      <MenuItem value={"mail"}>mail</MenuItem>
                      <MenuItem value={"mail-subject"}>mail-subject</MenuItem>
                      <MenuItem value={"other"}>other</MenuItem>
                      <MenuItem value={"regexp"}>regexp</MenuItem>
                      <MenuItem value={"registry"}>registry</MenuItem>
                      <MenuItem value={"uri_path"}>uri_path</MenuItem>
                      <MenuItem value={"url"}>url</MenuItem>
                      <MenuItem value={"user-agent"}>user-agent</MenuItem>
                    </Select>
                    {fields.dataType === "file" && (
                      <Box>
                        <Box display="flex" alignItems="center" gap={1} mt={1}>
                          <Tooltip title="The file is a zipped archive?">
                            <FolderZipIcon
                              fontSize="large"
                              color={fields.isZip ? "primary" : "inherit"}
                              onClick={() => {
                                setFields((pre) => ({
                                  ...pre,
                                  isZip: !fields.isZip,
                                }));
                                setFields((pre) => ({
                                  ...pre,
                                  attachment: null,
                                }));
                              }}
                            />
                          </Tooltip>
                          <FileUploadButton
                            value={fields.attachment}
                            setValue={handleAttachment}
                          />
                        </Box>
                        {fields.dataType === "file" && fields.isZip && (
                          <TextField
                            sx={{ minWidth: "300px", mt: 1 }}
                            placeholder="Type file password is availible"
                            label="File password"
                            size="small"
                            variant="outlined"
                            value={fields.zipPassword}
                            name="zipPassword"
                            onChange={handleFieldChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PasswordIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </FormControl>
                </Box>
              </Grid>

              <Grid container item spacing={"43px"}>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column" gap={1.25}>
                    <Typography variant="body2" fontWeight="bold" color="#000">
                      TLP*
                    </Typography>
                    <Stack direction="row" spacing={1.25}>
                      {tlp_enum.map((item) => (
                        <ButtonBase
                          key={item.title}
                          onClick={() => {
                            ADDTLP(item.value);
                          }}
                        >
                          <Chip
                            sx={{
                              minWidth: "82px",
                              justifyContent: item.bgColor,
                              backgroundColor:
                                fields.tlp === item.value && "#003272",
                              color: fields.tlp === item.value && "#fff",
                            }}
                            icon={
                              <CircleIcon
                                fontSize="small"
                                style={{ color: item.color }}
                              />
                            }
                            label={item.title}
                          />
                        </ButtonBase>
                      ))}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" flexDirection="column" gap={1.25}>
                    <Typography variant="body2" fontWeight="bold" color="#000">
                      Has been sighted
                    </Typography>
                    <SwitchIOS
                      lable=""
                      checked={fields.sighted}
                      onChange={(e) => {
                        setFields((pre) => ({
                          ...pre,
                          sighted: e.target.checked,
                        }));
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" flexDirection="column" gap={1.25}>
                    <Typography variant="body2" fontWeight="bold" color="#000">
                      Is IOC
                    </Typography>
                    <Box>
                      <SwitchIOS
                        lable=""
                        checked={fields.ioc}
                        onChange={(e) => {
                          setFields((pre) => ({
                            ...pre,
                            ioc: e.target.checked,
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" flexDirection="column" gap={1.25}>
                    <Typography variant="body2" fontWeight="bold" color="#000">
                      Ignore for similarity
                    </Typography>
                    <Box>
                      <SwitchIOS
                        lable=""
                        checked={fields.ignoreSimilarity}
                        onChange={(e) => {
                          setFields((pre) => ({
                            ...pre,
                            ignoreSimilarity: e.target.checked,
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {fields.dataType !== "file" && (
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column" gap={1.25}>
                    <Typography variant="body2" fontWeight="bold" color="#000">
                      Value*
                    </Typography>
                    <Box
                      component={"textarea"}
                      autoFocus
                      sx={{
                        backgroundColor: "transparent",
                        minHeight: "80px",
                        fontSize: "14px",
                        padding: "8px",
                        border: "1px solid #ECF0F4",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                      name="values"
                      // value={fields.data}
                      onChange={handleValueField}
                      id="value_textfield"
                      placeholder="Type Observable value here..."
                    />
                  </Box>
                </Grid>
              )}
              {fields.dataType !== "file" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <RadioGroup
                      sx={{ justifyContent: "space-between" }}
                      row
                      name="row-radio-buttons-group"
                      value={formate}
                      onChange={(e) => {
                        setFields((pre) => ({ ...pre, data: "" }));
                        setFormate(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={"perline"}
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 32,
                              },
                            }}
                          />
                        }
                        label={`One observable per line (${fields.data.length} observables)`}
                      />
                      <FormControlLabel
                        value={"multiline"}
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 32,
                              },
                            }}
                          />
                        }
                        label="One single multiline observable"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: "#ECF0F4",
                    p: "15px 14px",
                  }}
                >
                  <Grid container direction="column" spacing={1.25}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="#000"
                      >
                        Add Tags**
                      </Typography>
                    </Grid>
                    <Grid container item spacing={1.25} alignItems="center">
                      <Grid item xs>
                        <InputField
                          placeholder="Enter case tags"
                          fullWidth
                          inputRef={inputCasesTaskRef}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") addCasesTask();
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            height: "40px",
                            backgroundColor: "#1173FF",
                            minWidth: 96,
                          }}
                          onClick={addCasesTask}
                        >
                          Add
                        </Button>
                      </Grid>

                      <Grid item container spacing={1.25}>
                        {fields.tags.map((tag, i) => (
                          <Grid item key={tag}>
                            <Box
                              sx={{
                                height: "20px",
                                p: "5px",
                                borderRadius: "2px 0px 0px 2px",
                                borderLeft: "5px solid #13D38E",
                              }}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              gap="4px"
                              bgcolor={"#ECF0F4"}
                            >
                              {tag}
                              <IconButton
                                onClick={() => {
                                  removeCasesTask(tag);
                                }}
                                size="small"
                              >
                                <CancelIcon
                                  fontSize="small"
                                  sx={{ color: "black" }}
                                />
                              </IconButton>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" gap={1.25}>
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Description
                  </Typography>
                  <Box
                    component={"textarea"}
                    sx={{
                      backgroundColor: "transparent",
                      minHeight: "80px",
                      fontSize: "14px",
                      padding: "8px",
                      border: "1px solid #ECF0F4",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    name="message"
                    value={fields.message}
                    onChange={handleFieldChange}
                    placeholder="Type observable description here..."
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" gap={3}>
            <Typography variant="body2" fontWeight={400} color="#000000">
              * Required field
            </Typography>
            <Typography variant="body2" fontWeight={400} color="#000000">
              ** At least, one required field
            </Typography>
          </Box>
          <Btn
            onClick={handleSubmit}
            text="Create observable"
            startIcon={PlusIcon}
            height={50}
            fontSize={20}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
