import { CustomTableCell } from "../../../components/Display/CustomTable";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { TableRow, Box, Typography, Avatar, IconButton, Grid, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import { formatCreatedAt } from "../../../utils/utils";
import { severity_enum } from "../../Cases/enums/enums";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import cms from "../../../config/axiosConfig";

const CustomCaseTemplatesRow = ({
    row,
    isSelected,
    onEdit,
    onDelete,
    permissions,
}) => {

    const handleDownload = async () => {
        try {
            const response = await cms.get(`/cms/api/case/template/${row._id}`);
            const json = response.data;

            const blob = new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `${row.name} (${formatCreatedAt(row._createdAt)}).json`;
            a.click();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <TableRow
            key={row._id}
            sx={{
                borderRadius: "4px",
                minHeight: 76,
                verticalAlign: "baseline",
                "&:hover": {
                    // cursor: "pointer",
                    backgroundColor: "#F5F7FA",
                },
                ...(isSelected && {
                    backgroundColor: "#EAF6FF",
                }),
            }}
        >
            <CustomTableCell
                sx={{
                    borderColor: "#ECF0F4",
                    borderLeftColor: severity_enum[row.severity]?.color,
                    borderWidth: "1px 0 1px 9px",
                    borderRadius: "4px 0px 0px 4px",
                    verticalAlign: "top",
                }}>
                <Box display="flex" flexDirection="column" gap={1.25}>

                    <Typography color="#1173FF" variant="caption" fontWeight={700}>
                        {row.name}
                    </Typography>
                    <Grid container gap={1.25} maxWidth={"100%"}>
                        {row.tags.map((tag, i) => (
                            <Grid item key={tag + i}>
                                <Box
                                    sx={{
                                        height: "20px",
                                        p: "5px",
                                        borderRadius: "2px 0px 0px 2px",

                                        borderLeft: "5px solid #13D38E",
                                        width: "fit-content",
                                    }}
                                    display="flex"
                                    alignItems="center"
                                    gap="4px"
                                    bgcolor={"#ECF0F4"}
                                >
                                    {tag}
                                </Box>
                            </Grid>
                        ))}
                        {row.tags?.length < 1 &&
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={.5}>
                                    <LocalOfferIcon fontSize="small" />
                                    <Typography variant="caption" color="disabled" component="i">None</Typography>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </CustomTableCell>
            <CustomTableCell sx={{ minWidth: 200 }}>
                <Typography
                    variant="caption"
                    fontWeight={500}
                    sx={{ color: "#A5A5A5" }}
                >
                    {row.name}
                </Typography>
            </CustomTableCell>
            <CustomTableCell sx={{ minWidth: 110 }}>
                <Typography variant="caption" fontWeight={500}>
                    {typeof severity_enum[row.severity].icon === "string" ? (
                        <Box
                            component="img"
                            src={severity_enum[row.severity]?.icon}
                            alt="priority"
                            width={20}
                            height={20}
                            mr={"5px"}
                        />
                    ) : (
                        severity_enum[row.severity].icon
                    )}
                    {severity_enum[row.severity]?.value}
                </Typography>
            </CustomTableCell>
            <CustomTableCell sx={{ minWidth: 200 }}>
                <Typography
                    variant="caption"
                    fontWeight={500}
                    sx={{ color: "#A5A5A5" }}
                >
                    {row.tasks?.length}
                </Typography>
            </CustomTableCell>
            <CustomTableCell sx={{ minWidth: 200 }}>
                <Typography
                    variant="caption"
                    fontWeight={500}
                    sx={{ color: "#A5A5A5" }}
                >
                    {row.customFields && Object.keys(row.customFields)?.length}
                </Typography>
            </CustomTableCell>

            <CustomTableCell>
                <Box display="flex" alignItems="center" gap="5px">
                    <Avatar
                        sx={{ width: 26, height: 26 }}
                        alt={row.name}
                        src={`${window.location.origin}/cms/${row.avatar}`}
                    />

                    <Box display="flex" flexDirection={"column"}>
                        <Typography variant="caption" fontWeight={700}>
                            {row._createdBy?.split("@")[0]}
                        </Typography>
                        <Typography
                            variant="caption"
                            className="text-muted"
                            fontWeight={400}
                        >
                            {row._createdBy}
                        </Typography>
                    </Box>
                </Box>
            </CustomTableCell>
            <CustomTableCell sx={{ minWidth: 200 }}>
                <Box
                    display="flex"
                    //   justifyContent="space-between"
                    alignItems={"center"}
                    gap={1.25}
                >
                    <Typography variant="caption" fontWeight={500}>
                        <PlayCircleFilledRoundedIcon
                            fontSize="small"
                            sx={{ color: "#2497F3" }}
                        />
                        {formatCreatedAt(row._createdAt)}
                    </Typography>

                </Box>
            </CustomTableCell>
            <CustomTableCell sx={{ minWidth: 200 }}>
                <Tooltip title="Edit template">
                    <IconButton
                        onClick={onEdit(row)}
                        size="small"
                        sx={{ color: "primary.main", fontSize: 14, mr: 1 }}
                    >
                        <EditIcon fontSize="small" sx={{ color: "primary.light" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download template">
                    <IconButton
                        onClick={handleDownload}
                        size="small"
                        sx={{ color: "primary.main", fontSize: 14, mr: 1 }}
                    >
                        <FileDownloadIcon fontSize="small" color="secondary" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete template">
                    <IconButton
                        onClick={onDelete(row)}
                        size="small"
                        sx={{ color: "error.main", fontSize: 14 }}
                    >
                        <DeleteIcon fontSize="small" color="error" />
                    </IconButton>
                </Tooltip>
            </CustomTableCell>
        </TableRow >
    );
};

export default CustomCaseTemplatesRow;
