import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    ButtonBase,
    ButtonGroup,
    Chip,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import InputField from "../../../../components/Field/BootStrap/InputField.js";
import { pap_enum, severity_enum, tlp_enum } from "../../../Cases/enums/enums.js";
import CircleIcon from "@mui/icons-material/Circle";
import CancelIcon from "@mui/icons-material/Cancel";
import Btn from "../../../../components/Buttons/BootStrap/Btn.js";
import PlusIcon from "../../../../static/icons/plus-icon.svg";
import ReactScrollbar from "react-scrollbar";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useCallback } from "react";
import CaseTemplateTaskDialog from "./PlayBookTaskDialog.js";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from "@mui/icons-material/Delete";
import cms from "../../../../config/axiosConfig.js";
import EditIcon from '@mui/icons-material/Edit';


export default function CreateCaseTemplateDialog({ open, setOpen, update, selectedRow, reFrashFunc }) {
    const handleClose = () => {
        setOpen(false);
        setFields(initialFields);
    };

    const initialFields = update ? { ...selectedRow, customFields: {} } :
        {
            "name": "",
            "titlePrefix": "",
            "severity": 1,
            "tlp": 0,
            "pap": 0,
            "tags": [],
            "tasks": [],
            "customFields": {},
            "description": "",
            "displayName": "",
        }

    const [fields, setFields] = useState(initialFields);
    const [loading, setLoading] = useState(false)
    const [taskDialog, showTaskDialog] = useState(false);
    const [updateTask, setUpdateTask] = useState(false);
    const [selectedTaskRow, setTaskSelectedRow] = useState({});
    const [tags, setTags] = useState([]);
    const [tab, setTab] = useState(0);
    const inputTagsRef = useRef(null);
    const [alert, setAlert] = useState({
        show: false,
        severity: "success",
        text: "",
    });

    // Add  functions
    const AddTags = () => {
        const trimmedValue = inputTagsRef.current.value.trim();
        if (trimmedValue && !fields.tags.includes(trimmedValue)) {
            setFields(pre => ({ ...pre, tags: [...pre.tags, trimmedValue] }))
            inputTagsRef.current.value = "";
        } else if (fields.tags.includes(trimmedValue)) {
            toast.warning(`${trimmedValue} already exist`);
        }
    };
    const AddCasesTask = (row) => {
        const pre_task = fields.tasks;
        if (pre_task.find(t => t.title === row.title)) {
            toast.error(`Task with this title ("${row.title}" already exist`);
            return
        }
        setFields(pre => ({ ...pre, tasks: [...pre.tasks, row] }));
        showTaskDialog(false)
    };
    const UpdateTaskFunc = (updatedObj) => {
        const pre_tasks = fields.tasks;
        const newRow = pre_tasks.map((item) =>
            item.title === updatedObj.title ? updatedObj : item
        );
        setFields(pre => ({ ...pre, tasks: newRow }));
        showTaskDialog(false);
        setTaskSelectedRow({});
        setUpdateTask(false);
    };

    const ADDField = (_name, value) => {
        setFields(pre => ({ ...pre, [_name]: value }))
    };
    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFields((pre) => ({ ...pre, [name]: value }));
    };

    // Remove  functions
    const removeTag = (tagToRemove) => {
        setTags(tags.filter((tag) => tag !== tagToRemove));
    };
    const removeTask = (taskToRemove) => {
        const pre_task = fields.tasks;
        setFields(pre => ({ ...pre, tasks: pre_task.filter(t => t.title !== taskToRemove.title) }))
    };
    const handleUpdateTask = (_task) => {
        showTaskDialog(true);
        setUpdateTask(true);
        setTaskSelectedRow(_task);
    };
    const ResetFields = () => {
        handleClose();
    };
    // andle Submit
    const validate = () => {
        let valid = true;
        let message = "";
        if (!fields.name) {
            message = "Title is required";
            valid = false;
        } else if (!fields.description) {
            message = "Description is required";
            valid = false;
        }
        else if (fields.tasks.length < 1) {
            message = "Atleast one case task should add";
            valid = false;
        }
        else if (fields.tlp === "") {
            message = "Select one TLP";
            valid = false;
        } else if (fields.pap === "") {
            message = "Select one PAP";
            valid = false;
        } else if (fields.severity === "") {
            message = "Select one severity";
            valid = false;
        }

        if (!valid) {
            setAlert({ show: true, severity: "error", text: message });
        } else {
            setAlert({ show: false, severity: "error", text: "" });
        }

        return valid;
    };
    const handleSubmit = (e) => {
        if (validate()) {
            async function Create() {
                try {
                    await cms.post("/cms/api/case/template", fields).then((res) => {
                        toast.success("Playbook created successfuly.");
                        ResetFields();
                        setOpen(false);
                        reFrashFunc();
                    });
                } catch {
                    toast.error("something went wrong.");
                }
            }
            async function Update() {
                try {
                    await cms.patch(`/cms/api/case/template/${selectedRow._id}`, fields).then((res) => {
                        toast.success("Playbook updated successfuly.");
                        ResetFields();
                        setOpen(false);
                        reFrashFunc();
                    });
                } catch {
                    toast.error("something went wrong.");
                }
            }
            try {
                setLoading(true)
                if (update) {
                    Update()
                } else {
                    Create()
                }
            }
            finally {
                setLoading(false)
            }
        }
    };
    const handleTabChange = (e, _tab) => {
        setTab(_tab)
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const TaskDialogBox = useCallback(() => {
        return (<CaseTemplateTaskDialog
            open={taskDialog}
            setOpen={showTaskDialog}
            AddFunc={AddCasesTask}
            UpdateFunc={UpdateTaskFunc}
            isUpdate={updateTask}
            selectedRow={selectedTaskRow}
            order={fields.tasks?.length - 1}
        />)
    }, [taskDialog, selectedTaskRow, updateTask])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="lg"
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "822px",
                    borderRadius: "15px",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Typography variant="h5" fontWeight={700}>
                            {`${update ? "Update" : "Add"} Playbook`}
                        </Typography>
                        <Typography variant="caption" color="#A7BBCE">
                            Playbook
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <CancelIcon fontSize="large" />
                    </IconButton>
                </Box>
            </DialogTitle>

            <ReactScrollbar style={{ maxHeight: "70vh" }}>
                <DialogContent>
                    <Snackbar
                        open={alert.show}
                        autoHideDuration={6000}
                        onClose={() => {
                            setAlert((pre) => ({ ...pre, show: false }));
                        }}
                    >
                        <Alert
                            onClose={() => {
                                setAlert((pre) => ({ ...pre, show: false }));
                            }}
                            severity={alert.severity}
                            sx={{ width: "100%" }}
                        >
                            {alert.text}
                        </Alert>
                    </Snackbar>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Basic INformation" />
                                <Tab label="Tasks" />
                            </Tabs>
                        </Box>
                        {/* Basic INformation */}
                        <Collapse in={tab === 0}>
                            <Grid container item spacing={2.5} sx={{ marginTop: 2 }}>
                                <Grid item xs={6}>
                                    <Box display="flex" flexDirection="column" gap={1.25}>
                                        <Typography variant="body2" fontWeight="bold" color="#000">
                                            Template Name*
                                        </Typography>
                                        <InputField
                                            placeholder="Type template name here..."
                                            fullWidth
                                            name="name"
                                            value={fields.name}
                                            onChange={handleFieldChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" flexDirection="column" gap={1.25}>
                                        <Typography variant="body2" fontWeight="bold" color="#000">
                                            Display name
                                        </Typography>
                                        <InputField
                                            placeholder="Type display name here..."
                                            fullWidth
                                            name="displayName"
                                            value={fields.displayName}
                                            onChange={handleFieldChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" flexDirection="column" gap={1.25}>
                                        <Typography variant="body2" fontWeight="bold" color="#000">
                                            Title prefix*
                                        </Typography>
                                        <InputField
                                            placeholder="Type title prefix here..."
                                            fullWidth
                                            name="titlePrefix"
                                            value={fields.titlePrefix}
                                            onChange={handleFieldChange}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" gap={1.25}>
                                        <Typography variant="body2" fontWeight="bold" color="#000">
                                            Severity*
                                        </Typography>
                                        <ButtonGroup
                                            size="large"
                                            sx={{
                                                border: "1px solid #ECF0F4",
                                                height: "40px",
                                                "& .MuiButtonBase-root": {
                                                    fontSize: "12px",
                                                    fontWeight: 500,
                                                    color: "#000",
                                                },
                                            }}
                                            aria-label="large button group"
                                        >
                                            {Object.keys(severity_enum).map((sv) => (
                                                <Button
                                                    startIcon={
                                                        typeof severity_enum[sv].icon === "string" ? (
                                                            <img
                                                                src={severity_enum[sv].icon}
                                                                alt={`Icon for ${severity_enum[sv].value}`}
                                                                width="20px"
                                                                height="20px"
                                                            />
                                                        ) : (
                                                            severity_enum[sv].icon
                                                        )
                                                    }
                                                    onClick={() => {
                                                        ADDField("severity", severity_enum[sv]._value);
                                                    }}
                                                    variant="text"
                                                    key={severity_enum[sv].value}
                                                    fullWidth
                                                    sx={{
                                                        color:
                                                            fields.severity === severity_enum[sv]._value &&
                                                            "#fff !important",
                                                        "&:hover": {
                                                            color: "black !important",
                                                        },
                                                        backgroundColor:
                                                            fields.severity === severity_enum[sv]._value && "#003272",
                                                    }}
                                                >
                                                    {severity_enum[sv].value}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </Box>
                                </Grid>
                                <Grid container item spacing={"43px"}>
                                    <Grid item xs={6}>
                                        <Box display="flex" flexDirection="column" gap={1.25}>
                                            <Typography variant="body2" fontWeight="bold" color="#000">
                                                TLP*
                                            </Typography>
                                            <Stack direction="row" spacing={1.25}>
                                                {tlp_enum.map((item) => (
                                                    <ButtonBase
                                                        key={item.title}
                                                        onClick={() => {
                                                            ADDField("tlp", item.value);
                                                        }}
                                                    >
                                                        <Chip
                                                            sx={{
                                                                minWidth: "82px",
                                                                backgroundColor:
                                                                    fields.tlp === item.value ? "#003272" : item.bgColor,
                                                                color: fields.tlp === item.value && "#fff",
                                                            }}
                                                            icon={
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    style={{ color: item.color }}
                                                                />
                                                            }
                                                            label={item.title}
                                                        />
                                                    </ButtonBase>
                                                ))}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display="flex" flexDirection="column" gap={1.25}>
                                            <Typography variant="body2" fontWeight="bold" color="#000">
                                                PAP*
                                            </Typography>
                                            <Stack direction="row" spacing={1.25}>
                                                {pap_enum.map((item) => (
                                                    <ButtonBase
                                                        key={item.title}
                                                        onClick={() => {
                                                            ADDField("pap", item.value);
                                                        }}
                                                    >
                                                        <Chip
                                                            sx={{
                                                                minWidth: "82px",
                                                                backgroundColor:
                                                                    fields.pap === item.value ? "#003272" : item.bgColor,
                                                                color: fields.pap === item.value && "#fff",
                                                            }}
                                                            icon={
                                                                <CircleIcon
                                                                    fontSize="small"
                                                                    style={{ color: item.color }}
                                                                />
                                                            }
                                                            label={item.title}
                                                        />
                                                    </ButtonBase>
                                                ))}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ backgroundColor: "#ECF0F4", p: "15px 14px" }}>
                                        <Grid container direction="column" spacing={1.25}>
                                            <Grid item>
                                                <Typography
                                                    variant="body2"
                                                    fontWeight="bold"
                                                    color="#000"
                                                    onClick={AddTags}
                                                >
                                                    Add Tags
                                                </Typography>
                                            </Grid>
                                            <Grid container item spacing={1.25} alignItems="center">
                                                <Grid item xs>
                                                    <InputField
                                                        placeholder="Enter case tags"
                                                        fullWidth
                                                        inputRef={inputTagsRef}
                                                        onKeyPress={(event) => {
                                                            if (event.key === "Enter") AddTags();
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            height: "40px",
                                                            backgroundColor: "#1173FF",
                                                            minWidth: 100,
                                                        }}
                                                        onClick={AddTags}
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>


                                                <Grid item container spacing={1.25}>
                                                    {fields.tags.map((tag, i) => (
                                                        <Grid item key={tag}>
                                                            <Box
                                                                sx={{
                                                                    height: "20px",
                                                                    p: "5px",
                                                                    borderRadius: "2px 0px 0px 2px",
                                                                    borderLeft: "5px solid #13D38E",
                                                                }}
                                                                display="flex"
                                                                alignItems="center"
                                                                gap="4px"
                                                                bgcolor={"#ECF0F4"}
                                                            >
                                                                {tag}
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => removeTag(tag)}
                                                                >
                                                                    <CancelIcon sx={{ fontSize: "15px" }} />
                                                                </IconButton>
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" gap={1.25}>
                                        <Typography variant="body2" fontWeight="bold" color="#000">
                                            Description*
                                        </Typography>
                                        <Box
                                            component={"textarea"}
                                            sx={{
                                                backgroundColor: "transparent",
                                                minHeight: "80px",
                                                fontSize: "14px",
                                                padding: "8px",
                                                border: "1px solid #ECF0F4",
                                                borderRadius: "4px",
                                                width: "100%",
                                            }}
                                            name="description"
                                            value={fields.description}
                                            onChange={handleFieldChange}
                                            placeholder="Type case description here..."
                                        />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Collapse>
                        <Collapse in={tab === 1}>
                            <Box sx={{ backgroundColor: "#ECF0F4", p: "15px 14px", width: "100%", marginTop: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid xs={12} item container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography
                                                variant="body2"
                                                fontWeight="bold"
                                                color="#000"
                                            >
                                                Add Tasks*
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => { showTaskDialog(true) }}
                                                startIcon={<Box component="img" width={20} src={PlusIcon} />}
                                                sx={{ height: "40px", backgroundColor: "#1173FF" }}
                                            >
                                                Add Task
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="column" spacing={1.5} xs={12}>
                                        {fields.tasks.map((task, i) => (
                                            <Grid item>
                                                <Accordion key={task.title} >
                                                    <AccordionSummary aria-controls={`panel${i + 1}-content`}
                                                        id={`panel${i + 1}-header`}
                                                        sx={{ fontWeight: "bold" }} expandIcon={<ExpandMoreIcon />}>
                                                        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gap={.5}>
                                                            {task.title}
                                                            <Box display="flex" gap={1} alignItems="center">
                                                                <IconButton onClick={e => { e.stopPropagation(); handleUpdateTask(task) }} size="small">
                                                                    <EditIcon color="primary" fontSize="small" />
                                                                </IconButton>
                                                                <IconButton onClick={e => { e.stopPropagation(); removeTask(task) }} size="small">
                                                                    <DeleteIcon color="error" fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Typography ml={1.5} variant="body1" dangerouslySetInnerHTML={{ __html: task.description }}></Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </Box>
                    <TaskDialogBox />
                </DialogContent>
            </ReactScrollbar >
            <DialogActions>
                <Box
                    pl="22px"
                    pr="22px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Typography variant="body2" fontWeight={400} color="#000">
                        * Required field
                    </Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                        {loading && <CircularProgress />}
                        <Btn
                            display={loading}
                            onClick={handleSubmit}
                            text={`${update ? "Update" : "Create"} Playbook`}
                            startIcon={PlusIcon}
                            height={50}
                            fontSize={20}
                        />
                    </Box>
                </Box>
            </DialogActions>
        </Dialog >
    );
}
