import { Box, ButtonBase, IconButton, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import ColumnMenus from "./components.js/ColumnMenus";
import { useState } from "react";
import { useCallback } from "react";
import { datatypes_options } from "../../utils/filters/utils";
import DateSelector from "./components.js/DateSelector";
import NumaricField from "./components.js/NumaricField";
import BooleanSelector from "./components.js/BooleanSelector";
import AutoCompleteChipsSelector from "./components.js/AutoCompleteChipsSelector";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Compiler from "../../utils/filters/compiler";
import MultiSelection from "./components.js/MultiSelection";
import { sleep } from "../../utils/utils";
import broom from "../../static/icons/broom.png";

function FilterFields(props) {
  const { filterField = [], onSearch, _for } = props;
  const [query, createQuery] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [MenuButtonAnchor, setMenuButtonAnchor] = React.useState(null);
  const [valueMenuAnchor, setValueMenuAnchor] = React.useState(null);
  const MenuRef = useRef(null);

  const SubmitQuery = (_query) => {
    if (Array.isArray(_query)) {
      const payload = _query.map((q) => {
        let __q = q;
        try {
          if ("Any Of" in __q) {
            delete __q["Any Of"];
          } else if ("All Of" in __q) {
            delete __q["All Of"];
          }
          return __q;
        } catch { }
        return "";
      });
      onSearch(payload, true);
    }
  };
  const handleColumnValues = (event, _value) => {
    setSelectedField(_value);
    setValueMenuAnchor(event.currentTarget);
  };
  const handleColumnMenu = (event) => {
    setMenuButtonAnchor(event.currentTarget);
  };

  const handleRemoveQuery = (query_readable) => () => {
    const _query = query.filter((_q) => _q.query_readable !== query_readable);
    createQuery(_query);
    onSearch(
      _query.map((q) => q.query),
      true
    );
  };
  const handleClearFilters = () => {
    createQuery((pre) => []);
    SubmitQuery((pre) => []);
    onSearch([], true);
  };

  const QueryGenrator = (value) => {
    let _value = JSON.parse(value);
    const query_readable = Compiler(_value, selectedField.type, _for);
    if (query_readable) {
      const _query = [
        ...query,
        {
          query: _value,
          datatype: selectedField.type,
          query_readable: query_readable,
        },
      ];
      createQuery(_query);
      setMenuButtonAnchor(null);
      setValueMenuAnchor(null);
      SubmitQuery(_query.map((q) => q.query));
    }
  };

  useEffect(() => {
    const quries_filter = localStorage.getItem(`${_for}_quires`);
    if (quries_filter) {
      const _q = JSON.parse(quries_filter);
      createQuery(_q);
      if (_q.length > 0) {
        const _query = _q.map((q) => q.query);
        const sleepMe = async () => {
          await sleep(3000).then((res) => {
            SubmitQuery(_query);
          });
        };
        sleepMe();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const q = JSON.stringify(query);
    localStorage.setItem(`${_for}_quires`, q);
    // eslint-disable-next-line
  }, [query]);

  const QueryStack = useCallback(() => {
    return query.map((q) => (
      <Grid2 item key={q}>
        <Box
          display="flex"
          component={ButtonBase}
          disableRipple
          justifyContent="space-between"
          alignItems="center"
          borderRadius={1}
          gap={1}
          p={1}
          sx={{ bgcolor: "grey.200", cursor: "no-drop" }}
          size="large"
        >
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: q.query_readable }}
          />

          <IconButton
            size="small"
            sx={{ height: 20 }}
            onClick={handleRemoveQuery(q.query_readable)}
          >
            <CloseIcon color="disabled" />
          </IconButton>
        </Box>
      </Grid2>
    ));
    // eslint-disable-next-line
  }, [query]);

  //   render next Menu
  const ListMenu = useCallback(() => {
    switch (selectedField.type) {
      case datatypes_options.DATE.type:
        return (
          <DateSelector
            field={selectedField}
            anchorEl={valueMenuAnchor}
            setAnchorEl={setValueMenuAnchor}
            onChange={QueryGenrator}
          />
        );
      case datatypes_options.NUMARIC.type:
        return (
          <NumaricField
            field={selectedField}
            anchorEl={valueMenuAnchor}
            setAnchorEl={setValueMenuAnchor}
            onChange={QueryGenrator}
          />
        );

      case datatypes_options.BOOLEAN.type:
        return (
          <BooleanSelector
            field={selectedField}
            anchorEl={valueMenuAnchor}
            setAnchorEl={setValueMenuAnchor}
            onChange={QueryGenrator}
          />
        );
      case datatypes_options.AUTOCOMPLETE_CHIPS.type:
        return (
          <AutoCompleteChipsSelector
            field={selectedField}
            anchorEl={valueMenuAnchor}
            setAnchorEl={setValueMenuAnchor}
            onChange={QueryGenrator}
          />
        );
      case datatypes_options.MultiSelection.type:
        return (
          <MultiSelection
            field={selectedField}
            anchorEl={valueMenuAnchor}
            setAnchorEl={setValueMenuAnchor}
            onChange={QueryGenrator}
          />
        );

      default:
        <></>;
    }
    // eslint-disable-next-line
  }, [selectedField.type, valueMenuAnchor]);

  return (
    <Box
      component={Paper}
      p={"15px 10px"}
      borderRadius={1}
      elevation={0}
      minHeight={100}
    >
      <Box display="flex" justifyContent="space-between">
        <Grid2 container spacing={1.5}>
          <Grid2 xs={12}>
            <Typography variant="subtitle1">Filters</Typography>
          </Grid2>
          <QueryStack />
          <Grid2 item>
            <Box
              component={ButtonBase}
              onClick={handleColumnMenu}
              borderRadius={1}
              p={1}
              sx={{ bgcolor: "grey.200" }}
              size="large"
            >
              <AddIcon fontSize="small" />
            </Box>
          </Grid2>
        </Grid2>
        <ColumnMenus
          ref={MenuRef}
          fields={filterField}
          anchorEl={MenuButtonAnchor}
          setAnchorEl={setMenuButtonAnchor}
          onChange={handleColumnValues}
        />
        <ListMenu />
        <IconButton
          sx={{ height: 48, width: 48 }}
          onClick={handleClearFilters}
          disabled={query.length < 1}
        >
          <Box
            component="img"
            width="100%"
            height="100%"
            alt="clear"
            src={broom}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

export default FilterFields;
