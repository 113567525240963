import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const options = {
  type: "bar",
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const StackedBarChart = ({ data = { labels: [], values: [] } }) => {
  const _data = {
    labels: data.labels,
    datasets: [
      {
        label: "Figures 1",
        data: data.values,
        backgroundColor: "#8ac6d1",
        barThickness: 25,
      },
    ],
  };
  return (
    <Bar
      data={_data}
      options={{ ...options, maintainAspectRatio: false, height: "100%" }}
    />
  );
};

export default StackedBarChart;
