import {
  Box,
  Button,
  ButtonBase,
  ClickAwayListener,
  Collapse,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { Form, InputGroup } from "react-bootstrap";
import dayjs from "dayjs";
import {
  calculateDaysFromTimestamp,
  convertToEpochMillis,
  formatDate,
} from "../../utils/utils";

function AbsoluteTabPanel(props) {
  const { onDateAccept, selectedDate, ...other } = props;
  const [date, setDate] = useState(dayjs(selectedDate));

  const handleDateChange = (newdate) => {
    setDate(newdate);
    onDateAccept(formatDate(newdate));
  };
  const onAccept = (newdate) => {
    onDateAccept(formatDate(newdate), true);
  };

  return (
    <div role="tabpanel" {...other}>
      <Box sx={{ p: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateTimePicker
            value={date}
            onAccept={onAccept}
            onChange={handleDateChange}
            disableFuture
            orientation="landscape"
          />
        </LocalizationProvider>
      </Box>
    </div>
  );
}
function RelativeTabPanel(props) {
  const { onDateAccept, selectedSection, selectedDate } = props;
  const _date = dayjs(selectedDate).startOf("day");
  const [date, setDate] = useState(_date);
  const [roundDays, setRoundDaya] = useState(true);

  const [diff, setDiff] = useState(
    calculateDaysFromTimestamp(convertToEpochMillis(_date))
  );
  const [option, setOption] = useState("d");

  function handleDateTimeCalculation(e = undefined) {
    let unit = "";
    if (e) {
      unit = e.target.value;
    } else {
      unit = option;
    }
    let newDate = dayjs();
    switch (unit) {
      case "s":
        // Subtract seconds
        newDate = newDate.subtract(diff, "second");
        break;
      case "m":
        // Subtract minutes
        newDate = newDate.subtract(diff, "minute");
        break;
      case "h":
        // Subtract hours
        newDate = newDate.subtract(diff, "hour");
        break;
      case "d":
        // Subtract days
        newDate = newDate.subtract(diff, "day");
        break;
      case "w":
        // Subtract weeks (7 days per week)
        newDate = newDate.subtract(diff * 7, "day");
        break;
      case "M":
        // Subtract months
        newDate = newDate.subtract(diff, "month");
        break;
      case "y":
        // Subtract years
        newDate = newDate.subtract(diff, "year");
        break;
      case "s+":
        // Add seconds
        newDate = newDate.add(diff, "second");
        break;
      case "m+":
        // Add minutes
        newDate = newDate.add(diff, "minute");
        break;
      case "h+":
        // Add hours
        newDate = newDate.add(diff, "hour");
        break;
      case "d+":
        // Add days
        newDate = newDate.add(diff, "day");
        break;
      case "w+":
        // Add weeks (7 days per week)
        newDate = newDate.add(diff * 7, "day");
        break;
      case "M+":
        // Add months
        newDate = newDate.add(diff, "month");
        break;
      case "y+":
        // Add years
        newDate = newDate.add(diff, "year");
        break;
      default:
        break;
    }
    const _new_date = roundDays ? newDate.startOf("day") : newDate;
    setDate(_new_date);
    setOption(unit);
    onDateAccept(formatDate(_new_date));
  }

  useEffect(() => {
    handleDateTimeCalculation();
    // eslint-disable-next-line
  }, [diff]);

  useEffect(() => {
    if (roundDays) {
      setDate(date.startOf("day"));
      onDateAccept(formatDate(date.startOf("day")));
    } else {
      handleDateTimeCalculation();
    }
    // eslint-disable-next-line
  }, [roundDays]);

  return (
    <div role="tabpanel">
      <Box sx={{ p: 3 }} display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={2}>
          <TextField
            variant="outlined"
            value={diff}
            onChange={(e) => setDiff(e.target.value)}
            fullWidth
            size="small"
            type="number"
          />

          <Select
            value={option}
            fullWidth
            onChange={handleDateTimeCalculation}
            size="small"
            id="demo-simple-select"
          >
            {[
              { label: "Seconds ago", value: "s" },
              { label: "Minutes ago", value: "m" },
              { label: "Hours ago", value: "h" },
              { label: "Days ago", value: "d" },
              { label: "Weeks ago", value: "w" },
              { label: "Months ago", value: "M" },
              { label: "Years ago", value: "y" },
              { label: "Seconds from now", value: "s+" },
              { label: "Minutes from now", value: "m+" },
              { label: "Hours from now", value: "h+" },
              { label: "Days from now", value: "d+" },
              { label: "Weeks from now", value: "w+" },
              { label: "Months from now", value: "M+" },
              { label: "Years from now", value: "y+" },
            ].map((v) => (
              <MenuItem key={v.label} value={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <FormControlLabel
          control={
            <Switch
              checked={roundDays}
              onChange={(e) => {
                setRoundDaya(e.target.checked);
              }}
            />
          }
          label="Round to the day"
        />
        <InputGroup>
          <Box
            component={InputGroup.Text}
            sx={{ color: "prmary.main" }}
            id="basic-addon1"
          >
            {selectedSection === "from" ? "startDate" : "endDate"}
          </Box>
          <Form.Control value={formatDate(date).replace("T", " @ ")} readOnly />
        </InputGroup>
        <Box display="flex" justifyContent="flex-end">
          <Button
            className="w-25"
            onClick={() => {
              onDateAccept(formatDate(date), true);
            }}
            size="small"
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
        </Box>
      </Box>
    </div>
  );
}
function NowTabPanel(props) {
  const { onDateAccept } = props;
  const handleDateSet = () => {
    onDateAccept(formatDate(dayjs()), true);
  };
  return (
    <div role="tabpanel">
      <Box sx={{ p: 3 }} display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle2">
          Setting the time to 'now' means that on every refresh this time will
          be set to the time of the refresh.
        </Typography>
        <Button
          onClick={handleDateSet}
          variant="contained"
          color="primary"
          fullWidth
        >
          Set start date & time from now
        </Button>
      </Box>
    </div>
  );
}
const DataRangesTabs = ({
  onDateAccept,
  selectedSection,
  selectedDate,
  dateBox,
}) => {
  const [value, setValue] = React.useState(0);
  const _onDateAccept = (_date, closeBox = false) => {
    if (selectedSection) {
      onDateAccept((pre) => ({
        ...pre,
        [selectedSection === "from" ? "from_date" : "to_date"]: _date,
      }));
      if (closeBox) {
        dateBox(false);
      }
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickAway = () => {
    if (value !== 1) {
      dateBox(false);
    }
  };
  return (
    <Box
      component={Paper}
      width="100%"
      zIndex={4}
      position="absolute"
      top={"100%"}
      left={0}
      mt={"6px"}
      p={1}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{ justifyContent: "space-between" }}
            >
              <Tab label="Absolute" />
              <Tab label="Relative" />
              <Tab label="Now" />
            </Tabs>
          </Box>
          <Collapse unmountOnExit in={value === 0}>
            <AbsoluteTabPanel
              onDateAccept={_onDateAccept}
              selectedDate={selectedDate}
            />
          </Collapse>
          <Collapse unmountOnExit in={value === 1}>
            <RelativeTabPanel
              onDateAccept={_onDateAccept}
              selectedSection={selectedSection}
              selectedDate={selectedDate}
            />
          </Collapse>
          <Collapse unmountOnExit in={value === 2}>
            <NowTabPanel onDateAccept={_onDateAccept} />
          </Collapse>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

function DateRangeSelector({ dateRange, setDateRange }) {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const handleClick = (section) => () => {
    if (section === selectedSection || !isPopperOpen) {
      setIsPopperOpen(!isPopperOpen);
      if (isPopperOpen) {
        setSelectedSection("");
        return;
      }
    }
    setSelectedSection(section);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="h-100 w-100"
      position="relative"
      sx={{ border: "1px solid gray", borderRadius: "4px" }}
    >

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        gap={1.25}
        minHeight={35}
        sx={{
          "& .MuiTypography-subtitle2:hover": {
            borderBottom: "1px solid",
            borderColor: "primary.main",
          },
        }}
      >
        <Typography
          style={
            selectedSection === "from"
              ? {
                borderBottom: "1px solid",
                borderColor: "primary.main",
              }
              : {}
          }
          component={ButtonBase}
          onClick={handleClick("from")}
          variant="subtitle2"
        >
          {dateRange.from_date}
        </Typography>
        <ArrowForwardIcon color="primary" sx={{ fontSize: 20 }} />
        <Typography
          style={
            selectedSection === "to"
              ? {
                borderBottom: "1px solid",
                borderColor: "primary.main",
              }
              : {}
          }
          component={ButtonBase}
          onClick={handleClick("to")}
          variant="subtitle2"
        >
          {dateRange.to_date}
        </Typography>
      </Box>
      <Collapse unmountOnExit in={isPopperOpen && selectedSection}>
        <DataRangesTabs
          onDateAccept={setDateRange}
          dateBox={setIsPopperOpen}
          selectedDate={
            dateRange[selectedSection === "from" ? "from_date" : "to_date"]
          }
          selectedSection={selectedSection}
        />
      </Collapse>
    </Box>
  );
}

export default DateRangeSelector;
