import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import Polarchart from "../../../../components/charts/Polarchart.js";
import Scrollbars from "react-custom-scrollbars-2";
import SortTable from "../../../../components/Display/SortTable.js";
import wazuh from "../../../../config/wazuh.js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const sca_columns = [
  { id: "name", label: "Policy" },
  { id: "end_scan", label: "End scan" },
  { id: "pass", label: "Passed" },
  { id: "fail", label: "Failed" },
  { id: "invalid", label: "Not applicable" },
  { id: "score", label: "Score" },
];
function SCAInventory({ setGraphs, setTableData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const agentId =
    new URLSearchParams(location.search).get("agent_id") || undefined;
  const [pagination, setPagination] = useState({
    from: 0,
    size: 10,
  });
  const [graphData, setGraphData] = useState({ labels: [], values: [] });
  const [data, setData] = useState({ loading: false, data: [] });
  useEffect(() => {
    const report_graphs = [
      {
        id: "cis_graph",
        label: data.data[0]?.name,
        component: <Polarchart data={graphData} />,
        type: "component",
        xs: 12,
      }]
    setGraphs(report_graphs)
    const headers = sca_columns.map(cl => cl.label)
    const body = []
    const rows = data.data;
    for (const row of rows) {
      const _row = []

      for (const col of sca_columns) {
        _row.push(row[col.id])
      }
      body.push(_row)
    }
    setTableData({ headers: [headers], body: body })

  }, [graphData, data.data])

  const FetchSCA = async () => {
    const { from, size } = pagination;
    await wazuh
      .get(`/sca/${agentId}`, {
        params: { limit: size, offset: from, sort: "-end_scan" },
      })
      .then((res) => {
        const response = res.data;
        setData({ loading: false, data: response?.data.affected_items });
      });
  };

  useEffect(() => {
    if (agentId === undefined) {
      navigate(-1);
      return;
    }
    FetchSCA();
    // eslint-disable-next-line
  }, [pagination, agentId]);

  useEffect(() => {
    const formatDataForGraph = (data) => {
      const labels = ["Passed", "Failed", "Score", "Not applicable"];
      let pass = 0;
      let fail = 0;
      let score = 0;
      let invalid = 0;

      for (let i in data) {
        pass += data[i].pass;
        fail += data[i].fail;
        score += data[i].score;
        invalid += data[i].invalid;
      }
      const values = [pass, fail, score, invalid];

      return { labels, values };
    };

    const rawData = data.data;
    if (rawData.length > 0) {
      const formattedData = formatDataForGraph(rawData);
      setGraphData(formattedData);
    }
    // eslint-disable-next-line
  }, [data.data]);

  return (
    <Box mt={5}>
      <Box
        display="flex"
        height={300}
        width={400}
        flexDirection="column"
        component={Paper}
        elevation={3}
        sx={{ p: 2 }}
      >
        <Typography variant="subtitle2" paragraph>
          {data.data[0]?.name}
        </Typography>
        <Box height="90%" id="cis_graph">
          <Polarchart data={graphData} />
        </Box>
      </Box>
      <Box
        border="1px solid #D3DAE6"
        borderRadius="4px"
        height={"240px"}
        p={0.5}
        mt={2}
      >
        <Scrollbars height="100%">
          <SortTable
            columns={sca_columns}
            rows={data.data}
            pagination={pagination}
            setPagination={setPagination}
            onRowClick={(policy_id) =>
              navigate(
                `/modules-events?tab=sca&agent_id=${agentId}&detailPage=inventoryDetail&policy_id=${policy_id}`
              )
            }
          />
        </Scrollbars>
      </Box>
    </Box>
  );
}

export default SCAInventory;
