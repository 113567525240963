export const SET_MODE = "SET_MODE";
export const SET_DF_VERISON = "SET_DF_VERISON";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_BRAND = "SET_BRAND";
export const SET_META = "SET_META";
export const SET_CASES_COUNT = "SET_CASES_COUNT";
export const SET_TASKS_COUNT = "SET_TASKS_COUNT";
export const SET_ALERTS_COUNT = "SET_ALERTS_COUNT";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const ENABLE_OTP = "ENABLE_OTP";
