import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Selector from "../../components/Field/BootStrap/Selectcer.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomTable from "../../components/Display/CustomTable";
import CustomTasksRow from "./components/CustomTasksRow.js";
import InputField from "../../components/Field/BootStrap/InputField";
import CreateCaseTaskDialogBox from "./components/CreateCaseTaskDialogBox";
import { TaskFilterFields } from "../../utils/filters/fields.js";
import FilterFields from "../../components/filters/FilterFields.js";
import DeleteIcon from "@mui/icons-material/Delete";
import BulkTaskDeleteDialog from "./components/BulkTaskDeleteDialog.js";

const TaskList = ({
  user,
  loading,
  tasks,
  fetchTask,
  pagination,
  onPageChange,
  setPagination,
  count,
  caseId,
  fetchTaskDetail,
  permissions,
  resetDetailPage,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkDeleteDialog, showDeleteDialog] = useState(false);

  const columns = [
    // { id: "group", label: "Group", sortable: true },
    { id: "title", label: "Task", sortable: true },
    { id: "startDate", label: "Date", sortable: true },
    { id: "assignee", label: "Assignee", sortable: true },
    { id: "action", label: "Action", sortable: false },
  ];
  const sort_options = [
    {
      name: "My task",
      value: "my_task",
      divider: true,
      _field: [{ _field: "assignee", _value: user?.login }],
    },
    {
      name: "In progress tasks",
      value: "In progress tasks",
      divider: false,
      _field: [{ _field: "status", _value: "InProgress" }],
    },
    {
      name: "Waiting tasks",
      value: "Waiting tasks",

      divider: false,
      _field: [
        {
          _field: "status",
          _value: "Waiting",
        },
      ],
    },
    {
      name: "Complte tasks",
      value: "Complte tasks",

      divider: true,
      _field: [{ _field: "status", _value: "Completed" }],
    },
    {
      name: "Flag tasks",
      value: "Flag tasks",
      divider: false,
      _field: [
        {
          _field: "flag",
          _value: true,
        },
      ],
    },
  ];
  const handleSort = (e) => {
    let value = e.target.value;
    let ops = sort_options.find((s) => s.value === value);
    if (ops) {
      fetchTask(ops._field);
    } else {
      fetchTask();
    }
  };
  const handleActionSelect = (e) => {
    showDeleteDialog(true);
  };

  const BulkDeleteDialogBox = useCallback(() => {
    return (
      <BulkTaskDeleteDialog
        open={bulkDeleteDialog}
        setOpen={showDeleteDialog}
        tasks={tasks.filter((c) => selectedRows.includes(c._id))}
        refreshFunc={() => {
          fetchTask(false, true);
          setSelectedRows([]);
        }}
      />
    );
  }, [selectedRows, bulkDeleteDialog]);

  useEffect(() => {
    fetchTask();
    // eslint-disable-next-line
  }, [pagination.per_page, pagination.page]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={1.25} mt={4.375}>
        <Typography variant="h6" fontWeight={700}>
          List of tasks ({tasks.length} of {count})
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={1.25} height={36}>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="delete-label" sx={{ mt: -0.5 }}>
                {selectedRows.length < 1
                  ? `No case selected`
                  : `${selectedRows.length} tasks selected`}
              </InputLabel>
              <Select
                sx={{
                  minWidth: 180,
                  height: 36,
                }}
                color="error"
                labelId="delete-label"
                disabled={selectedRows.length < 1}
                label="Action"
                size="small"
                value=""
                onChange={handleActionSelect}
              >
                <MenuItem value="">
                  <em>Select Action</em>
                </MenuItem>
                <MenuItem value={"delete"}>
                  <DeleteIcon color="error" />
                  Delete
                </MenuItem>
              </Select>
              <BulkDeleteDialogBox />
            </FormControl>
            <Button
              hidden={!permissions.mTasks}
              startIcon={<AddCircleIcon />}
              variant={"primary"}
              sx={{
                borderRadius: "5px",
                borderColor: "#003272",
                color: "#fff",
                bgcolor: "#13D38E",
                "&:hover": {
                  color: "#0D2A56",
                },
              }}
              onClick={() => setOpen(true)}
            >
              Add Task
            </Button>
            <Selector
              handleChange={handleSort}
              title={"Quik Filters"}
              options={sort_options}
              size="small"
            />
          </Box>
          <Box>
            <Typography
              mr={1.25}
              variant="caption"
              fontWeight={500}
              color="textPrimary"
            >
              Per Pages
            </Typography>

            <InputField
              type="number"
              value={pagination.per_page}
              onChange={(e) =>
                setPagination((pre) => ({ ...pre, per_page: e.target.value }))
              }
              sx={{ maxWidth: 121 }}
            />
          </Box>
        </Box>

        {/* Filter Fields */}
        <FilterFields
          filterField={TaskFilterFields}
          onSearch={fetchTask}
          _for="tasks"
        />
        <Box>
          <CustomTable
            checkboxies
            columns={columns}
            data={tasks}
            CustomTableRow={CustomTasksRow}
            loading={loading}
            refrashFunc={fetchTask}
            onPageChange={onPageChange}
            page={pagination.page}
            permissions={permissions}
            total_count={Math.ceil(count / pagination.per_page)}
            onDetailOpen={fetchTaskDetail}
            SelectRows={setSelectedRows}
            _selectedRows={selectedRows}
            resetDetailPage={resetDetailPage}
          />
        </Box>
      </Box>
      {/* Dialog Box */}
      <CreateCaseTaskDialogBox
        refrashFunc={fetchTask}
        open={open}
        setOpen={setOpen}
        caseId={caseId}
        detail
      />
    </Box>
  );
};
export default TaskList;
