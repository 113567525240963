import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Polarchart from "../../components/charts/Polarchart.js";
// import PieChart from "../../components/charts/PieChart.js";
import SortTable from "../../components/Display/SortTable.js";
import { Scrollbars } from "react-custom-scrollbars-2";
// import SteppedLine from "../../components/charts/SteppedLine.js";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import WindowIcon from "@mui/icons-material/Window";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SynchronizedLineChart from "../../components/charts/SynchronizedLineChart.js";
import wazuh, { wazuhIndex } from "../../config/wazuh.js";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  agent_compliance_query,
  agent_event_count_query,
  agent_fim_query,
  agent_mitre_att_query,
} from "./enums/enums.js";
// import DateRangeSelector from "./components/Fields/DateRangeSelector.js";
import DateRangeSelector from "../../components/Field/DateRangeSeletor.js";
import dayjs from "dayjs";
import { convertToEpochMillis } from "../../utils/utils.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { connect } from "react-redux";

const compliance_filters = [
  { lable: "PCI DSS", value: "pci_dss" },
  { lable: "GDPR", value: "gdpr" },
  { lable: "NIST 800-53", value: "nist_800_53" },
  { lable: "HIPAA", value: "hipaa" },
  { lable: "GPG13", value: "gpg13" },
  { lable: "TSC", value: "tsc" },
];
const sca_columns = [
  { id: "name", label: "Policy" },
  { id: "end_scan", label: "End scan" },
  { id: "pass", label: "Passed" },
  { id: "fail", label: "Failed" },
  { id: "invalid", label: "Not applicable" },
  { id: "score", label: "Score" },
];
const fim_columns = [
  { id: "time", label: "Time" },
  { id: "path", label: "Path" },
  { id: "action", label: "Action" },
  { id: "ruleDescription", label: "Rule Description" },
  { id: "ruleLevel", label: "Rule Level" },
  { id: "ruleId", label: "Rule ID" },
];

const AgentDetail = (props) => {
  const location = useLocation();
  const navigation = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [agent, setAgent] = useState([]);
  const initialDateRange = {
    from_date: dayjs().subtract(30, "day").toISOString(),
    to_date: dayjs().toISOString(),
  };
  const [dateRange, setDateRange] = useState(initialDateRange);

  const initialdata = {
    loading: true,
    mitre: { loading: false, data: { labels: [], values: [] } },
    compliance: { labels: [], values: [] },
    elevation: { data: [], dataKeys: [] },
    sca: { loading: false, rows: [] },
    fim: { loading: false, rows: [] },
  };
  const [data, setData] = useState(initialdata);
  const [complaincsFilter, setComplaincsFilter] = useState("gdpr");

  const FetchMITRE = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        agent_mitre_att_query(id, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setData((pre) => ({ ...pre, mitre: { loading: false, data: data } }));
      });
  };
  const FetchCompliance = async (
    rule = complaincsFilter,
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        agent_compliance_query(
          id,
          rule,
          convertToEpochMillis(from_date),
          convertToEpochMillis(to_date)
        )
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setData((pre) => ({
          ...pre,
          compliance: data,
        }));
      });
  };
  const FetchEvolutionCount = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        agent_event_count_query(id, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        const transformedData = [];
        // Iterate over the API response to format the data
        buckets.forEach((item) => {
          const timestamp = new Date(item.key_as_string);

          // Format the timestamp as "HH:mm"
          const formattedTime = timestamp.toDateString();

          // // Create an Array with the formatted time as the 'name' property
          const formattedItem = { name: formattedTime, count: item.doc_count };
          transformedData.push(formattedItem);
        });
        setData((pre) => ({
          ...pre,
          elevation: { data: transformedData, dataKeys: ["count"] },
        }));
      });
  };
  const FetchFIMCount = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    setData((pre) => ({ ...pre, fim: { ...pre.fim, loading: true } }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        agent_fim_query(id, from_date, to_date)
      )
      .then((res) => {
        const hits = res.data?.hits?.hits;
        const rows = [];
        for (const row of hits) {
          const _source = row._source;
          rows.push({
            time: _source["timestamp"],
            path: _source?.syscheck?.path,
            action: _source?.syscheck?.event,
            ruleDescription: _source?.rule?.description,
            ruleLevel: _source?.rule?.level,
            ruleId: _source?.rule?.id,
          });
        }
        setData((pre) => ({ ...pre, fim: { ...pre.fim, rows: rows } }));
      })
      .catch(() => { })
      .finally(() => {
        setData((pre) => ({ ...pre, fim: { ...pre.fim, loading: false } }));
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const agentsResponse = await wazuh.get("/agents", {
          params: { q: "id!=000", agents_list: id },
        });
        const agentsData = agentsResponse.data;
        setAgent(agentsData?.data.affected_items[0]);

        const scaResponse = await wazuh.get(`/sca/${id}`, {
          params: { limit: 1, sort: "-end_scan" },
        });
        const scaData = scaResponse.data;

        setData((prevData) => ({
          ...prevData,
          sca: { loading: false, rows: scaData?.data.affected_items },
        }));

        FetchMITRE();
        FetchCompliance();
        FetchEvolutionCount();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    FetchMITRE();
    FetchCompliance();
    FetchEvolutionCount();
    FetchFIMCount();
    // eslint-disable-next-line
  }, [dateRange]);

  useEffect(() => {
    FetchCompliance(complaincsFilter);
    // eslint-disable-next-line
  }, [complaincsFilter]);

  return (
    <Box mt={3} display="flex" flexDirection="column" gap={2}>
      <Box display="flex">
        <IconButton onClick={() => navigation("/agents")}>
          <ArrowBackIosIcon color="primary" />
        </IconButton>
        <Typography
          color="primary"
          sx={{ textDecoration: "underline" }}
          variant="h5"
          fontWeight={700}
        >
          Agents
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems={"center"}>
        {/* Pages Navigation */}
        <Box display="flex" gap={2}>
          <Link to={`/modules-events?tab=mitre&agent_id=${id}`}>
            <Button >
              MITRE
            </Button>
          </Link>
          <Link
            to={`/modules-events?tab=fim&agent_id=${id}`}
          >
            <Button >

              FIM: Recent events
            </Button>
          </Link>
          <Link
            to={`/modules-events?tab=sca&agent_id=${id}`}
          >
            <Button >

              SCA: Lastest scans
            </Button>
          </Link>
          <Link
            to={`/modules-events?tab=vlby&agent_id=${id}`}
          >
            <Button>
              Vulnerabilities
            </Button>
          </Link>
        </Box>
        {/* Date Range */}
        <Box width={500}>
          <DateRangeSelector
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Box>
      </Box>

      <Box component={Paper} elevation={3} sx={{ width: "100%", p: 1.5 }}>
        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Id"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                {id}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Status"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FiberManualRecordIcon
                    sx={{
                      color: agent?.status !== "active" ? "#d32f2f" : "green",
                    }}
                    fontSize="small"
                  />
                  {agent?.status}
                </Box>
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"IP address"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                {agent?.ip}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Version"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                {agent?.version?.includes("DefenceFusion") ? agent?.version.replace("DefenceFusion", props.brand?.org_name) : agent?.version}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Groups"}
              <br />
              {agent?.group &&
                agent.group.map((g) => (
                  <Typography key={g} variant="caption" fontWeight="bold">
                    <Box
                      width={70}
                      style={{ border: "1px solid #D3DAE6" }}
                      p={"0 8px"}
                    >
                      <Box
                        sx={{
                          color: "black",
                          textDecoration: "none",
                          "&:hover": { textDecoration: "underline" },
                        }}
                      >
                        {g}
                      </Box>
                    </Box>
                  </Typography>
                ))}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Operating system"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <WindowIcon sx={{ color: "primary.main" }} fontSize="small" />
                  {agent?.os?.name}
                </Box>
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Cluster node"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                {agent?.node_name}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Registration date"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                {new Date(agent?.dateAdd)?.toDateString() +
                  " @ " +
                  new Date(agent?.dateAdd)?.toLocaleTimeString()}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" fontWeight={300}>
              {"Last keep alive"}
              <br />
              <Typography variant="caption" fontWeight="bold">
                {new Date(agent?.lastKeepAlive)?.toDateString() +
                  " @ " +
                  new Date(agent?.lastKeepAlive)?.toLocaleTimeString()}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid height={"80vh"} container spacing={2}>
        <Grid item container xs={12} md={6} spacing={2}>
          {/* MITRE  */}
          <Grid item xs={12} md={6}>
            <Box
              component={Paper}
              display="flex"
              flexDirection="column"
              gap={3}
              elevation={3}
              width="100%"
              height="100%"
              maxHeight={400}
              p="10px 20px"
            >
              {/* title */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" fontWeight={300}>
                  MITRE
                </Typography>
                <IconButton
                  size="small"
                  onClick={() =>
                    navigation(`/modules-events?tab=mitre&agent_id=${id}`)
                  }
                >
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </Box>
              {/* Body  */}
              <Box display="flex" gap={0.5} flexDirection="column">
                <Typography variant="subtitle2" paragraph>
                  Top Tactics
                </Typography>
                {data.mitre.data.labels.length < 1 && (
                  <Box
                    display="flex"
                    height="100%"
                    justifyContent="center"
                    color="grey"
                  >
                    No data
                  </Box>
                )}
                {data.mitre.data.labels.map((r, i) => (
                  <Box display="flex" key={r} justifyContent="space-between">
                    <Typography
                      variant="caption"
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                          cursor: "pointer",
                        },
                      }}
                    >
                      {r}
                    </Typography>
                    <Chip label={data.mitre.data.values[i]} size="small" />
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          {/* Compliance  */}
          <Grid item xs={12} md={6}>
            <Box
              component={Paper}
              display="flex"
              flexDirection="column"
              gap={3}
              elevation={3}
              width="100%"
              height="100%"
              maxHeight={400}
              xs={6}
              p="10px 20px"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                // flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
              >
                <Typography variant="h6" fontWeight={300}>
                  Compliance
                </Typography>
                <Select
                  sx={{ minWidth: "50%", height: 30 }}
                  onChange={(e) => {
                    setComplaincsFilter(e.target.value);
                  }}
                  value={complaincsFilter}
                >
                  {compliance_filters.map((op) => (
                    <MenuItem key={op.value} value={op.value}>
                      {op.lable}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              {/* Body  */}
              {data.compliance.labels.length < 1 ? (
                <Box
                  display="flex"
                  minHeight="105px"
                  justifyContent="center"
                  alignItems="center"
                  color="grey"
                >
                  No data
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  gap={0.5}
                  height="100%"
                  flexDirection="column"
                >
                  <Polarchart data={data.compliance} />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* FIM: Recent events */}
        <Grid item xs={12} md={6}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            elevation={3}
            height="100%"
            maxHeight={400}
            p="10px 20px"
            pb={3}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h6"
                fontWeight={300}
                paragraph
                sx={{ "&:hover": { textDecoration: "underline" } }}
              >
                FIM: Recent events
              </Typography>
              <IconButton
                size="small"
                onClick={() =>
                  navigation(`/modules-events?tab=fim&agent_id=${id}`)
                }
              >
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Box>

            {/* Table */}

            <Box
              border="1px solid #D3DAE6"
              borderRadius="4px"
              height={"240px"}
              p={0.5}
            >
              <Scrollbars height="100%">
                <SortTable columns={fim_columns} rows={data.fim.rows} />
              </Scrollbars>
            </Box>
          </Box>
        </Grid>
        {/* Events count evolution */}
        <Grid item xs={12} md={7}>
          <Box component={Paper} elevation={3} height="100%" maxHeight={400} p="10px 20px">
            <Typography variant="h6" fontWeight={300} paragraph>
              Events count evolution
            </Typography>
            {data.elevation.dataKeys.length > 0 ? (
              <SynchronizedLineChart
                data={data.elevation.data}
                dataKey={data.elevation.dataKeys}
                legend={false}
              />
            ) : (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            )}
          </Box>
        </Grid>
        {/* SCA: Lastest scans */}
        <Grid item xs={12} md={5}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            elevation={3}
            maxHeight={400}
            height="100%"
            p="10px 20px"
            pb={3}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h6"
                fontWeight={300}
                paragraph
                sx={{ "&:hover": { textDecoration: "underline" } }}
              >
                SCA: Lastest scans
              </Typography>
              <IconButton
                size="small"
                onClick={() =>
                  navigation(`/modules-events?tab=sca&agent_id=${id}`)
                }
              >
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Box>
            {/* /modules-events?tab=2 */}
            <Box
              display={data.sca?.rows[0]?.name ? "flex" : "none"}
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                color="primary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {data.sca?.rows[0]?.name}
              </Typography>

              <Chip
                color="success"
                size="small"
                label={data.sca.rows[0]?.policy_id}
                sx={{ ml: 2 }}
              />
            </Box>
            {/* Table */}
            <Box
              border="1px solid #D3DAE6"
              borderRadius="4px"
              // height={{ md: "100%", xs: "240px" }}
              height={"240px"}
              p={0.5}
              mt={2}
            >
              <Scrollbars height="100%">
                <SortTable columns={sca_columns} rows={data.sca.rows} />
              </Scrollbars>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};
const mapStateToProps = (state) => {
  return {
    brand: state.brand
  };
};

export default connect(mapStateToProps, null)(AgentDetail);

