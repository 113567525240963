import { Box } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Colors = [
  { stroke: "#3b72aa", fill: "#73a9ce" },
  { stroke: "#9a8ad0", fill: "#c9b8e2" },
  { stroke: "#bc2c1a", fill: "#f37567" },
  { stroke: "#916a4b", fill: "#d1bfa9" },
  { stroke: "#41a68d", fill: "#75d4c2" },
  { stroke: "#c2953f", fill: "#e8c487" },
  { stroke: "#35424a", fill: "#657d87" },
  { stroke: "#5f82a0", fill: "#8cb0c5" },
  { stroke: "#be4c39", fill: "#f07866" },
  { stroke: "#327c6c", fill: "#68b1a0" },
  {
    stroke: "#6e9bbd",
    fill: "#8ac6d1",
  },
  {
    stroke: "#8caf6f",
    fill: "#b4d7a7",
  },
  {
    stroke: "#000",
    fill: "#8ac6d1",
  },
];

const RoundedBar = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={10} // The overall corner radius
      />
      <rect
        x={x}
        y={y}
        width={width}
        height={height / 2} // Half of the height to get the top part
        fill={fill}
        rx={10} // The corner radius for the top part
        ry={10} // The corner radius for the top part
      />
    </g>
  );
};

const StackedBarChart = ({ data, height }) => {
  const dataKeys = Array.from(
    new Set(
      data.flatMap((item) => Object.keys(item).filter((key) => key !== "name"))
    )
  );
  return (
    <Box
      sx={{
        "& .recharts-cartesian-axis-tick-value tspan": { fontSize: "0.7em" },
        "& .recharts-cartesian-axis-tick-line ": { display: "none" },
      }}
    >
      <ResponsiveContainer width="100%" height={height}>
        <BarChart data={data} barSize={50} shape={<RoundedBar />}>
          <XAxis dataKey="name" />
          <YAxis
            label={{ value: "Counts", angle: -90, position: "insideLeft" }}
          />
          <Tooltip />
          <Legend />

          {dataKeys.map((key, index) => (
            <Bar
              focusable
              key={index}
              dataKey={key}
              stackId="a"
              fill={Colors[index].fill}
              stroke={Colors[index].stroke}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default StackedBarChart;
