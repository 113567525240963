import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import InputField from "../../../components/Field/BootStrap/InputField";

import Btn from "../../../components/Buttons/BootStrap/Btn";
import PlusIcon from "../../../static/icons/plus-icon.svg";
import ReactScrollbar from "react-scrollbar";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import cms from "../../../config/axiosConfig";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function CreateUserDialog({
  open,
  setOpen,
  initial_fields,
  organization = "",
  update = false,
  refrashFunc,
  resetField,
  fetchUsers,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const [fields, setFields] = useState(initial_fields);
  const [profiles, setProfiles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields((pre) => ({ ...pre, [name]: value }));
  };

  const fetchProfile = async (e) => {
    try {
      await axios
        .get("/cms/api/profile", { params: { range: "all" } })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            const _data = [];
            data.map((p) => {
              if (
                organization.toLowerCase() === "admin" ? p.isAdmin : !p.isAdmin
              ) {
                _data.push(p);
              }
              return "";
            });

            setProfiles(_data);
          }
        });
    } catch {
      toast.error("something went wrong.");
    }
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line
  }, []);

  // andle Submit
  const validate = () => {
    let valid = true;
    let message = "";

    if (fields.login) {
      const email = fields.login.split("@")[0];
      if (email.length < 4) {
        console.log(email, email.length);
        message = `(${email}) is to short must be atleast 4 char long.`;
        valid = false;
        toast.error(message);
        return valid;
      }
    }
    if (!fields.name) {
      message = "Name is required";
      valid = false;
    } else if (!fields.login) {
      message = "User email is required";
      valid = false;
    } else if (!fields.profile) {
      message = "Profile is required";
      valid = false;
    } else if (!fields.password && !update) {
      message = "Please provide password";
      valid = false;
    } else if (fields.password && !update) {
      if (fields.password !== fields.confirm_password) {
        message = "Password not matching";
        valid = false;
      } else if (fields.password.length < 8 || fields.password.length > 64) {
        message = "Password should be 8-64 characters long";
        valid = false;
      }
    }
    if (fields.password) {
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      const validatePassword = (_password) => passwordPattern.test(_password);
      if (!validatePassword(fields.password)) {
        message = "The password must contain at least one upper and lower case letter, a number and a symbol, and should be minimum 8 characters long.";
        valid = false;
      }
      console.log("pass", !validatePassword(fields.password))
    }
    if (!valid) {
      toast.error(message);
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    if (validate()) {
      try {
        delete fields["id"];
        // delete fields["confim_password"]
        // delete fields["confirm_password"]
        // delete fields["password"]
        await axios
          // .post("/cms/api/v1/user", { ...fields, organisation: organization })
          .post("/api/adduser", { ...fields, organisation: organization })
          .then((res) => {
            if (res.status === 201) {
              toast.success("User created successfuly.");
              refrashFunc();
              resetField();
              setOpen(false);
            }
          });
      } catch {
        toast.error("something went wrong.");
      }
    }
  };
  const handleUpdate = async (e) => {
    if (validate()) {
      try {
        await cms
          // .patch(`/cms/api/v1/user/${fields.id}`, {
          .patch(`/api/updateuser/${fields.id}`, {
            name: fields.name,
            organisation: fields.organisation,
            profile: fields.profile,
          })
          .then((res) => {
            if (res.status === 204) {
              toast.success("User updated successfuly.");
              fetchUsers();
              setOpen(false);
            }
          });
      } catch (err) {
        toast.error(err);
      }
    }
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleProfileChange = (event, newValue) => {
    setFields({
      ...fields,
      profile: newValue ? newValue.name : "",
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight={700}>
            {update ? "Update user" : "Add user"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <Grid container direction="column" item spacing={1.25}>
            <Grid item>
              <Typography variant="body2" fontWeight="bold" color="#000">
                Organization*
              </Typography>
            </Grid>
            <Grid item>
              <InputField
                fullWidth
                name="organisation"
                readOnly
                value={organization}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight="bold" color="#000">
                Email*
              </Typography>
            </Grid>
            <Grid item>
              <InputField
                placeholder="User’s email Address"
                fullWidth
                readOnly={update}
                name="login"
                value={fields.login}
                onChange={handleFieldChange}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight="bold" color="#000">
                Full Name*
              </Typography>
            </Grid>
            <Grid item>
              <InputField
                placeholder="User’s Name"
                fullWidth
                name="name"
                value={fields.name}
                onChange={handleFieldChange}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" fontWeight="bold" color="#000">
                Profile*
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                options={profiles}
                getOptionLabel={(option) => option.name}
                value={{ name: fields.profile }}
                onChange={handleProfileChange}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Select Profile" />
                )}
              />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Password*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Confirm Password*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  placeholder="Password"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={fields.password}
                  onChange={handleFieldChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  placeholder="Confirm Password"
                  fullWidth
                  name="confirm_password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={fields.confirm_password}
                  onChange={handleFieldChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="body2" fontWeight={400} color="#000">
            * Required field
          </Typography>
          <Btn
            onClick={update ? handleUpdate : handleSubmit}
            text={update ? "Update User" : "Save User"}
            style={{ backgroundColor: "#003272" }}
            startIcon={PlusIcon}
            height={50}
            fontSize={20}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
