import React, { useState, useEffect, useRef } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import wazuh, { wazuhIndex } from "../../../config/wazuh.js";
import { useLocation } from "react-router-dom";
import PieChartPercentage from "../../../components/charts/PieChartPercentage.js"
import SimplePieChart from "../../../components/charts/PieChart.js";
import { alert_authsuccess_query, alert_evolution_query, alert_mitre_att_query, alert_top_5_query, alerts_events_query } from "../../Modules/enums/enums.js";
import EventsTabs from "../../Modules/Security events/EventsTabs.js"
import Map from "../../../components/Maps/Map.js";
import { FetchAlerts } from "../../../utils/events/EventsIndexs.js";
import GenderBarChart from "../../../components/charts/BarChart.js";
import { getRandomColor } from "../../../utils/utils.js";
import "./T1Dashboard.css"
import Scrollbars from "react-custom-scrollbars-2";



const T1Dashboard = ({ layout, setLayout, dateRange, interval }) => {
    const location = useLocation();
    const getUniqueRandomColors = (len = 10) => {
        const colorSet = new Set();
        while (colorSet.size < len) {
            colorSet.add(getRandomColor());
        }
        return Array.from(colorSet);
    };
    const searchParams = new URLSearchParams(location.search);
    const agentId = searchParams.get("id") || "001";
    const [severity, setSeverity] = useState({
        data: {}, graphData: [], colors: {
            high: getRandomColor(),
            medium: getRandomColor(),
            low: getRandomColor(),
        }, loading: true
    })
    const [alerts, setAlerts] = useState({ top_5: [], loading: true, colors: getUniqueRandomColors() })
    const [mitresAttacks, setMitreAttacks] = useState({ data: [], loading: true, colors: getUniqueRandomColors() })
    const [alertLvls, setAlertLvls] = useState({ data: [], loading: true, colors: getUniqueRandomColors() });
    const [events, setEvents] = useState({ data: {}, graphData: [], loading: false, color: getRandomColor() });

    const title_styles = {
        fontSize: "71.6667px",
        fontWeight: 500,
        lineHeight: 1.2,
        textAlign: "center"
    }

    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    const FetchVulnerability = async () => {
        setSeverity(pre => ({ ...pre, loading: true }))
        wazuh.get(`/vulnerability/${agentId}/summary/severity`
        ).then(res => {
            const _severity = res.data?.data?.severity;
            const graphsData = [{ name: 'High', value: _severity.High, color: severity.colors.high },
            { name: 'Medium', value: _severity.Medium, color: severity.colors.medium },
            { name: 'Low', value: _severity.Low, color: severity.colors.low }];
            setSeverity(pre => ({ ...pre, data: _severity, graphData: graphsData }));
        }).catch(err => {
            console.log(err)
        }).finally(res => {
            setSeverity(pre => ({ ...pre, loading: false }))
        })
    };
    const FetchAlertsCount = async (
        from_date = dateRange.from_date,
        to_date = dateRange.to_date
    ) => {

        setSeverity(pre => ({ ...pre, loading: true }));
        await FetchAlerts(
            "POST",
            alert_authsuccess_query(null, from_date, to_date)
        ).then((res) => {
            const value = res.total.value;
            setSeverity(pre => ({ ...pre, data: { ...pre.data, authentication_success: value } }));
        }).catch(err => {
            console.log(err)
        }).finally(res => {
            setSeverity(pre => ({ ...pre, loading: false }));
        });

    };
    const FetchTop5Agents = async (
        from_date = dateRange.from_date,
        to_date = dateRange.to_date
    ) => {
        try {
            setAlerts((pre) => ({ ...pre, loading: true }));
            await wazuhIndex
                .post(
                    `/idx/wazuh-alerts-*/_search/`,
                    alert_top_5_query(null, from_date, to_date)
                )
                .then((res) => {
                    const aggregations = res.data?.aggregations;
                    const buckets = Object.values(aggregations)[0].buckets;
                    let data = buckets.map((b, i) => {
                        return { "name": b.key, value: b.doc_count, color: alerts.colors[i] };
                    });
                    setAlerts((pre) => ({ ...pre, top_5: data }));
                }).finally(res => {
                    setAlerts((pre) => ({ ...pre, loading: false }));
                }).catch(err => {
                    console.log(err)
                });
        } catch { }
    };
    const FetchMITRE_ATT_CKS = async (
        from_date = dateRange.from_date,
        to_date = dateRange.to_date
    ) => {
        setMitreAttacks(pre => ({ ...pre, loading: true }))
        await wazuhIndex
            .post(
                `/idx/wazuh-alerts-*/_search/`,
                alert_mitre_att_query(null, from_date, to_date)
            )
            .then((res) => {
                const aggregations = res.data?.aggregations;
                const buckets = Object.values(aggregations)[0].buckets;
                let data = buckets.map((b, i) => {
                    return { "name": b.key, value: b.doc_count, color: mitresAttacks.colors[i] };
                });
                // Sort the data by value in descending order and take the top 10
                data = data.sort((a, b) => b.value - a.value).slice(0, 9);

                setMitreAttacks((pre) => ({ ...pre, data: data }));
            }).catch(err => {
                console.log(err)
            }).finally(re => {
                setMitreAttacks(pre => ({ ...pre, loading: false }))
            });
    };
    const FetchLvlEvolution = async (
        from_date = dateRange.from_date,
        to_date = dateRange.to_date
    ) => {
        setAlertLvls(pre => ({ ...pre, loading: true }))
        await wazuhIndex
            .post(
                `/idx/wazuh-alerts-*/_search/`,
                alert_evolution_query(null, from_date, to_date)
            )
            .then((res) => {
                const aggregations = res.data?.aggregations;
                const buckets = Object.values(aggregations)[0].buckets;

                // Create an empty object to hold the transformed data
                const aggregatedData = {};

                // Iterate over the API response to accumulate doc_count for each key
                buckets.forEach((item) => {
                    const innerBuckets = item[3].buckets;

                    innerBuckets.forEach((bucket) => {
                        const key = bucket.key.toString();
                        const doc_count = bucket.doc_count;

                        if (aggregatedData[key]) {
                            aggregatedData[key] += doc_count;
                        } else {
                            aggregatedData[key] = doc_count;
                        }
                    });
                });
                // Calculate the total doc_count
                const totalDocCount = Object.values(aggregatedData).reduce((sum, count) => sum + count, 0);

                // Create an array to hold the data including the percentage
                const _data = [];
                let index = 0;
                for (const key in aggregatedData) {
                    const value = aggregatedData[key];
                    const percentage = ((value / totalDocCount) * 100).toFixed(2);
                    _data.push({ name: key, value: value, percent: percentage, color: alertLvls.colors[index] });
                    index += 1;
                }
                setAlertLvls((pre) => ({
                    ...pre,
                    data: _data,
                }));
            }).catch(err => {
                console.log(err)
            }).finally(res => {
                setAlertLvls(pre => ({ ...pre, loading: false }))
            });
    };

    const FetchEvent = async (from_date = dateRange.from_date, to_date = dateRange.to_date
    ) => {
        setEvents((pre) => ({ ...pre, loading: true, }));
        await wazuhIndex
            .post(
                `/idx/wazuh-alerts-*/_search/`,
                alerts_events_query(null, null, from_date, to_date, 0, 100)
            )
            .then((res) => {
                const aggregations = res.data?.aggregations;
                const buckets = Object.values(aggregations)[0].buckets;

                const TimeStampToString = (stamp) => {
                    const timestamp = stamp;
                    const date = new Date(timestamp);
                    // const hours = String(date.getUTCHours()).padStart(2, "0");
                    // const minutes = String(date.getUTCMinutes()).padStart(2, "0");
                    // return `${hours}:${minutes}`;
                    return date.toLocaleDateString();
                };
                const graphData = buckets.map((b) => {
                    const label = TimeStampToString(b.key);
                    const value = b.doc_count;
                    return { name: label, value: value, color: events.color };
                });
                setEvents((pre) => ({
                    ...pre,
                    data: res.data,
                    graphData: graphData,
                    loading: false,
                }));
            })
            .catch((err) => {
                console.log(err);
            }).finally(res => {
                setEvents((pre) => ({ ...pre, loading: false, }));
            });
    };

    const onLayoutChange = (layout) => {
        localStorage.setItem("tier1-layout", JSON.stringify(layout));
        setLayout(layout);
    };

    useEffect(() => {
        const fetchAllData = () => {
            FetchVulnerability();
            FetchAlertsCount();
            FetchTop5Agents();
            FetchMITRE_ATT_CKS();
            FetchLvlEvolution();
            FetchEvent();
        };
        // Initial fetch
        fetchAllData();

        // Set up interval for fetching data
        const intervalId = setInterval(fetchAllData, interval * 60000);

        // Clear interval on unmount
        return () => clearInterval(intervalId);
    }, [dateRange.from_date, dateRange.to_date, interval]);

    return (
        <Box ref={containerRef} height="100vh">
            <GridLayout
                className="custom-grid-layout"
                layout={layout}
                cols={8}
                rowHeight={dimensions.height / 6}
                width={dimensions.width}
                height={dimensions.height}
                onLayoutChange={onLayoutChange}
                isResizable={true}
                isDraggable={true}
            >
                <Box key="1"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {severity.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>High Vulnerabilities</Typography>
                    <Typography variant="h2" textAlign="center" fontWeight={500} color="red" sx={title_styles}>{severity.data?.High || 0}</Typography>
                </Box>
                <Box key="2"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {severity.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>Medium Vulnerabilities</Typography>
                    <Typography variant="h2" textAlign="center" fontWeight={500} color="green" sx={title_styles}>{severity.data?.Medium || 0}</Typography>
                </Box>
                <Box key="3"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {severity.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>Low Vulnerabilities</Typography>
                    <Typography variant="h2" textAlign="center" fontWeight={500} sx={{ ...title_styles, color: "primary.light" }}>{severity.data?.Low || 0}</Typography>
                </Box>
                <Box key="4"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {severity.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>Authentication Success</Typography>
                    <Typography variant="h2" textAlign="center" fontWeight={500} sx={title_styles}>{severity.data?.authentication_success || 0}</Typography>
                </Box>
                <Box key="5"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {alertLvls.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>Alert Levels</Typography>
                    <PieChartPercentage
                        innerRadius={50}
                        data={alertLvls.data}
                    />
                </Box>
                <Box key="6"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {alerts.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>TOP 5 Agents</Typography>
                    <SimplePieChart
                        innerRadius={0}
                        fillColor={getRandomColor()}
                        data={alerts.top_5}
                        legendWithValue
                        customLegend="custome3"
                    />
                </Box>
                <Box key="7"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {events.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>Network Events</Typography>
                    <GenderBarChart data={events.graphData} barSize={5} />
                </Box>
                <Box key="8"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {severity.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>Security Levels</Typography>
                    <SimplePieChart
                        innerRadius={0}
                        fillColor={getRandomColor()}
                        data={severity.graphData}
                        legendWithValue
                        customLegend="custome3"
                    />
                </Box>
                <Box key="9"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {mitresAttacks.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    <Typography variant="subtitle1" noWrap>Top MITRE ATT & CKS</Typography>
                    <SimplePieChart
                        innerRadius={0}
                        data={mitresAttacks.data}
                        legendWithValue
                        customLegend="custome3"
                    />
                </Box>

                <Box key="10"
                    component={Paper}
                    p={1}
                    overflow={"hidden"}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    {events.loading && <Box position="absolute" top={-0.5} left={0} width="100%"><LinearProgress /></Box>}
                    {events.data?.hits?.hits ? (
                        <Scrollbars style={{ height: "100%" }}>
                            <EventsTabs
                                loading={events.loading && events.data.length < 1}
                                data={events.data}
                                setTableData={() => { }}
                                extendable={false}
                            />
                        </Scrollbars>
                    )
                        :
                        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                            <Typography>No Data</Typography>
                        </Box>
                    }
                </Box>
                <Box key="11"
                    component={Paper}
                    overflow="hidden"
                    p={1}
                    position="relative"
                    display="flex" flexDirection="column" gap={2}
                >
                    <Map />
                </Box>
            </GridLayout>
        </Box >
    );
};

export default T1Dashboard;
