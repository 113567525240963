import axios from "axios";
import wazuh from "../../config/wazuh";
import * as actionTypes from "./actionTypes";
import cms from "../../config/axiosConfig";

const setPermission = (permissions) => {
  let _permissions = {
    mOrg: permissions.includes("manageOrganisation"),
    mPrf: permissions.includes("manageProfile"),
    mCases: permissions.includes("manageCase"),
    mObs: permissions.includes("manageObservable"),
    mAlerts: permissions.includes("manageAlert"),
    mUsers: permissions.includes("manageUser"),
    mTasks: permissions.includes("manageTask"),
    mAnlys: permissions.includes("manageAnalyse"),
  };
  return {
    type: actionTypes.SET_PERMISSIONS,
    permissions: _permissions,
  };
};

export const setMode = () => {
  return {
    type: actionTypes.SET_MODE,
  };
};
export const setWazuhVersion = (version) => {
  return {
    type: actionTypes.SET_DF_VERISON,
    DF_version: version,
  };
};
export const setMeta = (analyzers) => {
  return {
    type: actionTypes.SET_META,
    analyzers: analyzers,
  };
};
export const setBrand = (response) => {
  return {
    type: actionTypes.SET_BRAND,
    brand: response,
  };
};
export const SetWazuhVerion = () => {
  try {
    return async (dispatch) => {
      await wazuh
        .get("")
        .then((res) => {
          if (res.status === 200) {
            const version = res.data?.data;
            dispatch(setWazuhVersion(version));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    // eslint-disable-next-line
  } catch (err) {
    console.log("-----from redux in actions/SetWazuhVerion", err);
  }
};
export const SetBrandDetails = () => {
  try {
    return async (dispatch) => {
      await cms
        .get("/api/getorgdata")
        .then((res) => {
          if (res.status === 200) {
            const response = res.data;
            dispatch(setBrand(response));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    // eslint-disable-next-line
  } catch (err) {
    console.log("-----from redux in actions/SetBrand", err);
  }
};
export const FetchMeta = () => {
  try {
    return async (dispatch) => {
      const organization = localStorage.getItem("organization")
      // Get analyzers
      if (!organization) {

        cms.get(
          `/cms/api/connector/cortex/analyzer`,
          {
            params: {
              range: "all",
            },
          }
        ).then(res => {
          localStorage.setItem("organization", JSON.stringify(res.data))
          dispatch(setMeta(res.data))
        }).catch(err => {
          console.log("Error in fetching meta")
        });
      }
      else {
        dispatch(setMeta(JSON.parse(organization)))
      }
    };

    // eslint-disable-next-line
  } catch (err) {
    console.log("-----from redux in actions/SetBrand", err);
  }
};

export const setCaseCount = (count) => {
  return {
    type: actionTypes.SET_CASES_COUNT,
    count: count,
  };
};
export const setTasksCount = (count) => {
  return {
    type: actionTypes.SET_TASKS_COUNT,
    count: count,
  };
};
export const setAlertsCount = (count) => {
  return {
    type: actionTypes.SET_ALERTS_COUNT,
    count: count,
  };
};
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};
export const authSuccess = (ams_user, cms_user) => {
  localStorage.setItem("ams_user", JSON.stringify(ams_user));
  localStorage.setItem("cms_user", JSON.stringify(cms_user));

  return {
    type: actionTypes.AUTH_SUCCESS,
    ams_user: ams_user,
    cms_user: cms_user,
  };
};
export const enableOTP = (enableOtp) => {
  return {
    type: actionTypes.ENABLE_OTP,
    enableOtp: enableOtp,
  };
};
export const authFail = (error) => {
  localStorage.clear();
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};
export const authLogout = () => {
  try {
    axios
      .get(`/logout`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        console.log("True");
      });
  } catch { }
  localStorage.clear();
  return async (dispatch) => {
    dispatch(authFail("logout"));
    dispatch(enableOTP(false));
  };
};
export const authCheck = () => {
  return async (dispatch) => {
    const ams_user = localStorage.getItem("ams_user");
    const cms_user = localStorage.getItem("cms_user");
    if (ams_user !== null && cms_user !== null) {
      dispatch(setPermission(JSON.parse(cms_user).permissions));
      dispatch(authSuccess(JSON.parse(ams_user), JSON.parse(cms_user)));
    } else {
      dispatch(authFail("users not found"));
    }
  };
};


export const authLogin = (username, password, otp = false) => {
  return async (dispatch) => {
    dispatch(authStart());
    await axios
      .post(
        `/login`,
        otp
          ? { code: otp, user: username, password: password }
          : { user: username, password: password }
      )
      .then((res) => {
        if (res.status === 200) {
          axios.get(`/api/getuserdata`).then((res) => {
            dispatch(setPermission(res.data.data.cms_user.permissions));
            dispatch(
              authSuccess(res.data.data.ams_user, res.data.data.cms_user)
            );
            dispatch(enableOTP(false));
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          console.log("True");
          dispatch(enableOTP(true));
        } else {
          console.log("Incorrect email or password");
          dispatch(authFail(err));
          dispatch(enableOTP(false));
        }
      });
  };
};

export const GetDataCounts = () => {
  const CaseCount = async (dispatch) => {
    try {
      let query = [{ _name: "countCase" }];

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: "case-count-all" },
        }
      );
      let d = response.data;
      dispatch(setCaseCount(d));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const TasksCount = async (dispatch) => {
    try {
      let query = [
        {
          _name: "listTask",
        },
        { _name: "filter", _not: { _field: "status", _value: "Cancel" } },
        {
          _name: "count",
        },
      ];

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: "mytasks-count-all" },
        }
      );
      let d = response.data;
      dispatch(setTasksCount(d));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const AlertsCount = async (dispatch) => {
    try {
      let query = [{ _name: "countAlert" }];
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: "alert-count-all" },
        }
      );
      let d = response.data;
      dispatch(setAlertsCount(d));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return async (dispatch) => {
    CaseCount(dispatch);
    TasksCount(dispatch);
    AlertsCount(dispatch);
  };
};
