import { CustomTableCell } from "../../../components/Display/CustomTable";

import React from "react";
import {
  TableRow,
  Box,
  Typography,
  Grid,
  ButtonBase,
  IconButton,
  Tooltip,
  Checkbox,
} from "@mui/material";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { formatCreatedAt } from "../../../utils/utils";
import CircleIcon from "@mui/icons-material/Circle";
import StarIcon from "@mui/icons-material/Star";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LinkIcon from "@mui/icons-material/Link";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const CustomObservablesRow = ({
  row,
  isSelected,
  onSelect,
  onDetailOpen,
  handleDelete = false,
  checkboxies,
  resetDetailPage,
}) => {
  const handleRowClick = () => {
    onDetailOpen(row._id, row.data);
  };

  return (
    <TableRow
      sx={{
        borderRadius: "4px",
        minHeight: 76,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        "& .MuiTableCell-root": {
          verticalAlign: "middle ",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),
      }}
      onClick={handleRowClick}
    >
      {checkboxies && (
        <CustomTableCell
          onClick={(e) => {
            e.stopPropagation();
            onSelect(row._id);
          }}
        >
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
              onSelect(row._id);
            }}
            size="small"
            checked={isSelected}
          />
        </CustomTableCell>
      )}
      <CustomTableCell>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Box display="flex" gap={0.25}>
            <CircleIcon
              fontSize="small"
              sx={{ color: row?.tlp ? "#f0ad4e" : "#dfdfdf" }}
            />

            <StarIcon
              fontSize="small"
              sx={{ color: row?.ioc ? "#003272" : "#dfdfdf" }}
            />
            <ToggleOnIcon
              fontSize="small"
              sx={{ color: row?.sighted ? "#1173FF" : "#dfdfdf" }}
            />
            <VisibilityOffIcon
              fontSize="small"
              sx={{ color: row.extraData?.seen.seen ? "#f0ad4e" : "#dfdfdf" }}
            />
            <LinkIcon
              fontSize="small"
              sx={{ color: row?.ignoreSimilarity ? "#f0ad4e" : "#dfdfdf" }}
            />
          </Box>
        </Box>
      </CustomTableCell>
      <CustomTableCell>
        {row?.dataType && (
          <ButtonBase
            onClick={() => {
              onDetailOpen(row._id, row.data);
            }}
          >
            <Typography variant="caption" color="#1173FF" fontWeight={700}>
              {row.dataType.toUpperCase()}
            </Typography>
          </ButtonBase>
        )}
      </CustomTableCell>
      <CustomTableCell>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <ButtonBase
            onClick={() => {
              onDetailOpen(row._id, row.data);
            }}
            sx={{ width: "fit-content" }}
          >
            <Typography color="#1173FF" variant="caption" fontWeight={700}>
              {row?.data}
            </Typography>
          </ButtonBase>
          <Grid container gap={1.25} maxWidth={"100%"}>
            {row.tags.map((tag, i) => (
              <Grid item key={tag + i}>
                <Box
                  sx={{
                    height: "20px",
                    p: "5px",
                    borderRadius: "2px 0px 0px 2px",

                    borderLeft: "5px solid #13D38E",
                    width: "fit-content",
                  }}
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  bgcolor={"#ECF0F4"}
                >
                  {tag}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box display="flex">
            <SettingsOutlinedIcon fontSize="small" />
            <i>No reports available</i>
          </Box>
        </Box>
      </CustomTableCell>
      <CustomTableCell sx={{ width: 200 }}>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Typography variant="caption" fontWeight={500}>
            <PlayCircleFilledRoundedIcon
              fontSize="small"
              sx={{ color: "#2497F3" }}
            />
            {formatCreatedAt(row.startDate)}
          </Typography>
          <Typography variant="caption" fontWeight={500}>
            <CheckCircleRoundedIcon
              fontSize="small"
              sx={{ color: "#13D38E" }}
            />
            {formatCreatedAt(row._createdAt)}
          </Typography>
        </Box>
      </CustomTableCell>
      {handleDelete && (
        <CustomTableCell>
          <Tooltip title="Delete observable">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(row)();
                resetDetailPage(row._id);
              }}
            >
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Tooltip>
        </CustomTableCell>
      )}
    </TableRow>
  );
};

export default CustomObservablesRow;
