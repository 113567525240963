import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen, children, title }) {
  const handleClose = (props) => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {title}
        <IconButton size="large" onClick={handleClose}>
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: "100vh" }}>{children}</DialogContent>
    </Dialog>
  );
}
