import React from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/material";

const FileUploadButton = ({ value, setValue }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setValue(file);
  };

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <label htmlFor="file-upload">
        <input
          id="file-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          // accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
        />
        <Button
          variant="outlined"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Add Attachment
        </Button>
      </label>
      {value && (
        <div>
          <p>Selected file: {value.name}</p>
          {/* You can add additional logic to display the file preview if needed */}
        </div>
      )}
    </Box>
  );
};

export default FileUploadButton;
