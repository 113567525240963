import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Box,
  ButtonBase,
  ButtonGroup,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Btn from "../../../components/Buttons/BootStrap/Btn";
import ReactScrollbar from "react-scrollbar";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RichTextEditor from "../../../components/Field/RichTextEditor";
import SwitchIOS from "../../../components/Buttons/SwitchIOS";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";

export default function CloseCaseDialog({
  open,
  setOpen,
  caseId,
  refrashFunc = undefined,
}) {
  const [tasks, setTasks] = useState([]);
  const [impact, setImpact] = useState(false);
  const [status, setStatuses] = useState(0);
  const [step, setStep] = useState(0);
  const [summary, setSummary] = useState("");
  const handleClose = () => {
    setOpen(false);
    setStep(0);
  };

  const navigate = useNavigate();

  const descriptionElementRef = React.useRef(null);
  const fetchRelatedTasks = async () => {
    try {
      let query = {
        _and: [
          {
            _parent: {
              _query: {
                _id: `~${caseId}`,
              },
              _type: "case",
            },
          },
          {
            _in: {
              _field: "status",
              _values: ["Waiting", "InProgress"],
            },
          },
        ],
      };

      const response = await axios.post(
        `/cms/api/case/task/_search`,
        { query: query },
        {
          params: { range: `all` },
        }
      );
      if (response.status === 200) {
        setTasks(response.data);
      }
    } catch {}
  };
  const handleCloseCase = async () => {
    try {
      let resolutionStatus = "TruePositive";
      switch (status) {
        case 0:
          resolutionStatus = "TruePositive";
          break;
        case 1:
          resolutionStatus = "FalsePositive";
          break;
        case 2:
          resolutionStatus = "Indeterminate";
          break;
        case 3:
          resolutionStatus = "Other";
          break;
        default:
          resolutionStatus = "TruePositive";
          break;
      }
      let query = {
        status: "Resolved",
        resolutionStatus: resolutionStatus,
        summary: summary,
        customFields: {},
      };
      if (status === 0) {
        query["impactStatus"] = impact ? "WithImpact" : "NoImpact";
      }

      const response = await axios.patch(`/cms/api/case/~${caseId}`, query);
      if (response.status === 200) {
        if (refrashFunc !== undefined) {
          refrashFunc();
        } else {
          navigate("/cases");
        }
        toast.success("Case closed successfuly.");
        setOpen(false);
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (step === 2) {
      handleCloseCase();
    }
    // eslint-disable-next-line
  }, [step]);
  useEffect(() => {
    fetchRelatedTasks();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" fontWeight={700}>
              Close Case: (~{caseId})
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Collapse in={step === 0} unmountOnExit>
              <Box display="flex" gap={1.5} flexDirection="column">
                <Box display="flex" gap={0.5} p={1} flexDirection="column">
                  {/* Heading */}
                  <Box display="flex" justifyContent="space-between">
                    <Typography color="#000" variant="body1" fontWeight={700}>
                      Task
                    </Typography>
                    <Typography variant="body1" fontWeight={700}>
                      Assignee
                    </Typography>
                  </Box>
                  <Divider flexItem />
                  {/* TAsk list */}
                  <ReactScrollbar style={{ maxHeight: "30vh" }}>
                    {tasks.map((t, i) => (
                      <>
                        <Box
                          component={ButtonBase}
                          key={t.id}
                          display="flex"
                          width="100%"
                          p={2}
                          justifyContent="space-between"
                        >
                          <Typography color="#000" variant="body1">
                            {t.title}
                          </Typography>

                          <Typography variant="body1">
                            {t.owner ? t.owner : <li>"not assignee"</li>}
                          </Typography>
                        </Box>
                        <Divider flexItem variant="middle" />
                      </>
                    ))}
                  </ReactScrollbar>
                </Box>
              </Box>
            </Collapse>
            <Collapse in={step > 0} unmountOnExit>
              <Box display="flex" gap={2.5} flexDirection="column">
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Status*
                </Typography>
                <Box>
                  <ButtonGroup
                    sx={{
                      border: "1px solid #ECF0F4",
                      borderRadius: "5px",
                      "& .MuiButtonBase-root": {
                        borderRight: "none !important",
                      },
                    }}
                    size="large"
                    fullWidth
                    variant="text"
                  >
                    <Button
                      onClick={() => {
                        setStatuses(0);
                      }}
                      sx={{ bgcolor: status === 0 && "#ECF0F4" }}
                    >
                      Ture Positive
                    </Button>
                    <Button
                      onClick={() => {
                        setStatuses(1);
                      }}
                      sx={{ bgcolor: status === 1 && "#ECF0F4" }}
                    >
                      False Positive
                    </Button>
                    <Button
                      onClick={() => {
                        setStatuses(2);
                      }}
                      sx={{ bgcolor: status === 2 && "#ECF0F4" }}
                    >
                      Indeterminate
                    </Button>
                    <Button
                      onClick={() => {
                        setStatuses(3);
                      }}
                      sx={{ bgcolor: status === 3 && "#ECF0F4" }}
                    >
                      Other
                    </Button>
                  </ButtonGroup>
                  <Alert severity="warning" sx={{ mt: 1 }}>
                    {status === 0 &&
                      "Investigation clearly demonstrates that there is something malicious (scam, phishing, malspam, malware, cybersquatting...)"}
                    {status === 1 &&
                      "Investigation shows that there is nothing malicious (email with clean attachment ...)"}
                    {status === 2 &&
                      " There aren't enough elements to tell that there is something malicious (original message has been deleted and not transmitted, IOC lookup with 0 hits ...)"}
                    {status === 3 &&
                      "Everything that does not require an investigation (not an incident)"}
                  </Alert>
                </Box>

                <Box
                  display={status === 0 ? "flex" : "none"}
                  alignItems="center"
                  gap={2.5}
                >
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Impact*
                  </Typography>
                  <SwitchIOS
                    lable=""
                    checked={impact}
                    onChange={(e) => {
                      setImpact(e.target.checked);
                    }}
                  />
                </Box>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Summary
                </Typography>
                <RichTextEditor setValue={setSummary} />
              </Box>
            </Collapse>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" gap={3}>
            <Typography variant="body2" fontWeight={400} color="#000000">
              * Required field
            </Typography>
          </Box>
          <Btn
            onClick={() => setStep(step + 1)}
            text="Close Case"
            height={50}
            fontSize={20}
            style={{ backgroundColor: "#003272", minWidth: "125px" }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
