import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Collapse, FormControlLabel, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export const CustomDateRadipoButton = ({
  field_name,
  label,
  setValue,
  selectedValue,
  value,
}) => {
  const [date, setDate] = useState({
    _from: dayjs().subtract(3, "days"),
    _to: dayjs(),
  });
  const [query, setQuery] = useState({
    _between: {
      _field: field_name,
      _from: date._from.valueOf(),
      _to: date._to.valueOf(),
    },
  });
  const handleChange = (field) => (value) => {
    setDate((pre) => ({ ...pre, [field]: value }));
  };
  const triggerRadioGroupChange = (value) => {
    setValue(value);
  };
  useEffect(() => {
    const _query = {
      _between: {
        _field: field_name,
        _from: date._from.valueOf(),
        _to: date._to.valueOf(),
      },
    };
    setQuery(_query);
    triggerRadioGroupChange(JSON.stringify(_query));
    // eslint-disable-next-line
  }, [date._from, date._to]);
  return (
    <Box display="flex" width="100%" flexDirection="column" gap={1}>
      <FormControlLabel
        name="date-buttons-group"
        value={JSON.stringify(query)}
        onClick={(e) => {
          setValue(e.target.value);
        }}
        control={<Radio />}
        label={label}
      />
      <Collapse
        unmountOnExit
        in={value === "Custom" && JSON.stringify(query) === selectedValue}
      >
        <DateRangePicker
          _from={date._from}
          _to={date._to}
          onChange={handleChange}
        />
      </Collapse>
    </Box>
  );
};

export const DateRangePicker = ({ _from, _to, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          "& .MuiInputBase-root": {
            height: 40,
          },
        }}
        components={["DatePicker", "DatePicker"]}
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <DatePicker label="From" value={_from} onChange={onChange("_from")} />
        <DatePicker label="To" value={_to} onChange={onChange("_to")} />
      </Box>
    </LocalizationProvider>
  );
};
