import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";

import { useState, useEffect } from "react";
import cms from "../../config/axiosConfig.js";
import CustomProfileRow from "./components/CustomProfileRow.js";
import CustomTable from "../../components/Display/CustomTable.js";
import DeleteProfileDialog from "./components/DeleteProfileDialog.js";
import { useCallback } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateProfileDialog from "./components/CreateProfileDialog.js";
import { connect } from "react-redux";

const Profiles = (props) => {
  const [data, setData] = useState({ loading: false, data: [], count: 0 });
  const [deletedialogBox, showDeleteDialog] = useState(false);
  const initialProfileFields = {
    isAdmin: true,
    name: "",
    permissions: [],
  };
  const [selectedProfile, setSelectedProfile] = useState(initialProfileFields);
  const [profileDialog, showProfileDialog] = useState(false);

  const columns = [
    { id: "_id", label: "# Number", sortable: true },
    { id: "name", label: "Name", sortable: true },
    { id: "permissions", label: "Permissions", sortable: false },
    { id: "", label: "", sortable: false },
  ];

  const fecthProfiles = async () => {
    try {
      setData({ data: [], loading: true });

      const response = await cms.get("/cms/api/profile", {
        params: { range: "all" },
      });
      let d = response.data;
      setData({ data: d, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDeletDialog = (profile) => {
    setSelectedProfile(profile);
    showDeleteDialog(true);
  };
  const handleUpdate = (_data) => () => {
    console.log(_data);
    setSelectedProfile(_data);
    showProfileDialog(true);
  };

  const ProfileEditOrAddDialog = useCallback(() => {
    return (
      <CreateProfileDialog
        initial_fields={selectedProfile}
        update={selectedProfile.name}
        open={profileDialog}
        setOpen={showProfileDialog}
        reFreshFunc={fecthProfiles}
      />
    );
  }, [profileDialog, selectedProfile]);

  useEffect(() => {
    fecthProfiles();
  }, []);

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={3.5}>
      {/* Title & screens btns */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h5" fontWeight="bold">
            User Profiles{" "}
            {data.count && `(${data.data.length} of ${data.count})`}
          </Typography>
          <Typography color="#A7BBCE" variant="caption">
            {"Dashboard > User Profiles"}
          </Typography>
        </Box>
        {props.permissions?.mPrf && (
          <Button
            startIcon={<AddCircleIcon fontSize="small" />}
            onClick={() => {
              showProfileDialog(true);
              setSelectedProfile(initialProfileFields);
            }}
            variant={"primary"}
            sx={{
              borderRadius: "5px",
              borderColor: "#003272",
              color: "#fff",
              bgcolor: "#13D38E",
              "&:hover": {
                color: "#0D2A56",
              },
            }}
          >
            Add Profile
          </Button>
        )}
        {/* Dialog */}
        <ProfileEditOrAddDialog />
      </Box>

      {/* Table */}
      <Box
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={2.5}
        sx={{ borderRadius: 1.5 }}
        height="100%"
      >
        {/* <Typography variant="h5" fontWeight="bold">
          
        </Typography> */}
        {console.log(props.permissions.mPrf)}
        {props.permissions.mPrf && (
          <CustomTable
            columns={columns}
            data={data.data}
            CustomTableRow={CustomProfileRow}
            loading={data.loading}
            onProfileDelete={handleDeletDialog}
            setSelectedProfile={handleUpdate}
          />
        )}
        {/* DeleteProfileDialog */}
        {selectedProfile && (
          <DeleteProfileDialog
            open={deletedialogBox}
            setOpen={showDeleteDialog}
            refrashFunc={fecthProfiles}
            id={selectedProfile._id}
          />
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions,
  };
};
export default connect(mapStateToProps, null)(Profiles);
