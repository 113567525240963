import { getTimezone, getIntervalPayload } from "../../../utils/utils.js";

const manager = JSON.parse(localStorage.getItem("DF_version"));
export const agent_mitre_att_query = (
  id,
  range_from = "now-24h",
  range_to = "now"
) => ({
  query: {
    bool: {
      must: [],
      filter: [
        {
          match_all: {},
        },
        {
          match_phrase: {
            "manager.name": manager?.hostname,
          },
        },
        {
          match_phrase: {
            "agent.id": id,
          },
        },
        {
          exists: {
            field: "rule.mitre.id",
          },
        },
        {
          range: {
            timestamp: {
              from: range_from,
              to: range_to,
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
  aggs: {
    tactics: {
      terms: {
        field: "rule.mitre.tactic",
        size: 5,
      },
    },
  },
  size: 0,
});

export const agent_compliance_query = (
  id,
  rule,
  range_from = "now-24h",
  range_to = "now"
) => ({
  query: {
    bool: {
      must: [],
      filter: [
        {
          match_all: {},
        },
        {
          match_phrase: {
            "manager.name": manager?.hostname,
          },
        },
        {
          match_phrase: {
            "agent.id": id,
          },
        },
        {
          exists: {
            field: "rule." + rule,
            // "rule.pci_dss",
          },
        },
        {
          range: {
            timestamp: {
              from: range_from,
              to: range_to,
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
  aggs: {
    top_alerts_compliance: {
      terms: {
        field: "rule." + rule,
        size: 5,
      },
    },
  },
});

export const agent_event_count_query = (
  id,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        {
          match_all: {},
        },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: id,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});

export const agent_evolution_query = (
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
      aggs: {
        3: {
          terms: {
            field: "status",
            order: {
              _term: "desc",
            },
            size: 5,
          },
          aggs: {
            4: {
              cardinality: {
                field: "id",
              },
            },
          },
        },
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: [],
  },
  query: {
    bool: {
      must: [
        {
          match_all: {},
        },
      ],
      filter: [
        {
          bool: {
            should: [
              {
                term: {
                  "manager.keyword": manager?.hostname,
                },
              },
            ],
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});
export const agent_fim_query = (
  id,
  range_from,
  range_to
) => ({
  query: {
    bool: {
      must: [],
      filter: [
        {
          match_all: {},
        },
        {
          match_phrase: {
            "manager.name": manager?.hostname,
          },
        },
        {
          match_phrase: {
            "agent.id": id,
          },
        },
        {
          match_phrase: {
            "rule.groups": "syscheck",
          },
        },
        {
          range: {
            timestamp: {
              from: range_from,
              to: range_to,
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
  aggs: {},
  size: 5,
  sort: [
    {
      timestamp: "asc",
    },
  ],
});
