import * as React from "react";
import { styled } from "@mui/material/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { Divider } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-select": {
    width: "100px",
  },
  "& .MuiInputBase-input": {
    borderRadius: "5px",
    position: "relative",
    backgroundColor: "#1173FF",
    border: "1px solid #ced4da",
    fontSize: 14,
    fontWeight: 600,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "white",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiNativeSelect-icon": {
    color: "white",
  },
  "& option": {
    backgroundColor: "#1173FF",
    color: "black",
  },
  "& select:focus": {
    backgroundColor: "#003272",
  },
  "& .Mui-disabled": {
    backgroundColor: "#ECF0F4",
  },
}));

export default function Selectcer({
  title,
  options,
  value,
  handleChange,
  disabled = false,
  ...other
}) {
  return (
    <NativeSelect
      value={value}
      onChange={handleChange}
      input={<BootstrapInput />}
      disabled={disabled}
      {...other}
    >
      <option aria-label="None" value="">
        <li>{title}</li>
      </option>
      {options.map((op, index) => (
        <>
          <option key={index} aria-label="None" value={op.value}>
            {op.name}
          </option>
          {op?.divider && <Divider />}
        </>
      ))}
    </NativeSelect>
  );
}
