import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Scrollbars from "react-custom-scrollbars-2";

export default function ColumnMenus({
  fields,
  anchorEl,
  setAnchorEl,
  onChange,
  ref,
}) {
  const open = Boolean(anchorEl);

  const handleChange = (value) => (e) => {
    onChange(e, value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            borderRadius: 1,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ml: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        },
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <Scrollbars style={{ height: 550, width: 350 }}>
        {fields.map((field) => (
          <MenuItem key={field.name} onClick={handleChange(field)}>
            <ListItemIcon>{field.Icon}</ListItemIcon>
            {field.label}
          </MenuItem>
        ))}
      </Scrollbars>
    </Menu>
  );
}
