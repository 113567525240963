import {
  Box,
  Button,
  InputAdornment,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars-2";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { mitre_tactics, mitre_techniques } from "../../enums/enums";
import { useNavigate } from "react-router-dom";
import wazuh, { wazuhIndex } from "../../../../config/wazuh";
import SideCanvas from "./Components.js/SideCanvas";

const Chip = ({ label, color = "#DD0A73" }) => (
  <Box
    component={Badge}
    height="fit-content"
    sx={{
      minWidth: "25px",
      bgcolor: `${color} !important`,
      color: color !== "#DD0A73" && "#000",
      borderRadius: "4px",
    }}
  >
    {label}
  </Box>
);
const DisplayBox = ({
  text,
  key,
  count = 0,
  pilColor,
  variant,
  onClick,
  selected = false,
}) => {
  return (
    <Tooltip title={text}>
      <Box
        key={key}
        component={Button}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        variant={variant}
        onClick={onClick}
        p={1}
        maxHeight={100}
        className="w-100 rounded"
        sx={{
          "&:hover": {
            "& .MuiTypography-root": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography
          variant="subtitle2"
          color="#000"
          fontWeight={selected ? 700 : 400}
          noWrap
        >
          {text}
        </Typography>
        <Chip label={count} color={pilColor} />
      </Box>
    </Tooltip>
  );
};

function Framework({ agent_id, dateRange, setDateRange }) {
  const [tactics, setTactics] = useState([]);
  const [selectedtactics, selectTactics] = useState([]);
  const [selectedTechniques, selectTechniques] = useState(null);
  const [techniques, setTechniques] = useState({ loading: true, data: [] });
  const [filteredTechniques, setFilteredTechniques] = useState([]);
  const [detailBox, setDetailBox] = useState(false);
  const [isFilteredTechniquesUpdated, setIsFilteredTechniquesUpdated] =
    useState(false);
  const navigate = useNavigate();
  let timeoutId = React.useRef();

  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const FetchTactic = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuh.get(`/mitre/tactics`).then((res) => {
      const _tactics = res.data.data.affected_items;
      wazuhIndex
        .post(
          `/idx/wazuh-alerts-*/_search/`,
          mitre_tactics(agent_id, "", from_date, to_date)
        )
        .then((res) => {
          const tactics = res.data.aggregations.tactics.buckets;
          const results = [];
          const _selectedtactics = [];
          for (let t in _tactics) {
            const key = _tactics[t].name;
            let doc_count = 0;
            const obj = tactics.find((i) => i.key === key);
            if (obj) {
              doc_count = obj.doc_count;
            }
            results.push({ ..._tactics[t], key: key, doc_count: doc_count });
            _selectedtactics.push(key);
          }
          selectTactics(_selectedtactics);
          // eslint-disable-next-line
          setTactics(
            // eslint-disable-next-line
            results.sort((a, b) => {
              if (b.doc_count !== a.doc_count) {
                return b.doc_count - a.doc_count;
              }
            })
          );
        });
    });
  };

  const FetchTechniques = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    setTechniques((pre) => ({ ...pre, loading: true }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        mitre_techniques(agent_id, "", from_date, to_date)
      )
      .then((res) => {
        const _techniques = res.data.aggregations.techniques.buckets;
        const DataFormation = async () => {
          await wazuh
            .get("mitre/techniques", { params: { limit: 500 } })
            .then((res) => {
              const __techniques = res.data.data.affected_items;
              const results = [];
              for (let i in __techniques) {
                const _tech_result = _techniques.find(
                  (obj) => __techniques[i].external_id.split(".")[0] === obj.key
                );
                if (_tech_result) {
                  results.push({ ...__techniques[i], ..._tech_result });
                } else {
                  results.push({ ...__techniques[i], doc_count: 0 });
                }
              }
              setTechniques({
                data: results,
                loading: false,
              });
              setFilteredTechniques(
                results.sort((a, b) => b.doc_count - a.doc_count)
              );
            });
        };
        DataFormation();
      })
      .catch(() => {
        setTechniques((pre) => ({ ...pre, loading: false }));
      });
  };

  const SearchTechniques = debounce(async (_searchText) => {
    setTechniques((pre) => ({ ...pre, loading: true, data: [] }));
    await wazuh
      .get("mitre/techniques", { params: { search: _searchText } })
      .then((res) => {
        const __techniques = res.data.data.affected_items;
        const hide = document.getElementById("hide_no_alert_tech").checked;

        if (hide) {
          setFilteredTechniques(__techniques.filter((t) => t.doc_count > 0));
        } else {
          setFilteredTechniques(__techniques.data);
          selectTactics(tactics.map((i) => i.key));
        }
        setTechniques({ loading: false, data: __techniques });
        // setFilteredTechniques(__techniques);
      })
      .catch(() => {
        setTechniques((pre) => ({ ...pre, loading: false }));
      });
  }, 500);

  const HideNoDCountTechniques = (hide) => {
    if (hide) {
      setFilteredTechniques(techniques.data.filter((t) => t.doc_count > 0));
    } else {
      setFilteredTechniques(techniques.data);
      selectTactics(tactics.map((i) => i.key));
    }
  };

  const handleTactics = (tec) => {
    const t = selectedtactics.find((_t) => _t === tec.key);
    if (t) {
      selectTactics(selectedtactics.filter((s) => s !== tec.key));
    } else {
      selectTactics((pre) => [...pre, tec.key]);
    }
  };

  useEffect(() => {
    if (!agent_id) {
      navigate(-1);
      return;
    }
    FetchTactic();
    FetchTechniques();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsFilteredTechniquesUpdated(false);
    const _filteredTechniques = [];
    // eslint-disable-next-line
    selectedtactics.map((s_tac) => {
      const tactic = tactics.find((t) => t.key === s_tac);
      if (tactic) {
        const tech = techniques.data.filter((_t) => {
          return _t.tactics.includes(tactic.id);
        });
        if (tech.length > 1) {
          _filteredTechniques.push(...tech);
        }
      }
    });
    setFilteredTechniques(_filteredTechniques);
    setIsFilteredTechniquesUpdated(true);
    // eslint-disable-next-line
  }, [selectedtactics]);

  return (
    <Box mt={5}>
      <Grid container spacing={2}>
        <Grid xs={4} lg={3} xl={2}>
          <Scrollbars style={{ height: "75vh" }}>
            <Box
              component={Paper}
              p={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              minHeight="75vh"
            >
              <Typography
                variant="h6"
                ml={1.5}
                fontWeight={400}
                className="w-100"
              >
                Tactics
              </Typography>
              <Box mt={2} className="w-100" p={1}>
                {tactics.map((t) => (
                  <DisplayBox
                    text={t.key}
                    key={t.id}
                    count={t.doc_count}
                    onClick={() => handleTactics(t)}
                    selected={selectedtactics.includes(t.key)}
                  />
                ))}
              </Box>
            </Box>
          </Scrollbars>
        </Grid>
        <Grid xs>
          <Box
            p={"8px 16px"}
            component={Paper}
            display="flex"
            flexDirection="column"
            gap={2}
          >
            {/* Make below box fixed on */}

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" fontWeight={400}>
                Techniques
              </Typography>
              <Typography variant="subtitle2" fontWeight={400}>
                Hide techniques with no alerts
                <Switch
                  size="small"
                  id="hide_no_alert_tech"
                  onChange={(e) => {
                    HideNoDCountTechniques(e.target.checked);
                  }}
                />
              </Typography>
            </Box>
            <TextField
              size="small"
              margin="dense"
              placeholder="Filter techniques of selected tatic/s"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ManageSearchIcon />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                e.target.value && SearchTechniques(e.target.value);
              }}
            />

            {techniques.loading && (
              <Box width="100%">
                <LinearProgress />
              </Box>
            )}
            <Scrollbars
              style={{ height: "60vh", width: "100%", overflowX: "hidden" }}
            >
              <Grid container spacing={0.5}>
                {isFilteredTechniquesUpdated &&
                  filteredTechniques?.map((t) => (
                    <Grid xs={3} key={t.key}>
                      <DisplayBox
                        key={t.id}
                        text={`${t.external_id} - ${t.name}`}
                        count={t.doc_count}
                        pillColor={"#e3e6ef"}
                        variant={"outlined"}
                        toolTipTitle={t.key}
                        onClick={() => {
                          setDetailBox(true);
                          selectTechniques(t);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Scrollbars>
          </Box>
        </Grid>
      </Grid>
      {/* side Overlay */}
      {selectedTechniques && (
        <SideCanvas
          show={detailBox}
          setShow={(value) => {
            setDetailBox(value);
          }}
          name={selectedtactics?.name}
          tactics={tactics.filter((t) => {
            return selectedTechniques.tactics.includes(t.id);
          })}
          placement="end"
          agentId={agent_id}
          mitre_id={selectedTechniques?.key}
          techniques={selectedTechniques}
        />
      )}
    </Box>
  );
}

export default Framework;
