import {
  Box,
  Typography,
  Button,
  Avatar,
  Grid,
  Chip,
  ButtonBase,
  InputBase,
  IconButton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Selector from "../../components/Field/BootStrap/Selectcer.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  calculateDaysFromTimestamp,
  convertToDateTimeLocal,
} from "../../utils/utils.js";
import { AssigneeAutoComplete } from "./components/CustomTasksRow.js";
import messagereplyIcon from "../../static/icons/messagereplyIcon.svg";
import axios from "axios";
import { formatCreatedAt } from "../../utils/utils";
import AddTaskLogDialog from "./components/AddTaskLogDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { CloseOutlined } from "@mui/icons-material";
import DeleteTaskLog from "./components/DeleteTaskLog";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import RichTextEditor from "../../components/Field/RichTextEditor";
import DisplayMarkdown from "../../components/Display/MarkDownTable/DisplayMarkDown.js";

const TaskDetail = ({ data, caseId, refrashFunc, permissions, onClose }) => {
  const [responders, setResponders] = useState([]);

  const [sortBy, setSortBy] = useState({
    name: "New first",
    value: "desc",
  });
  const [logsDialof, showLogsDialog] = useState(false);
  const [logs, setLogs] = useState({ loading: false, data: [] });
  const [deleteDialog, showdeleteDialog] = useState(false);
  const [selectedLog, selectLog] = useState("");
  const [editFields, setEditFields] = useState([]);

  const updateField = async (filed_name, value, field_id = "") => {
    try {
      const response = await axios.patch(`/cms/api/case/task/${data._id}`, {
        [filed_name]: value,
      });
      if (response.status === 200) {
        toast.success(`Successfuly updated "${filed_name}"`);
        refrashFunc();
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    } finally {
      setEditFields("");
      if (field_id) {
        try {
          const ele = document.getElementById(field_id);
          ele.readOnly = true;
          ele.blur();
          // fetchCaseDetail(true);
        } catch {}
      }
    }
  };
  const TaskStatusUpdate =
    (_status = data.status.toLowerCase()) =>
    async () => {
      let status = "";
      switch (_status) {
        case "waiting":
          status = "InProgress";
          break;
        case "inprogress":
          status = "Completed";
          break;
        case "completed":
          status = "InProgress";
          break;
        default:
          status = "Cancel";
          break;
      }
      if (status) {
        try {
          const response = await axios.patch(`/cms/api/case/task/${data._id}`, {
            status: status,
          });
          if (response.status === 200) {
            if (status === "Cancel") {
              onClose();
            } else {
              refrashFunc();
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
  const quick_filter_options = [
    {
      name: "New first",
      value: "desc",
    },
    {
      name: "Oldest first",
      value: "asc",
    },
  ];

  const FetchTaskLogs = async () => {
    setLogs((pre) => ({ ...pre, loading: true }));
    try {
      let query = [
        {
          _name: "getTask",
          idOrName: data._id,
        },
        {
          _name: "logs",
        },
        { _name: "sort", _fields: [{ date: sortBy.value }] },
        {
          _name: "page",
          from: 0,
          to: 10,
          extraData: ["actionCount"],
        },
      ];

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: `case-task-logs` },
        }
      );
      let d = response.data;

      setLogs((pre) => ({ ...pre, data: d, loading: false }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLogs((pre) => ({ ...pre, loading: false }));
    }
  };
  const FetchTaskResponder = async () => {
    try {
      let query = [
        {
          _name: "getTask",
          idOrName: data._id,
        },
        {
          _name: "actions",
        },
        {
          _name: "sort",
          _fields: [
            {
              startDate: "desc",
            },
          ],
        },
      ];
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: `case-task-actions` },
        }
      );
      let d = response.data;

      setResponders(d);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    FetchTaskLogs();
    FetchTaskResponder();
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    FetchTaskLogs();
    // eslint-disable-next-line
  }, [sortBy]);
  const StartDate = useCallback(() => {
    return (
      <Typography
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateField(
              "startDate",
              new Date(e.target.value).getTime(),
              "startDate"
            );
          }
        }}
        type="datetime-local"
        onClick={() => {
          if (permissions.mTasks) {
            setEditFields("startDate");
          }
        }}
        component={InputBase}
        variant="body2"
        id="startDate"
        name="startDate"
        readOnly={!permissions.mCases && !editFields === "startDate"}
        fullWidth
        fontWeight={700}
        defaultValue={convertToDateTimeLocal(data.startDate)}
      />
    );
    // eslint-disable-next-line
  }, [data.startDate]);
  const handleQuickfilter = (event) => {
    setSortBy((pre) => ({ ...pre, value: event.target.value }));
  };
  const handleFieldUpdate =
    (name, id, field = "") =>
    (e) => {
      let value = document.getElementById(id).value;
      if (field === "startDate") {
        value = new Date(value).getTime();
      }
      // if (field === "tags") {
      //   value = editTags;
      // }
      updateField(name, value, id);
    };
  return (
    <Box display="flex" flexDirection="column" gap={1.25} mt={4.375}>
      <Grid container spacing={5.75}>
        <Grid item xs={12} sx={!permissions.mTasks && { display: "none" }}>
          <Box display="flex" justifyContent="flex-end" gap={1.25}>
            {data.status.toLowerCase() === "waiting" && (
              <Button
                startIcon={<CheckCircleIcon fontSize="small" />}
                variant={"primary"}
                onClick={TaskStatusUpdate("waiting")}
                sx={{
                  height: "max-content",
                  minWidth: 120,
                  borderRadius: "5px",
                  color: "#fff",
                  bgcolor: "success.main",
                  "&:hover": {
                    bgcolor: "success.main",
                  },
                }}
              >
                {"Start"}
              </Button>
            )}
            {data.status.toLowerCase() === "completed" && (
              <Button
                hidden={data.status.toLowerCase() === "waiting"}
                startIcon={<RefreshIcon fontSize="small" />}
                variant={"primary"}
                id="flag_btn"
                onClick={TaskStatusUpdate("completed")}
                sx={{
                  borderRadius: "5px",
                  borderColor: "#003272",
                  color: "#fff",
                  height: "max-content",
                  minWidth: 120,
                  bgcolor: "success.main",
                  "&:hover": {
                    bgcolor: "success.main",
                  },
                }}
              >
                {"Reopen"}
              </Button>
            )}
            {data.status.toLowerCase() === "inprogress" && (
              <Button
                startIcon={<CloseOutlined fontSize="small" />}
                variant={"primary"}
                onClick={TaskStatusUpdate("inprogress")}
                sx={{
                  height: "max-content",
                  minWidth: 120,
                  borderRadius: "5px",
                  borderColor: "#003272",
                  color: "#fff",
                  bgcolor: "#0D2A56",
                  "&:hover": {
                    bgcolor: "#0D2A56",
                  },
                }}
              >
                Close
              </Button>
            )}

            <Button
              startIcon={<DeleteForeverIcon fontSize="small" />}
              variant={"contained"}
              color="error"
              onClick={TaskStatusUpdate("cancel")}
              sx={{
                height: "max-content",
                minWidth: 120,
                borderRadius: "5px",
              }}
            >
              Remove
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" gap={8}>
            <Typography variant="body2" width={80}>
              Title
            </Typography>
            <Box
              mt={-1.5}
              p="7px"
              sx={{
                border: editFields === "title" && "1px solid #EAF2FF",
                "&:hover": permissions.mTasks && {
                  border: "1px solid #EAF2FF",
                  "& .editButton": {
                    display: "flex",
                  },
                },
                position: "relative",
              }}
            >
              <Typography
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateField("title", e.target.value, "title");
                  }
                }}
                onClick={() => setEditFields("title")}
                component={InputBase}
                variant="body2"
                name="title"
                // disabled={!permissions.mTasks}
                readOnly={!editFields === "title"}
                fullWidth
                fontWeight={700}
                id="title"
                defaultValue={data.title}
              />
              <Box
                display={editFields === "title" ? "flex" : "none"}
                gap="5px"
                position="absolute"
                sx={{ bottom: -40, right: 0 }}
                className="editButton"
              >
                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={handleFieldUpdate("title", "title")}
                >
                  <CheckCircleIcon sx={{ color: "primary.light" }} />
                </IconButton>

                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={() => setEditFields("")}
                >
                  <CancelIcon sx={{ color: "error.main" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" gap={8}>
            <Typography variant="body2" width={80}>
              Duration
            </Typography>

            <Typography variant="body2" fontWeight="bold">
              {data.startDate
                ? calculateDaysFromTimestamp(data.startDate) + "days"
                : "-------"}{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" gap={8}>
            <Typography variant="body2" width={80}>
              Start date
            </Typography>

            <Box flexGrow={1} display="flex" mt={-1.5}>
              <Box
                p="7px"
                className="w-100"
                sx={{
                  border: editFields === "startDate" && "1px solid #EAF2FF",
                  "&:hover": permissions.mTasks && {
                    border: "1px solid #EAF2FF",
                    "& .editButton": {
                      display: "flex",
                    },
                  },
                  position: "relative",
                }}
              >
                <StartDate />

                <Box
                  display={editFields === "startDate" ? "flex" : "none"}
                  gap="5px"
                  position="absolute"
                  sx={{ bottom: -40, right: 0 }}
                  className="editButton"
                >
                  <IconButton
                    sx={{ borderRadius: "5px" }}
                    onClick={handleFieldUpdate(
                      "startDate",
                      "startDate",
                      "startDate"
                    )}
                  >
                    <CheckCircleIcon sx={{ color: "primary.light" }} />
                  </IconButton>

                  <IconButton
                    sx={{ borderRadius: "5px" }}
                    onClick={() => setEditFields("")}
                  >
                    <CancelIcon sx={{ color: "error.main" }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" gap={8}>
            <Typography variant="body2" width={80}>
              Assignee
            </Typography>
            {editFields !== "assignee" ? (
              <Typography
                component={ButtonBase}
                onClick={() => setEditFields("assignee")}
                variant="body2"
                fontWeight="bold"
              >
                {data.assignee}
              </Typography>
            ) : (
              <Box mt={-1.5} minWidth={200}>
                <AssigneeAutoComplete
                  id={data._id}
                  onClose={() => setEditFields("")}
                  onUpdateAssignee={(newValue) => {
                    updateField("owner", newValue?.login);
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center" gap={8}>
            <Typography variant="body2" width={80}>
              Status
            </Typography>

            <Chip
              clickable={false}
              color={
                data.status === "Completed"
                  ? "success"
                  : data.status === "InProgress"
                  ? "primary"
                  : data.status === "Waiting"
                  ? "warning"
                  : "error"
              }
              sx={{
                "& .MuiChip-label": {
                  color: "#fff",
                },
              }}
              label={data.status}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={5} display="flex" flexDirection="column" gap={1.25}>
        <Box display="flex" gap={1}>
          <Typography fontWeight={700} variant="h6">
            Description
          </Typography>
          {permissions.mTasks && (
            <IconButton
              size="small"
              sx={{ borderRadius: "5px", color: "#000" }}
              onClick={() => setEditFields("description")}
            >
              <EditIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
          )}
        </Box>
        {editFields === "description" ? (
          <Box display="flex" flexDirection="column" gap={1}>
            <input id="description" hidden />
            <RichTextEditor
              defaultValue={data.description}
              setValue={(value) => {
                document.getElementById("description").value = value;
              }}
            />
            <Box>
              <IconButton
                sx={{ borderRadius: "5px" }}
                onClick={handleFieldUpdate("description", "description")}
              >
                <CheckCircleIcon sx={{ color: "primary.light" }} />
              </IconButton>

              <IconButton
                sx={{ borderRadius: "5px" }}
                onClick={() => setEditFields("")}
              >
                <CancelIcon sx={{ color: "error.main" }} />
              </IconButton>
            </Box>
          </Box>
        ) : data.description ? (
          <Box display="flex" gap={8}>
            <Box
              mt={-1.5}
              p="7px"
              sx={{
                border: editFields === "description" && "1px solid #EAF2FF",
                "&:hover": permissions.mTasks && {
                  border: "1px solid #EAF2FF",
                  "& .editButton": {
                    display: "flex",
                  },
                },
                position: "relative",
              }}
            >
              <DisplayMarkdown markdownValue={data.description} />

              <Box
                display={editFields === "description" ? "flex" : "none"}
                gap="5px"
                position="absolute"
                sx={{ bottom: -40, right: 0 }}
                className="editButton"
              >
                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={handleFieldUpdate("description", "description")}
                >
                  <CheckCircleIcon sx={{ color: "primary.light" }} />
                </IconButton>

                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={() => setEditFields("")}
                >
                  <CancelIcon sx={{ color: "error.main" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography variant="caption">
            <li>Not Specified</li>
          </Typography>
        )}
      </Box>

      <Box mt={5} display="flex" flexDirection="column" gap={1.25}>
        <Box display="flex" gap={1.25}>
          <Button
            startIcon={<AddCircleIcon fontSize="small" />}
            variant={"primary"}
            hidden={!permissions.mTasks}
            sx={{
              borderRadius: "5px",
              borderColor: "#003272",
              color: "#fff",
              bgcolor: "#13D38E",
              "&:hover": {
                color: "#0D2A56",
              },
            }}
            onClick={() => {
              showLogsDialog(true);
            }}
          >
            Add New Task log
          </Button>
          <Selector
            value={sortBy.value}
            handleChange={handleQuickfilter}
            title={"Quik Filters"}
            options={quick_filter_options}
            size="small"
          />
        </Box>
        {logs.data.map((log) => (
          <Box
            display="flex"
            flexDirection="column"
            gap={3.5}
            sx={{ border: "1px solid #ECF0F4", p: "18px 20px" }}
          >
            {/* Title */}
            <Box
              display="flex"
              alignItems="center"
              className="w-100"
              justifyContent="space-between"
            >
              <Box display="flex" gap={1}>
                <Avatar>{log.owner.slice(0, 1)}</Avatar>
                <Typography variant="subtitle1" fontWeight={700}>
                  {log.owner}
                  <Typography fontSize={10}>
                    {formatCreatedAt(log._createdAt)}
                  </Typography>
                </Typography>
              </Box>
              <Button
                sx={{ height: 36, borderRadius: "5px" }}
                variant="contained"
                color="error"
                onClick={() => {
                  selectLog(log._id);
                  showdeleteDialog(true);
                }}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </Box>
            {/* Body  */}
            <Box>
              <Box className="w-50">
                <DisplayMarkdown markdownValue={log.message} />
              </Box>
              {/* Attachments */}
              {console.log(log.attachment)}
              {log.attachment && (
                <Grid container>
                  <Grid item xs={3}>
                    <Box display="flex" flexDirection="column">
                      <Box
                        sx={{ borderRadius: "5px 5px 0px 0px" }}
                        component="img"
                        width="100%"
                        alt="attachment"
                        src={
                          window.location.origin +
                          `/cms/api/datastore/${log.attachment?.id}`
                        }
                      />
                      <Box
                        bgcolor="#ECF0F4"
                        p="10px 19px"
                        sx={{ borderRadius: "0px 0px 5px 5px" }}
                      >
                        <Typography
                          component="a"
                          target="_blank"
                          href={
                            window.location.origin +
                            `/cms/api/datastore/${log.attachment?.id}`
                          }
                        >
                          {log.attachment.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {responders.length > 0 && (
        <Box mt={5} display="flex" flexDirection="column" gap={1.25}>
          <Typography variant="h6" fontWeight={700}>
            Responder Jobs
          </Typography>
          {responders.map((res) => (
            <Box
              sx={{
                bgcolor: "#ECF0F4",
                borderRadius: "6px",
                width: "100%",
                p: "17px 20px",
                "&:hover": {
                  bgcolor: "#D4D8DB",
                },
              }}
              display="flex"
              gap={2.5}
            >
              <Box
                component="img"
                src={messagereplyIcon}
                width={28}
                height={28}
              />
              <Box
                display="flex"
                alignItems="center"
                className="w-100"
                justifyContent="space-between"
              >
                <Box>
                  <Typography
                    textAlign="left"
                    variant="subtitle1"
                    fontSize={14}
                  >
                    {res.responderName}
                  </Typography>
                  <Button
                    variant="contained"
                    color={res.status === "Failure" ? "error" : "success"}
                    sx={{ border: "5px", height: 18 }}
                  >
                    {res.status}
                  </Button>
                </Box>

                <Box textAlign="center">
                  <Typography variant="subtitle2" fontSize={14}>
                    {res.cortexId}
                  </Typography>
                  <Typography variant="caption">
                    ({formatCreatedAt(res.startDate)})
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {/* Dialog */}
      <AddTaskLogDialog
        open={logsDialof}
        setOpen={showLogsDialog}
        caseId={caseId}
        taskId={data._id}
        refrashFunc={FetchTaskLogs}
      />
      {selectedLog && (
        <DeleteTaskLog
          open={deleteDialog}
          setOpen={showdeleteDialog}
          refrashFunc={FetchTaskLogs}
          id={selectedLog}
        />
      )}
    </Box>
  );
};
export default TaskDetail;
