import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Link,
    Paper,
    Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputField from "../../../../components/Field/BootStrap/InputField.js";
import CustomTable from "../../../../components/Display/CustomTable.js";
// import CustomUsersRow from "../../components/CustomUsersRow.js";
import cms from "../../../../config/axiosConfig.js";
import { formatCreatedAt, insertElementAtIndex } from "../../../../utils/utils.js";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Selectcer from "../../../../components/Field/BootStrap/Selectcer.js";
import CustomCaseTemplatesRow from "../../components/CustomCaseTemplatesRow.js";
import FilterFields from "../../../../components/filters/FilterFields.js";
import { CaseTemplatesFilterFields } from "../../../../utils/filters/fields.js";
import CreateCaseTemplateDialog from "../components/CreatePlayBookDialog.js";
import UploadIcon from '@mui/icons-material/Upload';
import CaseTemplateUploadDialog from "../components/PlayBookUploadDialog.js";
import CaseTemplateDeleteDialoge from "../components/PlayBookDeleteDialoge.js";

function PlayBooks({ orgName, organization }) {
    const columns = [
        { id: "displayName", label: "Display Name", sortable: true },
        { id: "name", label: "Name", sortable: true },
        { id: "severity", label: "Severity", sortable: false },
        { id: "tasks", label: "Tasks", sortable: true },
        { id: "customFields", label: "Custom Fields", sortable: false },
        { id: "hasMFA", label: "By", sortable: true },
        { id: "_createdAt", label: "Dates", sortable: true },
        { id: "action", label: "", sortable: false },
    ];

    const sort_options = [
        {
            name: "Ascendant display name",
            value: "display_first",
            _field: [{ displayName: "asc" }],
        },
        {
            name: "Descendent display name",
            value: "display_last",
            _field: [{ displayName: "desc" }],
        },
        {
            name: "Newest first",
            value: "new_fisrt",
            _field: [{ _createdAt: "desc" }],
        },
        {
            name: "Oldest first",
            value: "old_fisrt",
            _field: [{ _createdAt: "asc" }],
        },
        {
            name: "Most recently updated first",
            value: "updated_first",
            _field: [{ _updatedAt: "desc" }],
        },
        {
            name: "Least recently updated first",
            value: "updated_last",
            _field: [{ _updatedAt: "asc" }],
        },
    ];
    const initialPagination = { page: 1, per_page: 15 };
    const [pagination, setPagination] = useState(initialPagination);

    const inital_filters = {
        sortBy: "",
        quickFilter: "",
        delete: "",
    };
    const [isUpdate, setUpdate] = useState(false);
    const [dialog, showDialog] = useState(false);
    const [uploadDialog, showUploadDialog] = useState(false);
    const [deleteDialog, showDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(false);
    const [data, setData] = useState({ data: [], loading: false, count: 0 })
    const [filters, setFilters] = useState(inital_filters);
    const initial_sortFields = [{ _createdAt: "desc" }];
    const [sortFields, setSortFields] = useState(initial_sortFields);

    const handleSort = (e) => {
        let value = e.target.value;
        setFilters({ ...filters, sortBy: value });
        let ops = sort_options.find((s) => s.value === value);
        if (ops) {
            setSortFields(ops._field);
        } else {
            setSortFields(initial_sortFields);
        }
    };
    const onPageChange = (e, new_page) => {
        setPagination((pre) => ({ ...pre, page: new_page }));
    };

    const fetchData = async (filter_query = false, get_count = true) => {
        try {
            if (!get_count) {
                setData((pre) => ({ ...pre, loading: true }));
            }
            let query = [
                {
                    _name: "getOrganisation",
                    idOrName: orgName,
                },
                {
                    _name: "caseTemplates",
                },
                {
                    _name: "sort",
                    _fields: sortFields,
                },
                {
                    _name: "page",
                    from: (pagination.page - 1) * pagination.per_page,
                    to: pagination.page * pagination.per_page,
                },
            ];

            if (get_count) {
                query.pop();
                query.pop();
                query.push({ _name: "count" });
            }
            if (filter_query && Array.isArray(filter_query)) {
                if (filter_query.length < 2) {
                    const element = {
                        _name: "filter",
                        ...filter_query[0],
                    };
                    query = insertElementAtIndex(query, element, 2);
                } else {
                    const element = {
                        _name: "filter",
                        _and: [
                            ...filter_query,
                        ],
                    };
                    query = insertElementAtIndex(query, element, 2);
                }
            }

            const response = await cms.post(
                "/cms/api/v1/query",
                {
                    query: query,
                },
                {
                    params: {
                        name: get_count ? "organisation-case-templates.count" : "organisation-case-templates",
                    }
                }
            );
            let d = response.data;
            if (get_count) {
                setData((pre) => ({ ...pre, count: d }));
                fetchData(filter_query, false);
            } else {
                setData((pre) => ({ ...pre, data: d }));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            setData((pre) => ({ ...pre, loading: false }));
        }
    };
    const handleEdit = (row) => () => {
        setSelectedRow(row)
        setUpdate(true);
        showDialog(true);
    }
    const handleDialog = () => () => {
        setUpdate(false);
        showDialog(true);
    }
    const handleDelete = (row) => () => {
        setSelectedRow(row);
        showDeleteDialog(true);
    }

    useEffect(() => {
        fetchData();
    }, [pagination.page, pagination.per_page, sortFields])

    // Playbook DialogBox
    const CaseTemplateDialogBox = useCallback(() => {
        return (
            <CreateCaseTemplateDialog open={dialog} setOpen={showDialog} update={isUpdate} selectedRow={selectedRow} reFrashFunc={fetchData} />
        )
    }, [dialog, isUpdate])
    // Case Upload Playbook DialogBox
    const CaseTemplateUploadDialogBox = useCallback(() => {
        return (
            <CaseTemplateUploadDialog open={uploadDialog} setOpen={showUploadDialog} reFrashFunc={fetchData} />
        )
    }, [uploadDialog])
    const CaseTemplateDeleteDialogeBox = useCallback(() => {
        return (
            <CaseTemplateDeleteDialoge open={deleteDialog} setOpen={showDeleteDialog} row={selectedRow} reFrashFunc={fetchData} />
        )
    }, [deleteDialog, selectedRow])

    return (
        <Box display="flex" flexDirection="column">
            {/* <Box display="flex" flexDirection={"column"}>
                <Typography fontSize="1.5rem" fontWeight="bold">
                    <Typography
                        component={"span"}
                        fontSize="1.5rem"
                        fontWeight="bold"
                        sx={{ color: "primary.light" }}
                    >
                        [{organization.data?._type}]
                    </Typography>{" "}
                    {organization.data?.name?.toUpperCase()}
                </Typography>

                <Typography variant="caption" color="#A7BBCE">
                    {"Dashboard > Organisation for Administration"}
                </Typography>
            </Box> */}
            <Box
                display="flex"
                flexDirection="column"
                mt={1.5}
                gap={3.125}
                component={Paper}
                elevation={0}
                p="12px 20px"
                borderRadius={"10px 10px 0px 0px"}
            >
                {/* <Breadcrumbs separator="" aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        sx={{ display: "flex", alignItems: "center" }}
                        color="inherit"
                        href="/"
                    >
                        <Avatar
                            src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFblndSZeUbaKJTXoUSPh0tpT_VhOLo_UnoQ&usqp=CAU"
                            }
                            sx={{ width: "26px", height: "26px", mr: 0.5 }}
                            fontSize="inherit"
                        />
                        {organization.data?.name}
                    </Link>
                    <Link
                        underline="hover"
                        sx={{ display: "flex", alignItems: "center" }}
                        color="inherit"
                        href=""
                    >
                        <CalendarMonthIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        {formatCreatedAt(organization.data?.createdAt)}
                    </Link>
                </Breadcrumbs> */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" gap={1.25} height={36}>
                        <Button
                            startIcon={<AddCircleIcon fontSize="small" />}
                            variant={"primary"}
                            sx={{
                                borderRadius: "5px",
                                borderColor: "#003272",
                                color: "#fff",
                                bgcolor: "#13D38E",
                                "&:hover": {
                                    color: "#0D2A56",
                                },
                            }}
                            onClick={handleDialog()}
                        >
                            Create new Playbook
                        </Button>
                        <Button
                            startIcon={<UploadIcon fontSize="small" />}
                            variant="contained"
                            color="inherit"
                            sx={{
                                borderRadius: "5px",
                            }}
                            onClick={() => { showUploadDialog(true) }}
                        >
                            Import Playbook
                        </Button>
                        <Selectcer
                            value={filters.sortBy}
                            handleChange={handleSort}
                            title={"Sort By"}
                            options={sort_options}
                            sx={{ "& .MuiNativeSelect-select": { minWidth: "150px !important" } }}
                        />

                        {/* )} */}
                        {/* <BTN
                            text="Filter"
                            disabled
                            startIcon={FilterIcon}
                            onClick={hanldeScreens("filter")}
                            variant={"primary"}
                            sx={{
                                borderColor: "#003272",
                                color: "#fff",
                                bgcolor: "#003272",
                            }}
                        /> */}
                    </Box>
                    <Box>
                        <Typography
                            mr={1.25}
                            variant="caption"
                            fontWeight={500}
                            color="textPrimary"
                        >
                            Per Pages
                        </Typography>

                        <InputField
                            type="number"
                            value={pagination.per_page}
                            onChange={(e) =>
                                setPagination((pre) => ({
                                    ...pre,
                                    per_page: e.target.value,
                                }))
                            }
                            sx={{ maxWidth: 121 }}
                        />
                    </Box>
                </Box>
            </Box>
            {/* Filter Fields */}
            <Box>
                <FilterFields
                    filterField={CaseTemplatesFilterFields}
                    onSearch={fetchData}
                    _for="casetemplates"
                />

            </Box>
            {/* Table */}
            <Box
                component={Paper}
                elevation={0}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                p={2.5}
                sx={{
                    maxWidth: "100%",
                    overflowX: "auto",
                }}
                height="100%"
            >
                {data.count &&
                    <Typography variant="h5" fontWeight="bold">
                        {`List of Playbook (${data.data?.length} of ${data.count})`}
                    </Typography>
                }
                <CustomTable
                    columns={columns}
                    data={data.data}
                    CustomTableRow={CustomCaseTemplatesRow}
                    loading={data.loading}
                    onPageChange={onPageChange}
                    page={pagination.page}
                    total_count={Math.ceil(data.count / pagination.per_page)}
                    onEdit={handleEdit}
                    // openEditBox={showUserDialog}
                    refrashFunc={() => { }}
                    onDelete={handleDelete}
                />
            </Box>
            {/* Dialogs */}
            <CaseTemplateDialogBox />
            <CaseTemplateUploadDialogBox />
            <CaseTemplateDeleteDialogeBox />
        </Box>
    );
}

export default PlayBooks;
