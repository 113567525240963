import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useReactToPrint } from 'react-to-print';
import Grid from '@mui/material/Grid';
import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Paper } from '@material-ui/core';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { html2pdf } from 'html2pdf.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ResponsiveTable = ({ headers, body, title }) => {
    return (
        <TableContainer sx={{ overflow: "hidden", maxWidth: '210mm', marginBottom: '20px', pageBreakBefore: 'always' }}>
            <Box sx={{ mt: 1, mb: 1 }}>
                <Typography sx={{ textDecoration: "underline" }} variant='h5' paragraph>{title}</Typography>
            </Box>
            <Table size='small' sx={{
                overflow: "hidden",
                minWidth: 650, "& td": {
                    fontSize: 9
                }, "& th": {
                    fontSize: 9,
                    color: "#fff",
                    textWrap: "nowrap"
                }
            }}>
                <TableHead sx={{ backgroundColor: 'primary.light', height: '30px', }}>
                    <TableRow>
                        {headers[0].map((header, index) => (
                            <TableCell key={index} sx={{ fontSize: 'small', fontWeight: 'bold' }}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {body.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <TableCell key={cellIndex} sx={{ fontSize: 'small' }}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const Header = ({ user }) => {
    return (
        <Box mt={1} mb={1} >
            <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant='caption'>Created by {user} </Typography>
                <Typography variant='caption'>{new Date().toUTCString()}</Typography>
            </Box>
        </Box>
    )
}
const Footer = ({ brand }) => {
    return (
        <Box mt={1} mb={1} position="fixed" bottom={0} left={10}>
            <Box component="img" src={brand.org_logo} alt={brand.org_name} sx={{ maxHeight: 50, maxWidth: 300 }} />
        </Box>
    )
}
function PrintDialogBox({ open, setOpen, title = "Print this Doc", components, tableData, user, ...props }) {
    const handleClose = () => {
        setOpen(false);
    };
    const [searchParams] = useSearchParams();
    const from_date = searchParams.get("fromDate");
    const to_date = searchParams.get("toDate");
    const [rows, setRows] = useState([]);
    const contentToPrint = useRef(null);

    const handlePrint = useReactToPrint({
        documentTitle: `${from_date}-${to_date}.pdf`,
        copyStyles: true,
        suppressErrors: true,
        // print: async (printIframe) => {
        //     const document = printIframe.contentDocument;
        //     if (document) {
        //         const html = document.get('html')[0];
        //     }
        // }
    });

    useEffect(() => {
        const _rows = components.map(cmp => {
            try {
                if (cmp.type === "component") {
                    return (
                        <Grid container item direction="column" sx={cmp.sx && cmp.sx} key={cmp.id} xs={cmp.xs} spacing={1}>
                            <Grid item sx={{ borderBottom: "1px solid black" }}> <Typography variant='body1'>{cmp.label}</Typography></Grid>
                            <Grid item>
                                {cmp.component}
                            </Grid>
                        </Grid>
                    );
                }
                else {
                    const graphRef = document.getElementById(cmp.id);
                    if (graphRef) {
                        return (
                            <Grid container item direction="column" sx={cmp.sx && cmp.sx} key={cmp.id} xs={cmp.xs} spacing={1} >
                                <Grid item sx={{ borderBottom: "1px solid black" }}> <Typography variant='body1'>{cmp.label}</Typography></Grid>
                                <Grid item dangerouslySetInnerHTML={{ __html: graphRef.innerHTML }} />
                            </Grid>
                        );
                    }
                }
            } catch (error) {
                console.error("Error:", error);
                return null;
            }
        });
        setRows(_rows.filter(Boolean));
    }, [components]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{
                '& .MuiDialog-paperFullScreen': {
                    width: '100%',
                    maxWidth: 'unset',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                },
                '& .MuiDialogContent-root': {
                    flex: '1 1 auto',
                    overflow: 'auto',
                },
                '& .MuiDialogContent-root': {
                    flex: '1 1 auto',
                    overflow: 'auto',
                },
            }}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {title}
                    </Typography>
                    <Button autoFocus variant='contained' startIcon={<LocalPrintshopIcon />} onClick={() => {
                        handlePrint(null, () => contentToPrint.current);
                    }}>
                        Print
                    </Button>
                </Toolbar>
            </AppBar>
            <Container ref={contentToPrint} component={Paper} elevation={0} maxWidth="xl" sx={{
                width: '210mm', height: "100%", p: 1
            }}>
                <Header user={user?.name} />
                <Grid item xs={12}>
                    <Typography variant='h5' sx={{ textDecoration: "underline" }} paragraph>{title}</Typography>
                </Grid>
                <Grid container spacing={2}>
                    {rows}
                </Grid>

                {tableData.body.length > 0 &&
                    <Box mt={4}>
                        <ResponsiveTable title={title} headers={tableData.headers} body={tableData.body} />
                    </Box>
                }
                <Footer brand={props.brand} />
            </Container>
        </Dialog >
    );
}


const mapStateToProps = (state) => {
    return {
        brand: state.brand,
        user: state.cms_user,
    };
};

export default connect(mapStateToProps, null)(PrintDialogBox);