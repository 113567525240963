import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";

// const convertToMarkdown = (content) => {
//   const md = new MarkdownIt();
//   const markdownContent = md.render(content);
//   return markdownContent;
// };

const RichTextEditor = ({ setValue, defaultValue = "" }) => {
  const [content, setContent] = useState(defaultValue);

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  useEffect(() => {
    if (content) {
      // setValue(convertToMarkdown(content));
      setValue(content);
    }
    // eslint-disable-next-line
  }, [content]);

  const overrideStyles = `
    .ql-tooltip {
      left: 10px !important;
    }
  `;

  return (
    <Box
      sx={{
        borderRadius: 2,
        "& .ql-formats": {
          bgcolor: "#fff",
        },
        "& .ql-toolbar": {
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          bgcolor: "#ECF0F4",
        },
        "& .ql-editor": {
          minHeight: 216,
          bgcolor: "#ECF0F4",
        },
      }}
    >
      <style>{overrideStyles}</style>
      <ReactQuill tabIndex={4} value={content} onChange={handleContentChange} />
    </Box>
  );
};

export default RichTextEditor;
