import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertIcon from "../../../../static/icons/alert-icon.svg";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function WarningDialogBox({ open, setOpen, title, message, onConfirm = () => { } }) {
    const handleClose = () => {
        setOpen(false);
    };

    // andle Submit

    const handleConfirm = (e) => {
        onConfirm();
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "822px",
                    borderRadius: "15px",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title" className="bg-warning">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight={700} color="#fff">
                            <Box component="img" src={AlertIcon} alt="text" mr={1} />
                            {title}
                        </Typography>

                    </Box>

                    <IconButton onClick={handleClose}>
                        <CancelIcon fontSize="large" sx={{ color: "#fff" }} />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <DialogContentText
                    tabIndex={-1}
                    p={5}
                >
                    <Typography paragraph variant="body1">
                        {message}
                    </Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Box
                    pl="22px"
                    pr="22px"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    width="100%"
                >
                    <Button
                        startIcon={<WarningAmberIcon />}
                        variant="contained"
                        color="warning"
                        onClick={handleConfirm}
                        sx={{ fontSize: 20, height: 50, minWidth: "80px", color: "white" }}
                    >
                        OK
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
