import wazuh from "../../config/wazuh";

export function FetchGroups(method = "GET", params = {}) {
  if (method === "GET") {
    return new Promise((resolve, reject) => {
      wazuh
        .get("/groups")
        .then((res) => {
          const response = res.data;
          if (res.status === 200 || res.status === 201) {
            if ("data" in response && "affected_items" in response.data) {
              resolve(response.data.affected_items);
            } else {
              reject("Invalid response format");
            }
          } else {
            reject("Request failed with status: " + res.status);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
