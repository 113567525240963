import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import InputField from "../../../components/Field/BootStrap/InputField.js";
import { useState } from "react";
import CustomTable from "../../../components/Display/CustomTable.js";
import CustomOrganizationRow from "../components/CustomOrganizationRow.js";
import { useEffect } from "react";
import CreateOrganizationDialog from "../components/CreateOrganizationDialog.js";
import cms from "../../../config/axiosConfig.js";
import { connect } from "react-redux";

function Organisations(props) {
  const columns = [
    { id: "name", label: "Name", sortable: true },
    { id: "_createdBy", label: "Create By", sortable: true },
    { id: "_createdAt", label: "Date", sortable: true },
    { id: "action", label: "", sortable: false },
  ];
  const initialPagination = { page: 1, per_page: 15 };
  const [pagination, setPagination] = useState(initialPagination);
  const [data, setData] = useState({ loading: false, data: [], count: 0 });
  const [OrgDialog, showOrgDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const handlePage = (event) => {
    if (event.keyCode === 13 && event.target.value) {
      fetchOrganizations();
    }
  };
  const onPageChange = (e, new_page) => {
    setPagination((pre) => ({ ...pre, page: new_page }));
  };
  const GroupedUsers = (organizations, users) => {
    const groupedUsers = [];
    organizations.map((org) => {
      let user = users.find((u) => u.login === org._createdBy);
      if (user) {
        groupedUsers.push({
          profile: user?.profile,
          avatar: user.avatar,
          ...org,
        });
      } else {
        groupedUsers.push({
          ...org,
        });
      }
      return "";
    });
    // console.log(groupedUsers);
    return groupedUsers;
  };
  const fetchUsers = async (organizations) => {
    try {
      let query = [
        {
          _name: "listOrganisation",
        },
        {
          _name: "users",
        },
      ];

      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: "users" },
        }
      );
      let d = response.data;

      setData((pre) => ({
        ...pre,
        data: GroupedUsers(organizations, d),
        loading: false,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchOrganizations = async (get_count = false) => {
    try {
      if (!get_count) {
        setData((pre) => ({ ...pre, loading: true }));
      }
      let query = [
        {
          _name: "listOrganisation",
        },
        {
          _name: "sort",
          _fields: [
            {
              _updatedAt: "desc",
            },
          ],
        },
        {
          _name: "page",
          from: (pagination.page - 1) * pagination.per_page,
          to: pagination.page * pagination.per_page,
        },
      ];
      if (get_count) {
        query.pop();
        query.pop();
        query.push({ _name: "count" });
      }

      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: get_count ? "organisations.count" : "organisations" },
        }
      );
      let d = response.data;
      if (get_count) {
        setData((pre) => ({ ...pre, count: d }));
      } else {
        fetchUsers(d);
        fetchOrganizations(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedOrg) {
      showOrgDialog(true);
    }
    // eslint-disable-next-line
  }, [selectedOrg]);

  useEffect(() => {
    if (!OrgDialog) {
      setSelectedOrg(null);
    }
    // eslint-disable-next-line
  }, [OrgDialog]);

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={3.5}>
      {/* Title & screens btns */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h5" fontWeight="bold">
            Organisations Listing{" "}
            {data && `(${data.data.length} of ${data.data.length})`}
          </Typography>
          <Typography color="#A7BBCE" variant="caption">
            {"Dashboard > Organisations Listing"}
          </Typography>
        </Box>
      </Box>
      {/* Buttons & Pagination */}
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={1.25}
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1.25}>
          <Typography variant="caption" fontWeight={500} color="textPrimary">
            Per Pages
          </Typography>

          <InputField
            type="number"
            onKeyDown={handlePage}
            value={pagination.per_page}
            onChange={(e) =>
              setPagination((pre) => ({
                ...pre,
                per_page: parseInt(e.target.value),
              }))
            }
            sx={{ maxWidth: 121 }}
          />
        </Box>
      </Box>
      {/* Mainb Body */}
      <Box
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={2.5}
        sx={{ borderRadius: "10px 10px 0px 0px" }}
        height="100%"
      >
        <CustomTable
          columns={columns}
          data={data.data}
          CustomTableRow={CustomOrganizationRow}
          loading={data.loading}
          onPageChange={onPageChange}
          page={pagination.page}
          total_count={Math.ceil(data.count / pagination.per_page)}
          onEdit={setSelectedOrg}
          permissions={props.permissions}
        />
      </Box>
      {/* update Organization Dialog */}
      {selectedOrg && (
        <CreateOrganizationDialog
          open={OrgDialog}
          setOpen={showOrgDialog}
          update
          initial_fields={selectedOrg}
          or_name={selectedOrg.name}
          refrashFunc={fetchOrganizations}
        />
      )}
    </Box>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.permissions,
  };
};
export default connect(mapStateToProps, null)(Organisations);
