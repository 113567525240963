import { getVulnerabilitiesFilterValues } from "../../controllers/vulnerabilities/common.js";
// import {
//     AGENT_SYNCED_STATUS,
//     // UI_ORDER_AGENT_STATUS,
// } from "../common/constants.js";

const filtersSuggestions = (agentId) => ([
    {
        type: "q",
        label: "name",
        description: "Filter by package ID",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "name", value),
    },
    {
        type: "q",
        label: "cve",
        description: "Filter by CVE ID",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "cve", value),
    },
    {
        type: "q",
        label: "version",
        description: "Filter by CVE version",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "version", value),
    },
    {
        type: "q",
        label: "architecture",
        description: "Filter by architecture",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "architecture", value),
    },
    {
        type: "q",
        label: "severity",
        description: "Filter by severity",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "severity", value),
    },
    {
        type: "q",
        label: "cvss2_score",
        description: "Filter by cvss2",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "cvss2_score", value),
    },
    {
        type: "q",
        label: "cvss3_score",
        description: "Filter by cvss3",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "cvss3_score", value),
    },
    {
        type: "q",
        label: "detection_time",
        description: "Filter by detection time",
        operators: ["=", "!=", "~"],
        values: async (value) =>
            getVulnerabilitiesFilterValues(agentId, "detection_time", value),
    },

])

export default filtersSuggestions;
