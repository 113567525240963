import { CustomTableCell } from "../../../components/Display/CustomTable";
import React from "react";
import {
  TableRow,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { Badge } from "react-bootstrap";

const CustomProfileRow = ({
  row,
  isSelected,
  onSelect,
  onProfileDelete,
  setSelectedProfile,
}) => {
  // const handleRowClick = () => {
  //   onSelect(row._id);
  // };
  const permissionPill_colors = [
    "#90C8FF",
    "#B0FFF6",
    "#CB98FF",
    "#F6C1FF",
    "#FFCB9B",
    "#FFD464",
  ];

  return (
    <TableRow
      key={row._id}
      sx={{
        borderRadius: "4px",
        minHeight: 76,
        verticalAlign: "baseline",
        "& td": {
          verticalAlign: "middle",
        },
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),
      }}
    >
      <CustomTableCell sx={{ minWidth: 100 }}>
        <Typography color="primary" fontSize={14}>
          {row._id}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <Typography fontSize={14} fontWeight="bold">
          {row.name}
        </Typography>
      </CustomTableCell>

      <CustomTableCell sx={{ maxWidth: 650 }}>
        <Grid item xs container spacing={1.25}>
          {row?.permissions?.map((p, i) => (
            <Grid item key={p}>
              <Box
                component={Badge}
                pill
                text="dark"
                sx={{
                  backgroundColor: `${
                    permissionPill_colors[i > 5 ? Math.ceil(i / 2) : i]
                  } !important`,
                  fontSize: 10,
                  fontWeight: 400,
                }}
              >
                {p}
              </Box>
            </Grid>
          ))}
        </Grid>
      </CustomTableCell>

      <CustomTableCell sx={{ minWidth: 200 }}>
        <Box display="flex" justifyContent="space-around">
          <Tooltip title="Delete profile">
            <IconButton
              onClick={() => onProfileDelete(row)}
              size="small"
              sx={{ color: "primary.main", fontSize: 14, mr: 1 }}
            >
              <DeleteIcon fontSize="small" sx={{ color: "error.main" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit profile">
            <IconButton
              onClick={setSelectedProfile(row)}
              size="small"
              sx={{ color: "primary.main", fontSize: 14, mr: 1 }}
            >
              <EditIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </CustomTableCell>
    </TableRow>
  );
};

export default CustomProfileRow;
