import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import parse from 'html-react-parser';
// import 'bootstrap/dist/css/bootstrap.min.css';

const template = `<uib-tabset active="active">
<uib-tab index="$index" ng-repeat="res in content.results" heading="{{res.name}} - {{res.result.length}} results" disable="tab.disabled">
  <div class="panel-body">
    <div ng-if="res.result.errors.length > 0">
      <dl class="dl-horizontal" ng-repeat="error in res.result.errors">
        <dt><i class="fa fa-warning"></i> Error:</dt>
        <dd>{{error}}</dd>
      </dl>
    </div>
    <div ng-if="res.result.length == 0">
      <dl class="dl-horizontal">
        <dt><i class="fa fa-warning"></i> No results</dt>
        <dd>No results from {{res.name}}</dd>
      </dl>
    </div>
    <div ng-repeat="event in res.result">
      <uib-accordion>
        <div uib-accordion-group class="panel-primary" heading="{{event.id}} - {{event.info}}" is-open="false" is-disabled="status.isFirstDisabled">				
        <dl class="dl-horizontal">
          <dt>EventID:</dt>
          <dd><a target="_blank" href="{{res.url}}/events/view/{{event.id}}">{{event.id}}</a></dd>
        </dl>
        <dl class="dl-horizontal">
          <dt>Event info:</dt>
          <dd>{{event.info}}</dd>
        </dl>
        <dl class="dl-horizontal">
          <dt>UUID:</dt>
          <dd>{{event.uuid}}</dd>
        </dl>
        <dl class="dl-horizontal">
          <dt>From:</dt>
          <dd>{{event.Orgc.name}}</dd>
        </dl>
        <dl class="dl-horizontal" ng-if="event.Tag.length > 0" >
          <dt>Tags:</dt>
          <dd ng-repeat="tag in event.Tag">
            <span ng-if="tag.colour==='#ffffff'" class="label"
            ng-style="{'border':'1px solid','color': '#000000', 'background-color': tag.colour}">{{tag.name}}
            </span>
            <span ng-if="tag.colour!=='#ffffff'" class="label"
            ng-style="{'background-color': tag.colour}">{{tag.name}}
            </span>
          </dd>
        </dl>
        <dl class="dl-horizontal" ng-if="event.Galaxy.length > 0">
          <dt>Cluster:</dt>
          <dd>
            <div ng-repeat="cluster in event.Galaxy">
              <span ng-repeat="clusterentry in cluster.GalaxyCluster">{{clusterentry.name}}: {{clusterentry.value}}</span>
            </div>
          </dd>
        </dl>
        <dl class="dl-horizontal" ng-if="event.RelatedEvent.length > 0">
          <dt>Related events:</dt>
          <dd>
            <div ng-repeat="rel in event.RelatedEvent">
              <a target="_blank" href="{{res.url}}/events/view/{{rel.id}}">{{rel.info}}</a><br />
            </div>
          </dd>
        </dl>
      </uib-accordion>
    </div>
  </div>
</uib-tab>
</uib-tabset>


<!-- General error  -->
<div class="panel panel-danger" ng-if="!success">
  <div class="panel-heading">
      <strong>{{(artifact.data || artifact.attachment.name) | fang}}</strong>
  </div>
  <div class="panel-body">
      {{content.errorMessage}}
  </div>
</div>`
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const RenderContent = (text) => {
  const content = {
    results: [
      {
        name: "Sample Name",
        result: [],
        url: "http://example.com"
      }
    ],
    errorMessage: "Your error message here",
  };
  return text.replace("{{res.name}}", content?.results[0].name)
    .replace("{{res.result.length}}", content?.results[0].result.length)
    .replace("{{error}}", content?.results[0]?.result.errors)
    .replace("{{res.url}}", content?.results[0]?.url)
    .replace("{{event.id}}", content?.results[0]?.result?.map(event => event.id))
    .replace("{{event.info}}", content?.results[0]?.result?.map(event => event.info))
    .replace("{{event.uuid}}", content?.results[0]?.result.map(event => event.uuid))
    .replace("{{event.Orgc.name}}", content?.results[0]?.result?.map(event => event.Orgc.name))
    .replace("{{tag.name}}", "tag1")
    .replace("{{clusterentry.value}}", content?.results[0]?.result?.map(event => event?.Galaxy?.map(cluster => cluster.GalaxyCluster.map(clusterentry => clusterentry.value))))
    .replace("{{clusterentry.name}}", content?.results[0]?.result?.map(event => event?.Galaxy?.map(cluster => cluster.GalaxyCluster.map(clusterentry => clusterentry.name))))
    .replace("{{rel.info}}", content?.results[0]?.result?.map(event => event?.RelatedEvent?.map(rel => rel.info)))
    .replace("{{artifact.data}}", content?.results[0]?.artifact?.data)
    .replace("{{artifact.attachment.name}}", content?.results[0]?.artifact?.attachment?.name)
    .replace("{{(artifact.data || artifact.attachment.name) | fang}}", content?.results[0]?.artifact?.attachment?.name)
    .replace("{{content.errorMessage}}", content?.errorMessage);
}
export default function ObservableReportsTemplate() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const replacedHTML = RenderContent(template)
    .replace(/{{([^}]+)}}/g, (match, p1) => {
      try {
        // Evaluate the expression inside the placeholder
        return eval(p1);
      } catch (error) {
        console.error('Error evaluating expression:', error);
        return match; // Return the original placeholder if there's an error
      }
    });

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>{parse(replacedHTML,)}</div>
        </Box>
      </Modal>
    </div>
  );
}