import { getTimezone } from "../../../utils/utils.js";
import { getIntervalPayload } from "../../../utils/utils.js"

const manager = JSON.parse(localStorage.getItem("DF_version"));

export const total_alerts_query = (
  _query,
  range_from,
  range_to
) => ({
  bool: {
    must: [],
    filter: [
      _query
        ? {
          query_string: {
            query: _query,
          },
        }
        : {
          match_all: {},
        },
      {
        match_phrase: {
          "manager.name": {
            query: manager?.hostname,
          },
        },
      },
      // {
      //   match_phrase: { "agent.id": { query: "001" } },
      // },
      {
        range: {
          timestamp: {
            from: range_from,
            to: range_to,
          },
        },
      },
    ],
    should: [],
    must_not: [],
  },
});

export const alert_level_12_or_above_query = (
  _query,
  range_from,
  range_to
) => ({
  bool: {
    must: [],
    filter: [
      _query
        ? {
          query_string: {
            query: _query,
          },
        }
        : {
          match_all: {},
        },
      {
        match_phrase: {
          "manager.name": {
            query: manager?.hostname,
          },
        },
      },
      // {
      //   match_phrase: { "agent.id": { query: "001" } },
      // },
      {
        range: {
          "rule.level": {
            gte: "12",
            lt: null,
          },
        },
      },
      {
        range: {
          timestamp: {
            from: range_from,
            to: range_to,
          },
        },
      },
    ],
    should: [],
    must_not: [],
  },
});

export const alert_authsuccess_query = (
  _query,
  range_from,
  range_to
) => ({
  bool: {
    must: [],
    filter: [
      _query
        ? {
          query_string: {
            query: _query,
          },
        }
        : {
          match_all: {},
        },
      {
        match_phrase: {
          "manager.name": {
            query: manager?.hostname,
          },
        },
      },
      // {
      //   match_phrase: { "agent.id": { query: "001" } },
      // },
      {
        match_phrase: {
          "rule.groups": "authentication_success",
        },
      },
      {
        range: {
          timestamp: {
            from: range_from,
            to: range_to,
          },
        },
      },
    ],
    should: [],
    must_not: [],
  },
});

export const alert_authfail_query = (
  _query,
  range_from,
  range_to
) => ({
  bool: {
    must: [],
    filter: [
      _query
        ? {
          query_string: {
            query: _query,
          },
        }
        : {
          match_all: {},
        },
      {
        match_phrase: {
          "manager.name": {
            query: manager?.hostname,
          },
        },
      },
      // {
      //   match_phrase: { "agent.id": { query: "001" } },
      // },
      {
        bool: {
          should: [
            {
              match_phrase: {
                "rule.groups": "win_authentication_failed",
              },
            },
            {
              match_phrase: {
                "rule.groups": "authentication_failed",
              },
            },
            {
              match_phrase: {
                "rule.groups": "authentication_failures",
              },
            },
          ],
          minimum_should_match: 1,
        },
      },
      {
        range: {
          timestamp: {
            from: range_from,
            to: range_to,
          },
        },
      },
    ],
    should: [],
    must_not: [],
  },
});
export const alert_mitre_att_query = (
  _query,
  range_from = "2023-08-30T19:21:09.791Z",
  range_to = "2024-08-31T19:21:09.791Z"
) => ({
  aggs: {
    2: {
      terms: {
        field: "rule.mitre.technique",
        order: {
          _count: "desc",
        },
        size: 20,
      },
    },
  },
  size: 10,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        // {
        //   match_phrase: { "agent.id": { query: "001" } },
        // },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});

export const alert_top_5_query = (
  _query,
  range_from = "2023-08-30T19:21:09.791Z",
  range_to = "2024-08-31T19:21:09.791Z"
) => ({
  aggs: {
    2: {
      terms: {
        field: "agent.name",
        order: {
          _count: "desc",
        },
        size: 5,
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});
export const alert_evolution_query = (
  _query,
  range_from = "2023-08-30T19:21:09.791Z",
  range_to = "2024-08-31T19:21:09.791Z"
) => ({
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
      aggs: {
        3: {
          terms: {
            field: "rule.level",
            order: {
              _count: "desc",
            },
            size: "15",
          },
        },
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        // {
        //   match_phrase: { "agent.id": { query: "001" } },
        // },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [
        {
          match_phrase: {
            "rule.mitre.technique": "Disable or Modify Tools",
          },
        },
      ],
    },
  },
});

export const alert_evolution_top_5 = (
  _query,
  range_from = "2023-08-30T19:21:09.791Z",
  range_to = "2023-08-31T19:21:09.791Z"
) => ({
  version: true,
  _source: false,
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
    },
  },
  size: 0,
  sort: [
    {
      timestamp: {
        order: "desc",
        unmapped_type: "boolean",
      },
    },
  ],
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "@timestamp",
      format: "date_time",
    },
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        // {
        //   match_phrase: { "agent.id": { query: "001" } },
        // },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [
        {
          match_phrase: {
            "rule.mitre.technique": "Disable or Modify Tools",
          },
        },
      ],
    },
  },
  highlight: {
    pre_tags: ["@opensearch-dashboards-highlighted-field@"],
    post_tags: ["@/opensearch-dashboards-highlighted-field@"],
    fields: {
      "*": {},
    },
    fragment_size: 2147483647,
  },
});

export const alerts_data_query = (
  _query = false,
  range_from,
  range_to,
  from = 0,
  size = 20
) => ({
  from: from,
  query: {
    bool: {
      must: [
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "epoch_millis",
            },
          },
        },
      ],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        // {
        //   match_phrase: { "agent.id": { query: "001" } },
        // },
      ],
      should: [],
      must_not: [],
    },
  },
  size: size,
  sort: {
    timestamp: {
      order: "desc",
    },
  },
});

export const alerts_events_query = (
  agentId,
  _query,
  range_from = "2023-09-01T07:12:54.582Z",
  range_to = "2024-09-02T07:12:54.582Z",
  from = 0,
  size = 20,
) => ({
  version: true,
  sort: [
    {
      timestamp: {
        order: "desc",
        unmapped_type: "boolean",
      },
    },
  ],
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
    },
  },
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  from: from,
  size: size,
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        agentId &&
        {
          match_phrase: { "agent.id": { query: agentId } },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
  highlight: {
    pre_tags: ["@opensearch-dashboards-highlighted-field@"],
    post_tags: ["@/opensearch-dashboards-highlighted-field@"],
    fields: {
      "*": {},
    },
    fragment_size: 2147483647,
  },
});
export const fim_events_data__query = (
  agentId,
  _query,
  range_from = "2023-09-01T07:12:54.582Z",
  range_to = "2023-09-02T07:12:54.582Z",
  from = 0,
  size = 20,
  calendar_interval = undefined
) => ({
  version: true,
  size: 500,
  sort: [
    {
      timestamp: {
        order: "desc",
        unmapped_type: "boolean",
      },
    },
  ],
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        // [calendar_interval && calendar_interval !== "12h"
        //   ? "calendar_interval"
        //   : "fixed_interval"]:
        //   calendar_interval && calendar_interval !== "12h"
        //     ? calendar_interval
        //     : "10m",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
    },
  },
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  from: from,
  size: size,
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [
        {
          match_phrase: {
            "syscheck.event": "modified",
          },
        },
      ],
    },
  },
  highlight: {
    pre_tags: ["@opensearch-dashboards-highlighted-field@"],
    post_tags: ["@/opensearch-dashboards-highlighted-field@"],
    fields: {
      "*": {},
    },
    fragment_size: 2147483647,
  },
});
export const fim_events_graph_query = (
  agentId,
  _query,
  range_from = "2023-09-01T07:12:54.582Z",
  range_to = "2023-09-02T07:12:54.582Z",
  from = 0,
  size = 20,
  calendar_interval = undefined
) => ({
  version: true,
  size: 500,
  sort: [
    {
      timestamp: {
        order: "desc",
        unmapped_type: "boolean",
      },
    },
  ],
  aggs: {
    2: {
      date_histogram: {
        // [calendar_interval && calendar_interval !== "12h"
        //   ? "calendar_interval"
        //   : "fixed_interval"]:
        //   calendar_interval && calendar_interval !== "12h"
        //     ? calendar_interval
        //     : "10m",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        field: "timestamp",
        min_doc_count: 1,
      },
    },
  },
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  from: from,
  size: size,
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [
        {
          match_phrase: {
            "syscheck.event": "modified",
          },
        },
      ],
    },
  },
  highlight: {
    pre_tags: ["@opensearch-dashboards-highlighted-field@"],
    post_tags: ["@/opensearch-dashboards-highlighted-field@"],
    fields: {
      "*": {},
    },
    fragment_size: 2147483647,
  },
});

export const mitre_events_query = (
  agentId,
  mitre_id,
  _query,
  range_from = "2023-09-01T07:12:54.582Z",
  range_to = "2023-09-02T07:12:54.582Z",
  from = 0,
  size = 20
) => ({
  query: {
    bool: {
      must: [
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
        {
          match: {
            "rule.mitre.id": mitre_id,
          },
        },
        {
          match: {
            "manager.name": manager?.hostname,
          },
        },
        {
          match: {
            "agent.id": agentId,
          },
        },
      ],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          exists: {
            field: "rule.mitre.id",
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
  size: size,
  from: from,
  sort: {
    timestamp: {
      order: "desc",
    },
  },
});

export const mitre_evolution_query = (
  agentId,
  _query,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
      aggs: {
        3: {
          terms: {
            field: "rule.mitre.technique",
            order: {
              _count: "desc",
            },
            size: 5,
          },
        },
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          exists: {
            field: "rule.mitre.id",
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});

export const mitre_top_tactics = (
  agentId,
  _query,
  range_from,
  range_to
) => ({
  "aggs": {
    "2": {
      "terms": {
        "field": "rule.mitre.tactic",
        "order": {
          "_count": "desc"
        },
        "size": 10
      }
    }
  },
  "size": 0,
  "stored_fields": [
    "*"
  ],
  "script_fields": {},
  "docvalue_fields": [
    {
      "field": "data.aws.createdAt",
      "format": "date_time"
    },
    {
      "field": "data.aws.end",
      "format": "date_time"
    },
    {
      "field": "data.aws.resource.instanceDetails.launchTime",
      "format": "date_time"
    },
    {
      "field": "data.aws.service.eventFirstSeen",
      "format": "date_time"
    },
    {
      "field": "data.aws.service.eventLastSeen",
      "format": "date_time"
    },
    {
      "field": "data.aws.start",
      "format": "date_time"
    },
    {
      "field": "data.aws.updatedAt",
      "format": "date_time"
    },
    {
      "field": "data.timestamp",
      "format": "date_time"
    },
    {
      "field": "data.vulnerability.published",
      "format": "date_time"
    },
    {
      "field": "data.vulnerability.updated",
      "format": "date_time"
    },
    {
      "field": "syscheck.mtime_after",
      "format": "date_time"
    },
    {
      "field": "syscheck.mtime_before",
      "format": "date_time"
    },
    {
      "field": "timestamp",
      "format": "date_time"
    }
  ],
  "_source": {
    "excludes": [
      "@timestamp"
    ]
  },
  "query": {
    "bool": {
      "must": [],
      "filter": [
        {
          "match_all": {}
        },
        {
          "match_phrase": {
            "manager.name": {
              "query": manager?.hostname
            }
          }
        },
        {
          "exists": {
            "field": "rule.mitre.id"
          }
        },
        {
          "match_phrase": {
            "agent.id": {
              "query": agentId
            }
          }
        },
        {
          "range": {
            "timestamp": {
              "gte": range_from,
              "lte": range_to,
              "format": "strict_date_optional_time"
            }
          }
        }
      ],
      "should": [],
      "must_not": []
    }
  }
});
export const mitre_rule_lvl_by_att = (
  agentId,
  _query,
  range_from,
  range_to
) => ({
  aggs: {
    3: {
      terms: {
        field: "rule.mitre.technique",
        order: {
          _count: "desc",
        },
        size: 5,
      },
      aggs: {
        4: {
          terms: {
            field: "rule.level",
            order: {
              _count: "desc",
            },
            size: 5,
          },
        },
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          exists: {
            field: "rule.mitre.id",
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          match_phrase: {
            "rule.mitre.tactic": "Defense Evasion",
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});
export const mitre_attac_by_tactics = (
  agentId,
  _query,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      terms: {
        field: "rule.mitre.technique",
        order: {
          _count: "desc",
        },
        size: 5,
      },
      aggs: {
        3: {
          terms: {
            field: "rule.mitre.tactic",
            order: {
              _count: "desc",
            },
            size: 5,
          },
        },
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          exists: {
            field: "rule.mitre.id",
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});
export const mitre_rule_lvl_by_tactics = (
  agentId,
  _query,
  range_from,
  range_to
) => ({
  "aggs": {
    "3": {
      "terms": {
        "field": "rule.mitre.tactic",
        "order": {
          "_count": "desc"
        },
        "size": 5
      },
      "aggs": {
        "4": {
          "terms": {
            "field": "rule.level",
            "order": {
              "_count": "desc"
            },
            "size": 5
          }
        }
      }
    }
  },
  "size": 0,
  "stored_fields": [
    "*"
  ],
  "script_fields": {},
  "docvalue_fields": [
    {
      "field": "data.aws.createdAt",
      "format": "date_time"
    },
    {
      "field": "data.aws.end",
      "format": "date_time"
    },
    {
      "field": "data.aws.resource.instanceDetails.launchTime",
      "format": "date_time"
    },
    {
      "field": "data.aws.service.eventFirstSeen",
      "format": "date_time"
    },
    {
      "field": "data.aws.service.eventLastSeen",
      "format": "date_time"
    },
    {
      "field": "data.aws.start",
      "format": "date_time"
    },
    {
      "field": "data.aws.updatedAt",
      "format": "date_time"
    },
    {
      "field": "data.timestamp",
      "format": "date_time"
    },
    {
      "field": "data.vulnerability.published",
      "format": "date_time"
    },
    {
      "field": "data.vulnerability.updated",
      "format": "date_time"
    },
    {
      "field": "syscheck.mtime_after",
      "format": "date_time"
    },
    {
      "field": "syscheck.mtime_before",
      "format": "date_time"
    },
    {
      "field": "timestamp",
      "format": "date_time"
    }
  ],
  "_source": {
    "excludes": [
      "@timestamp"
    ]
  },
  "query": {
    "bool": {
      "must": [],
      "filter": [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          "exists": {
            "field": "rule.mitre.id"
          }
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    }
  }
});
export const mitre_tactics = (
  agentId,
  _query,
  range_from,
  range_to
) => ({
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          exists: {
            field: "rule.mitre.id",
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              from: "now-30d",
              to: "now",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
  aggs: {
    tactics: {
      terms: {
        field: "rule.mitre.tactic",
        size: 1000,
      },
    },
  },
});

export const mitre_techniques = (
  agentId,
  _query,
  range_from,
  range_to
) => ({
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          exists: {
            field: "rule.mitre.id",
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              from: range_from,
              to: range_to,
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },

  aggs: {
    techniques: {
      terms: {
        field: "rule.mitre.id",
        size: 100,
      },
    },
  },
});

export const fim_most_active_user_query = (
  _query,
  agentId,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      terms: {
        field: "syscheck.uname_after",
        order: {
          _count: "desc",
        },
        size: 5,
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});

export const fim_action_query = (
  _query,
  agentId,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      terms: {
        field: "syscheck.event",
        order: {
          _count: "desc",
        },
        size: 5,
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});
export const fim_files_added_query = (
  _query,
  agentId,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      terms: {
        field: "syscheck.path",
        order: {
          _count: "desc",
        },
        size: 5,
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          bool: {
            should: [
              {
                match_phrase: {
                  "syscheck.event": "added",
                },
              },
              {
                match_phrase: {
                  "syscheck.event": "readded",
                },
              },
            ],
            minimum_should_match: 1,
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});

export const fim_files_modifed_query = (
  _query,
  agentId,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      terms: {
        field: "syscheck.path",
        order: {
          _count: "desc",
        },
        size: 5,
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          match_phrase: {
            "syscheck.event": {
              query: "modified",
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});

export const fim_files_modifed_deleted = (
  _query,
  agentId,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      terms: {
        field: "syscheck.path",
        order: {
          _count: "desc",
        },
        size: 5,
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          match_phrase: {
            "syscheck.event": {
              query: "deleted",
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});
export const fim_events_query = (
  _query,
  agentId,
  range_from,
  range_to
) => ({
  aggs: {
    2: {
      date_histogram: {
        field: "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      },
      aggs: {
        3: {
          terms: {
            field: "syscheck.event",
            order: {
              _count: "desc",
            },
            size: 5,
          },
        },
      },
    },
  },
  size: 0,
  stored_fields: ["*"],
  script_fields: {},
  docvalue_fields: [
    {
      field: "data.aws.createdAt",
      format: "date_time",
    },
    {
      field: "data.aws.end",
      format: "date_time",
    },
    {
      field: "data.aws.resource.instanceDetails.launchTime",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventFirstSeen",
      format: "date_time",
    },
    {
      field: "data.aws.service.eventLastSeen",
      format: "date_time",
    },
    {
      field: "data.aws.start",
      format: "date_time",
    },
    {
      field: "data.aws.updatedAt",
      format: "date_time",
    },
    {
      field: "data.timestamp",
      format: "date_time",
    },
    {
      field: "data.vulnerability.published",
      format: "date_time",
    },
    {
      field: "data.vulnerability.updated",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_after",
      format: "date_time",
    },
    {
      field: "syscheck.mtime_before",
      format: "date_time",
    },
    {
      field: "timestamp",
      format: "date_time",
    },
  ],
  _source: {
    excludes: ["@timestamp"],
  },
  query: {
    bool: {
      must: [],
      filter: [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          match_phrase: {
            "manager.name": {
              query: manager?.hostname,
            },
          },
        },
        {
          match_phrase: {
            "rule.groups": {
              query: "syscheck",
            },
          },
        },
        {
          match_phrase: {
            "agent.id": {
              query: agentId,
            },
          },
        },
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
      ],
      should: [],
      must_not: [],
    },
  },
});



export const vulnerabilit_severity_query = (
  agentId,
  _query,
  range_from,
  range_to,
  from = 1,
  size = 500,
) => ({
  "version": true,
  from: from,
  "size": size,
  "sort": [
    {
      "timestamp": {
        "order": "desc",
        "unmapped_type": "boolean"
      }
    }
  ],
  "aggs": {
    "2": {
      "date_histogram": {
        "field": "timestamp",
        ...getIntervalPayload(range_from, range_to),
        time_zone: getTimezone(),
        min_doc_count: 1,
      }
    }
  },
  "stored_fields": [
    "*"
  ],
  "script_fields": {},
  "docvalue_fields": [
    {
      "field": "data.aws.createdAt",
      "format": "date_time"
    },
    {
      "field": "data.aws.end",
      "format": "date_time"
    },
    {
      "field": "data.aws.resource.instanceDetails.launchTime",
      "format": "date_time"
    },
    {
      "field": "data.aws.service.eventFirstSeen",
      "format": "date_time"
    },
    {
      "field": "data.aws.service.eventLastSeen",
      "format": "date_time"
    },
    {
      "field": "data.aws.start",
      "format": "date_time"
    },
    {
      "field": "data.aws.updatedAt",
      "format": "date_time"
    },
    {
      "field": "data.timestamp",
      "format": "date_time"
    },
    {
      "field": "data.vulnerability.published",
      "format": "date_time"
    },
    {
      "field": "data.vulnerability.updated",
      "format": "date_time"
    },
    {
      "field": "syscheck.mtime_after",
      "format": "date_time"
    },
    {
      "field": "syscheck.mtime_before",
      "format": "date_time"
    },
    {
      "field": "timestamp",
      "format": "date_time"
    }
  ],
  "_source": {
    "excludes": [
      "@timestamp"
    ]
  },
  "query": {
    "bool": {
      "must": [],
      "filter": [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          "match_phrase": {
            "manager.name": {
              "query": manager?.hostname,
            }
          }
        },
        {
          "match_phrase": {
            "rule.groups": {
              "query": "vulnerability-detector"
            }
          }
        },
        {
          "match_phrase": {
            "agent.id": {
              "query": agentId
            }
          }
        },
        {
          "range": {
            "timestamp": {
              "gte": range_from,
              "lte": range_to,
              "format": "strict_date_optional_time"
            }
          }
        }
      ],
      "should": [],
      "must_not": []
    }
  },
  "highlight": {
    "pre_tags": [
      "@opensearch-dashboards-highlighted-field@"
    ],
    "post_tags": [
      "@/opensearch-dashboards-highlighted-field@"
    ],
    "fields": {
      "*": {}
    },
    "fragment_size": 2147483647
  }
});


export const vulnerabilit_events_query = (
  agentId,
  package_name,
  cve,
  architecture,
  version,
  _query,
  range_from,
  range_to,
  from = 0,
  size = 20
) => ({
  query: {
    "bool": {
      "must": [
        {
          range: {
            timestamp: {
              gte: range_from,
              lte: range_to,
              format: "strict_date_optional_time",
            },
          },
        },
        {
          "match": {
            "rule.groups": "vulnerability-detector"
          }
        },
        {
          "match": {
            "agent.id": agentId
          }
        },
        {
          "match": {
            "manager.name": manager?.hostname,
          }
        },
        {
          "match": {
            "data.vulnerability.package.name": package_name
          }
        },
        {
          "match": {
            "data.vulnerability.cve": cve
          }
        },
        {
          "match": {
            "data.vulnerability.package.architecture": architecture
          }
        },
        {
          "match": {
            "data.vulnerability.package.version": version
          }
        },
        {
          "match": {
            "agent.id": agentId
          }
        }
      ],
      "filter": [
        _query
          ? {
            query_string: {
              query: _query,
            },
          }
          : {
            match_all: {},
          },
        {
          "match_phrase": {
            "manager.name": {
              "query": manager?.hostname,
            }
          }
        },
        {
          "match_phrase": {
            "rule.groups": {
              "query": "vulnerability-detector"
            }
          }
        },
        {
          "match_phrase": {
            "agent.id": {
              "query": agentId
            }
          }
        }
      ],
      "should": [],
      "must_not": []
    }
  },
  size: size,
  from: from,
  sort: {
    timestamp: {
      order: "desc",
    },
  },
});