import { Box } from "@mui/material";
import React from "react";
import Button from "react-bootstrap/Button";

const Btn = (props) => {
  const {
    height = 36,
    variant = "primary",
    startIcon = false,
    fontSize = 14,
    text,
    muiSVG = false,
    ...other
  } = props;
  return (
    <Box
      fontSize={fontSize}
      fontWeight={600}
      height={height}
      component={Button}
      variant={variant}
      borderRadius={1.25}
      {...other}
    >
      {startIcon && <Box component="img" src={startIcon} mr={1} />}
      {muiSVG && muiSVG}

      {text}
    </Box>
  );
};

export default Btn;
