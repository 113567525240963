import wazuh from "../../config/wazuh";


export async function getVulnerabilitiesFilterValues(agentId, field, value, filters = {},) {
    const filter = {
        distinct: true,
        ...filters,
        select: field,
        limit: 30,
    };
    if (value) {
        filter["search"] = value;
    }
    const result = await wazuh.get(`/vulnerability/${agentId}`, {
        params: filter,
    });


    const getChild = (item, field) => {
        const subFields = field.split(".");
        if (subFields.length < 2) {
            return item[field];
        } else {
            const currentField = subFields.shift();
            if (!item[currentField]) {
                return "";
            }
            return getChild(item[currentField], subFields.join("."));
        }
    };
    const arrayResult = (
        ((result || {}).data || {}).data || {}
    ).affected_items.map((item) => {
        return getChild(item, field);
    });
    return arrayResult
    // .filter((item) => item && item.length)
    // .reduce(
    //     (accum, item) =>
    //         Array.isArray(item) ? [...accum, ...item] : [...accum, item],
    //     []
    // ) // it lets expand agent.group, which is an string[] (array of strings)
    // .filter(
    //     (item, index, array) =>
    //         array.indexOf(item) === index && item !== "unknown"
    // ); // return unique values
}
