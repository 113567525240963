import { CustomTableCell } from "../../../components/Display/CustomTable";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { TableRow, Box, Typography, Avatar, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { formatCreatedAt } from "../../../utils/utils";

const CustomOrganizationRow = ({
  row,
  isSelected,
  onSelect,
  onEdit,
  permissions,
}) => {
  const handleRowClick = () => {
    onSelect(row._id);
  };
  const navigation = useNavigate();
  return (
    <TableRow
      key={row._id}
      sx={{
        borderRadius: "4px",
        minHeight: 76,
        verticalAlign: "baseline",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),
      }}
      onClick={handleRowClick}
    >
      <CustomTableCell>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Link to={`/organizations/${row.name}`}>
            <Typography color="#1173FF" variant="caption" fontWeight={700}>
              {row.name}
            </Typography>
          </Link>
          <Typography variant="caption" className="text-muted" fontWeight={400}>
            {row.description}
          </Typography>
        </Box>
      </CustomTableCell>
      <CustomTableCell>
        <Box display="flex" alignItems="flex-start" gap="5px">
          {row?.avatar ? (
            <Avatar
              sx={{ width: 26, height: 26 }}
              alt={row.name}
              src={`${window.location.origin}/cms/${row.avatar}`}
            />
          ) : (
            <Avatar sx={{ width: 26, height: 26 }} alt={row.name}>
              {row.name.slice(0, 1)}
            </Avatar>
          )}
          <Box display="flex" flexDirection={"column"}>
            <Typography variant="caption" fontWeight={700}>
              {row.name}
            </Typography>
            <Typography
              variant="caption"
              className="text-muted"
              fontWeight={400}
            >
              {row.profile}
            </Typography>
          </Box>
        </Box>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <Box
          display="flex"
          //   justifyContent="space-between"
          alignItems={"center"}
          gap={1.25}
        >
          <Typography variant="caption" fontWeight={500}>
            <PlayCircleFilledRoundedIcon
              fontSize="small"
              sx={{ color: "#2497F3" }}
            />
            {formatCreatedAt(row._createdAt)}
          </Typography>
          {row._updatedAt && (
            <Typography variant="caption" fontWeight={500}>
              <CheckCircleRoundedIcon
                fontSize="small"
                sx={{ color: "#13D38E" }}
              />
              {formatCreatedAt(row._updatedAt)}
            </Typography>
          )}
        </Box>
      </CustomTableCell>
      <CustomTableCell>
        {permissions?.mOrg && (
          <Box display="flex" justifyContent="space-around" gap={0.25}>
            <IconButton
              size="small"
              onClick={() => navigation(`/organizations/${row.name}`)}
            >
              <SettingsIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                onEdit({ name: row.name, description: row.description });
              }}
            >
              <EditIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
          </Box>
        )}
      </CustomTableCell>
    </TableRow>
  );
};

export default CustomOrganizationRow;
