const hostnameOrIpRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.?)+(?!\d+$)[a-zA-Z0-9-_]{2,63}\.?[a-zA-Z]{2,6}$/;
const ipAddressRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^\s*([\da-fA-F]{1,4}:){7}([\da-fA-F]{1,4}|:)|::([\da-fA-F]{1,4}:){0,5}([\da-fA-F]{1,4}|:)|([\da-fA-F]{1,4}:){0,6}([\da-fA-F]{1,4}|:)\s*$/;
const sendmailPathRegex = /^\/(?:[^/ ]+\/)*[^/ ]+$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const pathRegex = /\/[a-zA-Z0-9_\/-]*[^\/]$/;
const urlRegex = /^https?:\/\/[^\s/$.?#].[^\s]*$/;
const DNSRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.?([A-Za-z0-9-]{1,63}\.?)*[A-Za-z]{2,6}$/;
const commandRegex = /^([\/\w\-.]+)(\s+[\/\w\-.:,='"`]+)*$/;
const PCRE2_REGEX = /^([\/\w\-.]+)(?:\s+[^\s]+)*$/;



export const ossecConfigSchema = {
    global: {
        name: "global",
        title: "Global Configuration",
        description: "Global and remote settings",
        sections: [
            {
                title: "Main settings",
                description: "Basic alerts and logging settings",
                options: [
                    { verbose: "Write alerts to alerts.log file", field: "alerts_log", alerts_log: { default_value: "yes", allowed_values: ["yes", "no"] }, type: "boolean" },
                    { verbose: "Write JSON formatted alerts to alerts.json file", field: "jsonout_output", jsonout_output: { default_value: "yes", allowed_values: ["yes", "no"] }, type: "boolean" },
                    { verbose: "Archive all the alerts in plain text format", field: "logall", logall: { default_value: "no", allowed_values: ["yes", "no"] }, type: "boolean" },
                    { verbose: "Archive all the alerts in JSON format", field: "logall_json", logall_json: { default_value: "no", allowed_values: ["yes", "no"] }, type: "boolean" },
                ]
            },
            {
                title: "Email settings",
                description: "Basic email settings (needed for granular email settings)",
                options: [
                    { verbose: "Enable alerts sent by email", field: "email_notification", email_notification: { default_value: "no", allowed_values: ["yes", "no"] }, type: "boolean" },
                    { verbose: "SMTP server", field: "smtp_server", smtp_server: { default_value: "", allowed_values: null }, type: "regex", regex: [hostnameOrIpRegex, ipAddressRegex, sendmailPathRegex] },
                    { verbose: "Email From", field: "email_from", email_from: { default_value: "", allowed_values: null }, type: "email", regex: [emailRegex] },
                    { verbose: "Email To", field: "email_to", email_to: { default_value: "", allowed_values: null }, type: "email", regex: [emailRegex] },
                    { verbose: "Email max per hour", field: "email_maxperhour", email_maxperhour: { default_value: 12, allowed_values: [1, 1000000] }, type: "number" },
                    { verbose: "Email logs source", field: "email_log_source", email_log_source: { default_value: "alerts.log", allowed_values: ["alerts.json", "alerts.log"] }, type: "select" },
                ]
            },
            {
                title: "Other settings",
                description: "Settings not directly related to any specific component",
                options: [
                    { verbose: "White-listed IP addresses", field: "white_list", white_list: { default_value: "", allowed_values: [] }, type: "regex", regex: [hostnameOrIpRegex, ipAddressRegex] },
                    { verbose: "Agent disconnect time", field: "agents_disconnection_time", agents_disconnection_time: { default_value: "10m", allowed_values: [], min_allowed: 1 }, type: "timeUnit" },
                    { verbose: "Agent disconnect alert time", field: "agents_disconnection_alert_time", agents_disconnection_alert_time: { default_value: "0s", allowed_values: [], min_allowed: 0 }, type: "timeUnit" },
                ]
            }
        ]
    },
    alerts: {
        name: "alerts",
        title: "Alert Configuration",
        description: "Settings related to alerting and notification",
        sections: [
            {
                title: "General settings",
                description: "Basic alert settings",
                options: [
                    {
                        verbose: "Sets the minimum severity level",
                        field: "log_alert_level",
                        log_alert_level: { default_value: 3, allowed_values: [1, 16] },
                        type: "number"
                    },
                    {
                        verbose: "Alert level",
                        field: "email_alert_level",
                        email_alert_level: { default_value: 12, allowed_values: [1, 16] },
                        type: "number"
                    },
                    // {
                    //     verbose: "Sets the minimum severity level",
                    //     field: "use_geoip",
                    //     use_geoip: { default_value: NaN, allowed_values: ["yes", "no"] },
                    //     type: "boolean"
                    // }
                ]
            }
        ]
    },
    logging: {
        name: "logging",
        title: "Log Format Configuration",
        description: "Settings related to the format of logs",
        sections: [
            {
                title: "Log Formatting",
                description: "Options for configuring log format",
                options: [
                    {
                        verbose: "Log format type",
                        field: "log_format",
                        log_format: { default_value: "plain", allowed_values: ["plain", "json", "plain, json"] },
                        type: "select"
                    }
                ]
            }
        ]
    },
    remote: {
        name: "remote",
        title: "Remote Configuration",
        description: "Settings related to remote communication and connections",
        MultiIndexies: true,
        sections: [
            {
                title: "Connection Settings",
                description: "Options for configuring remote connection settings",
                options: [
                    {
                        verbose: "Type of incoming connection to accept",
                        field: "connection",
                        connection: { default_value: "secure", allowed_values: ["secure", "syslog"] },
                        type: "select"
                    },
                    {
                        verbose: "Remote connection port",
                        field: "port",
                        port: { default_value: 1514, allowed_values: [1, 65535] },
                        type: "number"
                    },
                    {
                        verbose: "Remote connection protocol",
                        field: "protocol",
                        protocol: { default_value: "tcp", allowed_values: ["tcp", "udp"] },
                        type: "select"
                    },
                    {
                        verbose: "Queue size",
                        field: "queue_size",
                        queue_size: { default_value: 131072, allowed_values: [2, 262143] },
                        type: "number"
                    },
                    // {
                    //     verbose: "Allowed IPs",
                    //     field: "allowed_ips",
                    //     allowed_ips: { default_value: "", allowed_values: [] },
                    //     type: "regex",
                    //     regex: [ipAddressRegex, hostnameOrIpRegex]
                    // },
                    // {
                    //     verbose: "Denied IPs",
                    //     field: "denied_ips",
                    //     denied_ips: { default_value: "", allowed_values: [] },
                    //     type: "regex",
                    //     regex: [ipAddressRegex, hostnameOrIpRegex]
                    // },
                    // {
                    //     verbose: "Local IP",
                    //     field: "local_ip",
                    //     local_ip: { default_value: "", allowed_values: null },
                    //     type: "regex",
                    //     regex: [ipAddressRegex]
                    // },
                    // {
                    //     verbose: "Enable IPv6",
                    //     field: "ipv6",
                    //     ipv6: { default_value: "no", allowed_values: ["yes", "no"] },
                    //     type: "boolean"
                    // },

                    // {
                    //     verbose: "RIDS closing time",
                    //     field: "rids_closing_time",
                    //     rids_closing_time: { default_value: "5m", allowed_values: [], min_allowed: 1 },
                    //     type: "timeUnit"
                    // },
                ]
            }
        ]
    },
    rootcheck: {
        name: "rootcheck",
        title: "Rootcheck Configuration",
        description: "Settings related to rootkit and file integrity checks",
        sections: [
            {
                title: "Rootcheck Settings",
                description: "Options for configuring rootkit checks and file integrity monitoring",
                options: [
                    {
                        verbose: "Disable rootcheck",
                        field: "disabled",
                        disabled: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check files",
                        field: "check_files",
                        check_files: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check for trojans",
                        field: "check_trojans",
                        check_trojans: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check device files",
                        field: "check_dev",
                        check_dev: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check system files",
                        field: "check_sys",
                        check_sys: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check process IDs",
                        field: "check_pids",
                        check_pids: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check network ports",
                        field: "check_ports",
                        check_ports: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check network interfaces",
                        field: "check_if",
                        check_if: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Check frequency (in seconds)",
                        field: "frequency",
                        frequency: { default_value: 43200, allowed_values: [0, 2 ^ 54] },
                        type: "number"
                    },
                    {
                        verbose: "Rootkit files",
                        field: "rootkit_files",
                        rootkit_files: { default_value: "etc/shared/rootkit_files.txt", allowed_values: [] },
                        type: "regex",
                        regex: [pathRegex]
                    },
                    {
                        verbose: "Rootkit trojans",
                        field: "rootkit_trojans",
                        rootkit_trojans: { default_value: "", allowed_values: [] },
                        type: "regex",
                        regex: [pathRegex]
                    },
                    {
                        verbose: "Skip NFS mounts",
                        field: "skip_nfs",
                        skip_nfs: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                ]
            }
        ]
    },
    cluster: {
        name: "cluster",
        title: "Cluster Configuration",
        description: "Settings related to cluster management and communication",
        sections: [
            {
                title: "Cluster Settings",
                description: "Options for configuring cluster settings",
                options: [
                    {
                        verbose: "Lists all master nodes in the cluster",
                        field: "nodes",
                        name: { default_value: "", allowed_values: null },
                        type: "MultiFields",
                        options: [
                            {
                                verbose: "Cluster name",
                                field: "node",
                                node: { default_value: "NODE_IP", allowed_values: null },
                                type: "regex",
                                regex: [ipAddressRegex, DNSRegex]
                            },
                        ]
                    },
                    {
                        verbose: "Cluster name",
                        field: "name",
                        name: { default_value: "", allowed_values: null },
                        type: "string"
                    },
                    {
                        verbose: "Node name",
                        field: "node_name",
                        node_name: { default_value: "", allowed_values: null },
                        type: "string"
                    },
                    {
                        verbose: "Node type",
                        field: "node_type",
                        node_type: { default_value: "master", allowed_values: ["master", "agent", "other"] },
                        type: "select"
                    },
                    {
                        verbose: "Cluster key",
                        field: "key",
                        key: { default_value: "", allowed_values: null },
                        type: "string"
                    },
                    {
                        verbose: "Cluster port",
                        field: "port",
                        port: { default_value: 1516, allowed_values: [1, 65535] },
                        type: "number"
                    },
                    {
                        verbose: "Bind address",
                        field: "bind_addr",
                        bind_addr: { default_value: "0.0.0.0", allowed_values: null },
                        type: "regex",
                        regex: [ipAddressRegex]
                    },
                    {
                        verbose: "Hidden node",
                        field: "hidden",
                        hidden: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Disabled",
                        field: "disabled",
                        disabled: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                ]
            }
        ]
    },
    integration: {
        name: "integration",
        title: "Integration Configuration",
        description: "Settings related to third-party integrations",
        MultiIndexies: true,
        sections: [
            {
                title: "Integration Settings",
                description: "Options for configuring third-party integrations",
                options: [
                    {
                        verbose: "Integration name",
                        field: "name",
                        name: { default_value: "", allowed_values: null },
                        type: "string"
                    },
                    {
                        verbose: "Hook URL",
                        field: "hook_url",
                        hook_url: { default_value: "", allowed_values: null },
                        type: "regex",
                        regex: [urlRegex]
                    },
                    {
                        verbose: "API key",
                        field: "api_key",
                        api_key: { default_value: "", allowed_values: null },
                        type: "string"
                    },
                    {
                        verbose: "Alert format",
                        field: "alert_format",
                        alert_format: { default_value: "", allowed_values: ["json"] },
                        type: "select"
                    },
                ]
            }
        ]
    },
    sca: {
        name: "sca",
        title: "Software Composition Analysis Configuration",
        description: "Settings related to software composition analysis",
        sections: [
            {
                title: "SCA Settings",
                description: "Options for configuring software composition analysis",
                options: [
                    {
                        verbose: "Enable SCA",
                        field: "enabled",
                        enabled: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },

                    {
                        verbose: "Skip NFS mounts",
                        field: "skip_nfs",
                        skip_nfs: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                ]
            },
            {
                title: "SCA Scheduling",
                description: "Options for scheduling software",
                options: [
                    {
                        verbose: "Scan on start",
                        field: "scan_on_start",
                        scan_on_start: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Scan interval",
                        field: "interval",
                        interval: { default_value: "12h", allowed_values: [], min_allowed: 1 },
                        type: "timeUnit"
                    },
                ]
            }

        ]
    },
    "vulnerability-detector": {
        name: "vulnerability-detector",
        title: "Vulnerability Detector Configuration",
        description: "Settings related to vulnerability detection and monitoring",
        sections: [
            {
                title: "General settings",
                description: "Basic settings for the vulnerability detector",
                options: [
                    {
                        verbose: "Enable Vulnerability Detector",
                        field: "enabled",
                        enabled: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Feed update interval",
                        field: "interval",
                        interval: { default_value: "5m", allowed_values: [], min_allowed: 0 },
                        type: "timeUnit"
                    },
                    {
                        verbose: "Min Full Scan interval",
                        field: "min_full_scan_interval",
                        min_full_scan_interval: { default_value: "6h", allowed_values: [], min_allowed: 0 },
                        type: "timeUnit"
                    },
                    {
                        verbose: "Runs updates and vulnerabilities scans immediately",
                        field: "run_on_start",
                        run_on_start: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },

                ]
            },
            {
                title: "Provider Configuration",
                description: "Configuration block to specify vulnerability updates.",
                options: [
                    {
                        verbose: "Provider",
                        field: "vulnerability-detector",
                        tag: "provider",
                        description: "Configuration block to specify vulnerability updates.",
                        type: "multiAtters",
                        atterName: "name",
                        atters: ["canonical", "debian", "redhat", "alas"],
                        options: [
                            {
                                verbose: "Enables the vulnerability provider update.",
                                field: "enabled",
                                enabled: { default_value: "no", allowed_values: ["yes", "no"] },
                                type: "boolean"
                            },
                            {
                                verbose: "How often the vulnerability database is updated.",
                                field: "update_interval",
                                update_interval: { default_value: "1h", allowed_values: [], min_allowed: 0 },
                                type: "timeUnit"
                            },
                            {
                                verbose: "Enables / Disable Opration System.",
                                field: "os",
                                type: "multiTags",
                                os: [
                                    {
                                        "attr": "canonical",
                                        "default_value": "",
                                        "tags": ["trusty", "xenial", "bionic", "focal", "jammy"]
                                    },
                                    {
                                        "attr": "debian",
                                        "default_value": "",
                                        "tags": ["buster", "bullseye"]
                                    },
                                    {
                                        "attr": "alas",
                                        "default_value": "",
                                        "tags": ["amazon-linux", "amazon-linux-2"]
                                    },
                                    {
                                        "attr": "redhat",
                                        "default_value": "",
                                        "tags": [5, 6, 7, 8, 9]
                                    },
                                    {
                                        "attr": "suse",
                                        "default_value": "",
                                        "tags": ["11-server", "11-desktop", "12-server", "15-server", "15-desktop"]
                                    },
                                    {
                                        "attr": "suse",
                                        "default_value": "",
                                        "tags": ["11-server", "11-desktop", "12-server", "15-server", "15-desktop"]
                                    },
                                ]
                            }
                        ]
                    }
                ]
            }

        ]
    },
    syscheck: {
        name: "syscheck",
        title: "System Checks",
        description: "System threats and incident response",
        sections: [
            {
                title: "General settings",
                description: "Basic settings for the vulnerability detector",
                options: [
                    {
                        verbose: "Indicates if the syscheck scan is disabled or not.",
                        field: "disabled",
                        disabled: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Frequency that the syscheck will be run. Given in seconds.",
                        field: "frequency",
                        frequency: { default_value: 43200, allowed_values: [0, (2 ^ 54)] },
                        type: "number"
                    },
                    {
                        verbose: "Specifies if FIM should alert when new files are created.",
                        field: "alert_new_files",
                        alert_new_files: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "List of directories to be monitored.",
                        field: "directories",
                        directories: { default_value: "/etc", allowed_values: null },
                        type: "regex",
                        regex: [sendmailPathRegex]
                    },
                    {
                        verbose: "List of files or directories to be ignored",
                        field: "ignore",
                        ignore: { default_value: "", allowed_values: [] },
                        type: "regex",
                        regex: [sendmailPathRegex]
                    },
                    {
                        verbose: "Specifies if syscheck should scan network mounted filesystems",
                        field: "skip_nfs",
                        skip_nfs: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Specifies if syscheck should scan the /dev directory.",
                        field: "skip_dev",
                        skip_dev: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Specifies if syscheck should scan the /proc directory.",
                        field: "skip_proc",
                        skip_proc: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Specifies if syscheck should scan the /sys directory.",
                        field: "skip_sys",
                        skip_sys: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Sets the nice value for Syscheck process.",
                        field: "process_priority",
                        process_priority: { default_value: 12, allowed_values: [-20, 19] },
                        type: "number"
                    },
                    {
                        verbose: "Sets the maximum synchronization message throughput.",
                        field: "max_eps",
                        process_priority: { default_value: 10, allowed_values: [0, 1000000] },
                        type: "number"
                    },
                ]
            },
            {
                title: "Synchronization settings",
                description: "The database synchronization settings are configured inside this.",
                options: [
                    {
                        verbose: "The database synchronization settings are configured inside this tag.",
                        field: "syscheck",
                        tag: "synchronization",
                        type: "multiTags",
                        options: [
                            {
                                verbose: "Periodic inventory synchronizations or not",
                                field: "enabled",
                                enabled: { default_value: "yes", allowed_values: ["yes", "no"] },
                                type: "boolean"
                            },
                            {
                                verbose: "Initial number between every inventory synchronization",
                                field: "interval",
                                interval: { default_value: "300s", allowed_values: [], min_allowed: 0 },
                                type: "timeUnit"
                            },
                            {
                                verbose: "Maximum number of seconds between every inventory synchronization",
                                field: "max_interval",
                                max_interval: { default_value: "1h", allowed_values: [], min_allowed: 0 },
                                type: "timeUnit"
                            },
                            {
                                verbose: " maximum synchronization message throughput.",
                                field: "max_eps",
                                max_eps: { default_value: 10, allowed_values: [0, 1000000] },
                                type: "number"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    command: {
        name: "command",
        title: "Command Configuration",
        description: "In the command configuration section, a command is defined that will be used by one or more active responses.",
        MultiIndexies: true,
        sections: [
            {
                title: "Main settings",
                description: "Basic command settings",
                options: [
                    {
                        verbose: "Specifies the name of the command which is called in the active-response section.",
                        field: "name",
                        name: { default_value: "", allowed_values: [""] }, type: "text"
                    },
                    {
                        verbose: "Names an executable file to run.",
                        field: "executable",
                        executable: { default_value: "", allowed_values: [""] }, type: "text"
                    },
                    {
                        verbose: "Specifies whether the command is stateful or stateless. ",
                        field: "timeout_allowed",
                        timeout_allowed: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                ]
            },
        ]
    },
    localfile: {
        name: "localfile",
        title: "Local Files",
        description: "This configuration section is used to configure the collection of log data from files, Windows events, and from the output of commands.",
        MultiIndexies: true,
        sections: [
            {
                title: "Main settings",
                description: "Basic files settings",
                options: [
                    {
                        verbose: "Option to get the location of a log or a group of logs.",
                        field: "location",
                        location: { default_value: "", allowed_values: null },
                        regex: [pathRegex],
                        type: "regex"
                    },
                    {
                        verbose: "Given a command output.",
                        field: "command",
                        command: { default_value: "", allowed_values: null },
                        regex: [commandRegex],
                        type: "regex"
                    },
                    {
                        verbose: "Change a command name in the log message.",
                        field: "alias",
                        alias: { default_value: "", allowed_values: [""] },
                        type: "text"
                    },
                    {
                        verbose: "Prevents a command from being executed in less time than the specified time (in seconds).",
                        field: "frequency",
                        frequency: { default_value: "", allowed_values: [0, (2 ^ 9999999999999)] },
                        type: "number"
                    },
                    {
                        verbose: "It allows to read new log content since wazuh-logcollector was stopped.",
                        field: "only-future-events",
                        "only-future-events": { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "number"
                    },
                    {
                        verbose: "Used to add custom data in JSON events.",
                        field: "label",
                        label: { default_value: "", allowed_values: [""] },
                        type: "text"
                    },
                    {
                        verbose: "Target specifies the name of the socket where the output will be redirected.",
                        field: "target",
                        target: { default_value: "agent", allowed_values: [""] },
                        type: "text"
                    },
                    {
                        verbose: "Set the format of the log to be read.",
                        field: "log_format",
                        log_format: { default_value: "", allowed_values: ["syslog", "json", "snort-full", "squid", "eventlog", "eventchannel", "macos", "audit", "mysql_log", "postgresql_log", "nmapg", "iis", "command", "full_command", "djb-multilog", "multi-line", "multi-line-regex"] },
                        type: "select"
                    },
                    {
                        verbose: "Ignore binary files, testing if the file is UTF8 or ASCII.",
                        field: "ignore_binaries",
                        ignore_binaries: { default_value: "", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Read-only files that have been modified before the specified age.",
                        field: "age",
                        age: { default_value: "", allowed_values: [], min_allowed: 0 },
                        type: "timeUnit"
                    },
                    {
                        verbose: "This indicates the location of a wild-carded group of logs to be excluded.",
                        field: "exclude",
                        exclude: { default_value: "", allowed_values: null },
                        regex: [commandRegex],
                        type: "regex"
                    },
                    {
                        verbose: "Defines the interval of reconnection attempts when the Windows Event Channel service is down.",
                        field: "reconnect_time",
                        reconnect_time: { default_value: "5s", allowed_values: [], min_allowed: 0 },
                        type: "timeUnit"
                    },
                    {
                        verbose: "This specifies a regular expression, match criteria and replace option for logs with a variable amount of lines.",
                        field: "multiline_regex",
                        "multiline_regex": { default_value: "", allowed_values: null },
                        regex: [commandRegex],
                        type: "regex"
                    },
                ]
            }
        ]
    },
    rule_test: {
        name: "rule_test",
        title: "Test Rules.",
        description: "Here is how to configure the Wazuh-Logtest solution. It allows to test rules and decoders from Wazuh API and wazuh-logtest tool.",
        sections: [
            {
                title: "Main settings",
                description: "Basic Rules Test settings",
                options: [
                    {
                        verbose: "Enable Module",
                        field: "enabled",
                        enabled: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Number of Wazuh-Logtest solution threads.",
                        field: "threads",
                        threads: { default_value: 1, allowed_values: null },
                        regex: [/^(auto|([1-9]|[1-9][0-9]|1[01][0-9]|12[0-8]))$/],
                        type: "regex"
                    },
                ]
            }
        ]
    },
    auth: {
        name: "auth",
        title: "Authentication Configuration",
        description: "Settings related to authentication and agent enrollment",
        sections: [
            {
                title: "General settings",
                description: "Basic authentication settings",
                options: [
                    {
                        verbose: "Disable authentication",
                        field: "disabled",
                        disabled: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Allow remote agent enrollment",
                        field: "remote_enrollment",
                        remote_enrollment: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Port used for agent-manager communication",
                        field: "port",
                        port: { default_value: 1515, allowed_values: [0, 65535] },
                        type: "number"
                    },
                    {
                        verbose: "Use IPv6 for communication",
                        field: "ipv6",
                        ipv6: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Use source IP for verification",
                        field: "use_source_ip",
                        use_source_ip: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },

                    {
                        verbose: "Purge old keys",
                        field: "purge",
                        purge: { default_value: "yes", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Require a password for agent enrollment",
                        field: "use_password",
                        use_password: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Path to the agent's CA certificate",
                        field: "ssl_agent_ca",
                        ssl_agent_ca: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Verify the manager's hostname",
                        field: "ssl_verify_host",
                        ssl_verify_host: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "Path to the manager's certificate",
                        field: "ssl_manager_cert",
                        ssl_manager_cert: { default_value: "etc/sslmanager.cert", allowed_values: null },
                        regex: [pathRegex],
                        type: "regex"
                    },
                    {
                        verbose: "Path to the manager's private key",
                        field: "ssl_manager_key",
                        ssl_manager_key: { default_value: "etc/sslmanager.key", allowed_values: null },
                        regex: [pathRegex],
                        type: "regex"
                    },
                    {
                        verbose: "Enable SSL auto-negotiation",
                        field: "ssl_auto_negotiate",
                        ssl_auto_negotiate: { default_value: "no", allowed_values: ["yes", "no"] },
                        type: "boolean"
                    },
                    {
                        verbose: "List of SSL ciphers to use",
                        field: "ciphers",
                        ciphers: { default_value: "HIGH:!ADH:!EXP:!MD5:!RC4:!3DES:!CAMELLIA:@STRENGTH" },
                        type: "string"
                    },

                ]
            }
        ]
    }
};

