import {
  case_status_enum,
  impactStatus_enum,
  pap_enum,
  resolutionStatus_enum,
  severity_enum,
  tlp_enum,
} from "../../pages/Cases/enums/enums.js";
import { datatypes_options } from "./utils.js";
import { convertToDateTimeLocal } from "../utils.js";
import cms from "../../config/axiosConfig.js";
import { case_tasks_states } from "../../pages/Tasks/enums/enums.js";

const organisation = () => {
  try {
    const _organization = JSON.parse(
      localStorage.getItem("cms_user")
    )?.organisation;

    return _organization ? _organization : "";
  } catch {
    return "";
  }
};
const fetchAssignableUsers = async () => {
  try {
    let query = [
      {
        _name: "getOrganisation",
        idOrName: organisation(),
      },
      {
        _name: "users",
      },
      {
        _name: "sort",
        _fields: [
          {
            login: "asc",
          },
        ],
      },
      {
        _name: "page",
        from: 0,
        to: 1000,
        // organisation: "StrangeBee",
      },
    ];

    const response = await cms.post("/cms/api/v1/query", {
      query: query,
    });

    let d = response.data;
    let options = [];

    d.map((d) => {
      options.push({ title: d.name, value: d.login });
      return "";
    });

    return options;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const user_list = await fetchAssignableUsers();

const enum_getter = (field_name, field_vallue, _for) => {
  let title = "";
  switch (field_name) {
    case "pap":
      title = pap_enum.find((obj) => obj.value === field_vallue).title;
      break;
    case "tlp":
      title = tlp_enum.find((obj) => obj.value === field_vallue).title;
      break;
    case "severity":
      Object.keys(severity_enum).map((s) => {
        const obj = severity_enum[s];
        if (obj._value === field_vallue) {
          title = obj.value;
        }
        return "";
      });
      break;
    case "impactStatus":
      title = impactStatus_enum.find((obj) => obj.value === field_vallue).title;
      break;
    case "status":
      if (_for === "cases") {
        title = case_status_enum.find(
          (obj) => obj.value === field_vallue
        ).title;
      } else if (_for === "tasks") {
        title = Object.keys(case_tasks_states).find((s) => {
          const obj = case_tasks_states[s];
          if (obj._value === field_vallue) {
            return obj._value;
          }
        });
      }
      break;
    case "resolutionStatus":
      title = resolutionStatus_enum.find(
        (obj) => obj.value === field_vallue
      ).title;

      break;
    default:
      title = user_list.find((obj) => obj.value === field_vallue)?.title;
      break;
  }
  console.log(title);
  return title;
};

function AutotCompleteCompiler(_value, _for) {
  let key = "";

  // Checking for None type entry
  if ("_not" in _value) {
    //   Check if is empty
    key = _value["_not"];
    if ("_contains" in key) {
      return `<b>${key["_contains"]}</b> is <b>Empty</b>`;
    }
    // Check for None of
    if (Array.isArray(key)) {
      let values = [];
      for (const _key of key) {
        const field_name = _key["_field"];
        const field_value = _key["_value"];
        const label = enum_getter(field_name, field_value, _for);
        values.push(label);
      }
      return `<b>${key[0]["_field"]}</b> is not <b>${values.map(
        (_v) => _v
      )}</b>`;
    }
    if (typeof key === "object") {
      const field_name = key["_field"];
      const field_value = key["_value"];
      const label = enum_getter(field_name, field_value, _for);
      console.log(`${key["_field"]}__not=${label}`);
      return `<b>${key["_field"]}</b> is not <b>${label}</b>`;
    }
  }
  // For ANY of or All Of
  if ("_or" in _value || "_and" in _value) {
    const field_op = "_and" in _value ? " is " : " is any of ";
    if ("_and" in _value) {
      key = _value["_and"];
    } else {
      key = _value["_or"];
    }
    let values = [];
    for (const _key of key) {
      const field_name = _key["_field"];
      const field_value = _key["_value"];
      const label = enum_getter(field_name, field_value, _for);
      values.push(label);
    }
    return `<b>${key[0]["_field"]}</b> ${field_op} <b>${values.map(
      (_v) => _v
    )}</b>`;
  }
  if ("_field" in _value && "_value" in _value) {
    key = _value;
    const field_name = key["_field"];
    const field_value = key["_value"];
    const label = enum_getter(field_name, field_value, _for);
    const field_op = "Any Of" in key ? "is any of" : "is";
    return `<b>${field_name}</b> ${field_op} <b>${label}</b>`;
  }

  return "";
}
function MultiTextCompiler(_value, _for) {
  let key = "";
  // Checking for None type entry
  if ("_not" in _value) {
    //   Check if is empty
    key = _value["_not"];
    if ("_contains" in key) {
      return `<b>${key["_contains"]}</b> is <b>Empty</b>`;
    }
    // Check for None of
    if (Array.isArray(key)) {
      let values = [];
      for (const _key of key) {
        const field_name = _key["_like"]["_field"];
        const field_value = _key["_like"]["_value"];
        const label = enum_getter(field_name, field_value, _for);
        values.push(label);
      }

      return `<b>${key[0]["_like"]["_field"]}</b> is not <b>${values.map(
        (_v) => _v
      )}</b>`;
    }
    if (typeof key === "object") {
      const field_name = key["_like"]["_field"];
      const field_value = key["_like"]["_value"];
      const label = enum_getter(field_name, field_value, _for);
      return `<b>${key["_like"]["_field"]}</b> is not <b>${label}</b>`;
    }
  }
  // For ANY of or All Of

  if ("_or" in _value || "_and" in _value) {
    const field_op = "_and" in _value ? "is" : "is any of";

    if ("_and" in _value) {
      key = _value["_and"];
    } else {
      key = _value["_or"];
    }
    let values = [];

    for (const _key of key) {
      const field_value = _key["_like"]["_value"];
      values.push(field_value);
    }

    return `<b>${key[0]["_like"]["_field"]}</b> ${field_op} <b>${values.map(
      (_v) => _v
    )}</b>`;
  }

  if ("_like" in _value && ("Any Of" in _value || "All Of" in _value)) {
    const field_op = "Any Of" in _value ? "is any of" : "is";
    key = _value["_like"];
    const field_name = key["_field"];
    const field_value = key["_value"];
    return `<b>${field_name}</b> ${field_op} <b>${field_value}</b>`;
  }

  return "";
}
function DateCompiler(_value) {
  let key = "";
  if ("_not" in _value) {
    //   Check if is empty
    key = _value["_not"];
    if ("_contains" in key) {
      return `<b>${key["_contains"]}</b> is <b>Empty</b>`;
    }
  }
  if ("_between" in _value) {
    key = _value["_between"];
    if ("_field" in key && "_from" in key && "_to" in key) {
      const _field_name = key["_field"];
      const from = convertToDateTimeLocal(key["_from"]).split("T")[0];
      const to = convertToDateTimeLocal(key["_to"]).split("T")[0];
      console.log(`${_field_name}__from=${from},${to}`);
      return `<b>${_field_name}</b> is between <b>${from}</b> and <b>${to}</b>`;
    }
  }
}
function BooleanCompiler(_value) {
  let key = "";

  if ("_field" in _value && "_value" in _value) {
    key = _value;
    const _field_name = key["_field"];
    const _field_value = key["_value"];
    return `<b>${_field_name}</b> is <b>${_field_value}</b>`;
  }
  return null;
}
function NumaricCompiler(_value) {
  let key = "";
  if ("_not" in _value) {
    //   Check if is empty
    key = _value["_not"];
    if ("_contains" in key) {
      return `<b>${key["_contains"]}</b> is <b>Empty</b>`;
    }
    if (typeof key === "object") {
      const [field_name, field_value] = Object.entries(key)[0];
      return `<b>${field_name}</b> is not <b>${field_value}</b>`;
    }
  }
  if ("_field" in _value && "_value" in _value) {
    key = _value;
    const _field_name = key["_field"];
    const _field_value = key["_value"];
    console.log(`${_field_name}=${_field_value}`);
    return `<b>${_field_name}</b> is <b>${_field_value}</b>`;
  }
  if ("_lt" in _value) {
    key = _value["_lt"];
    const [_field_name, _field_value] = Object.entries(key)[0];
    return `<b>${_field_name}</b> less than <b>${_field_value}</b>`;
  }
  if ("_lte" in _value) {
    key = _value["_lte"];
    const [_field_name, _field_value] = Object.entries(key)[0];
    return `<b>${_field_name}</b> less than equal <b>${_field_value}</b>`;
  }
  if ("_gt" in _value) {
    key = _value["_gt"];
    const [_field_name, _field_value] = Object.entries(key)[0];
    return `<b>${_field_name}</b> greater than <b>${_field_value}</b>`;
  }
  if ("_gte" in _value) {
    key = _value["_gte"];
    const [_field_name, _field_value] = Object.entries(key)[0];
    return `<b>${_field_name}</b> greater than equal <b>${_field_value}</b>`;
  }
  return null;
}
function Compiler(_value, dataType, _for) {
  //   Checking if its valid Object
  if (typeof _value === "object") {
    switch (dataType) {
      case datatypes_options.AUTOCOMPLETE_CHIPS.type:
        return AutotCompleteCompiler(_value, _for);
      case datatypes_options.DATE.type:
        return DateCompiler(_value);
      case datatypes_options.BOOLEAN.type:
        return BooleanCompiler(_value);
      case datatypes_options.NUMARIC.type:
        return NumaricCompiler(_value);
      case datatypes_options.MultiSelection.type:
        return MultiTextCompiler(_value, _for);
      default:
        break;
    }
  }
}

export default Compiler;
