import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import Button from "../../components/Buttons/BootStrap/Btn.js";
import InputField from "../../components/Field/BootStrap/InputField.js";
import TuneIcon from "@mui/icons-material/Tune";
import CustomTable from "../../components/Display/CustomTable.js";
import axios from "axios";
import { useState, useEffect } from "react";
import CustomTasksRow from "./components/CustomTasksRow.js";
import { insertElementAtIndex } from "../../utils/utils.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import FilterFields from "../../components/filters/FilterFields.js";
import { TaskFilterFields } from "../../utils/filters/fields.js";
import { connect } from "react-redux";

const TasksList = (props) => {
  const navigator = useNavigate();
  const [showFilter, setShowFilter] = useState(true);
  const initialPagination = { page: 1, per_page: 15 };
  const [pagination, setPagination] = useState(initialPagination);
  const [data, setData] = useState({ loading: false, data: [], count: 0 });

  const columns = [
    { id: "_id", label: "# Number", sortable: true },
    { id: "number", label: "Task", sortable: false },
    { id: "status", label: "Status", sortable: true },
    { id: "assignee", label: "Assigned to", sortable: true },
    { id: "severity", label: "Severity", sortable: true },
    { id: "_createdAt", label: "Create", sortable: true },
    { id: "tlp", label: "TLP", sortable: true },
    { id: "pap", label: "PAP", sortable: true },
  ];

  const handlePage = (event) => {
    if (event.keyCode === 13 && event.target.value) {
      fetchTasks();
    }
  };

  const fetchTasks = async (filter_query = false, get_count = false) => {
    try {
      if (!get_count) {
        setData((pre) => ({ ...pre, data: [], loading: true }));
      }

      let query = [
        {
          _name: "listTask",
        },
        {
          _name: "sort",
          _fields: [
            {
              flag: "desc",
            },
            {
              startDate: "desc",
            },
          ],
        },
        {
          _name: "page",
          from: (pagination.page - 1) * pagination.per_page,
          to: pagination.page * pagination.per_page,
          extraData: ["case", "actionRequired"],
        },
      ];
      if (get_count) {
        if (filter_query && Array.isArray(filter_query)) {
          query.splice(1, 1);
        }
        query.pop();

        query.push({ _name: "limitedCount" });
      }
      if (filter_query && Array.isArray(filter_query)) {
        if (filter_query.length < 2) {
          const element = {
            _name: "filter",
            ...filter_query[0],
          };
          query = insertElementAtIndex(query, element, 1);
        } else {
          const element = {
            _name: "filter",
            _and: [
              // {
              //   _ne: {
              //     _field: "status",
              //     _value: "Cancel",
              //   },
              // },
              ...filter_query,
            ],
          };
          query = insertElementAtIndex(query, element, 1);
        }
      }
      if (Array.isArray(filter_query)) {
        if (filter_query.length < 1) {
          query = insertElementAtIndex(
            query,
            { _name: "filter", _not: { _field: "status", _value: "Cancel" } },
            1
          );
        }
      } else if (!filter_query) {
        query = insertElementAtIndex(
          query,
          { _name: "filter", _not: { _field: "status", _value: "Cancel" } },
          1
        );
      }

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: get_count ? "mytasks.count" : "mytasks" },
        }
      );
      let d = response.data;
      if (get_count) {
        setData((pre) => ({ ...pre, count: d }));
        fetchTasks(filter_query, false);
      } else {
        setData((pre) => ({ ...pre, data: d, loading: false }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onPageChange = (e, new_page) => {
    setPagination((pre) => ({ ...pre, page: new_page }));
  };
  const TaskStatusUpdate = (_id, _status) => async () => {
    let status = "";
    switch (_status) {
      case "waiting":
        status = "InProgress";
        break;
      case "inprogress":
        status = "Completed";
        break;
      case "completed":
        status = "InProgress";
        break;
      default:
        status = "Cancel";
        break;
    }
    if (status) {
      try {
        const response = await axios.patch(`/cms/api/case/task/${_id}`, {
          status: status,
        });
        if (response.status === 200) {
          fetchTasks(false, true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchTasks(false, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line
  }, [pagination.page]);

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={3.5}>
      {/* Title & screens btns */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h5" fontWeight="bold">
            <IconButton onClick={() => navigator(-1)}>
              <ArrowBackIosIcon color="primary" />
            </IconButton>
            List of Tasks ({data.count ? data.data.length : 0} of{" "}
            {data.count ? data.count : 0})
          </Typography>
          <Typography color="#A7BBCE" variant="caption">
            {"Dashboard > List of Tasks"}
          </Typography>
        </Box>

        <Box display="flex" gap={1.25} alignItems="center">
          <Button
            text="Filter"
            muiSVG={<TuneIcon sx={{ mr: 1 }} fontSize="small" />}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
            variant={"primary"}
            sx={{
              borderColor: "#003272",
              color: !showFilter ? "primary.dark" : "#fff",
              bgcolor: showFilter ? "#003272" : "transparent",
            }}
          />
          <Typography variant="caption" fontWeight={500} color="textPrimary">
            Per Pages
          </Typography>

          <InputField
            type="number"
            onKeyDown={handlePage}
            value={pagination.per_page}
            onChange={(e) =>
              setPagination((pre) => ({
                ...pre,
                per_page: parseInt(e.target.value),
              }))
            }
            sx={{ maxWidth: 121 }}
          />
        </Box>
      </Box>
      {/* Filter & sort Buttons */}
      <Collapse in={showFilter}>
        {/* <FiltersFields onSearch={fetchTasks} /> */}
        <FilterFields
          filterField={TaskFilterFields}
          onSearch={fetchTasks}
          _for="tasks"
        />
      </Collapse>
      {/* Table */}
      <Box
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={2.5}
        sx={{ borderRadius: 1.5 }}
        height="100%"
      >
        <CustomTable
          columns={columns}
          data={data.data}
          CustomTableRow={CustomTasksRow}
          loading={data.loading}
          TaskStatusUpdate={TaskStatusUpdate}
          onPageChange={onPageChange}
          page={pagination.page}
          permissions={props.permissions}
          total_count={Math.ceil(data.count / pagination.per_page)}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions,
  };
};

export default connect(mapStateToProps, null)(TasksList);
