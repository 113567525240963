import { CustomTableCell } from "../../../components/Display/CustomTable";
import React, { useState, useEffect } from "react";
import {
  TableRow,
  Box,
  Typography,
  Button,
  Tooltip,
  ButtonBase,
  Checkbox,
} from "@mui/material";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import { formatCreatedAt } from "../../../utils/utils";
import DeleteIcone from "../../../static/icons/delete-icon.svg";
import DeleteTaskDialog from "./DeleteTaskDialog";
import { case_tasks_states } from "../../Tasks/enums/enums";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import cms from "../../../config/axiosConfig";
import { toast } from "react-toastify";
import { connect } from "react-redux";

export const AssigneeAutoComplete = ({
  id,
  onUpdateAssignee,
  onClose,
  organisation,
  defaultValue = null

}) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchAssigneeOptions = async () => {
      try {
        const query = [
          {
            _name: "getTask",
            idOrName: id,
          },
          {
            _name: "assignableUsers",
          },
          {
            _name: "filter",
            _field: "locked",
            _value: false,
          },
          {
            _name: "sort",
            _fields: [
              {
                name: "asc",
              },
            ],
          },
        ];
        const response = await cms.post("/cms/api/v1/query", {
          query: query,
        });
        if (isMounted) {
          setOptions(response.data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    const fetchUsers = async () => {
      try {
        let query = [
          {
            _name: "getOrganisation",
            idOrName: organisation,
          },
          {
            _name: "users",
          },
          {
            _name: "sort",
            _fields: [
              {
                login: "asc",
              },
            ],
          },
        ];

        const response = await cms.post(
          "/cms/api/v1/query",
          {
            query: query,
          },
          {
            params: {
              name: "organisation-users",
            },
          }
        );
        let d = response.data;

        setOptions(d);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (open) {
      if (id) {
        fetchAssigneeOptions();
      } else {
        fetchUsers();
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [open]);

  return (
    <Autocomplete
      sx={{ minWidth: 100 }}
      size="small"
      options={options || []}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      defaultValue={defaultValue}
      getOptionLabel={(option) => `${option.organisation} - ${option.name}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Assignee"
          placeholder="Select assignee"
          variant="outlined"
          sx={{ fontSize: 12 }}
          fullWidth
        />
      )}
      onChange={(event, newValue) => {
        onUpdateAssignee(newValue);
      }}
    />
  );
};

const CustomDetailRow = ({
  row,
  isSelected,
  onSelect,
  refrashFunc,
  onDetailOpen,
  permissions,
  resetDetailPage,
  organisation,
}) => {
  const [deleteBox, showDeleteBox] = useState(false);
  const [editingAssignee, setEditingAssignee] = useState(false);

  const updateField = async (filed_name, value, field_id = "") => {
    try {
      const response = await axios.patch(`/cms/api/case/task/${row._id}`, {
        [filed_name]: value,
      });
      if (response.status === 200) {
        toast.success(`Successfuly updated "${filed_name}"`);
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };
  // DeleteTaskDialog
  const handleRowClick = () => {
    onDetailOpen(row._id);
    // onSelect(row._id);
  };
  const handleAssigneeClick = (e) => {
    e.stopPropagation();
    setEditingAssignee(true);
  };

  const handleAssigneeUpdate = (newAssignee) => {
    if (newAssignee.login) {
      updateField("owner", newAssignee.login);
    }
    setEditingAssignee(false);
    refrashFunc();
  };
  const GetNextStatusTitle = () => {
    switch (row.status.toLowerCase()) {
      case "waiting":
        return "Click to start";
      case "inprogress":
        return "Click to cLose";
      case "completed":
        return "Click to reopen";
      default:
        return "Click to start";
    }
  };
  const letNextLable = GetNextStatusTitle();
  const TaskStatusUpdate = async () => {
    let status = "";
    switch (row.status.toLowerCase()) {
      case "waiting":
        status = "InProgress";
        break;
      case "inprogress":
        status = "Completed";
        break;
      case "completed":
        status = "InProgress";
        break;
      default:
        break;
    }
    if (status) {
      try {
        const response = await axios.patch(`/cms/api/case/task/${row._id}`, {
          status: status,
        });
        if (response.status === 200) {
          refrashFunc();
          resetDetailPage(row.title);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  return (
    <TableRow
      sx={{
        borderRadius: "4px",
        minHeight: 76,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        "& .MuiTableCell-root": {
          verticalAlign: "middle ",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),
      }}
      onClick={handleRowClick}
    >
      <CustomTableCell
        onClick={(e) => {
          e.stopPropagation();
          onSelect(row._id);
        }}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();
            onSelect(row._id);
          }}
          size="small"
          checked={isSelected}
        />
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <ButtonBase
          onClick={() => {
            onDetailOpen(row._id);
          }}
        >
          <Typography color="#1173FF" variant="caption" fontWeight={700}>
            {row.title}
          </Typography>
        </ButtonBase>
      </CustomTableCell>

      <CustomTableCell sx={{ minWidth: 200 }}>
        {row?.startDate && (
          <Typography variant="caption" fontWeight={500}>
            <PlayCircleFilledRoundedIcon
              fontSize="small"
              sx={{ color: "#2497F3" }}
            />
            {formatCreatedAt(row?.startDate)}
          </Typography>
        )}
      </CustomTableCell>
      <CustomTableCell
        sx={{ minWidth: 260 }}
        onClick={(e) => e.stopPropagation()}
      >
        {editingAssignee ? (
          <AssigneeAutoComplete
            id={row._id}
            onClose={() => setEditingAssignee(false)}
            onUpdateAssignee={handleAssigneeUpdate}
            organisation={organisation}
          />
        ) : (
          <div
            onClick={(e) => {
              if (permissions.mTasks) {
                handleAssigneeClick(e);
              }
            }}
          >
            {row?.assignee ? (
              <Typography variant="caption" fontWeight={500}>
                {row?.assignee}
              </Typography>
            ) : (
              <Typography variant="caption" fontWeight={500} color="#A5A5A5">
                <i>{"Not Assigned"}</i>
              </Typography>
            )}
          </div>
        )}
      </CustomTableCell>
      <CustomTableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{ verticalAlign: "top", minWidth: 200 }}
      >
        <Box display="flex" gap={"22px"}>
          <Button
            disabled={!permissions.mTasks || row.status === "Cancel"}
            size="small"
            startIcon={
              <Box component="img" src={DeleteIcone} width={18} height={18} />
            }
            onClick={(e) => {
              e.stopPropagation();
              showDeleteBox(true);
              resetDetailPage(row.title);
            }}
            sx={{ color: "#FF3E3E" }}
          >
            Delete
          </Button>
          <DeleteTaskDialog
            open={deleteBox}
            noDetail
            setOpen={showDeleteBox}
            taskId={row._id}
            refrashFunc={() => {
              refrashFunc(false, true);
            }}
          />
          <Tooltip title={letNextLable}>
            <Button
              disabled={!permissions.mTasks}
              size="small"
              onClick={() => {
                TaskStatusUpdate();
              }}
              startIcon={case_tasks_states[row.status.toLowerCase()].icon}
            >
              {row.status === "Waiting"
                ? "Click to start"
                : row.status === "InProgress"
                  ? "Click to close"
                  : row.status === "Completed"
                    ? "Click to Reopen"
                    : row.status}
            </Button>
          </Tooltip>
        </Box>
      </CustomTableCell>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state.cms_user?.organisation,
  };
};

export default connect(mapStateToProps, null)(CustomDetailRow);
