import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import Btn from "../../../components/Buttons/BootStrap/Btn";
import PlusIcon from "../../../static/icons/plus-icon.svg";
import { useState } from "react";
import axios from "axios";
import { sleep } from "../../../utils/utils";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { AssigneeAutoComplete } from "./CustomTasksRow";
import RichTextEditor from "../../../components/Field/RichTextEditor";
import { organisation } from "../../../utils/filters/fields";

export default function CreateCaseTaskDialogBox({
  open,
  setOpen,
  caseId,
  refrashFunc,
}) {
  const handleClose = () => {
    setOpen(false);
    setFields(initialFields);
  };
  const initialFields = {
    group: "",
    status: "Waiting",
    title: "",
    owner: "",
    description: "",
  };
  const [fields, setFields] = useState(initialFields);

  const [alert, setAlert] = useState({
    show: false,
    severity: "success",
    text: "",
  });

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields((pre) => ({ ...pre, [name]: value }));
  };
  const updateField = async (filed_name, _id, value) => {
    try {
      const response = await axios.patch(`/cms/api/case/task/${_id}`, {
        [filed_name]: value,
      });
      if (response.status === 200) {
        refrashFunc(false, true);
        // toast.success(`Successfuly updated "${filed_name}"`);
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };
  // andle Submit
  const validate = () => {
    let valid = true;
    let message = "";
    console.log(fields.owner, !fields.owner);
    if (!fields.owner) {
      message = "Assignee is required";
      valid = false;
    } else if (!fields.title) {
      message = "Title is required";
      valid = false;
    } else if (!fields.description) {
      message = "Description is required";
      valid = false;
    }

    if (!valid) {
      setAlert({ show: true, severity: "error", text: message });
    } else {
      setAlert({ show: false, severity: "error", text: "" });
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    const sleepMe = async () => {
      await sleep(3000);
    };
    if (validate()) {
      let _fields = fields;
      const owner = _fields["owner"];
      delete _fields["owner"];
      await axios
        .post(`/cms/api/case/~${caseId}/task?`, fields, {
          params: { flag: false },
        })
        .then((res) => {
          if (res.status > 199) {
            toast.success("Task added successfuly.");
            updateField("owner", res.data._id, owner);
            setAlert({ show: true, severity: "success", text: "Task Added" });
            setFields(initialFields);
            // refrashFunc();
            sleepMe();
            setOpen(false);
          }
        })
        .catch((err) => {
          toast.error(`Something is wrong. ${err}`);
          setAlert({
            show: true,
            severity: "error",
            text: "Something is wrong",
          });
        });
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" fontWeight={700}>
            Add Task
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
              setFields(initialFields);
            }}
          >
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Snackbar
          open={alert.show}
          autoHideDuration={6000}
          onClose={() => {
            setAlert((pre) => ({ ...pre, show: false }));
          }}
        >
          <Alert
            onClose={() => {
              setAlert((pre) => ({ ...pre, show: false }));
            }}
            severity={alert.severity}
            sx={{ width: "100%" }}
          >
            {alert.text}
          </Alert>
        </Snackbar>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Grid container item spacing={2.5}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" gap={1.25}>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Assignee*
                </Typography>
                <AssigneeAutoComplete
                  organisation={organisation()}
                  onClose={() => { }}
                  onUpdateAssignee={(value) => {
                    setFields((pre) => ({ ...pre, owner: value.login }));
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item xs={6}>
              <Box display="flex" flexDirection="column" gap={1.25}>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Task Group*
                </Typography>
                <InputField
                  placeholder="Enter task group..."
                  name="title"
                  fullWidth
                  value={fields.title}
                  onChange={handleFieldChange}
                />
              </Box>
            </Grid> */}

            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" gap={1.25}>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Task Title*
                </Typography>
                <Box
                  component={"textarea"}
                  name="title"
                  sx={{
                    backgroundColor: "transparent",
                    minHeight: "80px",
                    fontSize: "14px",
                    padding: "8px",
                    border: "1px solid #ECF0F4",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  value={fields.title}
                  onChange={handleFieldChange}
                  placeholder="Type Task Title..."
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" gap={1.25}>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Task Description*
                </Typography>
                <RichTextEditor
                  setValue={(value) => {
                    setFields((pre) => ({ ...pre, description: value }));
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="body2" fontWeight={400} color="#000000">
            * Required field
          </Typography>

          <Btn
            onClick={handleSubmit}
            text="Add Task"
            startIcon={PlusIcon}
            height={50}
            fontSize={20}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
