import { Box, Button, Grid, Typography, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeakRemoveIcon from "@mui/icons-material/LeakRemove";
import wazuh from "../../../../config/wazuh";
import InventoryTable from "./InventoryTable";
import RefreshIcon from "@mui/icons-material/Refresh";
import SystemUpdateAltRoundedIcon from "@mui/icons-material/SystemUpdateAltRounded";
import FilterFields from "./components/Fields/FilterFields";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SensorsOffIcon from "@mui/icons-material/SensorsOff";
import SensorsIcon from "@mui/icons-material/Sensors";
import { convertToCSV } from "../../../../utils/utils";

function InventoryDeatil({ setTableData, setGraphs, policy_id, agentId }) {
  // const navigation = useNavigate();
  const [pagination, setPagination] = useState({
    from: 0,
    size: 10,
  });
  const inititalLogs = {
    invalid: 0,
    end_scan: "2023-09-30T19:34:19+00:00",
    fail: 0,
    pass: 0,
    name: "CIS Apple macOS 13.0 Ventura Benchmark v1.0.0",
    score: 0,
    start_scan: "2023-09-30T19:34:19+00:00",
  };
  const [logs, setLogs] = useState(inititalLogs);
  const [data, setData] = useState({ loading: false, data: [], count: 0 });

  const handlePageChange = (newFrom, newSize) => {
    setPagination({ from: newFrom, size: newSize });
  };
  const Fetchdata = async (
    from = pagination.from,
    size = pagination.size,
    filters = []
  ) => {
    setData((pre) => ({ ...pre, loading: true }));
    let params = { offset: from, limit: size, sort: "+id" };
    filters.map((f) => {
      const key = Object.keys(f)[0];
      params[key] = f[key];
      return "";
    });
    await wazuh
      .get(`/sca/${agentId}/checks/${policy_id}`, {
        params: params,
      })
      .then((res) => {
        setData((pre) => ({
          ...pre,
          data: res.data.data.affected_items,
          count: res.data.data.total_affected_items,
          loading: false,
        }));
      })
      .catch(() => {
        setData((pre) => ({ ...pre, loading: false }));
      });
  };
  const handleRefresh = () => {
    const fetchDetail = async () => {
      await wazuh
        .get(`/sca/${agentId}`, { params: { q: `policy_id=${policy_id}` } })
        .then((res) => {
          setLogs(res.data.data.affected_items[0]);
        });
    };
    Fetchdata();
    fetchDetail();
  };
  function handleExportCSV() {
    const csv = convertToCSV(data.data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  useEffect(() => {
    Fetchdata();
    // eslint-disable-next-line
  }, [pagination.from, pagination.size]);

  useEffect(() => {
    const headers = ["Id", "Title", "Target", "Result"]
    const body = []
    for (const row of data.data) {
      body.push([row["id"], row["title"], "Command: " + row["command"], row["result"]?.toUpperCase()])
    }
    setTableData({ headers: [headers], body: body })
    const report_graphs = [
      {
        id: "inventory_table",
        label: "Inventory",
      }]
    setGraphs(report_graphs)
  }, [data.data])

  useEffect(() => {
    const fetchDetail = async () => {
      await wazuh
        .get(`/sca/${agentId}`, { params: { q: `policy_id=${policy_id}` } })
        .then((res) => {
          setLogs(res.data.data.affected_items[0]);
        });
    };
    fetchDetail();
    // eslint-disable-next-line
  }, []);

  return (
    <Box mt={2}>
      {/* Cards */}
      <Grid
        container
        spacing={{ xs: 2, lg: 0 }}
        justifyContent={{ xs: "space-between", lg: "center" }}
      >
        <Grid container item justifyContent="center" mt={1.5} spacing={2.5} id="inventory_table">
          <Grid container item xs>
            <Box
              display="felx"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              component={Paper}
              width="100%"
              elevation={0}
              gap={2.5}
              p={2}
              borderRadius={2}
            >
              <Box display="flex" gap={1} justifyContent="space-between">
                <Typography variant="subtitle2">Passed</Typography>
                <SensorsIcon color="success" />
              </Box>
              <Typography variant="h5" fontWeight={600} color="primary">
                {logs?.pass}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs>
            <Box
              display="felx"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              component={Paper}
              width="100%"
              elevation={0}
              gap={2.5}
              p={2}
              borderRadius={2}
            >
              <Box display="flex" gap={1} justifyContent="space-between">
                <Typography variant="subtitle2">Failed</Typography>
                <SensorsOffIcon color="error" />
              </Box>
              <Typography variant="h5" fontWeight={600} color="primary">
                {logs?.fail}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs>
            <Box
              display="felx"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              component={Paper}
              width="100%"
              elevation={0}
              gap={2.5}
              p={2}
              borderRadius={2}
            >
              <Box display="flex" gap={1} justifyContent="space-between">
                <Typography variant="subtitle2">Not applicable</Typography>
                <HourglassBottomIcon color="warning" />
              </Box>
              <Typography variant="h5" fontWeight={600} color="primary">
                {logs?.invalid}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs>
            <Box
              display="felx"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              component={Paper}
              width="100%"
              elevation={0}
              gap={2.5}
              p={2}
              borderRadius={2}
            >
              <Box display="flex" gap={1} justifyContent="space-between">
                <Typography variant="subtitle2">Score</Typography>
                <LeakRemoveIcon color="primary" />
              </Box>
              <Typography variant="h5" fontWeight={600} color="primary">
                {logs?.score} %
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs>
            <Box
              display="felx"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              component={Paper}
              width="100%"
              elevation={0}
              gap={2.5}
              p={2}
              borderRadius={2}
            >
              <Box display="flex" gap={1} justifyContent="space-between">
                <Typography variant="subtitle2">End scan</Typography>
                <HowToRegIcon sx={{ color: "primary.light" }} />
              </Box>
              <Typography variant="h6" fontWeight={600} color="primary">
                {new Date(logs?.end_scan)
                  .toLocaleString()
                  .replace("/", "-")
                  .replace(",", " @ ")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Box mb={2} mt={2}>
          <Typography variant="h5" fontWeight="bold">Checks ({data.count} of {data.data?.length})</Typography>
          <br />
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              disabled={data.loading}
              onClick={handleRefresh}
              startIcon={<RefreshIcon />}
            >
              Refresh
            </Button>
            <Button
              disabled={data.loading || data.data.length < 1}
              onClick={handleExportCSV}
              startIcon={<SystemUpdateAltRoundedIcon />}
            >
              Export formated
            </Button>
          </Box>
          <FilterFields
            onSearch={(filters) => {
              Fetchdata(undefined, undefined, filters);
            }}
            agentId={agentId}
            policy_id={policy_id}
          />
        </Box>
        <InventoryTable
          rows={data.data}
          loading={data.loading}
          pagination={pagination}
          handlePageChange={handlePageChange}
          total_count={data.count}
        />
      </Box>
    </Box>
  );
}

export default InventoryDeatil;
