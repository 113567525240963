import * as React from "react";
import Menu from "@mui/material/Menu";
import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { datatypes_options } from "../../../utils/filters/utils";
import { useState } from "react";
import FooterButtons from "./fixed/FooterButtons";

export default function MultiSelection({
  field,
  anchorEl,
  setAnchorEl,
  onChange,
}) {
  const open = Boolean(anchorEl);
  const [valid, setValid] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    JSON.stringify({ _not: { _contains: field.name } })
  );

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onFilterApply = () => {
    onChange(selectedValue);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      dense
      sx={{ maxWidth: 450 }}
      onClose={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            width: "100%",
            minHeight: 300,
            borderRadius: 1,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ml: 1,
            p: 2,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        },
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <FormControl fullWidth>
        <FormLabel>{field.label}</FormLabel>
        <RadioGroup
          sx={{
            justifyContent: "space-between",
            "& .MuiFormControlLabel-root": {
              minWidth: 150,
            },
          }}
          name="radio-buttons-group"
          value={selectedValue}
        >
          {Object.values(datatypes_options.MultiSelection.operators).map(
            (op, index) =>
              op({
                field: field,
                selectedValue: selectedValue,
                setValue: setSelectedValue,
                setValid: setValid,
              })
          )}
        </RadioGroup>
      </FormControl>
      <FooterButtons disabled={!valid} onApply={onFilterApply} />
    </Menu>
  );
}
