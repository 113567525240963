import React from 'react';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { getRandomColor } from '../../utils/utils';


const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const renderLegend2 = (props) => {
    const { payload } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 120, marginBottom: 1 }}>
                <Typography variant="body2">Value</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body2">Percent</Typography>
            </Box>
            {payload.map((entry, index) => (
                <Tooltip title={entry.payload.name} key={`item-${index}`}>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', width: 120, marginBottom: 0.1, borderBottom: "0.5px solid #ababab" }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <svg width="10" height="10">
                                <rect width="10" height="10" fill={entry.color} />
                            </svg>
                            <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                                {entry.payload.name}
                            </Typography>
                        </Box>
                        <Typography variant="body2">{`${entry.payload.percent}%`}</Typography>
                    </Box>
                </Tooltip>
            ))}
        </Box>
    );
};

const PieChartPercentage = ({ data, graphHeight = "100%", innerRadius = 0, fill = getRandomColor(), sx }) => {
    // Compute percentages for each data entry

    return (
        <Box
            sx={{
                width: '100%',
                height: graphHeight,
                ...sx,
            }}
        >
            <ResponsiveContainer width="100%" height="100%">
                {data.length < 1 ?
                    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                        <Typography>No Data</Typography>
                    </Box>
                    :
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            // label={renderCustomizedLabel}
                            outerRadius="80%"
                            fill={fill}
                            dataKey="value"
                            innerRadius={innerRadius}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Legend
                            verticalAlign="middle"
                            align="right"
                            layout="vertical"
                            iconType="circle"
                            content={renderLegend2}
                        />
                    </PieChart>
                }
            </ResponsiveContainer>
        </Box>
    );
};

export default PieChartPercentage;
