import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Box,
  ButtonBase,
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import InputField from "../../../components/Field/BootStrap/InputField";
import { pap_enum, severity_enum, tlp_enum } from "../enums/enums";
import CircleIcon from "@mui/icons-material/Circle";
import CancelIcon from "@mui/icons-material/Cancel";
import Btn from "../../../components/Buttons/BootStrap/Btn";
import PlusIcon from "../../../static/icons/plus-icon.svg";
import ReactScrollbar from "react-scrollbar";
import { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DateTimeField from "../../../components/Field/DateTimeField";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function CreateCaseDialog({ open, setOpen }) {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    setFields({
      title: "",
      startDate: dayjs(),
      description: "",
    });
    setSeverity(1);
    setTlp(0);
    setPap(1);
    setCasesTasks([]);
    setTags([]);
  };
  const [fields, setFields] = useState({
    title: "",
    startDate: dayjs(),
    description: "",
  });
  const [severity, setSeverity] = useState(1);
  const [tlp, setTlp] = useState(0);
  const [pap, setPap] = useState(1);
  const [casesTasks, setCasesTasks] = useState([]);
  const [tags, setTags] = useState([]);
  const inputTagsRef = useRef(null);
  const inputCasesTaskRef = useRef(null);
  const [alert, setAlert] = useState({
    show: false,
    severity: "success",
    text: "",
  });

  // Add  functions
  const addTag = () => {
    const trimmedValue = inputTagsRef.current.value.trim();
    if (trimmedValue && !tags.includes(trimmedValue)) {
      setTags([...tags, trimmedValue]);
      inputTagsRef.current.value = "";
    } else if (tags.includes(trimmedValue)) {
      toast.warning(`${trimmedValue} already exist`);
    }
  };
  const addCasesTask = () => {
    const trimmedValue = inputCasesTaskRef.current.value.trim();
    if (trimmedValue && !casesTasks.includes(trimmedValue)) {
      setCasesTasks([...casesTasks, trimmedValue]);
      inputCasesTaskRef.current.value = "";
    } else if (casesTasks.includes(trimmedValue)) {
      toast.warning(`${trimmedValue} already exist`);
    }
  };
  const ADDTLP = (value) => {
    setTlp(value);
  };
  const ADDPAP = (value) => {
    setPap(value);
  };
  const ADDSeverity = (value) => {
    setSeverity(value);
  };
  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields((pre) => ({ ...pre, [name]: value }));
  };

  // Remove  functions
  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };
  const removeCasesTask = (taskToRemove) => {
    setCasesTasks(casesTasks.filter((task) => task !== taskToRemove));
  };
  const handleDateChange = (value) => {
    console.log(value);
    setFields((pre) => ({ ...pre, startDate: value }));
  };
  const ResetFields = () => {
    handleClose();
  };
  // andle Submit
  const validate = () => {
    let valid = true;
    let message = "";
    if (!fields.title) {
      message = "Title is required";
      valid = false;
    } else if (!fields.description) {
      message = "Description is required";
      valid = false;
    } else if (!fields.startDate) {
      message = "Set date is required";
      valid = false;
    }
    // else if (tags.length < 1) {
    //   message = "Add atleast one tag";
    //   valid = false;
    // }
    // else if (casesTasks.length < 1) {
    //   message = "Atleast one case task should add";
    //   valid = false;
    // }
    else if (tlp === "") {
      message = "Select one TLP";
      valid = false;
    } else if (pap === "") {
      message = "Select one PAP";
      valid = false;
    } else if (severity === "") {
      message = "Select one severity";
      valid = false;
    }

    if (!valid) {
      setAlert({ show: true, severity: "error", text: message });
    } else {
      setAlert({ show: false, severity: "error", text: "" });
    }

    return valid;
  };
  const handleSubmit = async (e) => {
    if (validate()) {
      const _fields = {
        status: "Open",
        severity: severity,
        tlp: tlp,
        pap: pap,
        startDate:
          typeof fields.startDate === "object"
            ? fields.startDate.unix() * 1000
            : fields.startDate,
        title: fields.title,
        description: fields.description,
        tags: tags,
        tasks: [],
      };
      for (let t in casesTasks) {
        const task = {
          title: casesTasks[t],
          flag: false,
          status: "Waiting",
        };
        _fields.tasks.push(task);
      }
      try {
        await axios.post("/cms/api/case", _fields).then((res) => {
          toast.success("Case created successfuly.");
          navigate(`/case-detail/${res.data.id.replace("~", "")}`);
          setOpen(false);
          ResetFields();
        });
      } catch {
        toast.error("something went wrong.");
      }
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h5" fontWeight={700}>
              Create a new case
            </Typography>
            <Typography variant="caption" color="#A7BBCE">
              Case details
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <Snackbar
            open={alert.show}
            autoHideDuration={6000}
            onClose={() => {
              setAlert((pre) => ({ ...pre, show: false }));
            }}
          >
            <Alert
              onClose={() => {
                setAlert((pre) => ({ ...pre, show: false }));
              }}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {alert.text}
            </Alert>
          </Snackbar>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container item spacing={2.5}>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column" gap={1.25}>
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Title*
                  </Typography>
                  <InputField
                    placeholder="Type case title here..."
                    fullWidth
                    name="title"
                    value={fields.title}
                    onChange={handleFieldChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column" gap={1.25}>
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Select Date*
                  </Typography>

                  <DateTimeField
                    onChange={handleDateChange}
                    value={fields.startDate}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" gap={1.25}>
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Severity*
                  </Typography>
                  <ButtonGroup
                    size="large"
                    sx={{
                      border: "1px solid #ECF0F4",
                      height: "40px",
                      "& .MuiButtonBase-root": {
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      },
                    }}
                    aria-label="large button group"
                  >
                    {Object.keys(severity_enum).map((sv) => (
                      <Button
                        startIcon={
                          typeof severity_enum[sv].icon === "string" ? (
                            <img
                              src={severity_enum[sv].icon}
                              alt={`Icon for ${severity_enum[sv].value}`}
                              width="20px"
                              height="20px"
                            />
                          ) : (
                            severity_enum[sv].icon
                          )
                        }
                        onClick={() => {
                          ADDSeverity(severity_enum[sv]._value);
                        }}
                        variant="text"
                        key={severity_enum[sv].value}
                        fullWidth
                        sx={{
                          color:
                            severity === severity_enum[sv]._value &&
                            "#fff !important",
                          "&:hover": {
                            color: "black !important",
                          },
                          backgroundColor:
                            severity === severity_enum[sv]._value && "#003272",
                        }}
                      >
                        {severity_enum[sv].value}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
              </Grid>
              <Grid container item spacing={"43px"}>
                <Grid item xs={6}>
                  <Box display="flex" flexDirection="column" gap={1.25}>
                    <Typography variant="body2" fontWeight="bold" color="#000">
                      TLP*
                    </Typography>
                    <Stack direction="row" spacing={1.25}>
                      {tlp_enum.map((item) => (
                        <ButtonBase
                          key={item.title}
                          onClick={() => {
                            ADDTLP(item.value);
                          }}
                        >
                          <Chip
                            sx={{
                              minWidth: "82px",
                              backgroundColor:
                                tlp === item.value ? "#003272" : item.bgColor,
                              color: tlp === item.value && "#fff",
                            }}
                            icon={
                              <CircleIcon
                                fontSize="small"
                                style={{ color: item.color }}
                              />
                            }
                            label={item.title}
                          />
                        </ButtonBase>
                      ))}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" flexDirection="column" gap={1.25}>
                    <Typography variant="body2" fontWeight="bold" color="#000">
                      PAP*
                    </Typography>
                    <Stack direction="row" spacing={1.25}>
                      {pap_enum.map((item) => (
                        <ButtonBase
                          key={item.title}
                          onClick={() => {
                            ADDPAP(item.value);
                          }}
                        >
                          <Chip
                            sx={{
                              minWidth: "82px",
                              backgroundColor:
                                pap === item.value ? "#003272" : item.bgColor,
                              color: pap === item.value && "#fff",
                            }}
                            icon={
                              <CircleIcon
                                fontSize="small"
                                style={{ color: item.color }}
                              />
                            }
                            label={item.title}
                          />
                        </ButtonBase>
                      ))}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: "#ECF0F4", p: "15px 14px" }}>
                  <Grid container direction="column" spacing={1.25}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="#000"
                        onClick={addTag}
                      >
                        Add Tags
                      </Typography>
                    </Grid>
                    <Grid container item spacing={1.25} alignItems="center">
                      <Grid item xs>
                        <InputField
                          placeholder="Enter case tags"
                          fullWidth
                          inputRef={inputTagsRef}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") addTag();
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          sx={{
                            height: "40px",
                            backgroundColor: "#1173FF",
                            minWidth: 100,
                          }}
                          onClick={addTag}
                        >
                          Add
                        </Button>
                      </Grid>
                      {/* <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ height: "40px", backgroundColor: "#13D38E" }}
                        >
                          Tag library
                        </Button>
                      </Grid> */}

                      <Grid item container spacing={1.25}>
                        {tags.map((tag, i) => (
                          <Grid item key={tag}>
                            <Box
                              sx={{
                                height: "20px",
                                p: "5px",
                                borderRadius: "2px 0px 0px 2px",
                                borderLeft: "5px solid #13D38E",
                              }}
                              display="flex"
                              alignItems="center"
                              gap="4px"
                              bgcolor={"#ECF0F4"}
                            >
                              {tag}
                              <IconButton
                                size="small"
                                onClick={() => removeTag(tag)}
                              >
                                <CancelIcon sx={{ fontSize: "15px" }} />
                              </IconButton>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" gap={1.25}>
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Description*
                  </Typography>
                  <Box
                    component={"textarea"}
                    sx={{
                      backgroundColor: "transparent",
                      minHeight: "80px",
                      fontSize: "14px",
                      padding: "8px",
                      border: "1px solid #ECF0F4",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    name="description"
                    value={fields.description}
                    onChange={handleFieldChange}
                    placeholder="Type case description here..."
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: "#ECF0F4",
                    p: "15px 14px",
                  }}
                >
                  <Grid container direction="column" spacing={1.25}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="#000"
                      >
                        Add Tasks
                      </Typography>
                    </Grid>
                    <Grid container item spacing={1.25} alignItems="center">
                      <Grid item xs>
                        <InputField
                          placeholder="Enter Task Name"
                          fullWidth
                          inputRef={inputCasesTaskRef}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") addCasesTask();
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={addCasesTask}
                          sx={{ height: "40px", backgroundColor: "#1173FF" }}
                        >
                          Add Task
                        </Button>
                      </Grid>

                      <Grid item container spacing={1.25}>
                        {casesTasks.map((tag, i) => (
                          <Grid item key={tag}>
                            <Chip
                              label={tag}
                              variant="outlined"
                              size="small"
                              sx={{
                                borderColor: "#1173FF",
                                color: "#1173FF",
                                "& .MuiSvgIcon-root": {
                                  color: "black",
                                },
                              }}
                              onDelete={() => {
                                removeCasesTask(tag);
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="body2" fontWeight={400} color="#000">
            * Required field
          </Typography>
          <Btn
            onClick={handleSubmit}
            text="Create Case"
            startIcon={PlusIcon}
            height={50}
            fontSize={20}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
