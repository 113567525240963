import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Users from "./Tabs/Users";
import cms from "../../../config/axiosConfig";
import { toast } from "react-toastify";
import PlayBooks from "./Tabs/PlayBooks.js";
import Configration from "./Tabs/Configration.js";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function OrganizationTabs() {

  const { orgName } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");
  const initialTabValue = tabParam ? parseInt(tabParam, 10) : 0;
  const [value, setValue] = useState(initialTabValue);

  const [organization, setOrganization] = useState({
    data: {},
    loading: false,
  });

  useEffect(() => {
    const fecthAttachments = async () => {
      setOrganization((pre) => ({ ...pre, loading: true }));
      try {
        const response = await cms.get(`/cms/api/organisation/${orgName}`);
        let d = response.data;
        setOrganization((pre) => ({ ...pre, data: d }));
      } catch (error) {
        toast.error("Error fetching data:", error);
      } finally {
        setOrganization((pre) => ({ ...pre, loading: false }));
      }
    };
    fecthAttachments()
  }, []);

  useEffect(() => {
    setValue(initialTabValue);
  }, [initialTabValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <Box mt={1} display="flex" flexDirection="column">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Users" />
            <Tab label="Playbook" />
            <Tab label="Configratons" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Users orgName={orgName} organization={organization} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PlayBooks orgName={orgName} organization={organization} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Configration />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

export default OrganizationTabs;
