import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Pagination,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactJson from "react-json-view";

function JsonView({ row }) {
  return (
    <Box sx={{ "& .react-json-view": { borderRadius: 1 } }}>
      <ReactJson
        collapsed={2}
        src={row}
        collapseStringsAfterLength={20}
        theme="monokai"
        displayDataTypes={false}
        enableClipboard
      />
    </Box>
  );
}
function RuleView({ row }) {
  const source = row._source;
  const [collab, handleCollab] = React.useState({
    information: false,
    detail: false,
    complaincs: false,
  });
  const handleCollbing = (key) => () => {
    handleCollab((pre) => ({ ...pre, [key]: !pre[key] }));
  };
  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Box display="flex" flexDirection="column" gap={2.5}>
        {/* INfo */}
        <Typography
          variant="subtitle1"
          sx={{ "&:hover": { textDecoration: "underline" } }}
        >
          <IconButton size="small" onClick={handleCollbing("information")}>
            {collab.information ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ArrowForwardIosIcon sx={{ fontSize: 13 }} />
            )}
          </IconButton>
          Information
        </Typography>
        <Collapse unmountOnExit in={collab.information}>
          <Grid container spacing={2.5} p={"0px 16px 0px 16px"}>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  ID
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  {source.agent.id}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  Level
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  {source.rule.level}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="subtitle2" fontWeight={600}>
                  File
                </Typography>

                <Typography variant="subtitle2" fontWeight="light"></Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  Path
                </Typography>

                <Typography variant="subtitle2" fontWeight="light"></Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  Group
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  {source.rule.groups}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
        {/* Detail */}
        <Typography
          variant="subtitle1"
          sx={{ "&:hover": { textDecoration: "underline" } }}
        >
          <IconButton size="small" onClick={handleCollbing("detail")}>
            {collab.detail ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ArrowForwardIosIcon sx={{ fontSize: 13 }} />
            )}
          </IconButton>
          Details
        </Typography>
        <Collapse unmountOnExit in={collab.detail}>
          <Grid container spacing={2.5} p={"0px 16px 0px 16px"}>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  If_sid
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  200
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  Win.system.eventID
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  {source?.data?.win?.system.eventID}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  Options
                </Typography>

                <Typography variant="subtitle2" fontWeight="light"></Typography>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
        {/* Complaincs */}
        <Typography
          variant="subtitle1"
          sx={{ "&:hover": { textDecoration: "underline" } }}
        >
          <IconButton size="small" onClick={handleCollbing("complaincs")}>
            {collab.complaincs ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ArrowForwardIosIcon sx={{ fontSize: 13 }} />
            )}
          </IconButton>
          Complaincs
        </Typography>
        <Collapse unmountOnExit in={collab.complaincs}>
          <Grid container spacing={2.5} p={"0px 16px 0px 16px"}>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  GDPR
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  200
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  GDPR
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  2
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  HIPAA
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  no_full_log
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="body2" fontWeight={600}>
                  MITRE
                </Typography>

                <Typography variant="subtitle2" fontWeight="light">
                  no_full_log
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
}
function TabelView({ row }) {
  return (
    <List
      sx={{
        bgcolor: "background.paper",
        "& .MuiTypography-root": {
          fontSize: 13,
        },
      }}
      aria-label="contacts"
    >
      {columns(row)?.map((data) => (
        <ListItem disablePadding key={data.column}>
          <ListItemButton disableRipple>
            <Grid container mb={0.25}>
              <Grid item xs={4} lg={3}>
                <Typography align="right" variant="caption">
                  {data.column}
                </Typography>
              </Grid>
              <Grid item xs={3} lg>
                <Typography align="right" variant="caption" fontWeight="light">
                  {data.value}
                </Typography>
              </Grid>
            </Grid>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 0, lg: 3 } }}>{children}</Box>}
    </div>
  );
}
const columns = (row) => {
  const source = row._source;
  return [
    { column: "_index", value: row._index },
    { column: "agent.id", value: source?.agent?.id },
    { column: "agent.ip", value: source?.agent?.ip },
    { column: "agent.name", value: source?.agent?.name },
    {
      column: "decoder.name",
      value: source?.decoder?.name,
    },
    {
      column: "full_log",
      value: source?.full_log,
    },
    {
      column: "id",
      value: source?.id,
    },
    {
      column: "input.type",
      value: source?.input?.type,
    },
    {
      column: "location",
      value: source?.location,
    },
    {
      column: "manager.name",
      value: source?.manager?.name,
    },
    {
      column: "rule.description",
      value: source?.rule?.description,
    },
    {
      column: "rule.firedtimes",
      value: source?.rule?.firedtimes,
    },
    {
      column: "rule.gdpr",
      value: source?.rule?.gdpr,
    },
    {
      column: "rule.gpg13",
      value: source?.data?.win?.system?.opcode,
    },
    {
      column: "rule.groups",
      value: source?.rule?.groups,
    },
    {
      column: "rule.hipaa",
      value: source?.rule?.hipaa,
    },
    {
      column: "rule.id",
      value: source?.rule?.id,
    },
    {
      column: "rule.level",
      value: source?.rule?.level,
    },
    {
      column: "rule.mail",
      value: source?.rule?.mail,
    },
    { column: "rule.mitre.id", value: source?.rule?.mitre?.id },
    {
      column: "rule.mitre.tactic",
      value: source?.rule?.mitre?.tactic,
    },
    {
      column: "rule.mitre.technique",
      value: source?.rule?.mitre?.technique,
    },
    { column: "rule.nist_800_53", value: source?.rule?.nist_800_53 },
    { column: "rule.pci_dss", value: source?.rule.pci_dss },
    { column: "rule.tsc", value: source?.rule.tsc },
    { column: "syscheck.arch", value: source?.syscheck?.arch },
    { column: "syscheck.event", value: source?.syscheck?.event },
    { column: "syscheck.mode", value: source?.syscheck?.mode },
    { column: "syscheck.path", value: source?.syscheck?.path },
    { column: "syscheck.sha1_after", value: source?.syscheck?.sha1_after },
    { column: "syscheck.sha256_after", value: source?.syscheck?.sha256_after },
    { column: "syscheck.value_name", value: source?.syscheck?.value_name },
    { column: "syscheck.value_type", value: source?.syscheck?.value_type },
    { column: "timestamp", value: source?.timestamp },
  ];
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ width: "10%" }}>
          {"@timestamp" in row._source
            ? row._source["@timestamp"]
            : row._source["timestamp"]}
        </TableCell>
        <TableCell style={{ width: "20%" }}>
          {row._source.syscheck?.path}
        </TableCell>
        <TableCell style={{ width: "10%" }}>
          {row._source.syscheck?.event}
        </TableCell>
        <TableCell style={{ width: "15%" }}>
          {row._source.rule?.description}
        </TableCell>
        <TableCell style={{ width: "10%" }}>
          {row._source.rule?.level}
        </TableCell>
        <TableCell style={{ width: "10%" }}>{row._source.rule?.id}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Tabel" />
                <Tab label="JSON" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <TabelView row={row} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {<JsonView row={row._source} />}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <RuleView row={row} />
            </TabPanel>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function PaginationControls({
  totalResults,
  from,
  size,
  onPageChange,
  pageSize,
  setPageSize,
}) {
  const pageCount = Math.ceil(totalResults / size);

  const handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    setPageSize(newPageSize);
    const newFrom = Math.floor(from / size) * newPageSize; // Calculate the new 'from' value
    onPageChange(newFrom, newPageSize);
  };
  const handleChange = (event, newPage) => {
    const newFrom = (newPage - 1) * size;
    onPageChange(newFrom, size);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <Pagination
        count={pageCount}
        page={from / size + 1}
        onChange={handleChange}
      />

      <Select
        size="small"
        className="rounded"
        sx={{ minWidth: 100, height: 25 }}
        value={pageSize}
        label="Row per page"
        onChange={handlePageSizeChange}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </Box>
  );
}
function extractTableData(rows) {
  // Extracting headers from the first row
  const headers = [
    "Time",
    "Agent",
    "Agent Name",
    "Technique(s)",
    "Tactic(s)",
    "Description",
    "Level",
    "Rule ID",
  ];

  // Extracting values for each row
  const body = rows.map((row) => {
    const time =
      "@timestamp" in row._source
        ? row._source["@timestamp"]
        : row._source["timestamp"];
    const syscheck_path = row._source.syscheck?.path || "";
    const syscheck_event = row._source.syscheck?.event || "";
    const rule_description = row._source.rule?.description || "";
    const rule_level = row._source.rule?.level || "";
    const rule_id = row._source.rule?.id || "";

    return [
      time,
      syscheck_path,
      syscheck_event,
      rule_description,
      rule_level,
      rule_id,
    ];
  });

  return { headers: [headers], body: body };
}
function _extractTableData(rows) {
  // Extracting headers from the first row
  const headers = [
    "Time",
    "syscheck.path",
    "syscheck.event",
    "rule.description",
    "rule.level",
    "rule.id"
  ];

  // Extracting values for each row
  const body = rows.map((row) => {
    const time =
      "@timestamp" in row._source
        ? row._source["@timestamp"]
        : row._source["timestamp"];
    const syscheck_path = row._source.syscheck?.path || "";
    const syscheck_events = row._source.syscheck?.event || "";
    const _source_rule_description = row._source.rule?.description || "";
    const _source_rule_level = row._source.rule?.level || "";
    const _source_rule_id = row._source.rule?.id || "";


    return [
      time,
      syscheck_path,
      syscheck_events,
      _source_rule_description,
      _source_rule_level,
      _source_rule_id,
    ];
  });

  return { headers: [headers], body: body };
}
export default function EventsTabs({
  data = {},
  handlePageChange,
  pagination = false,
  loading = false,
  setTableData,
}) {
  const [pageSize, setPageSize] = React.useState(pagination.size);
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    if (data.hits?.hits) {
      setRows(data.hits?.hits);
      setTableData(_extractTableData(data.hits?.hits))
      const tableData = extractTableData(rows);
      // setTableData(tableData);
    }
    // eslint-disable-next-line
  }, [data.hits?.hits]);
  return (
    <Box component={Paper}>
      {pagination && (
        <PaginationControls
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalResults={data.hits?.total?.value}
          from={pagination.from}
          size={pagination.size}
          onPageChange={handlePageChange}
        />
      )}
      <Divider my={1} />
      <TableContainer>
        {loading ? (
          <Table
            size="small"
            sx={{
              "& .MuiTableCell-root": {
                fontSize: 12,
              },
            }}
          >
            <TableHead>
              <LinearProgress color="primary" />
            </TableHead>
          </Table>
        ) : (
          <Table
            size="small"
            sx={{
              "& .MuiTableCell-root": {
                fontSize: 12,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Time</TableCell>
                <TableCell align="left">syscheck.path</TableCell>
                <TableCell align="left">syscheck.event</TableCell>
                <TableCell align="left">rule.description</TableCell>
                <TableCell align="left">rule.level</TableCell>
                <TableCell align="left">rule.id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row._id} row={row} />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Divider my={1} />
      {pagination && (
        <PaginationControls
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalResults={data.hits?.total?.value}
          from={pagination.from}
          size={pagination.size}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
  );
}
