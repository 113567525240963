import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  Box,
  Breadcrumbs,
  ButtonBase,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import InputField from "../../../components/Field/BootStrap/InputField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Btn from "../../../components/Buttons/BootStrap/Btn";
import ReactScrollbar from "react-scrollbar";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  calculateDaysFromTimestamp,
  formatCreatedAt,
} from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import MarkDownTable from "../../../components/Display/MarkDownTable/MarkDownTable";
import { toast } from "react-toastify";
export default function AlertMergeDialog({
  open,
  setOpen,
  alertName,
  alertId,
}) {
  let timeoutId = React.useRef();
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleClose = () => {
    setOpen(false);
    setsearchBy("title");
    setValue("");
    setCases([]);
    selectCase({});
  };
  const [search_by, setsearchBy] = useState("title");
  const [value, setValue] = useState("");
  const [cases, setCases] = useState([]);
  const [selectedCases, selectCase] = useState({});
  const navigator = useNavigate();

  // Add  functions
  const handleOnSearch = debounce(async () => {
    try {
      if (value) {
        let query = [
          {
            _name: "listCase",
          },
          {
            _name: "filter",
            _field: search_by === "title" ? "title" : "number",
            _value: search_by === "title" ? value : parseInt(value),
          },
        ];

        const response = await axios.post(
          "/cms/api/v1/query",
          {
            query: query,
          },
          {
            params: { name: "get-case-for-merge" },
          }
        );
        const data = response.data;
        setCases(data);
      }
    } catch { }
  }, 500);
  const handleSubmit = async (e) => {
    try {
      const response = await axios.post(
        `/cms/api/alert/${alertId}/merge/${selectedCases._id}`
      );
      toast.success("Merged successfuly.");
      setOpen(false);
      navigator(`/case-detail/${response.data._id.replace("~", "")}`);
    } catch (err) {
      toast.error("Something wents wrong", err);
    } finally {
      setOpen(false);
    }
  };

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  useEffect(() => {
    handleOnSearch();
    // eslint-disable-next-line
  }, [value]);
  const handleSelect = (obj) => {
    if (obj._id === selectedCases._id) {
      selectCase({});
    } else {
      selectCase(obj);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" fontWeight={700}>
            Merge Alert: {alertName}
          </Typography>

          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container item spacing={2.5}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    value={search_by}
                    onChange={(e) => {
                      setsearchBy(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="title"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 32,
                            },
                          }}
                        />
                      }
                      label={"By Title"}
                    />
                    <FormControlLabel
                      value="number"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 32,
                            },
                          }}
                        />
                      }
                      label="By Number"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {Object.keys(selectedCases).length > 0 && (
                  <Tooltip title="Click to remove">
                    <Box display="flex" gap={1}>
                      <Typography
                        component={ButtonBase}
                        sx={{
                          "&:hover": {
                            bgcolor: "#FF3E3E",
                            color: "#fff",
                          },
                          borderRadius: 1.25,
                          p: 0.5,
                        }}
                        onClick={() => {
                          handleSelect(selectedCases);
                        }}
                        key={selectedCases.number}
                        color="primary.main"
                      >
                        {selectedCases.number}- {selectedCases.title}
                        <b>,</b>
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" gap={1.25}>
                  <Typography variant="body2" fontWeight="bold" color="#000">
                    Search
                  </Typography>
                  <Tooltip title="Press Enter to search">
                    <InputField
                      value={value}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleOnSearch();
                        }
                      }}
                      type={search_by === "number" ? "number" : "text"}
                      placeholder={
                        search_by === "number"
                          ? "Search by number"
                          : "search by case title . e.g. malware* "
                      }
                      fullWidth
                    />
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box width="100%" textAlign="right">
                  <Typography
                    component={ButtonBase}
                    onClick={() => {
                      setCases([]);
                      setValue("");
                    }}
                    variant="subtitle2"
                    color="primary"
                    sx={{ textDecoration: "underline" }}
                  >
                    clear list
                  </Typography>
                </Box>
                <ReactScrollbar style={{ maxHeight: "350px" }}>
                  <Box
                    sx={{
                      backgroundColor: "#ECF0F4",
                      p: "15px 14px",
                    }}
                  >
                    {cases.map((c, i) => (
                      <ButtonBase
                        onClick={() => {
                          handleSelect(c);
                        }}
                        sx={{ textAlign: "left" }}
                        key={c._id + i}
                      >
                        <Tooltip title="Click to select">
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap={"15px"}
                            position="relative"
                            component={selectedCases._id === c._id && Paper}
                            p={selectedCases._id === c._id && 1}
                          >
                            {selectedCases._id === c._id && (
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  left: "-14px",
                                  top: "-10px",
                                }}
                              >
                                <CheckCircleIcon color="success" />
                              </IconButton>
                            )}
                            <Typography
                              color="#1173FF"
                              variant="caption"
                              fontWeight={700}
                              ml={selectedCases._id === c._id && 3}
                            >
                              #{c.number} - {c.title}
                            </Typography>
                            <Breadcrumbs separator="" aria-label="breadcrumb">
                              <Link
                                underline="hover"
                                sx={{ display: "flex", alignItems: "center" }}
                                color="#000"
                              >
                                <Avatar
                                  src={
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFblndSZeUbaKJTXoUSPh0tpT_VhOLo_UnoQ&usqp=CAU"
                                  }
                                  sx={{ width: "26px", height: "26px", mr: 0.5 }}
                                  fontSize="inherit"
                                />
                                {c.assignee}
                              </Link>
                              <Link
                                underline="hover"
                                sx={{ display: "flex", alignItems: "center" }}
                                color="#000"
                              >
                                <CalendarMonthIcon sx={{ mr: 0.5 }} />
                                {formatCreatedAt(c.startDate)}
                              </Link>
                              <Typography
                                sx={{ display: "flex", alignItems: "center" }}
                                color="text.primary"
                              >
                                <AccessTimeIcon sx={{ mr: 0.5 }} />
                                {calculateDaysFromTimestamp(c.startDate)} Days
                              </Typography>
                            </Breadcrumbs>
                            <Grid container gap={1.25} maxWidth={"100%"}>
                              {c.tags.map((tag, i) => (
                                <Grid item key={tag + i}>
                                  <Box
                                    sx={{
                                      height: "20px",
                                      p: "5px",
                                      borderRadius: "2px 0px 0px 2px",

                                      borderLeft: "5px solid #13D38E",
                                      width: "fit-content",
                                    }}
                                    display="flex"
                                    alignItems="center"
                                    gap="4px"
                                    bgcolor={"#ECF0F4"}
                                  >
                                    {tag}
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              color="#A5A5A5"
                            >
                              <MarkDownTable>{c.description}</MarkDownTable>
                            </Typography>
                            {i + 1 < cases.length && (
                              <Divider sx={{ mb: 1 }} flexItem />
                            )}
                          </Box>
                        </Tooltip>
                      </ButtonBase>
                    ))}
                    {cases.length === 0 && (
                      <Typography variant="h6" noWrap textAlign="center">
                        Please search for the case to be merged
                      </Typography>
                    )}
                  </Box>
                </ReactScrollbar>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" gap={3}>
            <Typography variant="body2" fontWeight={400} color="#000000">
              * Required field
            </Typography>
            <Typography variant="body2" fontWeight={400} color="#000000">
              ** At least, one required field
            </Typography>
          </Box>
          <Btn
            onClick={handleSubmit}
            text="Merge"
            disabled={Object.keys(selectedCases).length === 0}
            height={50}
            fontSize={20}
            style={{ backgroundColor: "#003272", minWidth: "125px" }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
