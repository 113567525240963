import {
  Autocomplete,
  Collapse,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

export const AutoCompleteChips = ({
  label,
  field,
  value,
  selectedValue,
  setValue,
  setValid,
}) => {
  const [query, setQuery] = useState({});
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    // Disbale or enable filter button
    if (value !== "Empty" && filters.length < 1) {
      setValid(false);
    } else {
      setValid(true);
    }
    let _query = [];
    let q = {};
    switch (value) {
      case "Empty":
        q = { _not: { _contains: field.name } };
        break;
      case "Any Of":
        filters.map((_value) => {
          _query.push({ _field: field.name, _value: _value });
          return "";
        });
        if (filters.length > 1) {
          q = { _or: _query };
          break;
        }
        // for radio button
        if (_query.length !== 0) {
          _query[0]["Any Of"] = "";
        } else {
          _query = [{ "Any Of": "" }];
        }
        q = _query[0];

        break;
      case "All Of":
        filters.map((_value) => {
          _query.push({ _field: field.name, _value: _value });
          return "";
        });
        if (filters.length > 1) {
          q = { _and: _query };
          break;
        }
        if (_query.length !== 0) {
          _query[0]["All Of"] = "";
        } else {
          _query = [{ "All Of": "" }];
        }
        q = _query[0];

        break;
      case "None Of":
        filters.map((_value) => {
          _query.push({ _field: field.name, _value: _value });
          return "";
        });
        if (filters.length > 1) {
          q = { _not: _query };
          break;
        }
        q = { _not: _query[0] };

        break;
      default:
        q = { _not: { _contains: field.name } };
        break;
    }
    setQuery(q);
    setValue(JSON.stringify(q));
    // eslint-disable-next-line
  }, [value, filters]);

  return (
    <>
      <FormControlLabel
        name="date-buttons-group"
        value={JSON.stringify(query)}
        control={<Radio />}
        label={label}
        onClick={(e) => {
          e.stopPropagation();
          setValue(e.target.value);
          setValid(value === "Empty");
        }}
      />
      <Collapse
        unmountOnExit
        in={selectedValue === JSON.stringify(query) && value !== "Empty"}
      >
        <Autocomplete
          onChange={(e, values) => {
            setFilters(values.map((v) => v.value));
          }}
          size="small"
          color="primary"
          multiple
          onKeyDown={(e) => e.stopPropagation()}
          options={field.options}
          getOptionLabel={(option) => option.title}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Select ${field.label} value`}
              placeholder="Favorites"
            />
          )}
        />
      </Collapse>
    </>
  );
};
