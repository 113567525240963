import { Avatar } from "@mui/material";
import React from "react";
import profileDefault from "../../static/image/profileDefault.avif"

function ProfileAvatar(props) {
  const {
    width = 26,
    height = 26,
    src = profileDefault,
    sx,
    ...other
  } = props;
  return (
    <Avatar
      sx={{ width: width, height: height, ...sx }}
      alt="User img"
      src={src}
      {...other}
    />
  );
}

export default ProfileAvatar;
