import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

export default function DateTimeField({ onChange, ...other }) {
  const handleDateTimeChange = (newDateTime) => {
    if (onChange) {
      // Convert the datetime to integer format (Unix timestamp in milliseconds)
      const integerTimestamp = newDateTime.valueOf();
      onChange(integerTimestamp);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        sx={{
          "& input": { height: 12 },
        }}
        onChange={handleDateTimeChange}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        {...other}
      />
    </LocalizationProvider>
  );
}
