import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Button, ButtonGroup, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { ossecConfigSchema } from './Configrations/OssecOptions.js';
import SideCanvas from '../../../../components/Dialog/SideCanvas.js';
import EmailIcon from '@mui/icons-material/Email';
import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import wazuh from '../../../../config/wazuh.js';
import NumbersIcon from '@mui/icons-material/Numbers';
import Scrollbars from 'react-custom-scrollbars-2';
import { FormLabel } from 'react-bootstrap';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import WarningDialogBox from '../components/WarningDialogBox.js';


const OssecConfiguration = ({ ossec, setOssec }) => {
    const onRowClick = (row) => {
        setOssec(ossec === row ? null : row);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(ossecConfigSchema).map(ossec => (
                        <TableRow key={ossec}
                            onClick={() => onRowClick(ossecConfigSchema[ossec])}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                },
                            }}
                        >
                            <TableCell>{ossecConfigSchema[ossec].title}</TableCell>
                            <TableCell>{ossecConfigSchema[ossec].description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const getField = (field, value, ossec, onChange, setXmlData, setErrors) => {
    function matchesAnyRegex(value, regexArray) {
        return regexArray.some(regex => regex.test(value));
    }
    // Handle Multiple values for a tag
    if (Array.isArray(value)) {
        const handleMultiTextChange = (index, atterName = false) => (e) => {
            let _value = e.target.value;
            if (field.type === "number") {
                const min = field[field.field].allowed_values[0];
                const max = field[field.field].allowed_values[1];
                if (_value > max) {
                    _value = max;
                }
                else if (_value < min) {
                    _value = min;
                }
            }
            onChange(_value, index, atterName);
            if (field.type === "regex" || field.type === "email") {
                const regexes = field.regex;
                if (Array.isArray(regexes) && regexes.length > 0) {
                    const _key = `${field.field} (${index})`;
                    if (!matchesAnyRegex(_value, regexes)) {

                        setErrors(prevErrors => {
                            const newErrors = { ...prevErrors };
                            if (!newErrors[ossec.name]) {
                                newErrors[ossec.name] = [];
                            }
                            // Add or update the error message for this field
                            const existingErrorIndex = newErrors[ossec.name].findIndex(err => err.field === _key);
                            if (existingErrorIndex === -1) {
                                newErrors[ossec.name].push({ field: _key, message: `Index ${index} not in correct format!` });
                            } else {
                                newErrors[ossec.name][existingErrorIndex].message = `Index ${index} not in correct format!`;
                            }
                            return newErrors;
                        });
                    } else {
                        setErrors(prevErrors => {
                            const newErrors = { ...prevErrors };
                            if (newErrors[ossec.name]) {
                                // Remove the error message for this field
                                newErrors[ossec.name] = newErrors[ossec.name].filter(err => err.field !== _key);
                                if (newErrors[ossec.name].length === 0) {
                                    delete newErrors[ossec.name];
                                }
                            }
                            return newErrors;
                        });
                    }
                }
            }
        };

        return (
            <Box display="flex" flexDirection="column" gap={1.5}>
                {value.map((v, i) => {
                    return (
                        // if atter found  (in object doem)
                        typeof v === "object" ?
                            "#text" in v && Object.keys(v).find(f => f.includes("@_")) &&
                            <Box display="flex" flexDirection="column" gap={1}>
                                <Tooltip title="attributes">
                                    <Typography variant='caption'>{Object.keys(v).filter(f => f.includes("@_"))?.map(lb => `${lb.replace("@_", "")}=${v[lb]}, `)}</Typography>
                                </Tooltip>
                                <TextField
                                    size="small"
                                    value={v["#text"]}
                                    sx={{ minWidth: 320 }}
                                    onChange={handleMultiTextChange(i, Object.keys(v).find(f => f.includes("@_")))}
                                />
                            </Box>
                            :
                            <TextField
                                size="small"
                                value={v}
                                sx={{ minWidth: 320 }}
                                onChange={handleMultiTextChange(i)}
                            />
                    )
                })}
            </Box>
        );
    }
    // Handle MultiTags in a tag
    if (field.type === "MultiFields") {
        const handleMultiField = (value, field_name, multiIndex) => {
            setXmlData(prevData => {
                const newData = { ...prevData };
                const data = newData[ossec.name][field.field];
                if (multiIndex || multiIndex === 0) {
                    data[field_name][multiIndex] = value;
                } else {
                    data[field_name] = value
                }
                return newData;
            });
        }
        if (!Array.isArray(value)) {
            // Object.entries(value).map(([field_name, field_value]) => {
            for (const key in value) {
                const field_name = key;
                const field_value = value[key];

                const _field = field.options.find(op => op.field === field_name);

                if (Array.isArray(field_value)) {
                    return <Box display="flex" flexDirection="column" gap={1}>
                        {field_value.map((_value, index) =>
                            <TextField
                                size="small"
                                label={field_name}
                                type={_field.type === 'number' ? 'number' : 'text'}
                                value={_value}
                                sx={{ minWidth: 320 }}
                                onChange={(e) => handleMultiField(e.target.value, field_name, index)}
                                InputProps={_field.type === 'email' ? { startAdornment: <EmailIcon color="primary" fontSize="small" /> } : _field.type === "number" && { startAdornment: <NumbersIcon color="primary" fontSize="small" /> }}
                            />
                        )
                        }
                    </Box>
                }
                else if (typeof field_value === "string") {
                    return (<TextField
                        size="small"
                        type={_field.type === 'number' ? 'number' : 'text'}
                        value={field_value}
                        sx={{ minWidth: 320 }}
                        onChange={(e) => handleMultiField(e.target.value, field_name)}
                        InputProps={_field.type === 'email' ? { startAdornment: <EmailIcon color="primary" fontSize="small" /> } : _field.type === "number" && { startAdornment: <NumbersIcon color="primary" fontSize="small" /> }}
                    />)
                }
            }
        }
        else {
            return <Box component="h6" sx={{ color: "error.main" }}>Something went's wrong.</Box>
        }
    }

    switch (field.type) {
        case 'boolean':
            return (
                <ButtonGroup disableElevation variant="contained">
                    <Button variant={value === "yes" ? "contained" : "outlined"} onClick={() => onChange('yes')}>Yes</Button>
                    <Button variant={value === "no" ? "contained" : "outlined"} onClick={() => onChange('no')}>No</Button>
                </ButtonGroup>
            );
        case 'string':
        case 'email':
        case 'regex':
        case 'text':
        case 'number':
            const handleTextChange = (e) => {
                let value = e.target.value;
                if (field.type === "number") {
                    const min = field[field.field].allowed_values[0];
                    const max = field[field.field].allowed_values[1];
                    if (value > max) {
                        value = max;
                    }
                    else if (value < min) {
                        value = min;
                    }
                }
                onChange(value);
                if (field.type === "regex" || field.type === "email") {
                    const regexes = field.regex;
                    if (Array.isArray(regexes) && regexes.length > 0) {
                        if (!matchesAnyRegex(value, regexes)) {
                            setErrors(prevErrors => {
                                const newErrors = { ...prevErrors };
                                if (!newErrors[ossec.name]) {
                                    newErrors[ossec.name] = [];
                                }
                                // Add or update the error message for this field
                                const existingErrorIndex = newErrors[ossec.name]?.findIndex(err => err.field === field.field);
                                if (existingErrorIndex && existingErrorIndex === -1) {
                                    newErrors[ossec.name].push({ field: field.field, message: "Not in correct format!" });
                                } else {
                                    newErrors[ossec.name][existingErrorIndex].message = "Not in correct format!";
                                }
                                return newErrors;
                            });
                        } else {
                            setErrors(prevErrors => {
                                const newErrors = { ...prevErrors };
                                if (newErrors[ossec.name]) {
                                    // Remove the error message for this field
                                    newErrors[ossec.name] = newErrors[ossec.name].filter(err => err.field !== field.field);
                                    if (newErrors[ossec.name].length === 0) {
                                        delete newErrors[ossec.name];
                                    }
                                }
                                return newErrors;
                            });
                        }
                    }
                }
            };
            return (
                <TextField
                    size="small"
                    type={field.type === 'number' ? 'number' : 'text'}
                    value={value}
                    sx={{ minWidth: 320 }}
                    onChange={handleTextChange}
                    InputProps={field.type === 'email' ? { startAdornment: <EmailIcon color="primary" fontSize="small" /> } : field.type === "number" && { startAdornment: <NumbersIcon color="primary" fontSize="small" /> }}
                />
            );
        case 'select':
            return (
                <FormControl sx={{ minWidth: "320px" }} size='small'>
                    <InputLabel id={field.verbose}>{field.verbose}</InputLabel>
                    <Select
                        labelId={field.verbose}
                        size='small'
                        value={value || null}
                        title={field.field}
                        onChange={(e) => onChange(e.target.value)}
                    >

                        {field[field.field]?.allowed_values?.map(op => (
                            <MenuItem key={op} value={op}>{op}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
            );
        case "timeUnit":
            const parseTimeUnitValue = (_value) => {
                // if 0 is coming 
                if (_value === 0) {
                    return { number: '0', unit: 's' };
                }
                else {
                    const match = _value?.match(/^(\d+)([smhd])$/);
                    return match ? { number: match[1], unit: match[2] } : { number: '', unit: '' };
                }
            };
            const formatTimeUnitValue = (number, unit) => `${number}${unit}`;
            const { number, unit } = parseTimeUnitValue(value);

            const handleTimeValueChange = (e) => {
                let _value = e.target.value;
                if (!_value) {
                    _value = field[field.field]?.min_allowed
                }
                onChange(formatTimeUnitValue(_value, unit));
            }
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        size="small"
                        type="number"
                        value={number}
                        onChange={handleTimeValueChange}
                        sx={{ minWidth: 120, marginRight: 1 }}
                        InputProps={{ inputProps: { min: field[field.field]?.min_allowed ? field[field.field]?.min_allowed : 0 }, startAdornment: <NumbersIcon color="primary" fontSize="small" /> }}
                    />
                    <FormControl size='small'>
                        <Select
                            size='small'
                            value={unit}
                            onChange={(e) => onChange(formatTimeUnitValue(number, e.target.value))}
                        >
                            <MenuItem value="s">s</MenuItem>
                            <MenuItem value="m">m</MenuItem>
                            <MenuItem value="h">h</MenuItem>
                            <MenuItem value="d">d</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            );

        default:
            return null;
    }
};

const RenderFieldRow = ({ field, value, ossec, setXmlData, multiIndexies = false, setErrors }) => {

    const onChange = (_value, multiIndex = null, atterName = false) => {
        setXmlData(prevData => {
            const newData = { ...prevData };
            const data = newData[ossec.name];

            if (Array.isArray(data)) {
                if (multiIndexies === 0 || multiIndexies) {
                    if (field.field in data[multiIndexies]) {
                        data[multiIndexies][field.field] = _value;
                    }
                } else {
                    data.forEach(tag => {
                        if (field.field in tag) {
                            if (multiIndex !== null || multiIndex === 0) {
                                tag[field.field][multiIndex] = _value;
                            }
                            else {
                                tag[field.field] = _value;
                            }
                        }
                    });
                }
            } else if (data && field.field in data) {
                if (multiIndex !== null || multiIndex === 0) {
                    if (atterName) {
                        data[field.field][multiIndex]["#text"] = _value;
                    }
                    else {
                        data[field.field][multiIndex] = _value;
                    }
                }
                else {
                    data[field.field] = _value;
                }
            }

            return newData;
        });
    };

    return (
        <TableRow key={field?.verbose}>
            <TableCell width="50%" align="right">
                <Typography>{field?.verbose}</Typography>
            </TableCell>
            <TableCell>
                {getField(field, value, ossec, onChange, setXmlData, setErrors)}
            </TableCell>
        </TableRow>
    );
};
const RenderMultiAtterFieldsRow = ({ field, tags, ossecConfigSchema, ossec, setXmlData, setErrors }) => {
    const FieldFinder = (tag_name, field_name) => {
        if (tag_name in ossecConfigSchema) {
            const sections = ossecConfigSchema[tag_name].sections;
            const options = [];
            for (const section of sections) {
                options.push(...section.options);
            }
            for (const op of options) {
                if (op.type === "multiAtters") {
                    for (const o of op.options) {
                        if (o.field === field_name) {
                            return o;
                        }
                    }
                }
            }
        }
    }
    const handleChange = (index, field_name, multiIndex = false) => (field_value) => {

        setXmlData(prevData => {
            const newData = { ...prevData };
            const data = newData[ossec];
            if (field.tag in data) {
                if (Array.isArray(data[field.tag])) {
                    if (field_name in data[field.tag][index]) {
                        if (multiIndex || multiIndex === 0) {
                            if (Array.isArray(data[field.tag][index][field_name])) {
                                if (data[field.tag][index][field_name].includes(field_value)) {
                                    const values = data[field.tag][index][field_name];
                                    console.log(values, values.filter(t => t !== field_value))
                                    data[field.tag][index][field_name] = values.filter(t => t !== field_value);
                                }
                                else {
                                    data[field.tag][index][field_name].push(field_value);
                                }
                            }
                            else {
                                if (data[field.tag][index][field_name]?.includes(field_value)) {
                                    data[field.tag][index][field_name] = [];
                                }
                                else {

                                    data[field.tag][index][field_name] = [field_value];
                                }
                            }
                        } else {
                            data[field.tag][index][field_name] = field_value;
                        }
                    }
                }
            }

            return newData;
        });
    }
    return (
        // Genrate Multi Atters Tags
        tags.map((tag, index) => {
            const atter = tag[Object.keys(tag).find(k => k.includes("@_"))]
            const fields = Object.keys(tag)
                .filter(key => key !== "@_name")
                .reduce((obj, key) => {
                    obj[key] = tag[key];
                    return obj;
                }, {});
            return <>
                <TableRow key={atter} sx={{ ml: 1 }}>
                    <TableCell width="50%" align="right">
                        {atter.toUpperCase()}
                    </TableCell>
                    <TableCell colSpan={fields?.length}>
                        <Box display="flex" flexDirection="column" gap={2}>
                            {Object.entries(fields).map(([field_name, field_value]) => {
                                const _field = FieldFinder(ossec, field_name);
                                if (_field) {
                                    return (
                                        !Array.isArray(field_value) && _field.type !== "multiTags" ?
                                            <Box display="flex" flexDirection="column" gap={0.5} maxWidth={"320px"} key={_field.field}>
                                                <Typography variant='caption'>{_field.verbose}</Typography>
                                                {getField(_field, field_value, ossec, handleChange(index, field_name), setXmlData, setErrors)}
                                            </Box> :
                                            _field.type === "multiTags" &&
                                            <FormControl key={_field.field} sx={{ maxWidth: '320px' }}>
                                                <FormLabel id={`${_field.field}-checkbox-group`}>{_field.verbose}</FormLabel>
                                                <Stack spacing={{ xs: 0.5, sm: 1 }} useFlexGap flexWrap="wrap" direction="row" >
                                                    {_field[_field.field]?.find(f => f.attr === atter)?.tags?.map((tag, i) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={tag}
                                                                label={tag}
                                                                control={
                                                                    <Checkbox
                                                                        checked={field_value?.includes(tag)}
                                                                        onChange={(e) => { handleChange(index, _field.field, i)(tag) }}
                                                                    />
                                                                }
                                                            />
                                                        );
                                                    })
                                                    }
                                                </Stack>
                                            </FormControl>
                                    )
                                }
                            })}
                        </Box>
                    </TableCell >
                </TableRow >

            </>
        })


    );
};
function Configuration() {
    const [ossec, setOssec] = useState(null);
    const [originalXML, setOriginalXML] = useState({ original: null, eddited: "" });
    const [xmlData, setXmlData] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [alert, showAlert] = useState(false);
    const [dialogbox, showDialogbox] = useState({ title: "", show: false, message: "" });


    useEffect(() => {
        // Load and parse the XML file
        setLoading(true)
        wazuh.get("/manager/configuration", { params: { raw: true } }).then(res => {
            if (res.status === 200) {
                const xmlText = res.data
                const options = {
                    ignoreAttributes: false,
                    attributeNamePrefix: "@_"
                };
                const parser = new XMLParser(options);
                const jsonData = parser.parse(xmlText);
                console.log(jsonData)
                const config = jsonData.ossec_config[0]
                setOriginalXML({ original: JSON.stringify(config), eddited: JSON.stringify(config) });
                setXmlData(config);
            }
        }).finally(err => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setOriginalXML(pre => ({ ...pre, eddited: JSON.stringify(xmlData) }));
    }, [xmlData, ossec?.name])


    const handleSave = () => {
        const options = {
            ignoreAttributes: false,
            attributeNamePrefix: "@_"
        };
        const builder = new XMLBuilder(options);
        const xml = builder.build(xmlData);
        localStorage.setItem("configraton_xml", encodeURI(xml));
        window.open(`/view-xml`, "_parent");
        showAlert(true);
        // const xml = JSON.stringify(builder.build(xmlData));
        // wazuh.put("/manager/configuration", { body: xml, origin: "raw" }).then(res => {
        //     console.log(res)
        // }).catch(error => {
        //     toast.error("Something wents wrong.")
        //     console.log(error)
        // })
    };
    const RemoveMulIndexSection = (index) => (e) => {
        setXmlData(prevData => {
            const newData = { ...prevData };
            const data = newData[ossec.name];
            if (Array.isArray(data)) {
                delete data[index]
            }
            return newData;
        })
    }
    const handleAddMultiIndexSection = () => {
        const sections = ossecConfigSchema[ossec.name].sections;

        setXmlData(prevData => {
            const newData = { ...prevData };
            const data = newData[ossec.name];
            const field = {};
            for (const section of sections) {
                for (const op of section.options) {
                    field[op.field] = op[op.field]?.default_value
                }
            }
            if (Array.isArray(data)) {
                data.push(field);
            }
            else {
                newData[ossec.name] = [data, field]
            }
            return newData;
        })
    }
    const FieldFinder = (tag_name, field_name) => {
        if (tag_name in ossecConfigSchema) {
            const sections = ossecConfigSchema[tag_name].sections;
            for (const section of sections) {
                for (const field of section.options) {
                    if (field.type === "multiAtters" && field.tag === field_name) {
                        return { section, field };
                    }
                    else {
                        if (field.field === field_name) {
                            return { section, field };
                        }
                    }
                }
            }
            return { section: null, field: null };
        }
        return { section: null, field: null };
    }
    const resetSection = () => {
        if (ossec?.name) {
            const orignal_xml = JSON.parse(originalXML.original);
            const orignal_section = orignal_xml[ossec.name];
            setXmlData(preData => {
                const newData = preData;
                newData[ossec.name] = orignal_section;
                return newData;
            });
            setOssec(null);
        }
    }
    const IsNotConfSectionChanged = () => {
        if (ossec) {
            const orignal_xml = JSON.parse(originalXML.original);
            const orignal_section = JSON.stringify(orignal_xml[ossec.name]);
            const current_section = JSON.stringify(xmlData[ossec.name]);
            if (current_section === orignal_section) {
                return true;
            }
        }
        return false;
    }
    const SactionBtns = useCallback(() => {
        const is_not_orignal = IsNotConfSectionChanged();

        return (
            <Box display="flex" justifyContent="flex-end" gap={2} mb={2}>
                <Button
                    sx={{ minWidth: 130 }}
                    onClick={resetSection}
                    disabled={is_not_orignal || loading}
                    startIcon={<RotateLeftIcon />}
                    color='error'
                    variant='outlined'
                >
                    Reset
                </Button>
                <Button
                    startIcon={<SettingsIcon />}
                    variant="contained"
                    onClick={() => { setOssec(null) }}
                    sx={{
                        minWidth: 130,
                        bgcolor: is_not_orignal ? "primary.main" : "success.main",
                        color: is_not_orignal ? "black" : "white"
                    }}
                    disabled={is_not_orignal || loading || ossec.name in errors}
                >
                    Save
                </Button>
            </Box>
        );
    }, [ossec?.name, errors]);

    const DialogBoxRender = useCallback(() => {
        return <WarningDialogBox
            title={dialogbox.title}
            message={dialogbox.message}
            open={dialogbox.show}
            onConfirm={() => { resetSection(); showDialogbox({ message: "", show: false, title: "" }) }}
            setOpen={(_open) => {
                showDialogbox({ message: "", show: _open, title: "" });
            }}
        />
    }, [dialogbox.show, dialogbox.title, dialogbox.message])


    return (
        <Box>
            <Box
                component={Paper}
                elevation={0}
                display="flex"
                flexDirection="column"
                p={2.5}
                gap={2}
                sx={{
                    minHeight: "78vh",
                }}
                height="100%"
            >
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold">
                        Configuration
                    </Typography>
                    <Button
                        startIcon={<SettingsIcon />}
                        variant="contained"
                        onClick={handleSave}
                        sx={{
                            bgcolor: originalXML.original === originalXML.eddited ? "primary.main" : "success.main",
                            color: originalXML.original === originalXML.eddited ? "black" : "white"
                        }}
                        disabled={originalXML.original === originalXML.eddited || loading} >
                        Save Configuration
                    </Button>
                </Box>
                {alert && !loading &&
                    <Alert sx={{ width: "50%" }} severity="info">Changes will not take effect until a restart is performed.</Alert>
                }

                <Divider flexItem />
                {loading ?
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh" width="100%">
                        <CircularProgress size={70} />
                    </Box>
                    :
                    <OssecConfiguration setOssec={setOssec} ossec={ossec} />
                }

            </Box>

            <SideCanvas
                backdrop="static"
                show={ossec !== null}
                setShow={(_v) => { setOssec(null) }}
                handleClose={() => {
                    if (!IsNotConfSectionChanged()) {
                        showDialogbox({ title: "Are you sure you?.", message: "The changes will be discard !", show: true });
                    }
                    else {
                        resetSection();
                        setOssec(null);
                    }
                }}
                title={ossec?.title} description={ossec?.description}
                width={"50%"}
            >
                <Box display="flex" flexDirection="column" gap={2}>
                    <Scrollbars style={{ height: '90vh', width: "100%" }}>

                        {ossec &&
                            <Scrollbars style={{ height: '90vh', width: "100%" }}>
                                {/* Errores */}
                                {ossec.name in errors &&
                                    <Box mb={1} display="flex" gap={.5} flexDirection="column">
                                        {errors[ossec.name].map(error => (
                                            <Alert key={error.field} severity="error">
                                                {`${error.field} -> ${error.message}`}
                                            </Alert>
                                        ))
                                        }
                                    </Box>
                                }

                                <Scrollbars style={{ height: '90vh', width: "100%" }}>
                                    {/* Save and ResetButtons */}
                                    <SactionBtns />
                                    {/* Warning Dialog */}
                                    {dialogbox.show &&
                                        <DialogBoxRender />
                                    }

                                    {/* {ossec && ossecConfigSchema[ossec] &&
                                        < Box key={ossecConfigSchema[ossec].section.title} display="flex" flexDirection="column" gap={0.5}>
                                            <Box display="flex" flexDirection="column">
                                                <Typography variant="h6">{ossecConfigSchema[ossec].section.title}</Typography>
                                                <Typography variant="caption">{ossecConfigSchema[ossec] / section.description}</Typography>
                                            </Box> */}
                                    {/* <Divider /> */}
                                    <TableContainer>
                                        <Table size="small" sx={{
                                            "& .MuiTableCell-root ": {
                                                borderBottom: "none"
                                            }
                                        }}>
                                            <TableBody>
                                                {ossec.name in xmlData && Array.isArray(xmlData[ossec.name]) ? (
                                                    xmlData[ossec.name]?.map((tags, index) => (
                                                        <>
                                                            {"MultiIndexies" in ossecConfigSchema[ossec.name] && (
                                                                <TableRow>
                                                                    <TableCell colSpan={2}>
                                                                        <Divider>{ossec.name?.toUpperCase()}</Divider>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                            <Paper sx={{ position: "relative" }}>
                                                                {
                                                                    "MultiIndexies" in ossecConfigSchema[ossec.name] && (
                                                                        <IconButton onClick={RemoveMulIndexSection(index)} sx={{ position: "absolute", right: 5, top: -20 }}>
                                                                            <RemoveCircleIcon color='error' />
                                                                        </IconButton>
                                                                    )
                                                                }


                                                                {Object.entries(tags).map(([field_name, value]) => {
                                                                    const { section, field } = FieldFinder(ossec.name, field_name);

                                                                    return field && (
                                                                        <RenderFieldRow
                                                                            multiIndexies={"MultiIndexies" in ossecConfigSchema[ossec.name] ? index : false}
                                                                            key={field_name}
                                                                            field={field}
                                                                            value={value}
                                                                            ossec={ossec}
                                                                            setXmlData={setXmlData}
                                                                            setErrors={setErrors}
                                                                        />
                                                                    );
                                                                })}
                                                            </Paper >
                                                        </>
                                                    ))
                                                ) : (
                                                    <Paper>
                                                        {Object.entries(xmlData[ossec.name]).map(([field_name, value]) => {
                                                            const { section, field } = FieldFinder(ossec.name, field_name);
                                                            if (field && field.type === "multiAtters") {
                                                                return <TableRow>
                                                                    <TableCell colSpan={2}>
                                                                        <Paper elevation={3}>
                                                                            <TableRow>
                                                                                <TableCell colSpan={2}>
                                                                                    <Divider />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell colSpan={2}>
                                                                                    <Typography variant='h6'>{field.verbose}</Typography>
                                                                                    <Typography>
                                                                                        {field.description}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <RenderMultiAtterFieldsRow
                                                                                field={field}
                                                                                ossecConfigSchema={ossecConfigSchema}
                                                                                tags={value}
                                                                                ossec={ossec.name}
                                                                                setXmlData={setXmlData}
                                                                                setErrors={setErrors} />
                                                                        </Paper>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            return field && (
                                                                <RenderFieldRow
                                                                    key={field_name}
                                                                    field={field}
                                                                    value={value}
                                                                    ossec={ossec}
                                                                    setXmlData={setXmlData}
                                                                    setErrors={setErrors}
                                                                />
                                                            );
                                                        })}

                                                        {"MultiIndexies" in ossecConfigSchema[ossec.name] && (
                                                            <TableRow>
                                                                <TableCell colSpan={2}>
                                                                    <Divider>{ossec.name?.toUpperCase()}</Divider>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </Paper>
                                                )}

                                                {"MultiIndexies" in ossecConfigSchema[ossec.name] && (
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Button onClick={handleAddMultiIndexSection} startAdornment={<AddToPhotosIcon />} fullWidth variant='contained'>Add new {ossec.name}</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )}

                                            </TableBody >
                                        </Table>
                                    </TableContainer>


                                </Scrollbars>

                            </Scrollbars>
                        }



                    </Scrollbars>
                </Box>
            </SideCanvas>

        </Box >
    );
}

export default Configuration;

