import React from 'react';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <Box display="flex" flexDirection="column" gap={2.525}>
      {payload.map((entry, index) => {
        const color = entry.color;
        return (
          <Box
            key={entry.value}
            bgcolor={"lightblue"}
            p={0.75}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius={"14px"}
            gap={2}
            maxWidth={200}
          >
            <Box display="flex" gap={0.5} alignItems="center">
              <svg width="18" height="18">
                <circle cx="50%" cy="50%" r="50%" fill={color} />
              </svg>
              <Typography
                sx={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
                variant="caption"
                color="#000"
              >
                {entry.value}
              </Typography>
            </Box>
            <Typography variant="body2">{entry.payload.value}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const renderLegend2 = (withValue) => (props) => {
  const { payload } = props;
  return (
    <Box
      component={"ul"}
      sx={{
        maxWidth: "250px",
        "& li": {
          fontSize: "12px ",
        },
      }}
    >
      {payload.map((entry, index) => (
        <Box
          key={index}
          component="li"
          sx={{
            wordWrap: 'break-word',
            "&::marker": {
              color: entry.color,
            },
            borderBottom: "0.5px solid #ababab"
          }}
        >
          {!withValue ? entry.value : `${entry.value} (${entry.payload.value})`}
        </Box>
      ))}
    </Box>
  );
};

const renderLegend3 = (props) => {
  const { payload } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: "250px", }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, width: "100%", minWidth: 120, marginBottom: 1 }}>
        <Typography variant="body2">Key</Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2">Value</Typography>
      </Box>
      {payload.map((entry, index) => (
        <Box
          key={`item-${index}`}
          sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, width: "100%", minWidth: 120, marginBottom: 0.1, borderBottom: "0.5px solid #ababab" }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <svg width="10" height="10">
              <rect width="10" height="10" fill={entry.color} />
            </svg>
            <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
              {entry.payload.name}
            </Typography>
          </Box>
          <Typography variant="body2">{entry.payload.value}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const SimplePieChart = ({
  data = [],
  customLegend = false,
  fillColor = "#8884d8",
  graphHeight = "100%",
  innerRadius = 45,
  legendWithValue = false,
  sx = {},
}) => {
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Box
      sx={{
        width: '100%',
        height: graphHeight,
        ...sx,
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        {data.length < 1 ?
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography>No Data</Typography>
          </Box>
          :
          <PieChart>
            <Pie
              dataKey="value"
              data={data}
              outerRadius="80%"
              innerRadius={innerRadius}
              fill={fillColor}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>
            {!lgDown && (
              <Legend
                verticalAlign="middle"
                align="right"
                layout="vertical"
                iconType="circle"
                content={customLegend && customLegend === "custome3" ? customLegend === "custome3" ? renderLegend3 : renderLegend : renderLegend2(legendWithValue)}
              />
            )}
          </PieChart>}
      </ResponsiveContainer>
    </Box>
  );
};

export default SimplePieChart;
