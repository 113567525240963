import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertIcon from "../../../static/icons/alert-icon.svg";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";

export default function DeleteObservable({ open, setOpen, refrashFunc, selectedObj }) {
  const handleClose = () => {
    setOpen(false);
  };

  // andle Submit

  const handleSubmit = async (e) => {
    try {
      e.target.disabled = true;
      await axios.delete(`/cms/api/case/artifact/${selectedObj._id}`).then((res) => {
        if (res.status === 204) {
          toast.success("Deleted successfuly");
          setOpen(false);
          refrashFunc(false,true);
        } else {
          toast.error("Something is wrong.");
        }
      });
    } catch {
    } finally {
      e.target.disabled = false;
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title" className="bg-danger">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" fontWeight={700} color="#fff">
            <Box component="img" src={AlertIcon} alt="text" mr={1} />
            Remove Observable (id)
          </Typography>

          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          p={5}
        >
          <Typography paragraph>
            Are you sure you want to delete the selected Observables?
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          <Button
            startIcon={<DeleteForeverIcon />}
            variant="contained"
            color="error"
            onClick={handleSubmit}
            sx={{ fontSize: 20, height: 50 }}
          >
            Remove
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
