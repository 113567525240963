import {
  Box,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  Avatar,
  Paper,
  Grid,
  Chip,
  Collapse,
  Divider,
  LinearProgress,
  ButtonBase,
  InputBase,
  IconButton,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MergeIcon from "@mui/icons-material/Merge";
import FlagIcon from "@mui/icons-material/Flag";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PestControlIcon from "@mui/icons-material/PestControl";
import { pap_enum, severity_enum, tlp_enum } from "./enums/enums";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  calculateDaysFromTimestamp,
  convertToDateTimeLocal,
} from "../../utils/utils.js";
import axios from "axios";
import { formatCreatedAt } from "../../utils/utils";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DelteCaseDialog from "./components/DelteCaseDialog";
import MergeDialog from "./components/MergeDialog";
import MarkDownTable from "../../components/Display/MarkDownTable/MarkDownTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ButtonGroup } from "react-bootstrap";
import CloseCaseDialog from "./components/CloseCaseDialog";
import { SearchOutlined } from "@mui/icons-material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import ProfileAvatar from "../../components/Display/ProfileAvatar.js";
import InfoIcon from "@mui/icons-material/Info";
import { FetchAgents } from "../../utils/agents/AgentUtils.js";
import ObservablesPage from "./ObservablesList.js";
import ObservableDetail from "./ObservableDetail.js";
import TaskList from "./TasksList.js";
import TaskDetail from "./TaskDetail.js";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { green } from "@mui/material/colors";
import SideCanvas from "../../components/Dialog/SideCanvas.js";
import PlaybookListView from "./PlaybookList.js";



const sort_options = [
  {
    name: "Newest first",
    value: "newest_first",
    _field: [{ flag: "desc" }, { startDate: "desc" }],
  },
  {
    name: "Oldest first",
    value: "oldest_first",
    _field: [{ flag: "desc" }, { startDate: "asc" }],
  },
  {
    name: "Height Severity first",
    value: "height_severity_first",
    _field: [{ flag: "desc" }, { severity: "desc" }],
  },
  {
    name: "Low Severity first",
    value: "low_severity_first",
    _field: [{ flag: "desc" }, { severity: "asc" }],
  },
  {
    name: "Ascendant assignee",
    value: "ascendant_assignee",
    _field: [{ flag: "desc" }, { assignee: "asc" }],
  },
  {
    name: "Descendant assignee",
    value: "",
    _field: [{ flag: "desc" }, { assignee: "desc" }],
  },
];

const DetailPage = ({ data, attachments, responderJobs }) => {
  const [responderReport, showResponderReport] = useState("");
  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={1.25} mt={4.375}>
        <Typography variant="h6" fontWeight={700}>
          Additional information
        </Typography>
        <Typography variant="caption" fontWeight={500} color="#A5A5A5">
          No additional information have been specified
        </Typography>
      </Box>

      <Box mt={5} display="flex" flexDirection="column" gap={1.25}>
        <Typography variant="h6" fontWeight={700}>
          Description
        </Typography>

        <MarkDownTable maxWidth={467}>
          {data?.description?.replace("wazuh.manager", "df.manager")}
        </MarkDownTable>
        <Box mt={5} p={1}>
          {attachments && attachments.length > 0 && (
            <>
              <Typography variant="h6" fontWeight={700}>
                Attachments
              </Typography>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr style={{ fontSize: 14, fontWeight: 700 }}>
                    <th style={{ border: "none", padding: "12px" }}>
                      Filename
                    </th>
                    <th style={{ border: "none", padding: "12px" }}>Date</th>
                    <th style={{ border: "none", padding: "12px" }}>Type</th>
                    <th style={{ border: "none", padding: "12px" }}>Size</th>
                    <th
                      style={{
                        border: "none",
                        padding: "12px",
                        textAlign: "center",
                      }}
                    >
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attachments.map((att, index) => (
                    <tr
                      key={att.attachment.size}
                      style={{
                        border: "1px solid #ECF0F4",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <td style={{ padding: "12px" }}>{att.attachment.name}</td>
                      <td style={{ padding: "12px" }}>
                        {new Date(att.date).toLocaleDateString()}
                      </td>
                      <td style={{ padding: "12px", fontWeight: 700 }}>
                        {att.attachment.contentType}
                      </td>
                      <td style={{ padding: "12px" }}>{att.attachment.size}</td>
                      <td style={{ padding: "12px", textAlign: "center" }}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            window.location.origin +
                            "/cms/api/datastorezip/" +
                            att.attachment.hashes[0] + `?name=${att.attachment.name}`
                          }
                        >
                          <DownloadForOfflineIcon color="primary" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {responderJobs.length > 0 && (
            <>
              <Typography variant="h6" fontWeight={700} sx={{ mt: 5 }}>
                Responder Jobs
              </Typography>

              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr style={{ fontSize: 14 }}>
                    <th style={{ border: "none", padding: "12px" }}>Status</th>
                    <th
                      style={{
                        border: "none",
                        padding: "12px",
                        minWidth: "150px",
                      }}
                    >
                      Name
                    </th>
                    <th style={{ border: "none", padding: "12px" }}>
                      Cortex Server
                    </th>
                    <th style={{ border: "none", padding: "12px" }}>Date</th>
                    <th style={{ border: "none", padding: "12px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {responderJobs.map((row, index) => (
                    <>
                      <tr
                        key={index}
                        style={{
                          border: "1px solid #ECF0F4",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        <td style={{ padding: "12px" }}>
                          {" "}
                          <Button
                            sx={{ height: 24, minWidth: 100 }}
                            variant="contained"
                            color={
                              row.status !== "Failure" ? "success" : "error"
                            }
                          >
                            {row.status}
                          </Button>
                        </td>
                        <td style={{ padding: "12px", fontWeight: 700 }}>
                          {row.responderName}
                        </td>
                        <td style={{ padding: "12px" }}>{row.cortexId}</td>
                        <td style={{ padding: "12px" }}>
                          {new Date(row.startDate).toLocaleString()}
                        </td>
                        <td style={{ padding: "12px" }}>
                          <Tooltip title="View report">
                            <IconButton
                              onClick={() => {
                                showResponderReport((pre) => {
                                  if (pre === row.cortexJobId) {
                                    return "";
                                  } else {
                                    return row.cortexJobId;
                                  }
                                });
                              }}
                            >
                              <SearchOutlined />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                      <Box
                        component="tr"
                        className={`animated-row ${responderReport === row.cortexJobId ? "visible" : ""
                          }`}
                        sx={{
                          display:
                            responderReport === row.cortexJobId
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <Box
                          component={"td"}
                          colSpan={5}
                          borderRadius="6px"
                          bgcolor={"#FFF0F0"}
                          p="14px 15px"
                        >
                          <ReactJson
                            style={{ borderRadius: 5 }}
                            src={JSON.parse(row.report)}
                            collapseStringsAfterLength={20}
                            theme="monokai"
                            displayDataTypes={false}
                            enableClipboard
                          />
                        </Box>
                      </Box>
                    </>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const AgentTable = ({ agents = [] }) => {
  // Sample data for the table
  const navigation = useNavigate();
  const data = agents.map((agent) => ({
    id: agent.id,
    name: agent.name,
    ip: agent.ip,
  }));

  const [hoveredRow, setHoveredRow] = useState(null);

  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th style={{ width: "33%" }}>ID</th>
          <th style={{ width: "33%" }}>Name</th>
          <th style={{ width: "33%" }}>IP</th>
        </tr>
        <tr>
          <th colSpan={3}>
            <Divider />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr
            key={row.id}
            onClick={() => {
              navigation(`/agents-preview?id=${row.id}`);
            }}
            onMouseEnter={() => setHoveredRow(row.id)}
            onMouseLeave={() => setHoveredRow(null)}
            style={{ cursor: "pointer", backgroundColor: hoveredRow === row.id ? '#f0f0f0' : 'transparent' }}
          >
            <td>{row.id}</td>
            <td>{row.name}</td>
            <td>{row.ip}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CaseDetail = (props) => {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialCountData = { task: 0, observables: 0 };
  const [dataCount, setDataCount] = useState(initialCountData);
  const initialData = {
    task: [],
    task_options: { statuses: [], assignees: [] },
    observable: [],
    loading: false,
    case: {},
    attachments: [],
  };
  const [data, setData] = useState(initialData);
  const [closeCaseDialog, showCloseCaseDialog] = useState(false);
  const [mergeBox, showMergeBox] = useState(false);
  const [deleteBox, showdeleteBox] = useState(false);
  const [page, setPage] = useState(0);
  const [extraPages, setExtraPages] = useState([]);
  const [editField, setEditField] = useState(null);
  const initialPagination = { page: 1, per_page: 15 };
  const [pagination, setPagination] = useState(initialPagination);
  const initial_sortFields = [{ flag: "desc" }, { startDate: "desc" }];
  const [sortFields, setSortFields] = useState(initial_sortFields);
  const [editTags, setEditTags] = useState([]);
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [responderJobs, setresponderJobs] = useState([]);
  const [agents, setAgents] = useState([]);
  const [playbookDialog, showPlaybookDialogBox] = useState(false);

  const [caseCloseTitle, setCaseCloseTitle] = useState(undefined);
  const inital_filters = {
    sortBy: "",
    quickFilter: "",
  };
  const [filters, setFilters] = useState(inital_filters);

  const handlePage = (p) => () => {
    setPage(p);
  };
  const handleSort = (e) => {
    let value = e.target.value;
    setFilters({ ...filters, sortBy: value });
    let ops = sort_options.find((s) => s.value === value);
    if (ops) {
      setSortFields(ops._field);
    } else {
      setSortFields(initial_sortFields);
    }
  };
  const handleExtraPageOpen = (title) => () => {
    // Close all open pages first
    const updatedPages = extraPages.map((page) =>
      page.title === title ? { ...page, open: true } : { ...page, open: false }
    );

    setExtraPages(updatedPages);
    setPage(title);
  };
  const AgentIdFinder = (_tags) => {
    const tags = [];
    for (const tag of _tags) {
      const regex = /^agent_id=(\d+)$/;
      const match = tag.match(regex);
      if (match) {
        const agentIdValue = match[1];
        tags.push(agentIdValue);
      }
    }
    return tags;
  };
  // APIS
  const fecthAttachments = async () => {
    try {
      let query = [
        {
          _name: "getCase",
          idOrName: "~" + caseId,
        },
        {
          _name: "tasks",
        },
        {
          _name: "filter",
          _ne: {
            _field: "status",
            _value: "Cancel",
          },
        },
        {
          _name: "logs",
        },
        {
          _name: "filter",
          _contains: "attachment.id",
        },
        {
          _name: "page",
          from: 0,
          to: 100,
          extraData: ["taskId"],
        },
      ];

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: "case-attachments" },
        }
      );
      let d = response.data;
      setData((pre) => ({ ...pre, attachments: d }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const FetchTaskDetail = async (_id) => {
    try {
      let query = [
        {
          _name: "getTask",
          idOrName: _id,
        },
        {
          _name: "page",
          from: 0,
          to: 1,
          extraData: ["actionRequired", "actionRequiredMap"],
        },
      ];

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: `get-task-${_id}` },
        }
      );
      let d = response.data[0];
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("task", d._id);

      // Use the navigate function to update the URL with the new search parameters
      navigate(`?${newSearchParams.toString()}`);

      handleDetailPage(
        d.title,
        <TaskDetail
          data={d}
          refrashFunc={() => FetchTaskDetail(_id)}
          caseId={caseId}
          onClose={() => {
            navigate(`/case-detail/${caseId}`);
            setPage(1);
            setExtraPages([]);
            fetchTaskDetail(false, true);
          }}
          permissions={props.permissions}
        />,
        () => {
          setPage(1);
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCaseDetail = async (disableRefrash = false) => {
    try {
      if (!disableRefrash) {
        setData((pre) => ({ ...initialData, loading: true }));
      }
      let query = [
        {
          _name: "getCase",
          idOrName: "~" + caseId,
        },
        {
          _name: "page",
          from: 0,
          to: 1,
          extraData: [
            "observableStats",
            "taskStats",
            "alerts",
            "isOwner",
            "shareCount",
            "permissions",
          ],
        },
      ];

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: "get-case-~49200" },
        }
      );
      let d = response.data;
      const agent_ids = AgentIdFinder(d[0].tags)
      if (agent_ids.length > 0) {
        _FetchAgent(agent_ids);
      }

      setData((pre) => ({ ...pre, loading: false, case: d[0] }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTaskDetail = async (filter_query = false, get_count = false) => {
    try {
      if (!get_count) {
        setData((pre) => ({ ...pre, loading: true }));
      }
      let query = [
        {
          _name: "getCase",
          idOrName: "~" + caseId,
        },
        {
          _name: "tasks",
        },
        {
          _name: "filter",
          _ne: {
            _field: "status",
            _value: "Cancel",
          },
        },
        {
          _name: "sort",
          _fields: [
            {
              flag: "desc",
            },
            {
              order: "asc",
            },
            {
              startDate: "asc",
            },
            {
              title: "asc",
            },
          ],
        },
        {
          _name: "page",
          from: (pagination.page - 1) * pagination.per_page,
          to: pagination.page * pagination.per_page,
          extraData: ["shareCount", "actionRequired"],
        },
      ];
      if (get_count) {
        if (filter_query && Array.isArray(filter_query)) {
          query.splice(2, 1);
          query.splice(3, 1);
        } else {
          query.pop();
          query.pop();
        }
        query.push({ _name: "limitedCount" });
      }
      if (filter_query && Array.isArray(filter_query)) {
        if (filter_query.length < 2) {
          query[2] = {
            _name: "filter",
            ...filter_query[0],
            // _ne: {
            //   _field: "status",
            //   _value: "Cancel",
            // },
          };
        } else {
          query[2] = {
            _name: "filter",
            _and: [
              // {
              //   _ne: {
              //     _field: "status",
              //     _value: "Cancel",
              //   },
              // },
              ...filter_query,
            ],
          };
        }
      }

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: get_count ? "case-tasks.count" : "case-tasks" },
        }
      );
      let d = response.data;
      if (get_count) {
        setDataCount((pre) => ({ ...pre, task: d }));
        fetchTaskDetail(filter_query, false);
      } else {
        setData((pre) => ({ ...pre, loading: false, task: d }));
      }
    } catch (error) {
      setData((pre) => ({ ...pre, loading: false }));
      console.error("Error fetching data:", error);
    }
  };
  const fetchObservablesDetail = async (
    filter_query = false,
    get_count = false
  ) => {
    try {
      if (!get_count) {
        setData((pre) => ({ ...pre, loading: true }));
      }
      let query = [
        {
          _name: "getCase",
          idOrName: "~" + caseId,
        },
        {
          _name: "observables",
        },
        {
          _name: "sort",
          _fields: [
            {
              startDate: "desc",
            },
          ],
        },
        {
          _name: "page",
          from: (pagination.page - 1) * pagination.per_page,
          to: pagination.page * pagination.per_page,
          extraData: ["seen", "permissions", "shareCount"],
        },
      ];
      if (get_count) {
        if (filter_query && Array.isArray(filter_query)) {
          query.splice(3, 1);
        } else {
          query.pop();
          query.pop();
        }
        query.push({ _name: "limitedCount" });
      }
      if (filter_query && Array.isArray(filter_query)) {
        if (filter_query.length < 2) {
          query[2] = {
            _name: "filter",
            ...filter_query[0],
          };
        } else {
          query[2] = {
            _name: "filter",
            _and: [
              // {
              //   _ne: {
              //     _field: "status",
              //     _value: "Cancel",
              //   },
              // },
              ...filter_query,
            ],
          };
        }
      }

      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: get_count ? "observables.count" : "observables" },
        }
      );

      let d = response.data;
      if (get_count) {
        setDataCount((pre) => ({ ...pre, observables: d }));
        fetchObservablesDetail(filter_query, false);
      } else {
        setData((pre) => ({ ...pre, loading: false, observable: d }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAssignableUsers = async () => {
    try {
      let query = [
        {
          _name: "getCase",
          idOrName: `~${caseId}`,
        },
        {
          _name: "assignableUsers",
        },
        {
          _name: "filter",
          _field: "locked",
          _value: false,
        },
        {
          _name: "sort",
          _fields: [
            {
              name: "asc",
            },
          ],
        },
      ];

      const response = await axios.post("/cms/api/v1/query", {
        query: query,
      });

      let d = response.data;
      setAssignableUsers(d);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchResponderJobs = async () => {
    try {
      let query = [
        {
          _name: "getCase",
          idOrName: `~${caseId}`,
        },
        {
          _name: "actions",
        },
        {
          _name: "sort",
          _fields: [
            {
              startDate: "desc",
            },
          ],
        },
      ];
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: "case-actions" },
        }
      );

      let d = response.data;
      setresponderJobs(d);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateField = async (filed_name, value, field_id = "") => {
    try {
      if (!props.permissions.mCases) {
        toast.error("permission denied");
        return;
      }
      const response = await axios.patch(`/cms/api/case/~${caseId}`, {
        [filed_name]: value,
      });

      let d = response.data;
      setData((pre) => ({ ...pre, case: d }));
      toast.success(`Successfuly updated "${filed_name}"`);
      fetchCaseDetail(true);
    } catch (error) {
      toast.error("Error fetching data:", error);
    } finally {
      if (field_id) {
        try {
          const ele = document.getElementById(field_id);
          ele.readOnly = true;
          ele.blur();
          fetchCaseDetail(true);
        } catch { }
      }
    }
  };
  const onPageChange = (e, new_page) => {
    setPagination((pre) => ({ ...pre, page: new_page }));
  };
  const handleTagsKeyDown = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value.trim();
      if (newTag && !editTags.includes(newTag)) {
        setEditTags([...editTags, newTag]);
      }
      event.target.value = "";
    }
  };
  const handleDetailPage = (title, Page, onClose = () => { }) => {
    // Close all open pages first
    const updatedPages = extraPages.map((page) =>
      page.title === title ? { ...page, open: true } : { ...page, open: false }
    );

    // Check if the fetched task page is already in the array
    const pageExists = extraPages.some((page) => page.title === title);

    if (!pageExists) {
      // If the fetched task page doesn't exist in the array, add it
      updatedPages.push({
        title: title,
        open: true,
        _Page: Page,
        onClose: onClose,
      });
    }

    setExtraPages(updatedPages);
    setPage(title);
  };

  const handleTagsRemoveTag = (tagName) => {
    setEditTags(editTags.filter((tag) => tag !== tagName));
  };
  const handleFieldUpdate =
    (name, id, field = "") =>
      (e) => {
        let value = document.getElementById(id).value;
        if (field === "date") {
          value = new Date(value).getTime();
        }
        if (field === "tags") {
          if (editTags.length < 1) {
            toast.warn("Atlest one tag is required.")
            return;
          }
          value = editTags;
        }
        updateField(name, value, id);
        setEditField([]);
      };
  const _FetchAgent = async (ids = []) => {
    let _query = "id!=000";

    ids.map((id, i) => {
      if (i === 0) {
        _query += `;id=${parseInt(id)}`;
      } else {
        _query += `,id=${parseInt(id)}`;
      }
      return "";
    });
    await FetchAgents("GET", { q: _query })
      .then((resp) => {
        setAgents(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // On load
  useEffect(() => {
    const _page = parseInt(searchParams.get("page"));
    if (_page && page !== _page) {
      setPage(_page);
    }
    const taskParam = searchParams.get("task");
    const observableParam = searchParams.get("observable");
    if (observableParam) {
      handleDetailPage(
        observableParam,
        <ObservableDetail
          id={observableParam}
          permissions={props.permissions}
          onClose={() => {
            setExtraPages(
              extraPages.filter((obj) => obj.title !== observableParam)
            );
            setPage(2);
          }}
        />,
        () => {
          setPage(2);
        }
      );
    } else if (taskParam) {
      FetchTaskDetail(taskParam);
    } else {
      if (extraPages.length > 0) {
        setExtraPages([]);
        setPage(0);
      }
    }
    fecthAttachments();
    fetchCaseDetail();
    fetchTaskDetail(false, true);
    fetchObservablesDetail(false, true);
    fetchResponderJobs();
    // eslint-disable-next-line
  }, [caseId]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page);
    navigate(`?${newSearchParams.toString()}`);
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (editField === "tags") {
      setEditTags(data.case?.tags);
    }
    // eslint-disable-next-line
  }, [editField]);

  useEffect(() => {
    if (data.case?.status === "Resolved") {
      const _data = data.case;
      let _text = <></>;
      const TextComponent = ({ title, note }) => (
        <Box display="flex" alignItems="center" color="seagreen" gap={1}>
          <Tooltip title="Close Date">
            <CalendarMonthIcon sx={{ mr: 0.5, mb: 0.5 }} fontSize="inherit" />
            {formatCreatedAt(data.case?.endDate)}
          </Tooltip>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="seagreen"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          {note && (
            <Tooltip
              title={
                <Typography
                  sx={{ color: "#fff" }}
                  dangerouslySetInnerHTML={{ __html: note }}
                />
              }
              arrow
            >
              <InfoIcon sx={{ color: "seagreen" }} fontSize="small" />
            </Tooltip>
          )}
        </Box>
      );
      switch (_data?.resolutionStatus) {
        case "TruePositive":
          _text = (
            <TextComponent
              title={`as ${_data?.resolutionStatus} ${_data?.impactStatus}`}
              note={_data.summary}
            />
          );
          break;
        default:
          _text = (
            <TextComponent
              title={`as ${_data?.resolutionStatus}`}
              note={_data.summary}
            />
          );
          break;
      }

      setCaseCloseTitle(_text);
    } else {
      setCaseCloseTitle(undefined);
    }
    // eslint-disable-next-line
  }, [data.case?.status]);

  const TextEditField = useCallback(() => {
    return (
      <Box p="7px" className="w-100">
        {data.case?.title && (
          <Box
            p="7px"
            sx={{
              border: editField === "title" && "1px solid #EAF2FF",
              "&:hover": props.permissions.mCases && {
                border: "1px solid #EAF2FF",
                "& .editButton": {
                  display: "flex",
                },
              },
              position: "relative",
            }}
          >
            <Typography
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleFieldUpdate("title", "basic_title")(e);
                }
              }}
              onClick={() => setEditField("title")}
              component={InputBase}
              variant="body2"
              name="title"
              disabled={!props.permissions.mCases}
              readOnly={!editField === "title"}
              fullWidth
              fontWeight={700}
              id="basic_title"
              defaultValue={data.case?.title}
            />
            <Box
              display={editField === "title" ? "flex" : "none"}
              gap="5px"
              position="absolute"
              sx={{ bottom: -40, right: 0 }}
              className="editButton"
            >
              <IconButton
                sx={{ borderRadius: "5px" }}
                onClick={handleFieldUpdate("title", "basic_title")}
              >
                <CheckCircleIcon sx={{ color: "primary.light" }} />
              </IconButton>

              <IconButton
                sx={{ borderRadius: "5px" }}
                onClick={() => setEditField(null)}
              >
                <CancelIcon sx={{ color: "error.main" }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    );
    // eslint-disable-next-line
  }, [data.case?.title]);

  const MainTitleField = useCallback(() => {
    let _value = "";
    if (data.case?.title.length > 50 && editField !== "main_title") {
      _value = data.case?.title.slice(0, 45) + " ...";
    } else {
      _value = data.case?.title;
    }
    return (
      <Box
        p="7px"
        display="flex"
        alignItems="center"
        sx={{
          border: editField === "title" && "1px solid #EAF2FF",
          "&:hover": props.permissions.mCases && {
            border: "1px solid #EAF2FF",
            "& .editButton": {
              display: "flex",
            },
          },
          position: "relative",
        }}
      >
        <Typography fontSize="1.5rem" fontWeight={700} sx={{ minWidth: 150 }}>
          {" "}
          Case # {data.case?.number} -{" "}
        </Typography>

        <Typography
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              updateField("title", e.target.value, "main_title");
            }
          }}
          onBlur={() => {
            editField === "main_title" && setEditField(null);
          }}
          disabled={!props.permissions.mCases}
          onFocus={() => props.permissions.mCases && setEditField("main_title")}
          component={InputBase}
          variant="body2"
          name="title"
          sx={{
            width:
              data.case?.title.length > 50
                ? "610px"
                : data.case?.title.length * 18,
          }}
          fontSize="1.5rem"
          fontWeight={700}
          id="main_title"
          defaultValue={_value}
        />
        <Box
          display={editField === "main_title" ? "flex" : "none"}
          gap="5px"
          position="absolute"
          sx={{ bottom: -40, right: 0 }}
          className="editButton"
        >
          <IconButton
            sx={{ borderRadius: "5px" }}
            onClick={handleFieldUpdate("title", "main_title")}
          >
            <CheckCircleIcon sx={{ color: "primary.light" }} />
          </IconButton>

          <IconButton
            sx={{ borderRadius: "5px" }}
            onClick={() => {
              setEditField(null);
              document.getElementById("main_title").blur();
            }}
          >
            <CancelIcon sx={{ color: "error.main" }} />
          </IconButton>
        </Box>
      </Box>
    );
    // eslint-disable-next-line
  }, [data.case?.title, props.permissions.mCases, editField === "main_title"]);

  const PlayBookListView = useCallback(() => {
    return <PlaybookListView caseId={caseId} handleClose={() => { showPlaybookDialogBox(false); fetchTaskDetail(null, true); setPage(1); }} />
  }, [])

  const PlayBookSideDialoge = useCallback(() => {
    return <SideCanvas
      show={playbookDialog}
      setShow={(value) => {
        showPlaybookDialogBox(value);
      }}
      title={"Playbook"}
      placement="end"
    >
      <PlayBookListView />
    </SideCanvas>
  }, [playbookDialog])

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Box display="flex" flexGrow={1} flexDirection={"column"}>
          {data.case?.title && (
            <Box display="flex">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIosIcon color="primary" />
              </IconButton>
              <MainTitleField />
            </Box>
          )}
          <Typography variant="caption" color="#A7BBCE">
            {"Dashboard > Task Listing > Case Details"}
          </Typography>
        </Box>
        <Box display="flex" gap={1.25}>
          <Button
            hidden={!props.permissions.mCases}
            startIcon={<MenuBookIcon fontSize="small" />}
            variant={"primary"}
            id="flag_btn"
            onClick={(e) => {
              showPlaybookDialogBox(true);
            }}
            sx={{
              height: "max-content",
              minWidth: 140,
              borderRadius: "5px",
              color: "#fff",
              bgcolor: "success.dark",
              "&:hover": {
                bgcolor: green[700],
              },
            }}
          >
            Playbook
          </Button>
          <Button
            hidden={!props.permissions.mCases}
            startIcon={
              data.case?.status === "Resolved" ? (
                <RefreshIcon fontSize="small" />
              ) : (
                <CheckCircleIcon fontSize="small" />
              )
            }
            variant={"primary"}
            id="flag_btn"
            onClick={(e) => {
              if (data.case?.status === "Resolved") {
                updateField("status", "Open");
              } else {
                showCloseCaseDialog(true);
              }
            }}
            sx={{
              height: "max-content",
              minWidth: 140,
              borderRadius: "5px",
              color: "#fff",
              bgcolor: "success.main",
              "&:hover": {
                bgcolor: "success.main",
              },
            }}
          >
            {data.case?.status === "Open" ? "Close Case" : "Re Open"}
          </Button>
          <Button
            hidden={!props.permissions.mCases}
            startIcon={<FlagIcon fontSize="small" />}
            variant={"primary"}
            id="flag_btn"
            onClick={(e) => {
              updateField("flag", !data.case?.flag);
            }}
            sx={{
              borderRadius: "5px",
              borderColor: "#003272",
              color: "#fff",
              height: "max-content",
              minWidth: 120,
              bgcolor: data.case?.flag ? "#FF6B00" : "#0D2A56",
              "&:hover": {
                bgcolor: data.case?.flag ? "#FF6B00" : "#0D2A56",
              },
            }}
          >
            {data.case?.flag ? "Un Flag" : "Flag"}
          </Button>
          <Button
            hidden={!props.permissions.mCases}
            startIcon={<MergeIcon fontSize="small" />}
            variant="contained"
            onClick={() => {
              showMergeBox(true);
            }}
            color="primary"
            sx={{
              height: "max-content",
              minWidth: 120,
              borderRadius: "5px",
            }}
          >
            Merge
          </Button>
          <MergeDialog
            open={mergeBox}
            setOpen={showMergeBox}
            caseNumber={data.case?.number}
            caseName={data.case?.title}
            caseId={caseId}
          />
          <Button
            hidden={!props.permissions.mCases}
            startIcon={<DeleteForeverIcon fontSize="small" />}
            variant={"primary"}
            onClick={() => {
              showdeleteBox(true);
            }}
            sx={{
              height: "max-content",
              minWidth: 120,
              borderRadius: "5px",
              borderColor: "#003272",
              color: "#fff",
              bgcolor: "#FF3E3E",
              "&:hover": {
                bgcolor: "#FF3E3E",
              },
            }}
          >
            Remove
          </Button>
          <DelteCaseDialog
            caseId={caseId}
            open={deleteBox}
            caseName={data.case?.title}
            caseNumbr={data.case?.number}
            setOpen={showdeleteBox}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1.5}
        component={Paper}
        elevation={0}
        p="12px 20px"
        height={50}
        borderRadius={"10px"}
      >
        <Breadcrumbs separator="" aria-label="breadcrumb">
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <Avatar
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFblndSZeUbaKJTXoUSPh0tpT_VhOLo_UnoQ&usqp=CAU"
              }
              sx={{ width: "26px", height: "26px", mr: 0.5 }}
              fontSize="inherit"
            />
            {data.case?.assignee}
          </Link>
          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <CalendarMonthIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {formatCreatedAt(data.case?._createdAt)}
          </Link>

          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            {caseCloseTitle}
          </Link>

          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="seagreen"
          >
            <AccessTimeIcon
              sx={{ mr: 0.5, color: "seagreen" }}
              fontSize="inherit"
            />
            {calculateDaysFromTimestamp(data.case?.startDate)} Days
          </Typography>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={2.5} mt={2.5}>
        <Grid item xs={8}>
          <Box
            component={Paper}
            p="12px 20px"
            elevation={0}
            sx={{ width: "100%" }}
          >
            {/* Tabs */}
            <Box
              display="flex"
              flexDirection={extraPages.length > 3 && "column"}
              alignItems={extraPages.length < 3 && "center"}
              gap={2}
            >
              <Box
                display="flex"
                gap={1.25}
                sx={{
                  width: "664px",
                  p: "5px",
                  backgroundColor: "#ECF0F4",
                  borderRadius: "55px",
                  "& .MuiButtonBase-root": {
                    borderRadius: "52px",
                    color: "#000",
                  },
                }}
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  variant="text"
                  fullWidth
                  sx={
                    page === 0 && {
                      bgcolor: "#003272",
                      color: "#fff !important",
                      "&:hover": {
                        bgcolor: "#1173FF",
                      },
                    }
                  }
                  disableFocusRipple
                  disableTouchRipple
                  onClick={handlePage(0)}
                >
                  <Box>
                    <ContentPasteSearchIcon
                      fontSize="small"
                      sx={{ mr: 1.25 }}
                    />
                    <span>Detail</span>
                  </Box>
                </Button>
                <Button
                  variant="text"
                  sx={
                    page === 1 && {
                      bgcolor: "#003272",
                      color: "#fff !important",
                      "&:hover": {
                        bgcolor: "#1173FF",
                      },
                    }
                  }
                  fullWidth
                  disableFocusRipple
                  disableTouchRipple
                  onClick={handlePage(1)}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-around"
                  >
                    <Box>
                      <AssignmentTurnedInIcon
                        fontSize="small"
                        sx={{ mr: 1.25 }}
                      />
                      <span>Task</span>
                    </Box>
                    <Chip
                      label={dataCount.task}
                      size="small"
                      sx={{
                        bgcolor: "#FF3E3E",
                        color: "#fff",
                        height: "22px",
                        minWidth: "34px",
                      }}
                    />
                  </Box>
                </Button>
                <Button
                  variant="text"
                  sx={
                    page === 2 && {
                      bgcolor: "#003272",
                      color: "#fff !important",
                      "&:hover": {
                        bgcolor: "#1173FF",
                      },
                    }
                  }
                  fullWidth
                  disableFocusRipple
                  disableTouchRipple
                  onClick={handlePage(2)}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-around"
                  >
                    <Box>
                      <PestControlIcon fontSize="small" sx={{ mr: 1.25 }} />
                      <span>Observables</span>
                    </Box>
                    <Chip
                      label={dataCount.observables ? dataCount.observables : 0}
                      size="small"
                      sx={{
                        bgcolor: "#FF3E3E",
                        color: "#fff",
                        height: "22px",
                        minWidth: "34px",
                      }}
                    />
                  </Box>
                </Button>
              </Box>
              <Box display="flex" gap={2}>
                {extraPages.map((obj) => (
                  <ButtonBase
                    key={obj.title}
                    sx={{ borderRadius: "55px" }}
                    onClick={handleExtraPageOpen(obj.title)}
                  >
                    <Chip
                      color="primary"
                      label={obj.title}
                      onDelete={() => {
                        setExtraPages((pre) =>
                          pre.filter((page) => page.title !== obj.title)
                        );
                        try {
                          obj?.onClose();
                        } catch { }
                        navigate(window.location.pathname);
                      }}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: "5px",
                        height: "46px",
                        minWidth: "169px",
                        maxWidth: "169px",
                        borderRadius: "55px",
                      }}
                    />
                  </ButtonBase>
                ))}
              </Box>
            </Box>
            {/* Pages */}
            <Collapse unmountOnExit in={page === 0}>
              <DetailPage
                data={data.case}
                responderJobs={responderJobs}
                attachments={data.attachments}
              />
            </Collapse>
            <Collapse unmountOnExit in={page === 1}>
              {props.user && (
                <TaskList
                  user={props.user}
                  permissions={props.permissions}
                  tasks={data.task}
                  loading={data.loading}
                  sortFields={sortFields}
                  filters={filters}
                  fetchTask={fetchTaskDetail}
                  count={dataCount.task}
                  pagination={pagination}
                  setPagination={setPagination}
                  onPageChange={onPageChange}
                  caseId={caseId}
                  fetchTaskDetail={FetchTaskDetail}
                  resetDetailPage={(_title) => {
                    setExtraPages((pre) =>
                      pre.filter((e) => e.title !== _title)
                    );
                  }}
                />
              )}
            </Collapse>
            <Collapse unmountOnExit in={page === 2}>
              <ObservablesPage
                permissions={props.permissions}
                data={data.observable}
                loading={data.loading}
                handleSort={handleSort}
                sortFields={sortFields}
                filters={filters}
                fetchObservables={fetchObservablesDetail}
                caseId={caseId}
                count={dataCount.observables}
                pagination={pagination}
                setPagination={setPagination}
                onPageChange={onPageChange}
                resetDetailPage={(_title) => {
                  setExtraPages((pre) => pre.filter((e) => e.title !== _title));
                }}
                onDetailOpen={(id, title) => {
                  handleDetailPage(
                    id,
                    <ObservableDetail
                      id={id}
                      permissions={props.permissions}
                      onClose={() => {
                        setExtraPages(
                          extraPages.filter((obj) => obj.title !== id)
                        );
                        setPage(2);
                      }}
                    />,
                    () => {
                      setPage(2);
                    }
                  );
                }}
              />
            </Collapse>
            {extraPages.map((p) => (
              <Collapse
                key={p.title}
                unmountOnExit
                in={p.open && page === p.title}
              >
                {p._Page}
              </Collapse>
            ))}
          </Box>
        </Grid>
        <Grid container item xs={4} spacing={2} direction="column">
          <Grid item>
            <Box
              component={Paper}
              p="12px 20px"
              elevation={0}
              sx={{ width: "100%" }}
            >
              <Typography variant="h6" fontWeight={700}>
                Basic Information
              </Typography>
              {data.loading ? (
                <LinearProgress sx={{ borderRadius: "4px" }} />
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  flexDirection="column"
                  gap={5.5}
                  mt={1.25}
                  height="100%"
                >
                  <Box display="flex" alignItems="center" gap={8}>
                    <Typography width={50} variant="body2">
                      Title
                    </Typography>
                    <TextEditField />
                  </Box>

                  <Box display="flex" alignItems="center" gap={8}>
                    <Typography width={50} variant="body2">
                      Severity
                    </Typography>
                    <Box flexGrow={1}>
                      {data.case?.severity && (
                        <Box
                          p="7px"
                          sx={{
                            border:
                              editField === "severity" && "1px solid #EAF2FF",
                            "&:hover": props.permissions.mCases && {
                              border: "1px solid #EAF2FF",
                              "& .editButton": {
                                display: "flex",
                              },
                            },
                            position: "relative",
                          }}
                        >
                          {editField === "severity" ? (
                            <ButtonGroup
                              size="large"
                              sx={{
                                border: "1px solid #ECF0F4",
                                height: "40px",
                                "& .MuiButtonBase-root": {
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "#000",
                                },
                              }}
                              aria-label="large button group"
                            >
                              {Object.keys(severity_enum).map((sv) => (
                                <Button
                                  disabled={!props.permissions.mCases}
                                  startIcon={
                                    typeof severity_enum[sv].icon ===
                                      "string" ? (
                                      <img
                                        src={severity_enum[sv].icon}
                                        alt={`Icon for ${severity_enum[sv].value}`}
                                        width="20px"
                                        height="20px"
                                      />
                                    ) : (
                                      severity_enum[sv].icon
                                    )
                                  }
                                  onClick={() => {
                                    if (props.permissions.mCases) {
                                      updateField(
                                        "severity",
                                        severity_enum[sv]._value
                                      );
                                      setEditField(null);
                                    }
                                  }}
                                  variant="text"
                                  key={severity_enum[sv].value}
                                  fullWidth
                                >
                                  {severity_enum[sv].value}
                                </Button>
                              ))}
                            </ButtonGroup>
                          ) : (
                            <Typography
                              component={ButtonBase}
                              onClick={() => {
                                if (props.permissions.mCases) {
                                  setEditField("severity");
                                }
                              }}
                              variant="body2"
                              fontWeight={700}
                            >
                              {typeof severity_enum[data.case?.severity]
                                .icon === "string" ? (
                                <Box
                                  component="img"
                                  src={severity_enum[data.case?.severity]?.icon}
                                  alt="Severity"
                                  width={20}
                                  height={20}
                                  mr={"5px"}
                                />
                              ) : (
                                severity_enum[data.case?.severity]?.icon
                              )}
                              {severity_enum[data.case?.severity].value}
                            </Typography>
                          )}
                          <Box
                            display={editField === "severity" ? "flex" : "none"}
                            gap="5px"
                            position="absolute"
                            sx={{ bottom: -40, right: 0 }}
                            className="editButton"
                          >
                            <IconButton sx={{ borderRadius: "5px" }}>
                              <CheckCircleIcon
                                sx={{ color: "primary.light" }}
                              />
                            </IconButton>

                            <IconButton
                              sx={{ borderRadius: "5px" }}
                              onClick={() => setEditField(null)}
                            >
                              <CancelIcon sx={{ color: "error.main" }} />
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap={8}>
                    <Typography width={50} variant="body2">
                      TLP
                    </Typography>
                    <Box flexGrow={1} ml={-1}>
                      <Box
                        p="7px"
                        sx={{
                          border: editField === "tlp" && "1px solid #EAF2FF",
                          "&:hover": props.permissions.mCases && {
                            border: "1px solid #EAF2FF",
                            "& .editButton": {
                              display: "flex",
                            },
                          },
                          position: "relative",
                        }}
                      >
                        {editField === "tlp" ? (
                          tlp_enum.map((item) => (
                            <ButtonBase
                              disabled={!props.permissions.mCases}
                              key={item.title}
                              onClick={() => {
                                if (props.permissions.mCases) {
                                  updateField("tlp", item.value);
                                  setEditField(null);
                                }
                              }}
                            >
                              <Chip
                                sx={{
                                  minWidth: "82px",
                                  backgroundColor: item.bgColor,
                                }}
                                icon={
                                  <CircleIcon
                                    fontSize="small"
                                    style={{ color: item.color }}
                                  />
                                }
                                label={item.title}
                              />
                            </ButtonBase>
                          ))
                        ) : (
                          <Typography
                            component={ButtonBase}
                            onClick={() => {
                              if (props.permissions.mCases) {
                                setEditField("tlp");
                              }
                            }}
                            variant="body2"
                            fontWeight={700}
                          >
                            <Chip
                              sx={{
                                height: "24px",
                                backgroundColor: tlp_enum.find(
                                  (i) => i.value === data.case?.tlp
                                )?.bgColor,
                              }}
                              icon={
                                <CircleIcon
                                  fontSize="small"
                                  style={{
                                    color: tlp_enum.find(
                                      (i) => i.value === data.case?.tlp
                                    )?.color,
                                  }}
                                />
                              }
                              label={
                                tlp_enum.find((i) => i.value === data.case?.tlp)
                                  ?.title
                              }
                            />
                          </Typography>
                        )}
                        <Box
                          display={editField === "tlp" ? "flex" : "none"}
                          gap="5px"
                          position="absolute"
                          sx={{ bottom: -40, right: 0 }}
                          className="editButton"
                        >
                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={handleFieldUpdate("title", "")}
                          >
                            <CheckCircleIcon sx={{ color: "primary.light" }} />
                          </IconButton>

                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={() => setEditField(null)}
                          >
                            <CancelIcon sx={{ color: "error.main" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap={8}>
                    <Typography width={50} variant="body2">
                      PAP
                    </Typography>
                    <Box flexGrow={1} ml={-1}>
                      <Box
                        p="7px"
                        sx={{
                          border: editField === "pap" && "1px solid #EAF2FF",
                          "&:hover": props.permissions.mCases && {
                            border: "1px solid #EAF2FF",
                            "& .editButton": {
                              display: "flex",
                            },
                          },
                          position: "relative",
                        }}
                      >
                        {editField === "pap" ? (
                          tlp_enum.map((item) => (
                            <ButtonBase
                              key={item.title}
                              onClick={() => {
                                updateField("pap", item.value);
                                setEditField(null);
                              }}
                            >
                              <Chip
                                sx={{
                                  minWidth: "82px",
                                  backgroundColor: item.bgColor,
                                }}
                                icon={
                                  <CircleIcon
                                    fontSize="small"
                                    style={{ color: item.color }}
                                  />
                                }
                                label={item.title}
                              />
                            </ButtonBase>
                          ))
                        ) : (
                          <Typography
                            component={ButtonBase}
                            onClick={() => {
                              if (props.permissions.mCases) {
                                setEditField("pap");
                              }
                            }}
                            variant="body2"
                            fontWeight={700}
                          >
                            <Chip
                              sx={{
                                height: "24px",
                                backgroundColor: pap_enum.find(
                                  (i) => i.value === data.case?.pap
                                )?.bgColor,
                              }}
                              icon={
                                <CircleIcon
                                  fontSize="small"
                                  style={{
                                    color: pap_enum.find(
                                      (i) => i.value === data.case?.pap
                                    )?.color,
                                  }}
                                />
                              }
                              label={
                                pap_enum.find((i) => i.value === data.case?.pap)
                                  ?.title
                              }
                            />
                          </Typography>
                        )}
                        <Box
                          display={editField === "pap" ? "flex" : "none"}
                          gap="5px"
                          position="absolute"
                          sx={{ bottom: -40, right: 0 }}
                          className="editButton"
                        >
                          <IconButton sx={{ borderRadius: "5px" }}>
                            <CheckCircleIcon sx={{ color: "primary.light" }} />
                          </IconButton>

                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={() => setEditField(null)}
                          >
                            <CancelIcon sx={{ color: "error.main" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap={8}>
                    <Typography width={50} variant="body2">
                      Assignee
                    </Typography>

                    <Box flexGrow={1} display="flex">
                      <Box
                        p="7px"
                        className="w-100"
                        sx={{
                          border:
                            editField === "assignee" && "1px solid #EAF2FF",
                          "&:hover": props.permissions.mCases && {
                            border: "1px solid #EAF2FF",
                            "& .editButton": {
                              display: "flex",
                            },
                          },
                          position: "relative",
                        }}
                      >
                        <Box display="flex" alignItems="center" gap={"6px"}>
                          <ProfileAvatar sx={{ mr: 0.5 }} />

                          {editField === "assignee" ? (
                            <Autocomplete
                              options={assignableUsers ? assignableUsers : []}
                              getOptionLabel={(user) => user.login}
                              fullWidth
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  updateField(
                                    "owner",
                                    e.target.value,
                                    "basic_assignee"
                                  );
                                  setEditField(null);
                                }
                              }}
                              onChange={(e, newvalue) => {
                                if (newvalue.login) {
                                  updateField(
                                    "owner",
                                    newvalue.login,
                                    "basic_assignee"
                                  );
                                  setEditField(null);
                                }
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  margin="dense"
                                  variant="standard"
                                  label="assignee"
                                  id="basic_assignee"
                                />
                              )}
                            />
                          ) : (
                            <Typography
                              // onKeyDown={(e) => {
                              //   if (e.key === "Enter") {
                              //     updateField(
                              //       "assignee",
                              //       e.target.value,
                              //       "basic_assignee"
                              //     );
                              //   }
                              // }}
                              onClick={() => {
                                if (props.permissions.mCases) {
                                  setEditField("assignee");
                                  fetchAssignableUsers();
                                }
                              }}
                              component={InputBase}
                              variant="body2"
                              name="assignee"
                              readOnly={!editField === "assignee"}
                              fullWidth
                              fontWeight={700}
                              id="basic_assignee"
                              defaultValue={data.case?.assignee}
                            >
                              data.case?.assignee
                            </Typography>
                          )}
                        </Box>
                        <Box
                          display={editField === "assignee" ? "flex" : "none"}
                          gap="5px"
                          position="absolute"
                          sx={{ bottom: -40, right: 0 }}
                          className="editButton"
                        >
                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={handleFieldUpdate(
                              "assignee",
                              "basic_assignee"
                            )}
                          >
                            <CheckCircleIcon sx={{ color: "primary.light" }} />
                          </IconButton>

                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={() => setEditField(null)}
                          >
                            <CancelIcon sx={{ color: "error.main" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap={8}>
                    <Typography width={50} variant="body2">
                      Created Date
                    </Typography>
                    {data.case?._createdAt && (
                      <Box flexGrow={1} display="flex">
                        <Box
                          p="7px"
                          className="w-100"
                          sx={{
                            border:
                              editField === "_created_at" &&
                              "1px solid #EAF2FF",
                            "&:hover": props.permissions.mCases && {
                              border: "1px solid #EAF2FF",
                              "& .editButton": {
                                display: "flex",
                              },
                            },
                            position: "relative",
                          }}
                        >
                          <Typography
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                updateField(
                                  "_created_at",
                                  new Date(e.target.value).getTime(),
                                  "basic_created_at"
                                );
                              }
                            }}
                            type="datetime-local"
                            onClick={() => {
                              if (props.permissions.mCases) {
                                setEditField("_created_at");
                              }
                            }}
                            component={InputBase}
                            variant="body2"
                            id="basic_created_at"
                            name="title"
                            readOnly={
                              !props.permissions.mCases &&
                              !editField === "_created_at"
                            }
                            fullWidth
                            fontWeight={700}
                            defaultValue={convertToDateTimeLocal(
                              data.case?._createdAt
                            )}
                          />

                          <Box
                            display={
                              editField === "_created_at" ? "flex" : "none"
                            }
                            gap="5px"
                            position="absolute"
                            sx={{ bottom: -40, right: 0 }}
                            className="editButton"
                          >
                            <IconButton
                              sx={{ borderRadius: "5px" }}
                              onClick={handleFieldUpdate(
                                "_created_at",
                                "basic_created_at",
                                "date"
                              )}
                            >
                              <CheckCircleIcon
                                sx={{ color: "primary.light" }}
                              />
                            </IconButton>

                            <IconButton
                              sx={{ borderRadius: "5px" }}
                              onClick={() => setEditField(null)}
                            >
                              <CancelIcon sx={{ color: "error.main" }} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box display="flex" alignItems="center" gap={8}>
                    <Typography width={50} variant="body2">
                      Tags
                    </Typography>
                    <Box flexGrow={1} display="flex">
                      <Box
                        p="7px"
                        sx={{
                          border: editField === "tags" && "1px solid #EAF2FF",
                          border: data.case?.tags?.length < 1 && "1px solid #EAF2FF",
                          width: "100%",
                          "&:hover": props.permissions.mCases && {
                            border: "1px solid #EAF2FF",
                            "& .editButton": {
                              display: "flex",
                            },
                          },
                          position: "relative",
                        }}
                      >
                        <ButtonBase
                          onClick={() => {
                            if (props.permissions.mCases) {
                              setEditField("tags");
                            }
                          }}
                        >
                          <Grid item container spacing={1.25}>
                            {(editField === "tags" || data.case?.tags?.length < 1)
                              ? editTags.map((tag, i) => (
                                <Grid item key={tag}>
                                  <Box
                                    sx={{
                                      height: "20px",
                                      p: "5px",
                                      borderRadius: "2px 0px 0px 2px",
                                      borderLeft: "5px solid #13D38E",
                                    }}
                                    display="flex"
                                    alignItems="center"
                                    gap="4px"
                                    bgcolor={"#ECF0F4"}
                                  >
                                    {tag}
                                    {(editField === "tags" || data.case?.tags?.length < 1) && (
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleTagsRemoveTag(tag)
                                        }
                                      >
                                        <CancelIcon
                                          sx={{ fontSize: "15px" }}
                                        />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Grid>
                              ))
                              : data.case?.tags &&
                              data.case?.tags.map((tag, i) => (
                                <Grid item key={tag}>
                                  <Box
                                    sx={{
                                      height: "20px",
                                      p: "5px",
                                      borderRadius: "2px 0px 0px 2px",
                                      borderLeft: "5px solid #13D38E",
                                    }}
                                    display="flex"
                                    alignItems="center"
                                    gap="4px"
                                    bgcolor={"#ECF0F4"}
                                  >
                                    {tag}
                                  </Box>
                                </Grid>
                              ))}
                            {(editField === "tags" || data.case?.tags?.length < 1) && (
                              <Grid item>
                                <InputBase
                                  fullWidth
                                  autoFocus
                                  id="basic_tags"
                                  onKeyDown={handleTagsKeyDown}
                                  placeholder="Enter tags"
                                  sx={{
                                    borderBottom: "1px solid",
                                    borderColor: "primary.light",
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </ButtonBase>
                        <Box
                          display={editField === "tags" ? "flex" : "none"}
                          gap="5px"
                          position="absolute"
                          sx={{ bottom: -40, right: 0, width: "100%" }}
                          className="editButton"
                        >
                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={handleFieldUpdate(
                              "tags",
                              "basic_tags",
                              "tags"
                            )}
                          >
                            <CheckCircleIcon sx={{ color: "primary.light" }} />
                          </IconButton>

                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={() => setEditField(null)}
                          >
                            <CancelIcon sx={{ color: "error.main" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          {agents.length > 0 && (
            <Grid item>
              <Box
                component={Paper}
                p="12px 20px"
                elevation={0}
                sx={{ width: "100%", minHeight: 200 }}
              >
                <Typography variant="h6" fontWeight={700}>
                  Agents Information
                </Typography>
                <br />
                <AgentTable agents={agents} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* Close Dialog */}
      <CloseCaseDialog
        open={closeCaseDialog}
        setOpen={showCloseCaseDialog}
        caseId={caseId}
      />
      {/* Show PLaybook */}
      <PlayBookSideDialoge />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions,
    user: state.cms_user,
  };
};
export default connect(mapStateToProps, null)(CaseDetail);
