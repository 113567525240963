import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { Box } from "@mui/material";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
export default function ChartPolar({ data = { labels: [], data: [] }, ...other }) {
  const _data = {
    labels: data.labels,
    datasets: [
      {
        label: "Figures",
        data: data.values,
        backgroundColor: [
          "rgb(87, 193, 123)",
          "rgb(111, 135, 216)",
          "rgb(102, 61, 184)",
          "rgb(188, 82, 188)",
          "rgb(218, 160, 93)",
        ],
        borderWidth: 2,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  return data.values.length > 0 ? (
    <PolarArea data={_data} options={options} {...other} />
  ) : (
    <Box
      display="flex"
      minHeight="105px"
      justifyContent="center"
      alignItems="center"
      color="grey"
    >
      No data
    </Box>
  );
}
