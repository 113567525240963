import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import InputField from "../../../components/Field/BootStrap/InputField";

import Btn from "../../../components/Buttons/BootStrap/Btn";
import PlusIcon from "../../../static/icons/plus-icon.svg";
import ReactScrollbar from "react-scrollbar";
import { useState } from "react";
import { toast } from "react-toastify";
import { permissions_enum } from "../enums/enums";
import cms from "../../../config/axiosConfig";

export default function CreateProfileDialog({
  open,
  setOpen,
  initial_fields,
  update = false,
  reFreshFunc,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const [fields, setFields] = useState(initial_fields);

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields((pre) => ({ ...pre, [name]: value }));
  };
  const handlePermissions = (value) => (e) => {
    console.log(value);
    const add = e.target.checked;
    if (add) {
      setFields((pre) => ({
        ...pre,
        permissions: [...pre.permissions, value],
      }));
    } else {
      setFields((pre) => ({
        ...pre,
        permissions: pre.permissions.filter((p) => p !== value),
      }));
    }
  };

  // andle Submit
  const validate = () => {
    let valid = true;
    let message = "";
    if (fields.permissions.length < 1) {
      message = "Add atleast one permissions";
      valid = false;
    } else if (!fields.name) {
      message = "Write profile title it is required";
      valid = false;
    }
    console.log(message);
    if (!valid) {
      toast.error(message);
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    if (validate()) {
      try {
        await cms
          .post("/cms/api/profile", fields)
          .then((res) => {
            if (res.status === 201) {
              toast.success("Profile created successfuly.");
              setOpen(false);
              reFreshFunc();
            }
          })
          .catch((res) => {
            toast.error(res.response?.data?.message);
          });
      } catch {
        toast.error("something went wrong.");
      }
    }
  };

  const handleUpdate = async (e) => {
    if (validate()) {
      try {
        await cms
          .patch(`/cms/api/profile/${fields.name}`, fields)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Profile updated successfuly.");
              setOpen(false);
              reFreshFunc();
            }
          })
          .catch((res) => {
            toast.error(res.response?.data?.message);
          });
      } catch {
        toast.error("Something went wrong.");
      }
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h5" fontWeight={700}>
          {update ? "Update profile" : "Add Profile"}
        </Typography>
        <Typography variant="caption" color="#A7BBCE">
          Profile details
        </Typography>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
            <Grid container direction="column" item spacing={1.25}>
              <Grid item>
                <FormControl fullWidth>
                  <RadioGroup
                    sx={{ gap: 1.5 }}
                    row
                    value={fields.isAdmin ? "admin" : "notadmin"}
                    onChange={(e) => {
                      setFields((pre) => ({
                        ...pre,
                        isAdmin: e.target.value === "admin",
                      }));
                    }}
                  >
                    <FormControlLabel
                      value={"admin"}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 32,
                            },
                          }}
                        />
                      }
                      label={`Administration Profile`}
                    />
                    <FormControlLabel
                      value={"notadmin"}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 32,
                            },
                          }}
                        />
                      }
                      label="Organisation Profile"
                    />
                  </RadioGroup>
                  <Typography variant="caption" fontSize={10}>
                    Permissions for organisation user profiles
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Name*
                </Typography>
              </Grid>
              <Grid item>
                <InputField
                  placeholder="Profile Title"
                  fullWidth
                  name="name"
                  value={fields.name}
                  onChange={handleFieldChange}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Permissions*
                </Typography>
              </Grid>
              <Grid item>
                <ReactScrollbar style={{ height: 350 }}>
                  <Box
                    border="1px solid #ECF0F4"
                    className="rounded"
                    p="21px 14px"
                  >
                    <Box display="flex" flexDirection="column" gap={"26px"}>
                      {Object.keys(
                        permissions_enum[fields.isAdmin ? "admin" : "org"]
                      ).map((key) => (
                        <Box display="flex" alignItems="center" gap={2}>
                          <Checkbox
                            onChange={handlePermissions(key)}
                            color="primary"
                            checked={fields.permissions.find((p) => p === key)}
                            sx={{ borderRadius: "5px", p: 0 }}
                          />
                          <Typography fontSize={14} fontWeight={400}>
                            {
                              permissions_enum[
                                fields.isAdmin ? "admin" : "org"
                              ][key]
                            }
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </ReactScrollbar>
                <Typography variant="caption" fontSize={10}>
                  Selected {fields.permissions.length}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="body2" fontWeight={400} color="#000">
            * Required field
          </Typography>
          <Btn
            onClick={update ? handleUpdate : handleSubmit}
            text={update ? "Update Profile" : "Add Profile"}
            style={{ backgroundColor: "#003272" }}
            startIcon={PlusIcon}
            height={50}
            fontSize={20}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
