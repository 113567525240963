import { datatypes_options } from "./utils";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import NumbersIcon from "@mui/icons-material/Numbers";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import {
  case_status_enum,
  impactStatus_enum,
  pap_enum,
  resolutionStatus_enum,
  severity_enum,
  tlp_enum,
} from "../../pages/Cases/enums/enums";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import cms from "../../config/axiosConfig";
import { case_tasks_states } from "../../pages/Tasks/enums/enums";
export const organisation = () => {
  try {
    const _organization = JSON.parse(
      localStorage.getItem("cms_user")
    )?.organisation;

    return _organization ? _organization : "";
  } catch {
    return "";
  }
};

const fetchAssignableUsers = async () => {
  try {
    let query = [
      {
        _name: "getOrganisation",
        idOrName: organisation(),
      },
      {
        _name: "users",
      },
      {
        _name: "sort",
        _fields: [
          {
            login: "asc",
          },
        ],
      },
      {
        _name: "page",
        from: 0,
        to: 1000,
        // organisation: "StrangeBee",
      },
    ];

    const response = await cms.post("/cms/api/v1/query", {
      query: query,
    });

    let d = response.data;
    let options = [];

    d.map((d) => {
      options.push({ title: d.name, value: d.login });
      return "";
    });

    return options;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const user_list = await fetchAssignableUsers();

const Severity_options = () => {
  let options = [];
  Object.keys(severity_enum).map((s) => {
    const obj = severity_enum[s];
    options.push({
      title: obj.value,
      value: obj._value,
    });
    return "";
  });
  return options;
};

export const CasesFilterFields = [
  {
    name: "_createdAt",
    label: "Created at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedAt",
    label: "Updated at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "endDate",
    label: "End date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "startDate",
    label: "Start date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  // {
  //   name: "computed.handlingDuration",
  //   label: "Computed Duration",
  //   type: datatypes_options.NUMARIC.type,
  //   operators: datatypes_options.NUMARIC.operators,
  //   Icon: <NumbersIcon fontSize="small" color="primary" />,
  // },
  // {
  //   name: "computed.handlingDurationInDays",
  //   label: "Computed Duration In Days",
  //   type: datatypes_options.NUMARIC.type,
  //   operators: datatypes_options.NUMARIC.operators,
  //   Icon: <NumbersIcon fontSize="small" color="primary" />,
  // },
  // {
  //   name: "computed.handlingDurationInHours",
  //   label: "Computed Duration In Hours",
  //   type: datatypes_options.NUMARIC.type,
  //   operators: datatypes_options.NUMARIC.operators,
  //   Icon: <NumbersIcon fontSize="small" color="primary" />,
  // },
  // {
  //   name: "computed.handlingDurationInMinutes",
  //   label: "Computed Duration In Minutes",
  //   type: datatypes_options.NUMARIC.type,
  //   operators: datatypes_options.NUMARIC.operators,
  //   Icon: <NumbersIcon fontSize="small" color="primary" />,
  // },
  // {
  //   name: "computed.handlingDurationInSeconds",
  //   label: "Computed Duration In Seconds",
  //   type: datatypes_options.NUMARIC.type,
  //   operators: datatypes_options.NUMARIC.operators,
  //   Icon: <NumbersIcon fontSize="small" color="primary" />,
  // },
  {
    name: "number",
    label: "Case number",
    type: datatypes_options.NUMARIC.type,
    operators: datatypes_options.NUMARIC.operators,
    Icon: <NumbersIcon fontSize="small" color="primary" />,
  },
  {
    name: "flag",
    label: "Flag",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "actionRequired",
    label: "Action Required",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "pap",
    label: "PAP",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: pap_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "tlp",
    label: "TLP",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: tlp_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "severity",
    label: "Severity",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: Severity_options(),
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "impactStatus",
    label: "Impact status",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: impactStatus_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "assignee",
    label: "Assignee",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "owningOrganisation",
    label: "Owning Organisation",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "status",
    label: "Status",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: case_status_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "resolutionStatus",
    label: "Resolution Status",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: resolutionStatus_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "patternId",
    label: "Pattern ID",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "summary",
    label: "Summary",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "tags",
    label: "tags",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "descripton",
    label: "Descripton",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "title",
    label: "Title",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
];

export const CaseTemplatesFilterFields = [
  {
    name: "_createdAt",
    label: "Created at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedAt",
    label: "Updated at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "flag",
    label: "Flag",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "pap",
    label: "PAP",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: pap_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "tlp",
    label: "TLP",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: tlp_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "severity",
    label: "Severity",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: Severity_options(),
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "summary",
    label: "Summary",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "tags",
    label: "tags",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "descripton",
    label: "Descripton",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "displayName",
    label: "Display Name",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "title",
    label: "Title",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "titlePrefix",
    label: "Title Prefix",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
];
export const TaskFilterFields = [
  {
    name: "_createdAt",
    label: "Created at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedAt",
    label: "Updated at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "startDate",
    label: "Start Date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "endDate",
    label: "End date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "dueDate",
    label: "Due Date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "assignee",
    label: "Assignee",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedBy",
    label: "Updated By",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "_createdBy",
    label: "Created By",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "status",
    label: "Status",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: Object.keys(case_tasks_states).map((s) => {
      const obj = case_tasks_states[s];
      return {
        title: obj._value,
        value: obj._value,
      };
    }),
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "tags",
    label: "tags",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "descripton",
    label: "Descripton",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  // {
  //   name: "group",
  //   label: "Group",
  //   type: datatypes_options.MultiSelection.type,
  //   options: [],
  //   operators: datatypes_options.MultiSelection.operators,
  //   Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  // },
  {
    name: "title",
    label: "Title",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "flag",
    label: "Flag",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "actionRequired",
    label: "Action Required",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "order",
    label: "Order",
    type: datatypes_options.NUMARIC.type,
    operators: datatypes_options.NUMARIC.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
];

export const ObservableFilterField = [
  {
    name: "_createdAt",
    label: "Created at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedAt",
    label: "Updated at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "startedDate",
    label: "Start Date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedBy",
    label: "Updated By",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "_createdBy",
    label: "Created By",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "ioc",
    label: "I.O.C",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "ignoreSimilarity",
    label: "Ignore Similarity",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "sighted",
    label: "Sighted",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "status",
    label: "Status",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "tags",
    label: "Tags",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "attachment.contentType",
    label: "Attachment ContentType",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "attachment.hashes",
    label: "Attachment Hashes",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "attachment.id",
    label: "Attachment Id",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "attachment.name",
    label: "Attachment Name",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "attachment.size",
    label: "Attachment Size",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "data",
    label: "Data",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "dataType",
    label: "Data Type",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "message",
    label: "Message",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "tlp",
    label: "Tlp",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: tlp_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
];

export const AlertFilterFields = [
  {
    name: "_createdAt",
    label: "Created at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedAt",
    label: "Updated at",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "date",
    label: "Date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "importDate",
    label: "Import Date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "lastSyncDate",
    label: "LastSync Date",
    type: datatypes_options.DATE.type,
    operators: datatypes_options.DATE.operators,
    Icon: <QueryBuilderIcon fontSize="small" color="primary" />,
  },
  {
    name: "_updatedBy",
    label: "Updated By",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "_createdBy",
    label: "Created By",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "actionRequired",
    label: "Action Required",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "flag",
    label: "Flag",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },

  {
    name: "follow",
    label: "Follow",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "imported",
    label: "Imported",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "read",
    label: "Read",
    type: datatypes_options.BOOLEAN.type,
    operators: datatypes_options.BOOLEAN.operators,
    Icon: <DownloadDoneIcon fontSize="small" color="primary" />,
  },
  {
    name: "case",
    label: "Case",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "case",
    label: "Case",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "description",
    label: "Description",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "type",
    label: "Type",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "title",
    label: "Title",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "summary",
    label: "Summary",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "source",
    label: "Source",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "sourceRef",
    label: "Source Ref",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "tags",
    label: "Tags",
    type: datatypes_options.MultiSelection.type,
    options: [],
    operators: datatypes_options.MultiSelection.operators,
    Icon: <LocalOfferIcon fontSize="small" color="primary" />,
  },
  {
    name: "user",
    label: "User",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: user_list,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "pap",
    label: "PAP",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: pap_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "tlp",
    label: "TLP",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: tlp_enum,
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
  {
    name: "severity",
    label: "Severity",
    type: datatypes_options.AUTOCOMPLETE_CHIPS.type,
    options: Severity_options(),
    operators: datatypes_options.AUTOCOMPLETE_CHIPS.operators,
    Icon: <BookmarkIcon fontSize="small" color="primary" />,
  },
];
