import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Divider,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";

function DetailView({ row }) {
  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Rationale
        </Typography>
        <Typography variant="body1">{row.rationale}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Remediation
        </Typography>
        <Typography variant="body1">{row.remediation}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Description
        </Typography>
        <Typography variant="body1">{row.description}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Check (Condition: all)
        </Typography>
        <ol>
          {row.rules.map((r) => (
            <Typography key={r.rule} component={"li"} variant="body1">
              {r.rule}
            </Typography>
          ))}
        </ol>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={500}>
          Compliance
        </Typography>
        <Box>
          {row.compliance.map((c) => (
            <Typography noWrap key={c.key} variant="body1">
              <strong>{c.key}:</strong>
              {c.value}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function Row(props) {
  const { row, key } = props;
  const [open, setOpen] = React.useState(false);

  const handleChange = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow key={key} hover>
        <TableCell onClick={handleChange}>{row.id}</TableCell>
        <TableCell onClick={handleChange}>{row.title}</TableCell>
        <TableCell onClick={handleChange}>
          <b>Command:</b> {row.command}
        </TableCell>
        <TableCell onClick={handleChange} style={{ minWidth: "150px" }}>
          <FiberManualRecordIcon
            sx={{ color: row.result === "failed" ? "#d32f2f" : "green" }}
            fontSize="small"
          />
          {row.result.toUpperCase()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} maxWidth={1510}>
              <DetailView row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function PaginationControls({
  totalResults,
  from,
  size,
  onPageChange,
  pageSize,
  setPageSize,
}) {
  const pageCount = Math.ceil(totalResults / size);

  const handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    setPageSize(newPageSize);
    const newFrom = Math.floor(from / size) * newPageSize; // Calculate the new 'from' value
    onPageChange(newFrom, newPageSize);
  };
  const handleChange = (event, newPage) => {
    const newFrom = (newPage - 1) * size;
    onPageChange(newFrom, size);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <Pagination
        count={pageCount}
        page={from / size + 1}
        onChange={handleChange}
      />

      <Select
        size="small"
        className="rounded"
        sx={{ minWidth: 100, height: 25 }}
        value={pageSize}
        label="Row per page"
        onChange={handlePageSizeChange}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </Box>
  );
}

export default function InventoryTable({
  rows = [],
  handlePageChange,
  pagination = false,
  loading = false,
  total_count = 0,
}) {
  const [pageSize, setPageSize] = React.useState(pagination.size);

  return (
    <Box component={Paper}>
      {pagination && (
        <PaginationControls
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalResults={total_count}
          from={pagination.from}
          size={pagination.size}
          onPageChange={handlePageChange}
        />
      )}
      <Divider my={1} />
      <TableContainer>
        {loading ? (
          <Table
            size="small"
            sx={{
              "& .MuiTableCell-root": {
                fontSize: 12,
              },
            }}
          >
            <TableHead>
              <LinearProgress color="primary" />
            </TableHead>
          </Table>
        ) : (
          <Table
            size="small"
            sx={{
              "& .MuiTableCell-root": {
                fontSize: 12,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row._id} row={row} />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Divider my={1} />
      {pagination && (
        <PaginationControls
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalResults={total_count}
          from={pagination.from}
          size={pagination.size}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
  );
}
