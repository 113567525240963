import { FormControlLabel, Radio } from "@mui/material";

export const BoolSelectorButtons = ({ field_name, label, value }) => {
  let query = {};

  switch (value) {
    case "Yes":
      query = { _field: field_name, _value: true };
      break;
    case "No":
      query = { _field: field_name, _value: false };
      break;
    default:
      query = {};
      break;
  }
  return (
    <FormControlLabel
      name="date-buttons-group"
      value={JSON.stringify(query)}
      control={<Radio />}
      label={label}
    />
  );
};
