import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
export const tasks_states = {
  in_progress: "#FFFFFF",
  waiting: "pink",
  open: "#1173FF",
  closed: "#FF3E3E",
};
export const case_tasks_states = {
  waiting: {
    icon: <HourglassBottomIcon fontSize="small" />,
    color: "pink",
    _value: "Waiting",
  },
  inprogress: {
    icon: <PublishedWithChangesIcon fontSize="small" />,
    color: "#FFFFFF",
    _value: "InProgress",
  },
  cancel: {
    icon: <DoDisturbOnIcon fontSize="small" />,
    color: "#1173FF",
    _value: "Cancel",
  },
  completed: {
    icon: <PlayCircleIcon fontSize="small" />,
    color: "#FF3E3E",
    _value: "Completed",
  },
};
