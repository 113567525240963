import { AutoCompleteChips } from "./components/AutoCompleteChips";
import { BoolSelectorButtons } from "./components/BoolSelectorButtons";
import { DateRadioButton } from "./components/DateRadioButton";
import { CustomDateRadipoButton } from "./components/DateRangePicker";
import { MultiSelectionTextField } from "./components/MultiSelectionTextField.js";
import { NumaricFieldBox } from "./components/NumaricFieldBox";

export const datatypes_options = {
  DATE: {
    type: "DATE",
    operators: {
      Empty: ({ ...props }) => (
        <DateRadioButton {...props} label="Empty" value={"Empty"} />
      ),
      "Last 7 Days": ({ ...props }) => (
        <DateRadioButton {...props} label="Last 7 Days" value={"Last 7 Days"} />
      ),
      "Last 30 Days": ({ ...props }) => (
        <DateRadioButton
          {...props}
          label="Last 30 Days"
          value={"Last 30 Days"}
        />
      ),
      "Last 6 Months": ({ ...props }) => (
        <DateRadioButton
          {...props}
          label="Last 6 Months"
          value={"Last 6 Months"}
        />
      ),
      "Last Year": ({ ...props }) => (
        <DateRadioButton {...props} label="Last Year" value={"Last Year"} />
      ),
      Custom: ({ ...props }) => (
        <CustomDateRadipoButton {...props} label="Custom" value={"Custom"} />
      ),
    },
  },
  NUMARIC: {
    type: "NUMARIC",
    operators: {
      Empty: ({ ...props }) => (
        <NumaricFieldBox label="Empty" value={"Empty"} {...props} />
      ),
      "=": ({ ...props }) => (
        <NumaricFieldBox label="Is Equal" value={"="} {...props} />
      ),
      "!=": ({ ...props }) => (
        <NumaricFieldBox label="Not Equal" value={"!="} {...props} />
      ),
      "<": ({ ...props }) => (
        <NumaricFieldBox label="Greater Then" value={"<"} {...props} />
      ),
      "<=": ({ ...props }) => (
        <NumaricFieldBox label="Greater Or Equal" value={"<="} {...props} />
      ),
      ">": ({ ...props }) => (
        <NumaricFieldBox label="Less Then" value={">"} {...props} />
      ),
      ">=": ({ ...props }) => (
        <NumaricFieldBox label="Less Or Equal" value={">="} {...props} />
      ),
    },
  },
  BOOLEAN: {
    type: "BOOLEAN",
    operators: {
      Yes: ({ field_name }) => (
        <BoolSelectorButtons
          field_name={field_name}
          label="Yes"
          value={"Yes"}
        />
      ),
      No: ({ field_name }) => (
        <BoolSelectorButtons field_name={field_name} label="No" value={"No"} />
      ),
    },
  },
  AUTOCOMPLETE_CHIPS: {
    type: "AUTOCOMPLETE_CHIPS",
    operators: {
      Empty: ({ ...props }) => (
        <AutoCompleteChips label="Is Empty" value={"Empty"} {...props} />
      ),
      "Any Of": ({ ...props }) => (
        <AutoCompleteChips label="Any Of" value={"Any Of"} {...props} />
      ),
      "All Of": ({ ...props }) => (
        <AutoCompleteChips label="All Of" value={"All Of"} {...props} />
      ),
      "None Of": ({ ...props }) => (
        <AutoCompleteChips label="None Of" value="None Of" {...props} />
      ),
    },
  },
  MultiSelection: {
    type: "MultiSelection",
    operators: {
      Empty: ({ ...props }) => (
        <MultiSelectionTextField label="Is Empty" value={"Empty"} {...props} />
      ),
      "Any Of": ({ ...props }) => (
        <MultiSelectionTextField label="Any Of" value={"Any Of"} {...props} />
      ),
      "All Of": ({ ...props }) => (
        <MultiSelectionTextField label="All Of" value={"All Of"} {...props} />
      ),
      "None Of": ({ ...props }) => (
        <MultiSelectionTextField label="None Of" value="None Of" {...props} />
      ),
    },
  },
};
