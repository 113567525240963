import axios from "axios";
import { toast } from "react-toastify";
import { store } from "..";
import { authLogout } from "../store/actions/actions";

var baseURL = window.location.origin + "/ams";
const wazuh = axios.create({
  baseURL: baseURL,
});

wazuh.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(authLogout());
    }
    return Promise.reject(error);
  }
);

export const wazuhIndex = axios.create({
  baseURL: window.location.origin,
});

wazuhIndex.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      toast.error("Permission denied.");
    }
    if (error.response && error.response.status === 401) {
      store.dispatch(authLogout());
    }
    return Promise.reject(error);
  }
);

export default wazuh;
