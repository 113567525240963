import React, { useEffect, useState } from "react";
import {
  fim_most_active_user_query,
  fim_action_query,
  fim_files_added_query,
  fim_files_modifed_query,
  fim_files_modifed_deleted,
  fim_events_query,
} from "../../enums/enums";
import { wazuhIndex } from "../../../../config/wazuh";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import ChartPolar from "../../../../components/charts/Polarchart";
import SynchronizedLineChart from "../../../../components/charts/SynchronizedLineChart";
import DateRangeSelector from "../../../../components/Field/DateRangeSeletor";
import HistoryIcon from "@mui/icons-material/History";
import { formatDateForGraph } from "../../../../utils/utils";

function FIMDashboard({ dateRange, setDateRange, agentId, _setGraphs }) {
  const [graphs, setGraphs] = useState({
    most_active_users: { labels: [], values: [], loading: false },
    action: { labels: [], values: [], loading: false },
    files_added: { labels: [], values: [], loading: false },
    files_modified: { labels: [], values: [], loading: false },
    files_deleted: { labels: [], values: [], loading: false },
    events: { labels: [], values: [], loading: false },
  });
  const [query, setQuery] = useState("");
  const report_graphs = [
    {
      id: "graph_most_active_users", label: "Most active user",
      component: <ChartPolar data={graphs.most_active_users} />,
      type: "component",
      xs: 6,
    },
    {
      id: "graph_action",
      label: "Action",
      component: <ChartPolar data={graphs.action} />,
      type: "component",
      xs: 6,
    },
    {
      id: "graph_events",
      label: "Events",
      xs: 12,
    },
    {
      id: "graph_files_added",
      label: "Files added",
      component: <ChartPolar data={graphs.files_added} />,
      type: "component",
      xs: 6,
    },
    {
      id: "graph_files_deleted",
      label: "Files deleted",
      component: <ChartPolar data={graphs.files_deleted} />,
      type: "component",
      xs: 6,
    },
  ];

  const FetchMostActiveUsers = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    setGraphs((pre) => ({
      ...pre,
      most_active_users: { ...pre.most_active_users, loading: true },
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        fim_most_active_user_query(query, agentId, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setGraphs((pre) => ({
          ...pre,
          most_active_users: { ...pre.most_active_users, ...data },
        }));
      })
      .catch((err) => {
        setGraphs((pre) => ({
          ...pre,
          most_active_users: { ...pre.most_active_users, loading: false },
        }));
      });
  };
  const FetchActiveUsers = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    setGraphs((pre) => ({
      ...pre,
      action: { ...pre.action, loading: true },
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        fim_action_query(query, agentId, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setGraphs((pre) => ({
          ...pre,
          action: { ...pre.action, ...data },
        }));
      })
      .catch((err) => {
        setGraphs((pre) => ({
          ...pre,
          action: { ...pre.action, loading: false },
        }));
      });
  };
  const FetchFilesAdded = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    setGraphs((pre) => ({
      ...pre,
      files_added: { ...pre.files_added, loading: true },
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        fim_files_added_query(query, agentId, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setGraphs((pre) => ({
          ...pre,
          files_added: { ...pre.files_added, ...data },
        }));
      })
      .catch((err) => {
        setGraphs((pre) => ({
          ...pre,
          files_added: { ...pre.files_added, loading: false },
        }));
      });
  };
  const FetchFilesModifed = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    setGraphs((pre) => ({
      ...pre,
      files_modified: { ...pre.files_modified, loading: true },
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        fim_files_modifed_query(query, agentId, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setGraphs((pre) => ({
          ...pre,
          files_modified: { ...pre.files_modified, ...data },
        }));
      })
      .catch((err) => {
        setGraphs((pre) => ({
          ...pre,
          files_modified: { ...pre.files_modified, loading: false },
        }));
      });
  };
  const FetchFilesDeleted = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    setGraphs((pre) => ({
      ...pre,
      files_deleted: { ...pre.files_deleted, loading: true },
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        fim_files_modifed_deleted(query, agentId, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        let data = { labels: [], values: [] };
        buckets.map((b) => {
          data.labels.push(b.key);
          data.values.push(b.doc_count);
          return "";
        });
        setGraphs((pre) => ({
          ...pre,
          files_deleted: { ...pre.files_deleted, ...data },
        }));
      })
      .catch((err) => {
        setGraphs((pre) => ({
          ...pre,
          files_deleted: { ...pre.files_deleted, loading: false },
        }));
      });
  };
  const parseApiResponse = (aggregations) => {
    const parsedData = aggregations["2"].buckets.map((bucket) => {
      const timestamp = formatDateForGraph(bucket.key_as_string);
      const actionCounts = bucket["3"].buckets.reduce((accumulator, item) => {
        accumulator[item.key] = item.doc_count;
        return accumulator;
      }, {});

      return {
        name: timestamp,
        ...actionCounts,
      };
    });

    // Extract unique keys from all data points
    const allKeys = parsedData.reduce((keys, dataPoint) => {
      Object.keys(dataPoint).forEach((key) => {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      });
      return keys;
    }, []);

    // Assuming 'name' is a common key for X-axis, remove it from the list
    const dataKeys = allKeys.filter((key) => key !== "name");

    return { parsedData, dataKeys };
  };
  const FetchEvolution = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date
  ) => {
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        fim_events_query(query, agentId, from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const { parsedData, dataKeys } = parseApiResponse(aggregations);
        setGraphs((pre) => ({
          ...pre,
          events: {
            values: parsedData,
            labels: dataKeys,
            loading: false,
          },
        }));
      });
  };
  const handleSearch = (e) => {
    const dqlQuery = e.target.value;
    setQuery(dqlQuery);
  };
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      CallAPIS();
    }
  };
  const CallAPIS = () => {
    FetchMostActiveUsers();
    FetchActiveUsers();
    FetchFilesAdded();
    FetchFilesModifed();
    FetchFilesDeleted();
    FetchEvolution();
  };
  useEffect(() => {
    CallAPIS();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    _setGraphs(report_graphs)
    // eslint-disable-next-line
  }, [graphs]);
  return (
    <Box mt={5}>
      <Grid container spacing={2}>
        {/* Filtes */}
        <Grid item container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} lg={7}>
            <TextField
              size="small"
              fullWidth
              placeholder="agent.id:000 AND agent.name:agent OR ip:127.0.0.1."
              value={query}
              onChange={handleSearch}
              onKeyDown={handleSearchKeyDown}
            />
          </Grid>
          <Grid item xs={10} sm lg={4}>
            {/* <DatTimeSelect /> */}
            <DateRangeSelector
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Grid>
          <Grid item xs={2} sm>
            <Button
              startIcon={<HistoryIcon fontSize="small" />}
              fullWidth
              variant="contained"
              color="primary"
              onClick={CallAPIS}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            gap={3}
            elevation={3}
            width="100%"
            height="100%"
            xs={6}
            p="10px 20px"
          >
            <Typography variant="h6" fontWeight={300}>
              Most active user
            </Typography>
            {graphs.most_active_users.labels.length < 1 ? (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            ) : (
              <Box
                display={"flex"}
                gap={0.5}
                height="100%"
                flexDirection="column"
                id="graph_most_active_users"
              >
                <ChartPolar data={graphs.most_active_users} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            gap={3}
            elevation={3}
            width="100%"
            height="100%"
            xs={6}
            p="10px 20px"
          >
            <Typography variant="h6" fontWeight={300}>
              Action
            </Typography>
            {graphs.action.labels.length < 1 ? (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            ) : (
              <Box
                display={"flex"}
                gap={0.5}
                height="100%"
                flexDirection="column"
                id="graph_action"
              >
                <ChartPolar data={graphs.action} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box component={Paper} elevation={3} height="100%" p="10px 20px">
            <Typography variant="h6" fontWeight={300} paragraph>
              Events
            </Typography>
            {graphs.events.labels.length > 0 ? (
              graphs.events.labels && (
                <Box id="graph_events">

                  <SynchronizedLineChart
                    data={graphs.events.values}
                    dataKey={graphs.events.labels}
                    fill={true}
                    strokeWidth={2}
                    legend={true}
                    height={250}
                  />
                </Box>
              )
            ) : (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            gap={3}
            elevation={3}
            width="100%"
            height="100%"
            xs={6}
            p="10px 20px"
            minHeight={250}
          >
            <Typography variant="h6" fontWeight={300}>
              Files added
            </Typography>
            {graphs.files_added.labels.length < 1 ? (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            ) : (
              <Box
                display={"flex"}
                gap={0.5}
                height="100%"
                flexDirection="column"
                id="graph_files_added"
              >
                <ChartPolar data={graphs.files_added} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            gap={3}
            elevation={3}
            width="100%"
            height="100%"
            xs={6}
            p="10px 20px"
            minHeight={250}
          >
            <Typography variant="h6" fontWeight={300}>
              Files modified
            </Typography>
            {graphs.files_modified.labels.length < 1 ? (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            ) : (
              <Box
                display={"flex"}
                gap={0.5}
                height="100%"
                flexDirection="column"
                id="graph_files_modified"
              >
                <ChartPolar data={graphs.files_modified} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            component={Paper}
            display="flex"
            flexDirection="column"
            gap={3}
            elevation={3}
            width="100%"
            height="100%"
            xs={6}
            p="10px 20px"
            minHeight={250}
          >
            <Typography variant="h6" fontWeight={300}>
              Files deleted
            </Typography>
            {graphs.files_deleted.labels.length < 1 ? (
              <Box
                display="flex"
                minHeight="105px"
                justifyContent="center"
                alignItems="center"
                color="grey"
              >
                No data
              </Box>
            ) : (
              <Box
                display={"flex"}
                gap={0.5}
                height="100%"
                flexDirection="column"
                id="graph_files_deleted"
              >
                <ChartPolar data={graphs.files_deleted} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default FIMDashboard;
