import {
  Box,
  Button,
  Collapse,
  Grow,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Event from "./Event";
import DashBoard from "./DashBoard";
import dayjs from "dayjs";
import { formatDate } from "../../../utils/utils.js";
import MitreAttach from "./Mitre/Mitre_Attach.js";
import SCAInventory from "./SCA/SCAInventory";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import ContentPasteIcon from "@mui/icons-material/ContentPaste.js";
import Framework from "./Mitre/Framework";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InventoryDeatil from "./SCA/InventoryDeatil";
import FIMDashboard from "./FIM/Dashboard.js";
import VlbyDashboard from "./Vulnerabilities/Dashboard.js";
import FIMEVENTS from "./FIM/Events.js";
import { FallingLines } from "react-loader-spinner";
import PrintDialogBox from "../../../utils/PDF/PrintDialogBox.js";
import VulnerabilitiesEVENTS from "./Vulnerabilities/Events.js";
import { connect } from "react-redux";

const SecurityEventIndex = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const detailPage =
    new URLSearchParams(location.search).get("detailPage") || "";
  const policy_id = new URLSearchParams(location.search).get("policy_id") || "";
  const pageTab = new URLSearchParams(location.search).get("tab") || "";
  const pageIndex =
    parseInt(new URLSearchParams(location.search).get("index")) || 0;
  const agentId =
    new URLSearchParams(location.search).get("agent_id") || undefined;
  const [tableData, setTableData] = useState({ headers: [], body: [] });
  const [reportDialog, showReportDialog] = useState(false);
  const [page, setPage] = useState(0);
  const from_date = searchParams.get("fromDate");
  const to_date = searchParams.get("toDate");
  const _fromdate = from_date
    ? formatDate(dayjs(from_date).startOf("day"))
    : formatDate(dayjs().subtract(3, "days").startOf("day"));
  const _to_date = to_date
    ? formatDate(dayjs(to_date).startOf("day"))
    : formatDate(dayjs().startOf("day"));

  const [dateRange, setDateRange] = useState({
    from_date: localStorage.getItem("fromDate") || _fromdate,
    to_date: localStorage.getItem("toDate") || _to_date,
  });

  const [title, setTitle] = useState("Print This Doc");
  const [graphs, setGraphs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e, newValue) => {
    setPage(newValue);
    let _updatedParams = searchParams;
    setSearchParams((params) => {
      const updatedParams = new URLSearchParams(params);
      updatedParams.set("index", newValue);
      _updatedParams = updatedParams;
      return updatedParams;
    });
    navigate(`?${_updatedParams.toString()}`);
  };
  const handleGenrateReport = useCallback(async () => {
    setLoading(true);
    // const data = await GenratePDF(graphs);
    // GenratePDFTEMPLATE(graphs)
    showReportDialog(true)
    // const Component = await GenratePDF(graphs);
    // await GenerateReport(graphs, tableData);
    setLoading(false);
  }, [graphs])

  useEffect(() => {
    // Update searchParams state
    setSearchParams((params) => {
      const updatedParams = new URLSearchParams(params);
      updatedParams.set("fromDate", dateRange.from_date);
      updatedParams.set("toDate", dateRange.to_date);

      // Save dateRange values to local storage
      localStorage.setItem("fromDate", dateRange.from_date);
      localStorage.setItem("toDate", dateRange.to_date);

      return updatedParams;
    });
    // eslint-disable-next-line
  }, [dateRange.from_date, dateRange.to_date]);
  useEffect(() => {
    if (page !== pageIndex) {
      setPage(pageIndex);
    }
    // eslint-disable-next-line
  }, [pageIndex]);
  useEffect(() => {
    let _title = "";
    switch (pageTab) {
      case "mitre":
        _title = "MITRE";
        break;
      case "fim":
        _title = "FIM: Recent events";
        break;
      case "sca":
        _title = "SCA: Lastest scans";
        break;
      case "vlby":
        _title = "Vulnerability";
        break;
      default:
        if (window.location.pathname === '/modules-events') {
          _title = "EVENTS & Logs";
          break
        }
        _title = "Print This doc";
        break
    }
    setTitle(_title)
  }, [pageTab])

  const PrintDialog = useCallback(() => {
    return <PrintDialogBox title={title} open={reportDialog} setOpen={showReportDialog} components={graphs} tableData={tableData} />
  }, [graphs, reportDialog, tableData, title])

  return (
    <Box>
      <Box sx={{ width: "100%", mt: 1 }}>
        <Box display="flex">
          <IconButton onClick={() => navigate(`/agents-preview?id=${agentId}`)}>
            <ArrowBackIosIcon color="primary" />
          </IconButton>
          <Box
            flexGrow={1}
            display="flex"
            justifyContent="space-between"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            {pageTab === "sca" || pageTab === "fim" ? (
              <Tabs value={page} onChange={handleChange}>
                <Tab label={pageTab === "fim" ? "Dashboard" : "Inventory"} />
                <Tab label="Events" />
              </Tabs>
            ) : (
              <Tabs value={page} onChange={handleChange}>
                <Tab label="Dashboard" />
                <Tab label="Events" />
                {agentId !== undefined && pageTab === "mitre" && (
                  <Tab label="Framework" />
                )}
              </Tabs>
            )}
            {loading ? (
              <FallingLines
                color="#4fa94d"
                width="60"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            ) : (
              <Button
                color="primary"
                size="small"
                className="rounded"
                onClick={handleGenrateReport}
                startIcon={<ContentPasteIcon fontSize="small" />}
              >
                Genrate Report
              </Button>


            )}

          </Box>
        </Box>
        {pageTab === "sca" || pageTab === "fim" ? (
          pageTab === "fim" ? (
            <>
              <Collapse unmountOnExit in={page === 0}>
                <FIMDashboard
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  agentId={agentId}
                  _setGraphs={setGraphs}
                />
              </Collapse>
              <Collapse unmountOnExit in={page === 1}>
                <FIMEVENTS
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setGraphs={setGraphs}
                  setTableData={setTableData}
                />
              </Collapse>
            </>
          ) : (
            <>
              <Grow unmountOnExit in={page === 0}>
                <Box>
                  {detailPage === "inventoryDetail" && policy_id ? (
                    <InventoryDeatil setGraphs={setGraphs} setTableData={setTableData} agentId={agentId} policy_id={policy_id} />
                  ) : (
                    <SCAInventory
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      setGraphs={setGraphs}
                      setTableData={setTableData}
                    />
                  )}
                </Box>
              </Grow>
              <Grow unmountOnExit in={page === 1}>
                <Box>
                  <Event
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setGraphs={setGraphs}
                    setTableData={setTableData}
                  />
                </Box>
              </Grow>
            </>
          )
        ) : (
          pageTab === "vlby" && props.vulnerabilities ?
            <>
              <Collapse unmountOnExit in={page === 0}>
                <VlbyDashboard
                  agentId={agentId}
                  setGraphs={setGraphs}
                  setTableData={setTableData}
                />
              </Collapse>
              <Collapse unmountOnExit in={page === 1}>
                <VulnerabilitiesEVENTS
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setGraphs={setGraphs}
                  setTableData={setTableData} />
              </Collapse>
            </>
            :
            <>
              <Grow unmountOnExit in={page === 0}>
                <Box>
                  {agentId !== undefined && pageTab === "mitre" ? (
                    <MitreAttach
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      agentId={agentId}
                      setGraphs={setGraphs}
                    />
                  ) : (
                    <DashBoard
                      setGraphs={setGraphs}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      setTableData={setTableData}
                    />
                  )}
                </Box>
              </Grow>
              <Grow unmountOnExit in={page === 1}>
                <Box>
                  <Event
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setGraphs={setGraphs}
                    setTableData={setTableData}
                  />
                </Box>
              </Grow>
              {agentId !== undefined && pageTab === "mitre" && (
                <Grow unmountOnExit in={page === 2}>
                  <Box>
                    <Framework
                      agent_id={agentId}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      setGraphs={setGraphs}
                    />
                  </Box>
                </Grow>
              )}
            </>
        )}
      </Box>
      <PrintDialog />
    </Box >
  );
};
const mapStatestoProps = (state) => {
  return {
    vulnerabilities: state.vulnerabilities
  }
}
export default connect(mapStatestoProps, null)(SecurityEventIndex);

