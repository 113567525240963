import React, { useCallback, useEffect, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    LinearProgress,
    ListItemButton,
    Divider,
    Tooltip,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
} from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Scrollbars from 'react-custom-scrollbars-2';
import { insertElementAtIndex } from '../../utils/utils';
import { connect } from 'react-redux';
import cms from '../../config/axiosConfig';
import FilterFields from '../../components/filters/FilterFields';
import { CaseTemplatesFilterFields } from '../../utils/filters/fields';
import MarkDownTable from '../../components/Display/MarkDownTable/MarkDownTable';
import { toast } from 'react-toastify';
import { Footer } from '../../components/Dialog/SideCanvas';


const PlaybookList = ({ caseId, handleClose, ...props }) => {
    const [data, setData] = useState({ loading: false, data: [] });
    const [selectedRows, setSelectedRows] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTasks, setDialogTasks] = useState([]);
    const [loading, setLoading] = useState(false)

    const Finder = useCallback((task_id) => {
        return selectedRows.find(r => r.task._id === task_id);
    }, [selectedRows]);

    const handleAddorRemoveRow = (plb, task) => () => {
        const finder = selectedRows.find(r => r.task?._id === task._id);
        if (!finder) {
            const _row = { playbook: plb, task: task };
            setSelectedRows(pre => [...pre, _row]);
        } else {
            setSelectedRows(pre => (pre.filter(r => r.task?._id !== task?._id)));
        }
    };

    const handleSelectAllRows = (plb, tasks) => () => {
        const allSelected = tasks.every(task => Finder(task._id));
        if (allSelected) {
            setSelectedRows(pre => pre.filter(r => r.playbook !== plb));
        } else {
            const newSelectedRows = tasks.map(task => ({ playbook: plb, task: task }));
            setSelectedRows(pre => [
                ...pre.filter(r => r.playbook !== plb),
                ...newSelectedRows,
            ]);
        }
    };

    const handleOpenDialog = () => {
        const selectedTasks = selectedRows.map(row => row.task);
        setDialogTasks(selectedTasks);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const fetchData = async (filter_query = false) => {
        try {
            setData(pre => ({ ...pre, loading: true }));
            let query = [
                {
                    _name: "getOrganisation",
                    idOrName: props.org_name,
                },
                {
                    _name: "caseTemplates",
                },
                {
                    _name: "sort",
                    _fields: [{ _createdAt: "desc" }],
                },
                {
                    _name: "page",
                    from: 1,
                    to: 20,
                },
            ];

            if (filter_query && Array.isArray(filter_query)) {
                if (filter_query.length < 2) {
                    const element = {
                        _name: "filter",
                        ...filter_query[0],
                    };
                    query = insertElementAtIndex(query, element, 2);
                } else {
                    const element = {
                        _name: "filter",
                        _and: [
                            ...filter_query,
                        ],
                    };
                    query = insertElementAtIndex(query, element, 2);
                }
            }

            const response = await cms.post(
                "/cms/api/v1/query",
                {
                    query: query,
                },
                {
                    params: {
                        name: "organisation-case-templates",
                    }
                }
            );
            let d = response.data;

            setData((pre) => ({ ...pre, data: d }));

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setData((pre) => ({ ...pre, loading: false }));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log(selectedRows);
    }, [selectedRows]);

    const handleImportTasks = async () => {
        const tasks = [];
        for (const row of selectedRows) {
            const _task = row.task;
            tasks.push({
                title: _task.title,
                description: _task.description,
                status: "Waiting",
            });
        }
        setLoading(true);
        try {
            const completedIds = [];

            const promises = tasks.map((payload) =>
                cms.post(`/cms/api/case/~${caseId}/task?`, payload, {
                    params: { flag: false },
                }).then((res) => {
                    if (res.status > 199) {
                        completedIds.push(res.data.id); // Assuming the ID is in res.data.id
                    }
                }).catch((err) => {
                    toast.error(`Something is wrong. ${err}`);
                })
            );

            await Promise.all(promises);

            if (completedIds.length > 0) {
                toast.success(`All tasks added successfully. Completed IDs: ${completedIds.join(', ')}`);
            } else {
                toast.info("No tasks were added.");
            }

            setDialogOpen(false);
            handleClose();
        } catch (error) {
            console.error("Error importing tasks:", error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Scrollbars style={{ height: "83vh" }}>
                <Box sx={{ p: "0px 20px 0px 20px" }} borderRadius={4}>
                    <Box>
                        <FilterFields
                            filterField={CaseTemplatesFilterFields}
                            onSearch={fetchData}
                            _for="case_casetemplates"
                        />
                        <Divider />
                    </Box>
                    {data.loading && <Box width="100%"><LinearProgress /></Box>}
                    {data.data.map(p => (
                        <Accordion key={p.name}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${p.name}-content`}
                                id={`panel${p.name}-header`}
                            >
                                <Box display="flex" gap={1} alignItems="center">
                                    <Typography>{p.name}</Typography>
                                    <Tooltip title="Select All Tasks">
                                        <IconButton
                                            size="small"
                                            onClick={(e) => { e.stopPropagation(); handleSelectAllRows(p.name, p.tasks)(e) }}
                                            edge="end"
                                            sx={{ marginLeft: 'auto' }}
                                        >
                                            <DoneAllIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {selectedRows.find(plb => plb.playbook === p.name) && (
                                        <Typography variant='caption' fontWeight={600}>
                                            {`(${selectedRows.filter(plb => plb.playbook === p.name)?.length}/${p.tasks?.length})`}
                                        </Typography>
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List dense disablePadding sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} className='rounded'>
                                    {p?.tasks.map((task, index) => (
                                        <ListItem key={index} dense divider selected={Finder(task._id)}>
                                            <ListItemButton sx={{ flex: 0, minWidth: 50 }} onClick={handleAddorRemoveRow(p.name, task)}>
                                                {Finder(task._id) ?
                                                    <Tooltip title="Unselect">
                                                        <CheckCircleOutlineRoundedIcon edge="start" color='success' fontSize='small' />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Select">
                                                        <RemoveCircleOutlineRoundedIcon edge="start" color="disabled" fontSize='small' />
                                                    </Tooltip>
                                                }
                                            </ListItemButton>
                                            <ListItemText
                                                primary={
                                                    <Typography noWrap variant="body1">
                                                        {task.title} - <Box component="span" sx={{ color: "grey.400" }}>{task.description}</Box>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Scrollbars>
            <Footer handleClose={handleClose} handleOpenDialog={handleOpenDialog} disabled={selectedRows.length < 1} />

            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>Selected Tasks</DialogTitle>
                <DialogContent dividers>
                    <Scrollbars style={{ maxWidth: "85vh", height: "79vh" }}>
                        <List dense disablePadding sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} className='rounded'>
                            {dialogTasks.length === 0 ? (
                                <Typography>No tasks selected</Typography>
                            ) : (
                                dialogTasks.map((task, index) => (
                                    <ListItem key={index} dense divider selected sx={{ "&.MuiListItem-root": { alignItems: "flex-start" } }}>
                                        <ListItemButton sx={{ flex: 0, minWidth: 50 }} >
                                            <CheckCircleOutlineRoundedIcon sx={{ mt: 0.5 }} edge="start" color='success' fontSize='small' />
                                        </ListItemButton>
                                        <ListItemText
                                            primary={
                                                <Typography noWrap variant="body1">
                                                    {task.title}
                                                </Typography>
                                            }
                                            secondary={<MarkDownTable>{task.description}</MarkDownTable>}
                                        />
                                    </ListItem>
                                )
                                ))
                            }
                        </List>
                    </Scrollbars>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} disabled={loading} color="primary">Close</Button>
                    {loading ? <CircularProgress sx={{ width: "30px !important", height: "30px !important" }} /> :
                        <Button onClick={handleImportTasks} variant="contained" color="primary">Confirm Import</Button>
                    }
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        org_name: state.cms_user?.organisation
    };
};

export default connect(mapStateToProps, null)(PlaybookList);
