import Markdown from "marked-react";
import React from "react";
import "./table.css";
import { Box } from "@mui/material";

const MarkDownTable = ({ maxWidth, children }) => {
  return (
    <Box
      className="markdown"
      sx={{
        overflow: "auto",
        "& table": {
          width: maxWidth,
        },
        "& td": {
          width: "50%",
        },
      }}
    >
      <Markdown>{children}</Markdown>
    </Box>
  );
};

export default MarkDownTable;
