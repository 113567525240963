import Papa from "papaparse";
import notificationBell from "../static/audios/notification-bell.mp3"

export const formatCreatedAt = (_date) => {
  const createdAt = new Date(_date);

  const formattedCreatedAt = createdAt
    .toLocaleString()
    .replace("/", "-")
    .replace("/", "-");
  return formattedCreatedAt;
};

export const calculateDaysFromTimestamp = (date) => {
  const startDateTimestamp = date;
  const currentTimestamp = Date.now();
  const millisecondsDifference = currentTimestamp - startDateTimestamp;
  const daysDifference = millisecondsDifference / (1000 * 60 * 60 * 24);
  return parseInt(daysDifference);
};

export const insertElementAtIndex = (array, element, index) => {
  return [...array.slice(0, index), element, ...array.slice(index)];
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const convertToDateTimeLocal = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp);

    // Get the local time zone offset and convert it to minutes
    const offsetMinutes = date.getTimezoneOffset();

    // Adjust the date by subtracting the offset in minutes
    date.setMinutes(date.getMinutes() - offsetMinutes);

    // Format the date to YYYY-MM-DDTHH:mm (datetime-local format)
    return date.toISOString().slice(0, 16);
  }
};

export function convertToEpochMillis(dateString) {
  const dateObject = new Date(dateString);
  const timestamp = dateObject.getTime(); // Milliseconds since January 1, 1970
  return timestamp;
}
export function getTimezone() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
}

export const formatDate = (date) => {
  return date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  // return date.toISOString();
};
export const formatDateForGraph = (datetime) => {
  return new Date(datetime).toLocaleString();
  // return date.toISOString();
};
export const adminName = "admin";


export function convertToCSV(data) {
  const csv = Papa.unparse(data);
  return csv;
}

export const PasswordValidator = (_password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/;
  return passwordRegex.test(_password);
};

export const Debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export function NotificationBell() {
  try {
    const audio = new Audio(notificationBell);
    audio.play();
    return audio;
  }
  catch {

  }
}

export function getIntervalPayload(_fromDate, _toDate) {
  const fromDate = new Date(_fromDate);
  const toDate = new Date(_toDate);

  // Assuming 30 days in a month for simplicity
  const dateDiffInMonths = (toDate - fromDate) / (1000 * 60 * 60 * 24 * 30);
  const dateDiffInYears = dateDiffInMonths / 12;

  if (dateDiffInMonths < 2) {
    if ((toDate - fromDate) / (1000 * 60 * 60 * 24) < 7) {
      return { fixed_interval: "30m" };
    } else if ((toDate - fromDate) / (1000 * 60 * 60 * 24) < 14) {
      return { fixed_interval: "3h" };
    } else {
      return { fixed_interval: "12h" };
    }
  } else {
    if (dateDiffInYears < 1) {
      return { fixed_interval: "1d" };
    } else if (dateDiffInYears < 2) {
      return { fixed_interval: "1w" };
    } else {
      return { fixed_interval: "30d" };
    }
  }
}


export const graphs_colors = [
  "#8884d8", "#82ca9d", "#ffc658", "#ff7f0e", "#0088fe",
  "#00C49F", "#FFBB28", "#FF8042", "rgb(87, 193, 123)",
  "rgb(111, 135, 216)", "rgb(102, 61, 184)", "rgb(188, 82, 188)",
  "rgb(218, 160, 93)", "#ffff00", "#00ffff", "#ff5733",
  "#c70039", "#900c3f", "#581845", "#8e44ad", "#2ecc71"
];

const colorPalette = [
  "#4285F4", // Google Blue
  "#EA4335", // Google Red
  "#FBBC05", // Google Yellow
  "#34A853", // Google Green
  "#1E90FF", // Microsoft Blue
  "#FF4500", // Microsoft Red
  "#FFD700", // Microsoft Yellow
  "#32CD32", // Microsoft Green
  "#4E79A7", // Tableau Blue
  "#F28E2C", // Tableau Orange
  "#E15759", // Tableau Red
  "#76B7B2", // Tableau Teal
  "#59A14F", // Tableau Green
  "#EDC949", // Tableau Yellow
  "#AF7AA1", // Tableau Purple
  "#FF9DA7", // Tableau Pink
  "#9C755F", // Tableau Brown
  "#BAB0AC", // Tableau Gray
  "#3366CC", // Generic Blue
  "#DC3912", // Generic Red
  "#FF9900", // Generic Orange
  "#109618", // Generic Green
  "#990099", // Generic Purple
  "#0099C6", // Generic Cyan
  "#DD4477", // Generic Pink
  "#66AA00", // Generic Lime
  "#B82E2E", // Generic Dark Red
  "#316395", // Generic Dark Blue
  "#994499", // Generic Plum
  "#22AA99", // Generic Dark Cyan
  "#AAAA11", // Generic Olive
  "#6633CC", // Generic Violet
  "#E67300", // Generic Dark Orange
  "#8B0707", // Generic Dark Maroon
  "#329262", // Generic Dark Teal
  "#5574A6", // Generic Slate Blue
  "#3B3EAC"  // Generic Indigo
];

export function getRandomColor() {
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
}