import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// import bagIcon from "../../static/icons/bagIcon.svg";
// import exitIcon from "../../static/icons/exitIcon.svg";
// import personIcon from "../../static/icons/personIcon.svg";
// import listIcon from "../../static/icons/listIcon.svg";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LogoIcon from "../../static/logos/logo.svg";
// import LogoIcon from "../../static/icons/logo-icon.svg";
import PlusIcon from "../../static/icons/plus-icon.svg";
import OrganizationIcon from "../../static/icons/organizationIcon.svg";
import linkedBlockIcon from "../../static/icons/linkedBlockIcon.svg";
import {
  ButtonBase,
  Chip,
  Collapse,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Btn from "../Buttons/BootStrap/Btn";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import TextfFeldExpandable from "../Field/TextfFeldExpandable.js";
import Canvase from "../Display/offcanvase";
import { Link, useNavigate } from "react-router-dom";
import CreateCaseDialog from "../../pages/Cases/components/CreateCaseDialog";
import { connect } from "react-redux";
import MiniDrawer from "./DrawerMinMax";
import CreateOrganizationDialog from "../../pages/administration/components/CreateOrganizationDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { authLogout } from "../../store/actions/actions";
import { adminName } from "../../utils/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import GroupsIcon from "@mui/icons-material/Groups";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import ContactsIcon from "@mui/icons-material/Contacts";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ProfileAvatar from "../Display/ProfileAvatar";
import DraggableDialog from "../Dialog/DraggableDialog";
import AboutPage from "../../pages/administration/AboutPage";
import GridViewIcon from '@mui/icons-material/GridView';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { user_enums } from "../../store/reducers/reducer";

function ClippedDrawer(props) {
  let drawerTabs = [

    {
      title: "Cases List",
      count: props.casesCount,
      url: "/cases",
      pathSearch: "case",
      showCount: true,
      icon: SplitscreenIcon,
    },
    {
      title: "Tasks List",
      count: props.tasksCount,
      url: "/tasks",
      showCount: true,
      pathSearch: "task",
      icon: AssignmentIcon,
    },
    {
      title: "Alerts List",
      count: props.alertsCount,
      url: "/alerts",
      showCount: true,
      pathSearch: "alert",
      icon: PrivacyTipIcon,
    },
    {
      title: "Agents & Endpoints",
      url: "/agents",
      pathSearch: "agent",
      icon: GroupsIcon,
    },
    {
      title: "Events & Logs",
      url: "/modules-events?index=0",
      pathSearch: "events",
      icon: InsertPageBreakIcon,
    },
    // {
    //   title: "Logout",
    //   url: "/",
    //   icon: exitIcon,
    // },
  ];
  if (props.dashboards) {
    let dashboard = {
      title: "Dashboards",
      url: "/dashboard",
      pathSearch: "dashboard",
      icon: GridViewIcon,
      show: false,
      subMenus: []
    }
    switch (props.userType) {
      case user_enums.ANALYST:
        dashboard.subMenus = [{
          title: "Dashboard",
          url: "/dashboards/Tier1",
          icon: GridViewIcon,
        }]
        break
      case user_enums.ADMIN:
        dashboard.subMenus = [{
          title: "Dashboard",
          url: "/dashboards/Tier1",
          icon: GridViewIcon,
        },
        {
          title: "Dashboard",
          url: "/dashboards/Tier2",
          icon: GridViewIcon,
        }]
        break
      default:
        dashboard.subMenus = [
          {
            title: "Dashboard",
            url: "/dashboards/Tier2",
            icon: GridViewIcon,
          }]
        break
    }
    const newPaths = [dashboard, ...drawerTabs];
    drawerTabs = newPaths
  }
  if (props.permissions.mPrf) {
    drawerTabs.push({
      title: "User Profiles",
      url: "/profiles",
      pathSearch: "profile",
      icon: ContactsIcon,
    });
  }
  if (props.permissions.mOrg || props.permissions.mUsers) {
    drawerTabs.push({
      title: "My Organization",
      url: props.permissions.mOrg
        ? "/organizations"
        : `/organizations/${props.user.organisation}`,
      pathSearch: "organization",
      icon: ApartmentIcon,
    });
  }
  const adminDrawerTAbs = [
    {
      title: "My Organization",
      url: "/organizations",
      pathSearch: "organization",
      icon: OrganizationIcon,
    },
    {
      title: "User Profiles",
      url: "/profiles",
      pathSearch: "profile",
      icon: linkedBlockIcon,
    },
  ];

  const [createDialogBox, openCreateDialogBox] = React.useState(false);
  const [createOrgDialogBox, openCreateOrgDialogBox] = React.useState(false);
  const [drawer, setDrawer] = React.useState(localStorage.getItem("drawer") ? JSON.parse(localStorage.getItem("drawer")) : false);
  const [menuOpened, setMenuOpend] = React.useState("");
  const [profileMenu, showProfileMenu] = React.useState(null);

  const [liveStream, setLiveStream] = React.useState(false);
  const [showAbout, openAbout] = React.useState(false);
  const navigation = useNavigate();

  let brand = localStorage.getItem("brand");
  const [logo, setLogo] = React.useState(brand ? JSON.parse(brand)?.org_logo : LogoIcon);

  React.useEffect(() => {
    if (props.brand?.org_logo) {
      setLogo(props.brand?.org_logo);
    }
    else if (brand) {
      brand = JSON.parse(brand);
      setLogo(brand.org_logo);
    }
  }, [props.brand])

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          zIndex: 1,
          backgroundColor: "#FFFFFF",
        }}
      >
        <Toolbar
          sx={{
            minHeight: "76px !important",
            paddingLeft: "8px !important",
            paddingRight: "8px !important",
          }}
        >
          <Link
            to="/"
            style={{ flexGrow: 1, display: "flex", alignItems: "center" }}
          >
            <Box component="img" src={logo} maxHeight={50} />
          </Link>

          <Box display="flex" gap="18px">
            <Box
              component={Canvase}
              sx={{ zIndex: 1, width: "470px" }}
              show={liveStream}
              onHide={() => {
                setLiveStream(false);
              }}
            />

            <Btn
              hidden={
                !props.permissions.mCases || props.user.profile === adminName
              }
              text="Create Case"
              startIcon={PlusIcon}
              height={50}
              fontSize={16}
              onClick={() => {
                openCreateDialogBox(true);
              }}
            />
            <CreateCaseDialog
              open={createDialogBox}
              setOpen={openCreateDialogBox}
            />
            <Divider orientation="vertical" flexItem />
            <Box display="flex" alignItems="center" gap={1.25}>
              <ProfileAvatar
                height={50}
                src={window.location.origin + `/cms${props.user.avatar}`}
                width={50}
                sx={{ borderRadius: 1.25 }}
              />
              <Typography variant="h6" color="textPrimary" fontWeight={700}>
                {props.user.name}
              </Typography>
              <IconButton
                onClick={(e) => {
                  showProfileMenu((pre) =>
                    pre === null ? e.currentTarget : null
                  );
                }}
                size="small"
                sx={{ bgcolor: "#ECF0F4" }}
              >
                {profileMenu ? (
                  <KeyboardArrowUp fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
                <Menu
                  sx={{ mt: 1 }}
                  id="profile-menu"
                  anchorEl={profileMenu}
                  open={Boolean(profileMenu)}
                  onClose={() => {
                    showProfileMenu(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "profile-menu",
                  }}
                >
                  <MenuItem onClick={() => navigation("/settings")}>
                    <ListItemIcon>
                      <SettingsIcon color="primary" />
                    </ListItemIcon>
                    Setting
                  </MenuItem>
                  <MenuItem onClick={() => { openAbout(true) }}>
                    <ListItemIcon>
                      <InfoIcon color="primary" />
                    </ListItemIcon>
                    About
                  </MenuItem>
                  <MenuItem onClick={() => props.authLogout()}>
                    <ListItemIcon>
                      <ExitToAppIcon color="primary" />
                    </ListItemIcon>
                    Log out
                  </MenuItem>
                </Menu>
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <MiniDrawer open={drawer} setOpen={setDrawer}>
        <Box
          display="flex"
          height={"100%"}
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            sx={{ overflow: "auto", overflowX: "hidden" }}
            display="flex"
            // gap={2}
            flexDirection="column"
          >
            {props.permissions.mOrg &&
              drawer &&
              props.user.profile === adminName && (
                <>
                  <Btn
                    text="Create Organisation"
                    startIcon={PlusIcon}
                    height={50}
                    fontSize={20}
                    ml={1.5}
                    mr={1.5}
                    onClick={() => {
                      openCreateOrgDialogBox(true);
                    }}
                  />
                  <CreateOrganizationDialog
                    open={createOrgDialogBox}
                    setOpen={openCreateOrgDialogBox}
                  />
                </>
              )}
            {props.user.profile === adminName && <Divider flexItem />}
            {props.user.profile === adminName &&
              adminDrawerTAbs.map((src) => (
                <Box
                  key={src.title}
                  display="flex"
                  component={ButtonBase}
                  onClick={() => {
                    navigation(src.url);
                  }}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    bgcolor:
                      window.location.pathname.includes(src.pathSearch) ||
                        (window.location.pathname === "/" &&
                          src.pathSearch === "organization")
                        ? "#ECF0F4"
                        : "transparent",
                  }}
                  // borderRadius={1.25}
                  width="100%"
                  minHeight={50}
                  p={1.5}
                >
                  <Box display="flex" gap={1.25} alignItems="center">
                    <Tooltip title={src.title} placement="right-start">
                      <Box
                        component="img"
                        src={src.icon}
                        width={24}
                        height={24}
                        alt={src.title}
                        sx={{ ml: !drawer && 1 }}
                      />
                    </Tooltip>
                    {drawer && (
                      <Typography variant="subtitle1" color="#003272">
                        {src.title}
                      </Typography>
                    )}
                  </Box>
                  {drawer && (
                    <Chip
                      label={src.count}
                      size="small"
                      sx={{
                        fontSize: 14,
                        bgcolor: "#b4d3ff",
                        color: "#003272",
                        fontWeight: "bold",
                        minWidth: 50,
                        p: 1,
                      }}
                    />
                  )}
                </Box>
              ))}
            {/* <Divider flexItem /> */}
            {props.user.profile !== adminName &&
              drawerTabs.map((src, i) => (
                <Box display="flex" flexDirection="column" gap={0.5} key={src.title}>
                  <Box
                    key={src.title}
                    display="flex"
                    component={ButtonBase}
                    onClick={() => {
                      Array.isArray(src.subMenus) ?
                        setMenuOpend(pre => pre === src.title ? "" : src.title) :
                        navigation(src.url);
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      bgcolor:
                        window.location.pathname.includes(src.pathSearch) ||
                          (window.location.pathname === "/" &&
                            src.pathSearch === "case")
                          ? "#ECF0F4"
                          : "transparent",
                    }}
                    // borderRadius={1.25}
                    width="100%"
                    minHeight={50}
                    p={1.5}
                  >
                    <Box display="flex" gap={1.25} alignItems="center">
                      <Tooltip title={src.title} placement="right-start">
                        {typeof src.icon === "string" ? (
                          <Box
                            component="img"
                            src={src.icon}
                            width={24}
                            height={24}
                            alt={src.title}
                            sx={{ ml: !drawer && 1 }}
                          />
                        ) : (
                          <src.icon
                            color="primary"
                            sx={{ fontSize: 28, ml: !drawer && 0.7 }}
                          />
                        )}
                      </Tooltip>
                      {drawer && (
                        <Typography variant="subtitle1" color="#003272">
                          {src.title}
                        </Typography>
                      )}
                    </Box>
                    {Array.isArray(src.subMenus) &&
                      (menuOpened !== src.title ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                    }
                    {drawer && src.showCount && (
                      <Chip
                        label={src.count || 0}
                        size="small"
                        sx={{
                          fontSize: 14,
                          bgcolor: "#b4d3ff",
                          color: "#003272",
                          fontWeight: "bold",
                          minWidth: 50,
                          p: 1,
                        }}
                      />
                    )}

                  </Box>
                  {/* {Array.isArray(menuOpened === src.title && src.subMenus) && */}
                  <Collapse in={menuOpened === src.title && src.subMenus} unmountOnExit>
                    <Box display="flex" flexDirection="column" gap={.5} ml={drawer ? 4 : "18px"} mb={1}>
                      {src.subMenus?.map((menu, i) => (
                        <>
                          {i !== 0 && <Divider sx={{ width: "90%", borderColor: "primary.light" }} />}
                          <Box component={ButtonBase} onClick={() => { navigation(menu.url) }} display="flex" justifyContent="flex-start" gap={1.25} alignItems="center">
                            <Tooltip title={menu.title} placement="right-start">
                              {typeof menu.icon === "string" ? (
                                <Box
                                  component="img"
                                  src={menu.icon}
                                  width={20}
                                  height={20}
                                  alt={src.title}
                                  sx={{ ml: !drawer && 1 }}
                                />
                              ) : (
                                <menu.icon
                                  color="primary"
                                  sx={{ fontSize: 24, ml: !drawer && 0.7 }}
                                />
                              )}
                            </Tooltip>
                            {drawer && (
                              <Typography variant="subtitle2" color="#003272">
                                {menu.title}
                              </Typography>
                            )}
                          </Box>
                        </>
                      ))}
                    </Box>
                  </Collapse>
                  {/* } */}
                </Box>
              ))}

          </Box>
          <Box
            mb={10}
            textAlign={drawer ? "right" : "left"}
            p={1}
            ml={!drawer && 0.5}
          >
            <IconButton
              sx={{ borderRadius: "10px", bgcolor: "#ECF0F4" }}
              onClick={() => { setDrawer(!drawer); localStorage.setItem("drawer", JSON.stringify(!drawer)) }}
            >
              {/* <Box component="img" src={MenuIcon} width="100%" height="100%" /> */}
              {drawer ? (
                <KeyboardArrowLeftIcon fontSize="small" />
              ) : (
                <KeyboardArrowRightIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
        </Box>
      </MiniDrawer >

      <Box component="main" sx={{ flexGrow: 1, overflowX: "hidden", p: 2.75 }}>
        <Toolbar />
        {props.children}
      </Box>
      {/* About Dialog Box */}
      <DraggableDialog open={showAbout} setOpen={openAbout} keepMounted>
        <AboutPage onClose={() => { openAbout(false) }} />
      </DraggableDialog>
    </Box >
  );
}
const mapStateToProps = (state) => {
  return {
    brand: state.brand,
    user: state.cms_user,
    dashboards: state.dashboards,
    casesCount: state.casesCount,
    tasksCount: state.tasksCount,
    alertsCount: state.alertsCount,
    permissions: state.permissions,
    userType: state.cms_user?.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authLogout: async () => await dispatch(authLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClippedDrawer);
