import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Collapse,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateRangeSelector from "../../../../../components/Field/DateRangeSeletor";
import { formatDate } from "../../../../../utils/utils";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import EventsTabs from "../EventsTabs";
import { vulnerabilit_events_query } from "../../../enums/enums";
import { wazuhIndex } from "../../../../../config/wazuh";
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';

function SideCanvas({
  agentId,
  show,
  setShow,
  row,
  last_partial_scan,
  last_full_scan,
  ...props
}) {
  const handleClose = () => setShow(false);

  const initialDateRange = {
    from_date: formatDate(dayjs().subtract(7, "day")),
    to_date: formatDate(dayjs()),
  };
  const [showreferences, setShowReferences] = useState(false)
  const [events, setEvents] = useState({ loading: false, data: {} });
  const [query, setQuery] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);

  const [pagination, setPagination] = React.useState({
    from: 0,
    size: 10,
  });

  const handleSearch = (e) => {
    const dqlQuery = e.target.value;
    setQuery(dqlQuery);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      FetchEvent();
    }
  };

  const FetchEvent = async (
    _query = query,
    from_date = dateRange.from_date,
    to_date = dateRange.to_date,
    from = pagination.from,
    size = pagination.size
  ) => {
    setEvents((pre) => ({ ...pre, loading: true }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        vulnerabilit_events_query(
          agentId, row.name, row.cve, row.architecture, row.version,
          _query,
          from_date,
          to_date,
          from,
          size
        )
      )
      .then((res) => {
        setEvents({ data: res.data, loading: false });
      })
      .catch(() => {
        setEvents((pre) => ({ ...pre, loading: false }));
      });
  };
  const handlePageChange = (newFrom, newSize) => {
    setPagination({ from: newFrom, size: newSize });
    FetchEvent(undefined, undefined, newFrom, newSize);
  };

  useEffect(() => {
    FetchEvent();
    // eslint-disable-next-line
  }, [row]);

  return (
    <Box
      component={Offcanvas}
      sx={{
        width: "70% !important",
        bgcolor: "#ecf0f4",
      }}
      show={show}
      onHide={handleClose}
      {...props}
    >
      <Offcanvas.Header  closeButton>
        <Offcanvas.Title>{row.cve}</Offcanvas.Title>
      </Offcanvas.Header>
      <Divider />
      <Offcanvas.Body>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" justifyContent="space-between" gap={2}>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <TitleOutlinedIcon /> Title
                  </Typography>
                  <Typography variant="subtitle1">{row.title}</Typography>
                </Box>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <FiberManualRecordIcon color="primary" />
                    Name
                  </Typography>
                  <Typography variant="subtitle1">{row.name}</Typography>
                </Box>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <WifiTetheringIcon color="primary" />
                    CVE
                  </Typography>
                  <Typography variant="subtitle1">{row.cve}</Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" gap={2}>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <AccountTreeIcon color="primary" /> Version
                  </Typography>
                  <Typography variant="subtitle1">{row.version}</Typography>
                </Box>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <HexagonOutlinedIcon color="primary" />
                    Architecture
                  </Typography>
                  <Typography variant="subtitle1">{row.architecture}</Typography>
                </Box>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <MyLocationIcon color="primary" />
                    Condition
                  </Typography>
                  <Typography variant="subtitle1">{row.condition}</Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" gap={2}>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <AccessTimeIcon color="primary" /> Last full scan
                  </Typography>
                  <Typography variant="subtitle1">{last_full_scan}</Typography>
                </Box>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <AccessTimeIcon color="primary" />
                    Last partial scan
                  </Typography>
                  <Typography variant="subtitle1">{last_partial_scan}</Typography>
                </Box>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <AccessTimeIcon color="primary" />
                    Published
                  </Typography>
                  <Typography variant="subtitle1">
                    {new Date(row.published).toDateString()}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" gap={2}>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <AccessTimeIcon color="primary" /> Updated
                  </Typography>
                  <Typography variant="subtitle1">
                    {new Date(row.updated).toDateString()}
                  </Typography>
                </Box>
                <Box width="30%">
                  <Typography variant="subtitle1" fontWeight={700}>
                    <LinkIcon color="primary" />
                    References
                  </Typography>
                  <Typography variant="subtitle1">
                    <ButtonBase sx={{ textDecoration: "underline" }} onClick={() => setShowReferences(!showreferences)}>
                      View external references
                    </ButtonBase>
                    <Collapse in={showreferences} unmountOnExit>
                      {row.external_references?.map(r => (
                        <a href={r} key={r} style={{ display: "block" }}>
                          {r}
                        </a>
                      ))}
                    </Collapse>
                  </Typography>
                </Box>
                <Box width="30%"></Box> {/* Empty box for spacing */}
              </Box>
            </Box>

          </AccordionDetails>
        </Accordion>
        <Divider />
        <Box mt={1} />
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Recent Events</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={"space-around"}
              gap={2}
            >
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Search Event"
                    value={query}
                    onChange={handleSearch}
                    onKeyDown={handleSearchKeyDown}
                  />
                </Grid>
                <Grid item xs={5}>
                  <DateRangeSelector
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    startIcon={<HistoryIcon fontSize="small" />}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => FetchEvent()}
                  >
                    Refresh
                  </Button>
                </Grid>
                {events.data?.hits?.hits && (
                  <Grid item xs={12}>
                    <EventsTabs
                      loading={events.loading}
                      data={events.data}
                      pagination={pagination}
                      handlePageChange={handlePageChange}
                      setTableData={() => { }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Offcanvas.Body>
    </Box >
  );
}

export default SideCanvas;
