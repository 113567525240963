import { CustomTableCell } from "../../../components/Display/CustomTable";
import React from "react";
import {
  TableRow,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { formatCreatedAt } from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import InputField from "../../../components/Field/BootStrap/InputField";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CustomUsersRow = ({
  row,
  isSelected,
  onEdit,
  openEditBox,
  updateField,
  updatePassword,
  onDelete,
}) => {
  const [editPassword, setEditPassword] = useState(false);
  // const [apiKey, setApikey] = useState("");

  const handlePasswordField = (e) => {
    if (e.key === "Enter" && e.target.value.length > 3) {
      updatePassword(row._id, e.target.value);
      setEditPassword(false);
    } else if (e.key === "c" && e.ctrlKey) {
      setEditPassword(false);
    }
  };
  // const createKey = (id) => {
  //   // cms.post(`/cms/api/v1/user/${id}/key/renew`).then((res) => {
  //   cms.post(`/api/adduser/${id}/key/renew`).then((res) => {
  //     if (res.status === 200) {
  //       toast.info("key created successfully");
  //       refrashFunc();
  //     }
  //   });
  // };
  // const revealKey = (id) => {
  //   // cms.get(`/cms/api/v1/user/${id}/key`).then((res) => {
  //   cms.get(`/api/adduser/${id}/key`).then((res) => {
  //     if (res.status === 200) {
  //       setApikey(res.data);
  //     }
  //   });
  // };
  // const revokeKey = (id) => {
  //   // cms.delete(`/cms/api/v1/user/${id}/key`).then((res) => {
  //   cms.delete(`/api/adduser/${id}/key`).then((res) => {
  //     toast.info("key revoked");
  //     refrashFunc();
  //   });
  // };
  // const renewKey = (id) => {
  //   // cms.post(`/cms/api/v1/user/${id}/key/renew`).then((res) => {
  //   cms.post(`/api/adduser/${id}/key/renew`).then((res) => {
  //     if (res.status === 200) {
  //       toast.info("key revoked");
  //       setApikey(res.data);
  //     }
  //   });
  // };
  return (
    <TableRow
      key={row._id}
      sx={{
        borderRadius: "4px",
        minHeight: 76,
        verticalAlign: "baseline",
        "& td": {
          verticalAlign: "middle",
        },
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),
      }}
    >
      <CustomTableCell>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Button
            sx={{
              height: 26,
              bgcolor: !row.locked ? "success.main" : "error.main",
              color: "#fff",
            }}
          >
            {row.locked ? "Inactive" : "Active"}
          </Button>
        </Box>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 250 }}>
        <Typography fontSize={14} fontWeight="bold">
          {row.login}
        </Typography>
      </CustomTableCell>

      <CustomTableCell sx={{ minWidth: 200 }}>
        <Typography fontSize={14} fontWeight={400}>
          {row.name}
        </Typography>
      </CustomTableCell>

      <CustomTableCell sx={{ minWidth: 200 }}>
        <Tooltip title={row.permissions.map((p) => `${p}, `)}>
          <Typography fontSize={14} fontWeight={400}>
            {row.profile}
          </Typography>
        </Tooltip>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        {!editPassword && (
          <Button
            onClick={() => setEditPassword(true)}
            sx={{ bgcolor: "#ECF0F4", color: "primary.main", height: "26px" }}
          >
            {row.hasPassword ? "Edit Password" : "New Password"}
          </Button>
        )}
        {editPassword && (
          <Tooltip title="Enter to update or Ctl + C to cancel">
            <Box display="flex" gap={1} onKeyDown={handlePasswordField}>
              <InputField
                sx={{ "& input": { height: 8 } }}
                type="password"
                placeholder="Enter password"
              />

              <IconButton
                onClick={() => setEditPassword(false)}
                sx={{ borderRadius: "5px", p: 0 }}
              >
                <CancelIcon sx={{ color: "error.main" }} />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </CustomTableCell>
      {/* <CustomTableCell>
        {row.hasKey ? (
          !apiKey ? (
            <ButtonGroup size="small" variant="outlined" color="primary">
              <Button color="secondary" onClick={() => renewKey(row._id)}>
                Renew
              </Button>
              <Button
                color="error"
                onClick={() => {
                  revokeKey(row._id);
                }}
              >
                Revoke
              </Button>
              <Button
                onClick={() => {
                  revealKey(row._id);
                }}
              >
                Reveal
              </Button>
            </ButtonGroup>
          ) : (
            <TextField
              size="small"
              value={apiKey}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <CopyToClipboard
                      text={apiKey}
                      onCopy={() => {
                        toast.info("key copied");
                        setApikey("");
                      }}
                    >
                      <ContentCopyIcon color="primary" fontSize="small" />
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          )
        ) : (
          <Button
            onClick={() => {
              createKey(row._id);
            }}
            sx={{ bgcolor: "#ECF0F4", color: "primary.main", height: "26px" }}
          >
            Create API Key
          </Button>
        )}
      </CustomTableCell> */}
      <CustomTableCell>
        <Typography fontSize={14} fontWeight={400}>
          {row.hasMFA ? "Yes" : "No"}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 120 }}>
        <Box
          display="flex"
          flexDirection="column"
          //   justifyContent="space-between"
          alignItems={"center"}
          gap={1.25}
        >
          <Typography variant="caption" fontWeight={500}>
            <PlayCircleFilledRoundedIcon
              fontSize="small"
              sx={{ color: "#2497F3" }}
            />
            {formatCreatedAt(row._createdAt)}
          </Typography>
          {row._updatedAt && (
            <Typography variant="caption" fontWeight={500}>
              <CheckCircleRoundedIcon
                fontSize="small"
                sx={{ color: "#13D38E" }}
              />
              {formatCreatedAt(row._updatedAt)}
            </Typography>
          )}
        </Box>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <Tooltip title="Edit user">
          <IconButton
            onClick={() => {
              onEdit({
                id: row._id,
                login: row.login,
                name: row.name,
                organisation: row.organisation,
                profile: row.profile,
              });
              openEditBox(true);
            }}
            size="small"
            sx={{ color: "primary.main", fontSize: 14, mr: 1 }}
          >
            <EditIcon fontSize="small" sx={{ color: "primary.light" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete user">
          <IconButton
            onClick={() => {
              onDelete({
                id: row._id,
                login: row.login,
                name: row.name,
                organisation: row.organisation,
                profile: row.profile,
              });
            }}
            size="small"
            sx={{ color: "primary.main", fontSize: 14, mr: 1 }}
          >
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </Tooltip>

        {!row.locked ? (
          <Tooltip title="Lock user">
            <IconButton
              onClick={() => {
                updateField(row._id, "locked", true);
              }}
              size="small"
              sx={{ color: "primary.main", fontSize: 14 }}
            >
              <LockIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Unlock user">
            <IconButton
              size="small"
              onClick={() => {
                updateField(row._id, "locked", false);
              }}
              sx={{ color: "primary.main", fontSize: 14 }}
            >
              <LockOpenIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
          </Tooltip>
        )}
      </CustomTableCell>
    </TableRow>
  );
};

export default CustomUsersRow;
