import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";


export function Footer({ handleClose, handleOpenDialog, disabled }) {
    return <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" gap={4} sx={{ borderTop: "1px solid", borderColor: "divider", position: "absolute", bottom: -2, p: 2, left: 0, bgcolor: "background.paper" }}>
        <Button size="large" onClick={handleClose}  >
            Cancel
        </Button>
        <Button size="large" disabled={disabled} variant="contained" onClick={handleOpenDialog}>
            Confirm
        </Button>
    </Box>
}
function SideCanvas({ show, setShow, title, handleClose = null, description = null, placement = "end", ...props }) {
    const _handleClose = () => {
        if (handleClose) {
            handleClose();
        }
        else {
            setShow(false);
        }
    };

    return (
        <Box
            component={Offcanvas}
            sx={{
                width: props.width ? props.width + " !important" : "70% !important",
                bgcolor: "#ecf0f4",
            }}
            show={show}
            onHide={_handleClose}
            placement={placement}
            {...props}
        >
            <Box component={Offcanvas.Header} closeButton sx={{ bgcolor: "background.paper", height: "75px" }}>
                <Box className="d-flex flex-column">
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                    {description &&
                        <Typography variant="caption">{description}</Typography>
                    }
                </Box>
            </Box>
            <Divider />
            <Offcanvas.Body style={{ position: "relative", overflow: "hidden", }}>
                {props.children}
            </Offcanvas.Body>
        </Box>
    );
}

export default SideCanvas;
