import { CustomTableCell } from "../../../components/Display/CustomTable";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  TableRow,
  Box,
  Typography,
  Button,
  Grid,
  Checkbox,
  Chip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { pap_enum, severity_enum, status_enum, tlp_enum } from "../enums/enums";
import {
  calculateDaysFromTimestamp,
  formatCreatedAt,
} from "../../../utils/utils";
import ProfileAvatar from "../../../components/Display/ProfileAvatar";
import CloseCaseDialog from "./CloseCaseDialog";
import CircleIcon from "@mui/icons-material/Circle";

const CustomTableRow = ({
  row,
  isSelected,
  updateCase,
  refrashFunc,
  onSelect,
  permissions,
  SelectRows,
}) => {
  const navigation = useNavigate();
  const handleRowClick = () => {
    console.log("row click", closeCaseDialog);
    if (!closeCaseDialog) {
      navigation(`/case-detail/${row._id.replace("~", "")}`);
    }
  };
  const [closeCaseDialog, showCloseCaseDialog] = useState(false);

  return (
    <TableRow
      key={row._id}
      sx={{
        backgroundColor: row.flag && "#FFF9ED",
        borderRadius: "4px",
        minHeight: 76,
        verticalAlign: "baseline",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        "& .MuiTableCell-root": {
          verticalAlign: "middle",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),
      }}
      onClick={handleRowClick}
    >
      <CustomTableCell
        onClick={(e) => {
          e.stopPropagation();
          onSelect(row._id);
        }}
        sx={{
          borderColor: "#ECF0F4",
          borderLeftColor: severity_enum[row.severity]?.color,
          borderWidth: "1px 0 1px 9px",
          borderRadius: "4px 0px 0px 4px",
          verticalAlign: "top",
        }}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();
            onSelect(row._id);
          }}
          size="small"
          checked={isSelected}
          sx={{ padding: "0.25px" }}
        />
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 100 }}>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Typography variant="caption" fontWeight={500}>
            #{row.number}
          </Typography>
          <Box
            sx={{
              minHeight: "20px",
              p: "5px",
              borderRadius: "2px 0px 0px 2px",
              width: "fit-content",
            }}
            display="flex"
            alignItems="center"
            gap="4px"
            bgcolor={"#ECF0F4"}
            color="#FF3E3E"
          >
            <AccessTimeIcon fontSize={"12px"} sx={{ color: "#FF3E3E" }} />
            <Typography fontSize={10} fontWeight={400}>
              {calculateDaysFromTimestamp(row.startDate)} days
            </Typography>
          </Box>
        </Box>
      </CustomTableCell>
      <CustomTableCell>
        <Chip
          sx={{
            minWidth: "82px",
            backgroundColor: tlp_enum[row.tlp]?.bgColor,
            color: tlp_enum[row.tlp]?.value,
          }}
          icon={
            <CircleIcon
              fontSize="small"
              style={{ color: tlp_enum[row.tlp]?.color }}
            />
          }
          label={tlp_enum[row.tlp]?.title}
        />
      </CustomTableCell>
      <CustomTableCell>
        <Chip
          sx={{
            minWidth: "82px",
            backgroundColor: pap_enum[row.pap]?.bgColor,
            color: pap_enum[row.pap]?.value,
          }}
          icon={
            <CircleIcon
              fontSize="small"
              style={{ color: pap_enum[row.pap]?.color }}
            />
          }
          label={pap_enum[row.pap]?.title}
        />
      </CustomTableCell>
      <CustomTableCell width={400}>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Link to={`/case-detail/${row._id.replace("~", "")}`}>
            <Typography color="#1173FF" variant="caption" fontWeight={700}>
              {row.title}
            </Typography>
          </Link>
          <Grid container gap={1.25} maxWidth={"100%"}>
            {row.tags.map((tag, i) => (
              <Grid item key={tag + i}>
                <Box
                  sx={{
                    height: "20px",
                    p: "5px",
                    borderRadius: "2px 0px 0px 2px",

                    borderLeft: "5px solid #13D38E",
                    width: "fit-content",
                  }}
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  bgcolor={"#ECF0F4"}
                >
                  {tag}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CustomTableCell>
      <CustomTableCell>
        <Button
          variant="contained"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            if (permissions.mCases) {
              if (row.status === "Resolved") {
                updateCase(row._id, "status", "Open");
              } else {
                showCloseCaseDialog(true);
              }
            }
          }}
          style={{
            backgroundColor: status_enum[row.status.toLocaleLowerCase()],
            height: "26px",
            fontSize: 12,
            fontWeight: 700,
          }}
          className=" shadow-none"
        >
          {row.status === "Resolved" ? "Solved" : row.status}
        </Button>
        <CloseCaseDialog
          open={closeCaseDialog}
          setOpen={showCloseCaseDialog}
          caseId={row._id.replace("~", "")}
          refrashFunc={refrashFunc}
        />
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 110 }}>
        <Typography variant="caption" fontWeight={500}>
          {typeof severity_enum[row.severity].icon === "string" ? (
            <Box
              component="img"
              src={severity_enum[row.severity]?.icon}
              alt="priority"
              width={20}
              height={20}
              mr={"5px"}
            />
          ) : (
            severity_enum[row.severity].icon
          )}
          {severity_enum[row.severity]?.value}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Typography variant="caption" fontWeight={500}>
            <PlayCircleFilledRoundedIcon
              fontSize="small"
              sx={{ color: "#2497F3" }}
            />
            {formatCreatedAt(row.startDate)}
          </Typography>
          <Typography variant="caption" fontWeight={500}>
            <CheckCircleRoundedIcon
              fontSize="small"
              sx={{ color: "#13D38E" }}
            />
            {formatCreatedAt(row._createdAt)}
          </Typography>
        </Box>
      </CustomTableCell>
      <CustomTableCell>
        <Box display="flex" alignItems="flex-start" gap="5px">
          <ProfileAvatar />
          <Typography variant="caption" fontWeight={500}>
            {row.assignee}
          </Typography>
        </Box>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <Box display="flex" flexDirection="column" gap={"2px"}>
          <Box display="flex"
            sx={{
              textDecoration: "none", "&:hover": {
                textDecoration: "underline"
              }
            }}
            component={Link}
            onClick={e => e.stopPropagation()}
            to={`/case-detail/${row._id.replace("~", "")}?page=1`}
            gap={1} justifyContent="space-between">
            <Typography variant="caption" fontWeight={500} color="#A5A5A5">
              Task
            </Typography>
            <Typography
              variant="caption"
              fontWeight={500}
              color="#2497F3"
            >
              {row.extraData?.taskStats?.total}
            </Typography>
          </Box>
          <Box display="flex" sx={{
            textDecoration: "none", "&:hover": {
              textDecoration: "underline"
            }
          }}
            component={Link}
            onClick={e => e.stopPropagation()}
            to={`/case-detail/${row._id.replace("~", "")}?page=2`}
            gap={1} justifyContent="space-between">
            <Typography variant="caption" fontWeight={500} color="#A5A5A5">
              Observables
            </Typography>
            <Typography
              variant="caption"
              fontWeight={500}
              color="#2497F3"
            >
              {row.extraData?.observableStats?.total}
            </Typography>
          </Box>
          <Box display="flex"
            component={Link}
            onClick={e => e.stopPropagation()}
            to={`/case-detail/${row._id.replace("~", "")}?page=1`}
            sx={{
              textDecoration: "none", "&:hover": {
                textDecoration: "underline"
              }
            }}
            gap={1} justifyContent="space-between">
            <Typography variant="caption" fontWeight={500} color="#A5A5A5">
              TTPs
            </Typography>
            <Typography
              variant="caption"
              fontWeight={500}
              color="#2497F3"
            >
              {row.extraData?.procedureCount}
            </Typography>
          </Box>
        </Box>
      </CustomTableCell>
    </TableRow>
  );
};

export default CustomTableRow;
