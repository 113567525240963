import { Collapse, FormControlLabel, Radio, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export const NumaricFieldBox = ({
  label,
  field,
  value,
  selectedValue,
  setValue,
  setValid,
}) => {
  const [number, setNumber] = useState("");
  const [query, setQuery] = useState({});

  useEffect(() => {
    if (value !== "Empty" && (number === "" || !number || number < 0)) {
      setValid(false);
    } else {
      setValid(true);
    }

    let q = {};
    switch (value) {
      case "Empty":
        q = { _not: { _contains: field.name } };
        break;
      case "=":
        q = { _field: field.name, _value: parseInt(number) };
        break;
      case "!=":
        q = { _not: { [field.name]: parseInt(number) } };
        break;
      case "<":
        q = { _lt: { [field.name]: parseInt(number) } };
        break;
      case "<=":
        q = { _lte: { [field.name]: parseInt(number) } };
        break;
      case ">":
        q = { _gt: { [field.name]: parseInt(number) } };
        break;
      case ">=":
        q = { _gte: { [field.name]: parseInt(number) } };
        break;
      default:
        q = { _not: { _contains: "order" } };
        break;
    }
    setQuery(q);
    setValue(JSON.stringify(q));
    // eslint-disable-next-line
  }, [value, number]);

  return (
    <>
      <FormControlLabel
        name="date-buttons-group"
        onClick={(e) => {
          setValue(e.target.value);
          setValid(value === "Empty");
          setNumber("");
        }}
        value={JSON.stringify(query)}
        control={<Radio />}
        label={label}
      />
      <Collapse
        unmountOnExit
        in={value !== "Empty" && selectedValue === JSON.stringify(query)}
      >
        <TextField
          fullWidth
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          onKeyDown={(e) => e.stopPropagation()}
          size="small"
          margin="dense"
          color="primary"
          placeholder="Enter Numbre"
          type="number"
        />
      </Collapse>
    </>
  );
};
