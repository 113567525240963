import { wazuhIndex } from "../../config/wazuh";

export function FetchAlerts(method = "POST", query) {
  if (method === "POST") {
    return new Promise((resolve, reject) => {
      const body = {
        query: query,
        aggs: {},
        size: 0,
        track_total_hits: true,
      };
      wazuhIndex
        .post(`/idx/wazuh-alerts-*/_search`, body)
        .then((res) => {
          const response = res.data;
          if (res.status === 200 || res.status === 201) {
            if ("hits" in response) {
              resolve(response.hits);
            } else {
              reject("Invalid response format");
            }
          } else {
            reject("Request failed with status: " + res.status);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
