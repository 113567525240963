import { Box, Breadcrumbs, FormControl, Button, InputAdornment, InputLabel, Link, MenuItem, Paper, Select, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import T1Dashboard from './TIERS/T1Dashboard';
import { formatDate } from '../../utils/utils';
import dayjs from 'dayjs';
import DateRangeSelector from '../../components/Field/DateRangeSeletor';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import T2Dashboard from './TIERS/T2Dashboard';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { connect } from 'react-redux';
import { user_enums } from '../../store/reducers/reducer';

const TimeIntervalSelector = ({ interval, setInterval }) => {
    const handleChange = (event) => {
        setInterval(event.target.value);
    };

    return (
        <FormControl variant="outlined" size="small">
            <InputLabel id="interval-select-label">Interval</InputLabel>
            <Select
                labelId="interval-select-label"
                id="interval-select"
                value={interval}
                onChange={handleChange}
                label="Interval"
                startAdornment={
                    <InputAdornment position='start'>
                        <AccessTimeIcon sx={{ marginRight: 1 }} />
                    </InputAdornment>
                }
            >
                <MenuItem value={0.0625}>4 Seconds</MenuItem>
                <MenuItem value={0.25}>15 Seconds</MenuItem>
                <MenuItem value={0.5}>30 Seconds</MenuItem>
                <MenuItem value={1}>1 Minutes</MenuItem>
                <MenuItem value={10}>10 Minutes</MenuItem>
                <MenuItem value={15}>15 Minutes</MenuItem>
                <MenuItem value={30}>30 Minutes</MenuItem>
                <MenuItem value={60}>1 Hour</MenuItem>
            </Select>
        </FormControl>
    );
};

const T1Defaultlayout = [
    {
        "w": 1,
        "h": 1,
        "x": 0,
        "y": 0,
        "i": "1",
        "moved": false,
        "static": false
    },
    {
        "w": 1,
        "h": 1,
        "x": 1,
        "y": 0,
        "i": "2",
        "moved": false,
        "static": false
    },
    {
        "w": 1,
        "h": 1,
        "x": 2,
        "y": 0,
        "i": "3",
        "moved": false,
        "static": false
    },
    {
        "w": 1,
        "h": 1,
        "x": 3,
        "y": 0,
        "i": "4",
        "moved": false,
        "static": false
    },
    {
        "w": 2,
        "h": 1.5,
        "x": 4,
        "y": 0,
        "i": "5",
        "moved": false,
        "static": false
    },
    {
        "w": 2,
        "h": 1.5,
        "x": 6,
        "y": 0,
        "i": "6",
        "moved": false,
        "static": false
    },
    {
        "w": 4,
        "h": 2,
        "x": 0,
        "y": 1,
        "i": "7",
        "moved": false,
        "static": false
    },
    {
        "w": 2,
        "h": 1.5,
        "x": 4,
        "y": 1.5,
        "i": "8",
        "moved": false,
        "static": false
    },
    {
        "w": 2,
        "h": 1.5,
        "x": 6,
        "y": 1.5,
        "i": "9",
        "moved": false,
        "static": false
    },
    {
        "w": 4.5,
        "h": 2,
        "x": 0,
        "y": 3,
        "i": "10",
        "moved": true,
    },
    {
        "w": 3.5,
        "h": 2,
        "x": 4.5,
        "y": 3,
        "i": "11",
        "moved": false,
        "static": false
    }
]
const T2Defaultlayout = [
    {
        "w": 3,
        "h": 2,
        "x": 0,
        "y": 0,
        "i": "1",
        "moved": false,
        "static": false
    },
    {
        "w": 3,
        "h": 2,
        "x": 3,
        "y": 0,
        "i": "2",
        "moved": false,
        "static": false
    },
    {
        "w": 3,
        "h": 2,
        "x": 6,
        "y": 0,
        "i": "3",
        "moved": false,
        "static": false
    },
    {
        "w": 3,
        "h": 1,
        "x": 9,
        "y": 0,
        "i": "4",
        "moved": false,
        "static": false
    },
    {
        "w": 3,
        "h": 1,
        "x": 9,
        "y": 1,
        "i": "5",
        "moved": false,
        "static": false
    },
    {
        "w": 7,
        "h": 2,
        "x": 0,
        "y": 2,
        "i": "6",
        "moved": false,
        "static": false
    },
    {
        "w": 5,
        "h": 2,
        "x": 7,
        "y": 2,
        "i": "7",
        "moved": false,
        "static": false
    }
]
function Dashboard(props) {
    const { tier } = useParams();

    const [layout, setLayout] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const from_date = searchParams.get("fromDate");
    const to_date = searchParams.get("toDate");
    const timeInterval = searchParams.get("timeInterval");

    const _fromdate = from_date
        ? formatDate(dayjs(from_date).startOf("day"))
        : formatDate(dayjs().subtract(3, "days").startOf("day"));
    const _to_date = to_date
        ? formatDate(dayjs(to_date).startOf("day"))
        : formatDate(dayjs().startOf("day"));

    const [interval, setInterval] = useState(timeInterval ? timeInterval : 0.5);

    const [dateRange, setDateRange] = useState({
        from_date: localStorage.getItem("fromDate") || _fromdate,
        to_date: localStorage.getItem("toDate") || _to_date,
    });

    const handleLayoutReset = () => {
        if (tier === "Tier1") {
            localStorage.removeItem("tier1-layout");
        }
        else {
            localStorage.removeItem("tier2-layout");
        }
        setLayout(tier === "Tier1" ? T1Defaultlayout : T2Defaultlayout)
    }
    useEffect(() => {
        // Update searchParams state
        setSearchParams((params) => {
            const updatedParams = new URLSearchParams(params);
            updatedParams.set("fromDate", dateRange.from_date);
            updatedParams.set("toDate", dateRange.to_date);
            updatedParams.set("timeInterval", interval);

            // Save dateRange values to local storage
            localStorage.setItem("fromDate", dateRange.from_date);
            localStorage.setItem("toDate", dateRange.to_date);

            return updatedParams;
        });
        // eslint-disable-next-line
    }, [dateRange.from_date, dateRange.to_date, interval]);


    const Page = useCallback(({ _dateRange }) => {
        const T1Layer = localStorage.getItem("tier1-layout") ? JSON.parse(localStorage.getItem("tier1-layout")) : T1Defaultlayout;
        const T2Layer = localStorage.getItem("tier2-layout") ? JSON.parse(localStorage.getItem("tier2-layout")) : T2Defaultlayout;
        if (tier === "Tier1") {
            if (props.userType === user_enums.READONLY) {
                const _layout = T2Layer;
                return <T2Dashboard layout={_layout} setLayout={setLayout} dateRange={_dateRange} interval={interval} />
            }
            const _layout = T1Layer;
            return <T1Dashboard layout={_layout} setLayout={setLayout} dateRange={_dateRange} interval={interval} />
        }
        else {
            if (props.userType === user_enums.ANALYST) {
                const _layout = T1Layer;
                return <T1Dashboard layout={_layout} setLayout={setLayout} dateRange={_dateRange} interval={interval} />
            }
            const _layout = T2Layer;
            return <T2Dashboard layout={_layout} setLayout={setLayout} dateRange={_dateRange} interval={interval} />
        }
    }, [tier, interval])

    return (
        <Box height="100vh" mt={2}>
            <Box display="flex" alignItems={"center"} justifyContent="space-between" gap={2}>
                <Typography variant="h5" fontWeight="bold">Dashboard</Typography>
                <Box width="100%" alignItems="center" display="flex" gap={2} flexDirection="row-reverse">
                    <Button onClick={handleLayoutReset} startIcon={<ViewQuiltIcon fontSize='large' />} sx={{ textTransform: "none" }}>
                        Reset layout
                    </Button>
                    <Box>
                        <TimeIntervalSelector interval={interval} setInterval={setInterval} />
                    </Box>
                    <Box minWidth={600}>
                        <DateRangeSelector
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />
                    </Box>
                </Box>
            </Box>
            <Page _dateRange={dateRange} />
        </Box>
    )
}
const mapStateToProps = (state) => {
    return {
        userType: state.cms_user.profile,
    };
};
export default connect(mapStateToProps, null)(Dashboard);
