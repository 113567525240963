import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import wazuh from "../../../../../../config/wazuh";
import { Box, Divider, Grid } from "@mui/material";

const FilterFields = ({ policy_id, onSearch, agentId }) => {
  const initialOptions = [
    "condition",
    "description",
    "file",
    "registry",
    "rationale",
    "reason",
    "remediation",
    "result",
    "title",
  ];
  const ref = useRef(null);
  const [options, setOptions] = useState(initialOptions);
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value, setValue] = useState("");
  const [step, setStep] = useState(0);

  const CreateObjectFromQuery = (query) => {
    const matches = query.split(":");

    const fileObject =
      matches && matches[1].trimStart()
        ? { [matches[0].trim()]: matches[1].trimStart() }
        : null;
    return fileObject;
  };

  const FetchFields = async (field) => {
    await wazuh
      .get(`/sca/${agentId}/checks/${policy_id}`, {
        params: {
          distinct: true,
          select: field,
          limit: 30,
        },
      })
      .then((res) => {
        const data = res.data.data.affected_items;
        let _aapend = [];
        for (let d in data) {
          _aapend.push([data[d][field]]);
        }
        setOptions(_aapend);
        setStep(1);
        setValue(field + " : ");
      })
      .catch(() => {});
  };

  const handleOptionSelect = (event, _value) => {
    if (step === 0) {
      if (options.includes(_value)) {
        setValue(_value);
        FetchFields(_value);
        setStep(1);
      }
    } else {
      const new_value = value + _value;
      setSelectedOptions((pre) => [...pre, CreateObjectFromQuery(new_value)]);
      setStep(0);
      setOptions(initialOptions);
      setOpen(false);
      setValue(new_value);
    }
  };
  const RemoveFilter = (key) => {
    setSelectedOptions((pre) => pre.filter((p) => Object.keys(p)[0] !== key));
  };

  useEffect(() => {
    if (step !== 1) {
      setValue("");
      onSearch(selectedOptions);
    }
    // eslint-disable-next-line
  }, [selectedOptions]);
  return (
    <Box>
      <Autocomplete
        ref={ref}
        open={open}
        onFocus={(e) => {
          setOpen(true);
        }}
        onBlur={(e) => {
          setOpen(false);
        }}
        size="small"
        freeSolo
        value={value}
        options={options}
        onChange={handleOptionSelect}
        onKeyDown={(e) => {
          if (e.key === "Backspace") {
            setValue("");
            setOptions(initialOptions);
            setOpen(true);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Select options"
            placeholder="Select options"
          />
        )}
      />
      {selectedOptions.length > 0 && <Box component={Divider} mt={1} mb={1} />}
      {selectedOptions && (
        <Grid container spacing={1}>
          {selectedOptions.map((option, index) => (
            <Grid item key={Object.keys(option)[0]}>
              <Chip
                onDelete={() => {
                  RemoveFilter(Object.keys(option)[0]);
                }}
                size="small"
                key={index}
                label={`${Object.keys(option)[0]} : ${
                  Object.values(option)[0]
                }`}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default FilterFields;
