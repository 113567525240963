import { CustomTableCell } from "../../../components/Display/CustomTable";

import React, { useEffect } from "react";
import {
  TableRow,
  Box,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Checkbox,
  Chip,
  Slide,
  Fade,
} from "@mui/material";
import { pap_enum, severity_enum, tlp_enum } from "../../Cases/enums/enums";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { formatCreatedAt } from "../../../utils/utils";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link, useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
import LaunchIcon from "@mui/icons-material/Launch";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleIcon from "@mui/icons-material/Circle";
import DraftsIcon from "@mui/icons-material/Drafts";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "../../../static/css/rowSplasher.css";

const CustomAlertsRow = ({
  row,
  isSelected,
  onSelect,
  openDetailDialog,
  fetchAlertDetail,
  followORunfollowAlert,
  readOrunReadAlert,
  fetchAlertResponders,
  permissions,
  loading = false,
}) => {
  const navigation = useNavigate();

  const handleRowClick = () => {
    if (row.caseId) {
      navigation(`/case-detail/${row.caseId.replace("~", "")}`);
    }
  };
  useEffect(() => {
    if (row.updatedRow) {
      const updatedRow = document.querySelector('.updatedRow');
      if (updatedRow) {
        window.scrollTo({ top: 400, behavior: 'smooth' });
      }
    }
  }, [row.updatedRow]);

  return (
    <TableRow
      className={row.updatedRow && "updatedRow"}
      sx={{
        borderRadius: "4px",
        minHeight: 76,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#F5F7FA",
        },
        "& .MuiTableCell-root": {
          verticalAlign: "middle ",
        },
        ...(isSelected && {
          backgroundColor: "#EAF6FF",
        }),

      }}
      onClick={handleRowClick}
    >
      <CustomTableCell
        onClick={(e) => {
          e.stopPropagation();
          onSelect(row._id);
        }}
        sx={{
          borderColor: "#ECF0F4",
          borderLeftColor: severity_enum[row.severity]?.color,
          borderWidth: "1px 0 1px 9px",
          borderRadius: "4px 0px 0px 4px",
          verticalAlign: "top",
        }}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();
            onSelect(row._id);
          }}
          size="small"
          checked={isSelected}
          sx={{ padding: "0.25px" }}
        />
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="caption" fontWeight={500}>
          {row._id.replace("~", "#")}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Box display="flex" flexDirection="column" gap={1}>
          <Chip
            sx={{
              minWidth: "82px",
              backgroundColor: tlp_enum[row.tlp]?.bgColor,
              color: tlp_enum[row.tlp]?.value,
            }}
            icon={
              <CircleIcon
                fontSize="small"
                style={{ color: tlp_enum[row.tlp]?.color }}
              />
            }
            label={tlp_enum[row.tlp]?.title}
          />
          <Chip
            sx={{
              minWidth: "82px",
              backgroundColor: pap_enum[row.pap]?.bgColor,
              color: pap_enum[row.pap]?.value,
            }}
            icon={
              <CircleIcon
                fontSize="small"
                style={{ color: pap_enum[row.pap]?.color }}
              />
            }
            label={pap_enum[row.pap]?.title}
          />
        </Box>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 120 }}>
        <Typography variant="caption" fontWeight={500}>
          {typeof severity_enum[row.severity]?.icon === "string" ? (
            <Box
              component="img"
              src={severity_enum[row.severity]?.icon}
              alt="priority"
              width={20}
              height={20}
              mr={"5px"}
            />
          ) : (
            severity_enum[row.severity]?.icon
          )}

          {severity_enum[row.severity]?.value}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        {row.read ? (
          <Box display="flex" alignItems="center" gap={1}>
            <DraftsIcon />
            Read
          </Box>
        ) : (
          <Box display="flex" alignItems="center" gap={1} size="small">
            <MarkunreadIcon sx={{ color: "primary.light" }} size="small" />
            Unread
          </Box>
        )}
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <Box display="flex" flexDirection="column" gap={1.25}>
          {row.caseId ? (
            <Link to={`/case-detail/${row.caseId?.replace("~", "")}`}>
              <Typography color="#1173FF" variant="caption" fontWeight={700}>
                {row.title}
              </Typography>
            </Link>
          ) : (
            <Typography variant="caption" fontWeight={700}>
              {row.title}
            </Typography>
          )}
          <Grid container gap={1.25} maxWidth={"100%"}>
            {row.tags.map((tag, i) => (
              <Grid item key={tag + i}>
                <Box
                  sx={{
                    height: "20px",
                    p: "5px",
                    borderRadius: "2px 0px 0px 2px",
                    borderLeft: "5px solid #13D38E",
                    width: "fit-content",
                  }}
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  bgcolor={"#ECF0F4"}
                >
                  {tag}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top", minWidth: 100 }}>
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{ color: "#A5A5A5" }}
        >
          {row.extraData?.caseNumber ? row.extraData?.caseNumber : "None"}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{ color: "#A5A5A5" }}
        >
          {row.type}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{ color: "#A5A5A5" }}
        >
          {row.source}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{ color: "#A5A5A5" }}
        >
          {row.sourceRef}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ verticalAlign: "top" }}>
        <Typography
          variant="caption"
          fontWeight={500}
          sx={{ color: "#A5A5A5" }}
        >
          {row.observableCount}
        </Typography>
      </CustomTableCell>
      <CustomTableCell sx={{ minWidth: 200 }}>
        <Box display="flex" flexDirection="column" gap={1.25}>
          <Tooltip title="Started Date">
            <Typography variant="caption" fontWeight={500}>
              <AccessTimeIcon fontSize="small" sx={{ color: "#2497F3" }} />
              {formatCreatedAt(row.date)}
            </Typography>
          </Tooltip>
          <Tooltip title="Created Date">
            <Typography variant="caption" fontWeight={500}>
              <CheckCircleRoundedIcon
                fontSize="small"
                sx={{ color: "#13D38E" }}
              />
              {formatCreatedAt(row._createdAt)}
            </Typography>
          </Tooltip>
          {row._updatedAt && (
            <Tooltip title="Updated Date">
              <Typography variant="caption" fontWeight={500}>
                <AutorenewIcon fontSize="small" color="primary" />
                {formatCreatedAt(row._updatedAt)}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </CustomTableCell>
      <CustomTableCell>
        <Box display="flex" gap={"2px"}>
          <Tooltip title="Preview & import">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                openDetailDialog(true);
                fetchAlertDetail(row._id);
              }}
            >
              <LaunchIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            hidden={!permissions.mAlerts}
            title={row.read ? "Marke as unread" : "Marke as read"}
          >
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                readOrunReadAlert(
                  row._id,
                  row.read ? "markAsUnread" : "markAsRead"
                );
              }}
            >
              {row.read ? (
                <DraftsIcon fontSize="small" sx={{ color: "primary.light" }} />
              ) : (
                <MailIcon fontSize="small" sx={{ color: "primary.light" }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip hidden={!permissions.mAlerts} title="Ignore new updates">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                followORunfollowAlert(
                  row._id,
                  row.follow ? "unfollow" : "follow"
                );
              }}
            >
              {row.follow ? (
                <VisibilityIcon
                  fontSize="small"
                  sx={{ color: "primary.light" }}
                />
              ) : (
                <VisibilityOffIcon
                  fontSize="small"
                  sx={{ color: "primary.light" }}
                />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip hidden={!permissions.mAlerts} title="Run responders">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                fetchAlertResponders(row._id);
              }}
            >
              <SettingsIcon fontSize="small" sx={{ color: "primary.light" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </CustomTableCell>
    </TableRow>

  );
};

export default CustomAlertsRow;
