import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

export default function AutoCompleteFilters({ filtersSuggestions, onSearch }) {
  let timeoutId = React.useRef();
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoader] = useState(false);
  //   Values
  const [queries, createQuery] = useState([]);

  //   track of stage
  const [querySteps, selectQuerySteps] = useState(1);
  //   Selected Options
  const [selectedcolumn, setSelectedColumn] = useState([]);

  const loadColumn = () => {
    const columns = [];
    for (let column in filtersSuggestions) {
      columns.push({
        display: filtersSuggestions[column].label,
        description: filtersSuggestions[column].description,
      });
    }
    setOptions(columns);
  };
  const loadOperators = (selectedcolumn) => {
    if (selectedcolumn) {
      const columns = [];
      let operators = selectedcolumn.operators;
      for (let op in operators) {
        columns.push({
          display: operators[op],
          description: operators[op] === "!=" ? "Not equal" : "Equal",
        });
      }
      setOptions(columns);
    }
  };
  const loadValues = async () => {
    setLoader(true);

    const obj = selectedcolumn;
    let columns = [];
    let values = [];
    await obj.values(null).then((res) => {
      values = res;
    });
    for (let op in values) {
      columns.push({
        display: values[op],
        description: "",
      });
    }
    setOptions(columns);
    setLoader(false);
  };
  const loadGates = () => {
    const columns = [];
    let operators = ["OR", "AND"];
    for (let op in operators) {
      columns.push({
        display: operators[op],
        description:
          operators[op] === "OR"
            ? "Return one argument to be true"
            : "Return Both argument to be true",
      });
    }
    setOptions(columns);
  };
  //   Manage steps
  const handleOptionSelect = debounce(async (e, newQ) => {
    if (e.key === "Backspace" && e.repeat) {
      // Handle backspace key held down
      return;
    }
    createQuery(newQ.map((v) => v.display));
    const _selectedcolumn = newQ[newQ.length - 1];
    if (e.key === "Backspace") {
      if (querySteps === 3) {
        const _selected_column = filtersSuggestions.find(
          (field) => field.label === _selectedcolumn.display
        );
        setSelectedColumn(_selected_column);
        loadOperators(_selected_column);
      } else if (querySteps === 4) {
        loadValues();
      } else if (querySteps === 2) {
        loadColumn();
      } else if (querySteps === 1) {
        if (queries.length > 0) {
          loadGates();
          selectQuerySteps(4);
          const _newQuery = queries.splice(-3 - 1)[0];
          setSelectedColumn(
            filtersSuggestions.find((field) => field.label === _newQuery)
          );
        } else {
          loadColumn();
        }
        return;
      }
      selectQuerySteps((pre) => (pre !== 1 ? pre - 1 : 1));
    } else {
      if (querySteps === 1) {
        const _selected_column = filtersSuggestions.find(
          (field) => field.label === _selectedcolumn.display
        );
        setSelectedColumn(_selected_column);
        loadOperators(_selected_column);
      } else if (querySteps === 2) {
        loadValues();
      } else if (querySteps === 3) {
        loadGates();
      } else if (querySteps === 4) {
        loadColumn();
        selectQuerySteps(1);
        return;
      }
      selectQuerySteps((pre) => pre + 1);
    }
  }, 500);
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !open) {
      if (querySteps === 4 || queries.length < 1) {
        onSearch(queries.join(""));
      } else {
        toast.warning("Complete query first !");
      }
    }
  };
  //   on First Fall
  useEffect(() => {
    loadColumn();
    // eslint-disable-next-line
  }, []);

  return (
    <Autocomplete
      multiple
      onKeyDown={handleKeyDown}
      sx={{ mt: 2 }}
      size="small"
      open={open && !loading}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      options={options}
      getOptionLabel={(option) => option.display}
      filterSelectedOptions
      renderTags={(values, getTagProps) =>
        queries.map((option, index) => (
          <Tooltip title={values[index]?.description}>
            <Typography variant="subtitle2" ml={0.5}>
              {option}{" "}
            </Typography>
          </Tooltip>
        ))
      }
      onChange={handleOptionSelect}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Filter Fields"
          placeholder={
            querySteps === 1
              ? "Select Column"
              : selectedcolumn && selectedcolumn.description
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
