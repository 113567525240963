import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertIcon from "../../../static/icons/alert-icon.svg";
import { Alert, Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";

export default function BulkTaskDeleteDialog({
  open,
  setOpen,
  tasks,
  refreshFunc,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  // andle Submit

  const handleSubmit = async (e) => {
    e.target.disabled = true;

    await axios
      .patch(`/cms/api/v1/task/_bulk`, {
        ids: tasks.map((t) => t._id),
        status: "Cancel",
      })
      .then((res) => {
        if (res.status === 204) {
          refreshFunc();
          setOpen(false);
        } else {
          toast.error("Something wents wrong");
        }
      });
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title" className="bg-danger">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="self-start"
        >
          <Box>
            <Typography variant="h5" fontWeight={700} color="#fff">
              <Box component="img" src={AlertIcon} alt="text" mr={1} />
              Permanently remove selected tasks
            </Typography>
            <Typography variant="caption" color="#fff">
              Deleting tasks
            </Typography>
          </Box>

          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          p={5}
        >
          <Typography paragraph>
            Are you sure you want to permanently delete the following {tasks.length}
            tasks(s)?
            <Typography display="block" color="error.main">
              This action might take a while and cannot be undone.
            </Typography>
          </Typography>
          <Box display="flex" gap={1.5} flexDirection="column">
            {tasks.map((c) => (
              <Alert variant="filled" severity="error">
                {c._id} - {c.title}
              </Alert>
            ))}
          </Box>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          <Button
            startIcon={<DeleteForeverIcon />}
            variant="contained"
            color="error"
            onClick={handleSubmit}
            sx={{ fontSize: 20, height: 50 }}
          >
            Confirm delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
