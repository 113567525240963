import { FormControlLabel, Radio } from "@mui/material";

export const DateRadioButton = ({ field_name, label, value, setValue }) => {
  let query = {};
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const currentTime = currentDate.getTime();

  switch (value) {
    case "Empty":
      query = { _not: { _contains: field_name } };
      break;
    case "Today":
      query = {
        _between: {
          _field: field_name,
          _from: currentTime,
          _to: currentTime,
        },
      };
      break;
    case "Last 7 Days":
      const last7DaysTime = 7 * 24 * 60 * 60 * 1000;
      const last7DaysAgoTime = currentTime - last7DaysTime;
      query = {
        _between: {
          _field: field_name,
          _from: last7DaysAgoTime,
          _to: currentTime,
        },
      };
      break;
    case "Last 30 Days":
      const last30DaysTime = 30 * 24 * 60 * 60 * 1000;
      const last30DaysAgoTime = currentTime - last30DaysTime;
      query = {
        _between: {
          _field: field_name,
          _from: last30DaysAgoTime,
          _to: currentTime,
        },
      };
      break;
    case "Last 6 Months":
      const last6MonthsTime = 6 * 30 * 24 * 60 * 60 * 1000;
      const last6MonthsAgoTime = currentTime - last6MonthsTime;
      query = {
        _between: {
          _field: field_name,
          _from: last6MonthsAgoTime,
          _to: currentTime,
        },
      };
      break;
    case "Last Year":
      const lastYearTime = 365 * 24 * 60 * 60 * 1000;
      const lastYearAgoTime = currentTime - lastYearTime;
      query = {
        _between: {
          _field: field_name,
          _from: lastYearAgoTime,
          _to: currentTime,
        },
      };
      break;
    default:
      query = null;
      break;
  }
  return (
    <FormControlLabel
      name="date-buttons-group"
      value={JSON.stringify(query)}
      onClick={(e) => setValue(e.target.value)}
      control={<Radio />}
      label={label}
    />
  );
};
