import { getAgentFilterValues } from "../../controllers/agents/common.js";
import {
  AGENT_SYNCED_STATUS,
  // UI_ORDER_AGENT_STATUS,
} from "../common/constants.js";

const filtersSuggestions = [
  {
    type: "q",
    label: "status",
    description: "Filter by agent connection status",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("status", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "group_config_status",
    description: "Filter by agent synced configuration status",
    operators: ["=", "!="],
    values: async (value) => [
      AGENT_SYNCED_STATUS.SYNCED,
      AGENT_SYNCED_STATUS.NOT_SYNCED,
    ],
  },
  {
    type: "q",
    label: "os.platform",
    description: "Filter by operating system platform",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("os.platform", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "ip",
    description: "Filter by agent IP address",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("ip", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "name",
    description: "Filter by agent name",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("name", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "id",
    description: "Filter by agent id",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("id", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "group",
    description: "Filter by agent group",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("group", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "node_name",
    description: "Filter by node name",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("node_name", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "manager",
    description: "Filter by manager",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("manager", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "version",
    description: "Filter by agent version",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("version", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "configSum",
    description: "Filter by agent config sum",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("configSum", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "mergedSum",
    description: "Filter by agent merged sum",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("mergedSum", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "dateAdd",
    description: "Filter by add date",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("dateAdd", value, { q: "id!=000" }),
  },
  {
    type: "q",
    label: "lastKeepAlive",
    description: "Filter by last keep alive",
    operators: ["=", "!="],
    values: async (value) =>
      getAgentFilterValues("lastKeepAlive", value, { q: "id!=000" }),
  },
];

export default filtersSuggestions;
