// import HeightIcon from "../../../static/icons/HeightIcon.svg";
// import MediumIcon from "../../../static/icons/MediumIcon.svg";
// import CriticalIcon from "../../../static/icons/critical-icon.svg";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
// import DensityLargeRoundedIcon from "@mui/icons-material/DensityLargeRounded";
import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";

export const severity_enum = {
  1: {
    value: "Low ",
    _value: 1,
    color: "#FF008A",
    icon: <KeyboardDoubleArrowDownRoundedIcon sx={{ color: "#FF008A" }} />,
  },
  2: {
    value: "Medium ",
    _value: 2,
    color: "#FF6B00",
    icon: <ViewStreamIcon sx={{ color: "#FF6B00" }} />,
  },
  3: {
    value: "High",
    _value: 3,
    color: "#3580F2",
    icon: <KeyboardDoubleArrowUpRoundedIcon sx={{ color: "#3580F2" }} />,
  },
  4: {
    value: "Critical",
    _value: 4,
    color: "#FF0F00",
    icon: <WarningRoundedIcon sx={{ color: "#FF0F00" }} />,
  },
};

export const status_enum = {
  open: "#1173FF",
  resolved: "#13D38E",
  closed: "#FF0F00",
  Reopen: "#13D38E",
};
export const case_status_enum = [
  {
    title: "Open",
    value: "Open",
  },
  {
    title: "Solved",
    value: "Resolved",
  },
  {
    title: "DELETED",
    value: "Deleted",
  },
  {
    title: "DUPLICATE",
    value: "Duplicate",
  },
];
export const resolutionStatus_enum = [
  { title: "Other", value: "Other" },
  { title: "FalsePositive", value: "FalsePositive" },
  { title: "TruePositive", value: "TruePositive" },
  { title: "Intermediate", value: "Intermediate" },
  { title: "Dublicated", value: "Dublicated" },
];
export const impactStatus_enum = [
  { title: "WithImpact", value: "WithImpact" },
  { title: "NotApplicable", value: "NotApplicable" },
  { title: "NoImpact", value: "NoImpact" },
];
export const resolution_enum = {
  falsepositive: "#FF6B00",
  truepositive: "#1173FF",
  indeterminate: "#FFA800",
  other: "#13D38E",
};

export const tlp_enum = [
  {
    title: "White",
    value: 0,
    color: "#FFFF",
    bgColor: "rgba(17, 115, 255, 0.09)",
  },
  {
    title: "Green",
    value: 1,
    color: "#13D38E",
    bgColor: "rgba(19, 211, 142, 0.09)",
  },
  {
    title: "Amber",
    value: 2,
    color: "#FF6B00",
    bgColor: "rgba(255, 107, 0, 0.09)",
  },
  {
    title: "Red",
    value: 3,
    color: "#FF3E3E",
    bgColor: "rgba(255, 62, 62, 0.09)",
  },
];

export const pap_enum = [
  {
    title: "White",
    value: 0,
    color: "#fff",
    bgColor: "rgba(17, 115, 255, 0.09)",
  },
  {
    title: "Green",
    value: 1,
    color: "#13D38E",
    bgColor: "rgba(19, 211, 142, 0.09)",
  },
  {
    title: "Amber",
    value: 2,
    color: "#FF6B00",
    bgColor: "rgba(255, 107, 0, 0.09)",
  },
  {
    title: "Red",
    value: 3,
    color: "#FF3E3E",
    bgColor: "rgba(255, 62, 62, 0.09)",
  },
];
