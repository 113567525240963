import React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@mui/material';
// import LogoIcon from "../../static/logos/logo.svg";
import { connect } from 'react-redux';

function AboutPage({ onClose, brand }) {
    return (
        <>
            <DialogTitle style={{ cursor: 'move', textAlign: "center" }} id="draggable-dialog-title">
                <Box component="img" src={brand.org_logo} maxHeight={180} />
            </DialogTitle>
            <DialogContent sx={{ minWidth: 500, p: "10px 50px" }}>
                <Grid container spacing={0.5} direction="column" justifyContent="center">
                    <Grid item container justifyContent="space-between" alignItems="center" spacing={4} >
                        <Grid item xs={6} textAlign="end">
                            <Typography variant="subtitle2" fontWeight="bold" component="b">Website</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="subtitle2" component="a" href={brand.org_website} target='_blank' >{brand.org_website}</Typography>
                        </Grid>
                    </Grid>
                    {brand.org_contact &&
                        <Grid item container justifyContent="space-between" alignItems="center" spacing={4} >
                            <Grid item xs={6} textAlign="end">
                                <Typography variant="subtitle2" fontWeight="bold" component="b">Contact</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="subtitle2" >{brand.org_contact}</Typography>
                            </Grid>
                        </Grid>
                    }
                    <Grid item container justifyContent="space-between" alignItems="center" spacing={4}  >
                        <Grid item xs={6} textAlign="end">
                            <Typography variant="subtitle2" component="b" fontWeight="bold">Name</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="subtitle2" >{brand.org_name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Grid item xs>
                            <Typography textAlign="center" variant="subtitle2" fontWeight="bold">{brand.org_address}</Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ textAlign: "center" }}>
                <Button size='small' variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        brand: state.brand
    };
};

export default connect(mapStateToProps, null)(AboutPage);
