import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Box,
  IconButton,
  // ButtonBase,
  // ButtonGroup,
  // Chip,
  // Grid,
  // IconButton,
  // InputAdornment,
  Snackbar,
  // Stack,
  Typography,
} from "@mui/material";

import Btn from "../../../components/Buttons/BootStrap/Btn";
import ReactScrollbar from "react-scrollbar";
import { useState } from "react";
import RichTextEditor from "../../../components/Field/RichTextEditor";
import FileUploadButton from "../../../components/Buttons/FileUploadButton";
import axios from "axios";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";

export default function AddTaskLogDialog({
  open,
  setOpen,
  caseId,
  refrashFunc,
  taskId,
}) {
  // const navigate = useNavigate();
  const [fields, setFields] = useState({ message: "", file: "" });

  const handleClose = () => {
    setOpen(false);
    setFields({ message: "", file: "" });
  };

  const [alert, setAlert] = useState({
    show: false,
    severity: "success",
    text: "",
  });
  const validate = () => {
    let valid = true;
    if (!fields.message && fields.message.length < 4) {
      toast.error("Message is required.");
      valid = false;
    }
    return valid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (validate()) {
        let formData = new FormData();
        if (fields.file) {
          formData.append("attachment", fields.file);
        }
        formData.append("_json", JSON.stringify({ message: fields.message }));

        const response = await axios.post(
          `/cms/api/case/task/${taskId}/log`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        let d = response;
        if (d.status === 201) {
          refrashFunc();
          toast.success("Task Logs added");
          handleClose();
        }
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" fontWeight={700}>
          Task Log
        </Typography>
        <IconButton onClick={handleClose}>
          <CancelIcon fontSize="large" />
        </IconButton>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <Snackbar
            open={alert.show}
            autoHideDuration={6000}
            onClose={() => {
              setAlert((pre) => ({ ...pre, show: false }));
            }}
          >
            <Alert
              onClose={() => {
                setAlert((pre) => ({ ...pre, show: false }));
              }}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {alert.text}
            </Alert>
          </Snackbar>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box display="flex" flexDirection="column" gap={2.5}>
              <Box>
                <Typography fontWeight="bold" variant="subtitle1">
                  Message*
                </Typography>
                <RichTextEditor
                  setValue={(msg) => {
                    setFields((pre) => ({ ...pre, message: msg }));
                  }}
                />
              </Box>
              <FileUploadButton
                value={fields.file}
                setValue={(file) => {
                  setFields((prev) => ({ ...prev, file: file }));
                }}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box
          pl="22px"
          pr="22px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="body2" fontWeight={400} color="#000">
            * Required field
          </Typography>
          <Btn
            onClick={handleSubmit}
            text="Add Log"
            height={50}
            fontSize={20}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
