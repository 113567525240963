import { Box, Typography } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  // Legend,
} from "recharts";

const RoundedBar = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={10}
      />
      <rect
        x={x}
        y={y}
        width={width}
        height={height / 2}
        fill={fill}
        rx={10}
        ry={10}
      />
    </g>
  );
};

const GenderBarChart = ({ data, barSize = 50 }) => {
  return (
    <Box
      component={ResponsiveContainer}
      sx={{
        "& .recharts-cartesian-axis-tick-value tspan": { fontSize: "0.7em" },
      }}
      width="100%"
      height={200}
    >
      {data.length < 1 ?
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Typography>No Data</Typography>
        </Box>
        :
        <BarChart
          data={data}
          margin={{ top: 30, right: 0, left: -30, bottom: 0 }}
        >
          <XAxis dataKey="name" axisLine={false} />
          <YAxis tick={{ fill: "#D8DFE7" }} axisLine={false} />
          <Tooltip />
          <Bar dataKey="value" barSize={barSize} shape={<RoundedBar />}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      }
    </Box>
  );
};

export default GenderBarChart;
