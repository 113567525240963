import {
  Box,
  Grid,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Collapse,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useCallback } from "react";
import Button from "../../components/Buttons/BootStrap/Btn.js";
// import codeIcon from "../../static/icons/code-icon.svg";
import PiecartIcon from "../../static/icons/piecart-icon.svg";
import PiecartBlueIcon from "../../static/icons/piechart-blue-icon.svg";
import InputField from "../../components/Field/BootStrap/InputField.js";
import Selector from "../../components/Field/BootStrap/Selectcer.js";
import PieChart from "../../components/charts/PieChart.js";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import GenderBarChart from "../../components/charts/BarChart.js";
import CustomTable from "../../components/Display/CustomTable.js";
import axios from "axios";
import { useState, useEffect } from "react";
import CustomTableRow from "./components/CustomRows.js";
import { resolution_enum, status_enum } from "./enums/enums.js";
import { insertElementAtIndex } from "../../utils/utils.js";
import ListView from "../../components/Display/ListView.js";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import TuneIcon from "@mui/icons-material/Tune";
import cms from "../../config/axiosConfig.js";
import FilterFields from "../../components/filters/FilterFields.js";
import { CasesFilterFields } from "../../utils/filters/fields.js";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import BulkCasesDeleteDialog from "./components/BulkCasesDeleteDialog.js";
import { connect } from "react-redux";

const CasesList = (props) => {
  const initialStatus = { by_status: [], by_resolution: [], by_tags: [] };
  const [quickFilterField, setQuickFilterFiled] = useState({});
  const initial_sortFields = [{ flag: "desc" }, { startDate: "desc" }];
  const [sortFields, setSortFields] = useState(initial_sortFields);
  const inital_filters = {
    sortBy: "",
    quickFilter: "",
    delete: "",
  };

  const [filters, setFilters] = useState(inital_filters);
  const initialPagination = { page: 1, per_page: 15 };
  const [pagination, setPagination] = useState(initialPagination);
  const [statuses, setStatuses] = useState(initialStatus);
  const [screens, setScreens] = useState(["filter", "status"]);
  const [data, setData] = useState({ loading: false, data: [], count: 0 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkDeleteDialog, showDeleteDialog] = useState(false);

  const [statsuViews, setStatusView] = useState({
    status_list: true,
    resolution_list: true,
    tags_list: true,
  });

  const columns = [
    { id: "_id", label: "# Number", sortable: true },
    { id: "tlp", label: "TLP", sortable: true },
    { id: "pap", label: "PAP", sortable: true },
    { id: "number", label: "Title", sortable: true },
    { id: "status", label: "Status", sortable: true },
    { id: "severity", label: "Severity", sortable: true },
    { id: "_createdAt", label: "Date", sortable: true },
    { id: "assignee", label: "Assignee", sortable: true },
    { id: "extraData", label: "Details", sortable: false },
  ];
  const sort_options = [
    {
      name: "Newest first",
      value: "newest_first",
      _field: [{ flag: "desc" }, { startDate: "desc" }],
    },
    {
      name: "Oldest first",
      value: "oldest_first",
      _field: [{ flag: "desc" }, { startDate: "asc" }],
    },
    {
      name: "Height Severity first",
      value: "height_severity_first",
      _field: [{ flag: "desc" }, { severity: "desc" }],
    },
    {
      name: "Low Severity first",
      value: "low_severity_first",
      _field: [{ flag: "desc" }, { severity: "asc" }],
    },
    {
      name: "Ascendant assignee",
      value: "ascendant_assignee",
      _field: [{ flag: "desc" }, { assignee: "asc" }],
    },
    {
      name: "Descendant assignee",
      value: "",
      _field: [{ flag: "desc" }, { assignee: "desc" }],
    },
  ];
  const quick_filter_options = [
    {
      name: "Open Case",
      value: "open_case",
      _field: { _name: "filter", _field: "status", _value: "Open" },
    },
    {
      name: "Close Case",
      value: "close_case",
      _field: {
        _name: "filter",
        _field: "status",
        _value: "Resolved",
      },
    },
  ];

  const hanldeScreens = (scr) => () => {
    const index = screens.indexOf(scr);

    if (index !== -1) {
      const updatedScreens = [...screens];
      updatedScreens.splice(index, 1);
      setScreens(updatedScreens);
    } else {
      setScreens((prevScreens) => [...prevScreens, scr]);
    }
  };
  const handleSort = (e) => {
    let value = e.target.value;
    setFilters({ ...filters, sortBy: value });
    let ops = sort_options.find((s) => s.value === value);
    if (ops) {
      setSortFields(ops._field);
    } else {
      setSortFields(initial_sortFields);
    }
  };
  const handleActionSelect = (event) => {
    showDeleteDialog(true);
  };
  const handleQuickFilter = (e) => {
    let value = e.target.value;
    setFilters({ ...filters, quickFilter: value });
    let ops = quick_filter_options.find((s) => s.value === value);
    if (ops) {
      setQuickFilterFiled(ops._field);
    } else {
      setQuickFilterFiled({});
    }
  };
  const handlePage = (event) => {
    const value = event.target.value;
    if (event.keyCode === 13 && value && value !== pagination.per_page) {
      setPagination((pre) => ({
        ...pre,
        per_page: parseInt(value),
      }));
      // fetchCases();
    }
  };
  const fetchCaseByTag = async () => {
    try {
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "listCase",
            },
            {
              _name: "aggregation",
              _agg: "field",
              _field: "tags",
              _select: [
                {
                  _agg: "count",
                },
              ],
              _order: ["-count"],
              _size: 5,
            },
          ],
        },
        {
          params: { name: "case-by-tags-stats" },
        }
      );

      let d = response.data;
      let _statuses = [];
      const COLORS = [
        "#FFAF15",
        "#FF3E3E",
        "#13D38E",
        "#1173FF",
        "#003272",
        "#FFAF15",
        "#FF6B00",
        "#000000",
        "#ffffff",
      ];
      let index = 0;
      for (let i in d) {
        _statuses.push({ name: i, value: d[i].count, color: COLORS[index] });
        index += 1;
      }
      setStatuses((pre) => ({ ...pre, by_tags: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCaseByStatus = async () => {
    try {
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "listCase",
            },

            {
              _name: "aggregation",
              _agg: "field",
              _field: "status",
              _select: [{ _agg: "count" }],
            },
          ],
        },
        {
          params: { name: "case-by-tags-stats" },
        }
      );
      let d = response.data;
      let _statuses = [];
      let defult_data = ["Resolved", "Open"];
      for (let i in d) {
        defult_data = defult_data.filter(
          (d) => d.toLowerCase() !== i.toLowerCase()
        );
        _statuses.push({
          name: i,
          value: d[i].count,
          color: status_enum[i.toLocaleLowerCase()],
        });
      }
      for (let row in defult_data) {
        _statuses.push({
          name: defult_data[row],
          value: 0,
          color: status_enum[defult_data[row].toLocaleLowerCase()],
        });
      }
      setStatuses((pre) => ({ ...pre, by_status: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCaseByResolution = async () => {
    try {
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: [
            { _name: "listCase" },

            {
              _name: "aggregation",
              _agg: "field",
              _field: "resolutionStatus",
              _select: [{ _agg: "count" }],
            },
          ],
        },
        {
          params: { name: "case-by-resolutionStatu-stats" },
        }
      );
      let d = response.data;
      let _statuses = [];

      let defult_data = ["FalsePositive", "TruePositive", "Other"];
      for (let i in d) {
        defult_data = defult_data.filter(
          (d) => d.toLowerCase() !== i.toLowerCase()
        );
        _statuses.push({
          name: i,
          value: d[i].count,
          color: resolution_enum[i.toLowerCase()],
        });
      }
      for (let row in defult_data) {
        _statuses.push({
          name: defult_data[row],
          value: 0,
          color: resolution_enum[defult_data[row].toLocaleLowerCase()],
        });
      }
      setStatuses((pre) => ({ ...pre, by_resolution: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCases = async (filter_query = false, get_count = false) => {
    try {
      if (!get_count) {
        setData((pre) => ({ ...pre, data: [], loading: true }));
      }
      let query = [
        {
          _name: "listCase",
        },
        {
          _name: "sort",
          _fields: sortFields,
        },
        {
          _name: "page",
          from: (pagination.page - 1) * pagination.per_page,
          to: pagination.page * pagination.per_page,
          extraData: [
            "observableStats",
            "taskStats",
            "procedureCount",
            "isOwner",
            "shareCount",
            "permissions",
            "actionRequired",
          ],
        },
      ];
      if (get_count) {
        if (filter_query && Array.isArray(filter_query)) {
          query.splice(1, 1);
        }
        query.pop();

        query.push({ _name: "limitedCount" });
      }

      if (filter_query && Array.isArray(filter_query)) {
        if (filter_query.length < 2) {
          const element = {
            _name: "filter",
            ...filter_query[0],
          };
          query = insertElementAtIndex(query, element, 1);
        } else {
          const element = {
            _name: "filter",
            _and: [
              // {
              //   _ne: {
              //     _field: "status",
              //     _value: "Cancel",
              //   },
              // },
              ...filter_query,
            ],
          };
          query = insertElementAtIndex(query, element, 1);
        }
      }
      if (Object.keys(quickFilterField).length > 0) {
        const indexToInsert = 2;
        const newObject = quickFilterField;
        const newArray = [...query];
        newArray.splice(indexToInsert, 0, newObject);
        query = newArray;
      }

      const response = await cms.post(
        "/cms/api/v1/query",
        {
          query: query,
        },
        {
          params: { name: get_count ? "case.count" : "cases" },
        }
      );
      let d = response.data;
      // setData({data: d, loading: false, count: d.length})
      if (get_count) {
        setData((pre) => ({ ...pre, count: d, loading: false }));
        fetchCases(filter_query, false);
      } else {
        setData((pre) => ({ ...pre, data: d, loading: false }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData((pre) => ({ ...pre, data: [], count: 0, loading: false }));
    }
  };
  const updateCase = async (caseId, filed_name, value) => {
    try {
      const response = await axios.patch(`/cms/api/case/${caseId}`, {
        [filed_name]: value,
      });

      if (response.status === 200) {
        toast.success(`Successfuly updated "${filed_name}"`);
        fetchCases();
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCaseByTag();
    fetchCaseByResolution();
    fetchCaseByStatus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCases(false, true);
    // eslint-disable-next-line
  }, [sortFields, quickFilterField, pagination.page, pagination.per_page]);

  const BulkDeleteDialogBox = useCallback(() => {
    return (
      <BulkCasesDeleteDialog
        open={bulkDeleteDialog}
        setOpen={showDeleteDialog}
        cases={data.data.filter((c) => selectedRows.includes(c._id))}
        refreshFunc={() => {
          fetchCases(false, true);
          setSelectedRows([]);
        }}
      />
    );
    // eslint-disable-next-line
  }, [selectedRows, bulkDeleteDialog]);

  const onPageChange = (e, new_page) => {
    setPagination((pre) => ({ ...pre, page: new_page }));
  };

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={3.5}>
      {/* Title & screens btns */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h5" fontWeight="bold">
            List of Cases ({data.count ? data.data.length : 0} of{" "}
            {data.count ? data.count : 0})
          </Typography>
          <Typography color="#A7BBCE" variant="caption">
            {"Dashboard > List of Cases"}
          </Typography>
        </Box>

        <Box display="flex" gap={1.25} alignItems="center">
          {/* <Button
          disabled
            text="Custom Fields"
            startIcon={codeIcon}
            onClick={hanldeScreens("custom_fields")}
            variant={"outline-primary"}
            sx={{
              borderColor: "#003272",
              color: "#003272",
              bgcolor: "transparent",
            }}
          /> */}
          <Button
            text="Stats"
            muiSVG={<DataSaverOffIcon sx={{ mr: 1 }} fontSize="small" />}
            onClick={hanldeScreens("status")}
            variant={"primary"}
            sx={{
              borderColor: "#003272",
              color: !screens.includes("status") ? "primary.dark" : "#fff",
              bgcolor: screens.includes("status") ? "#003272" : "transparent",
            }}
          />
          <Button
            text="Filter"
            muiSVG={<TuneIcon sx={{ mr: 1 }} fontSize="small" />}
            onClick={hanldeScreens("filter")}
            variant={"primary"}
            sx={{
              borderColor: "#003272",
              color: !screens.includes("filter") ? "primary.dark" : "#fff",
              bgcolor: screens.includes("filter") ? "#003272" : "transparent",
            }}
          />
          <Typography variant="caption" fontWeight={500} color="textPrimary">
            Per Pages
          </Typography>
          <Tooltip title="Enter to apply">
            <InputField
              type="number"
              defaultValue={pagination.per_page}
              onKeyDown={handlePage}
              // value={pagination.per_page}
              // onChange={(e) =>
              //   setPagination((pre) => ({
              //     ...pre,
              //     per_page: parseInt(e.target.value),
              //   }))
              // }
              sx={{ maxWidth: 121 }}
            />
          </Tooltip>
        </Box>
      </Box>
      {/* Filter & sort Buttons */}
      <Box display="flex" gap={1.25} alignItems="center">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="delete-label">
            {selectedRows.length < 1
              ? `No case selected`
              : `${selectedRows.length} cases selected`}
          </InputLabel>
          <Select
            sx={{ minWidth: 180 }}
            color="error"
            labelId="delete-label"
            disabled={selectedRows.length < 1}
            label="Action"
            value=""
            onChange={handleActionSelect}
          >
            <MenuItem value="">
              <em>Select Action</em>
            </MenuItem>
            <MenuItem value={"delete"}>
              <DeleteIcon color="error" />
              Delete
            </MenuItem>
          </Select>
          <BulkDeleteDialogBox />
        </FormControl>
        <Selector
          title={"Quick Filter"}
          value={filters.quickFilter}
          handleChange={handleQuickFilter}
          options={quick_filter_options}
        />
        <Selector
          value={filters.sortBy}
          handleChange={handleSort}
          title={"Sort By"}
          options={sort_options}
        />
      </Box>
      {/* Graphs */}
      <Collapse in={screens.find((s) => s === "status")}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6} xl={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={2.5}
              sx={{ borderRadius: 1.5 }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Case by Status</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, status_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.status_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.status_list ? PiecartIcon : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, status_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.status_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{
                        color: statsuViews.status_list ? "#1173FF" : "#fff",
                      }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box flex="1" height="100%">
                {/* Adjusted height here */}
                {statuses.by_status.length > 0 ? (
                  statsuViews.status_list ? (
                    <PieChart customLegend data={statuses.by_status} />
                  ) : (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_status} />
                    </Box>
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={2.5}
              sx={{ borderRadius: 1.5 }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Case by Resolution</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({
                        ...pre,
                        resolution_list: true,
                      }))
                    }
                    sx={{
                      bgcolor: statsuViews.resolution_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.resolution_list
                          ? PiecartIcon
                          : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({
                        ...pre,
                        resolution_list: false,
                      }))
                    }
                    sx={{
                      bgcolor: !statsuViews.resolution_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{
                        color: statsuViews.resolution_list ? "#1173FF" : "#fff",
                      }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1">
                {statuses.by_resolution.length > 0 ? (
                  !statsuViews.resolution_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_resolution} />
                    </Box>
                  ) : (
                    <GenderBarChart data={statuses.by_resolution} />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={2.5}
              sx={{ borderRadius: 1.5 }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Top 5 tags</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, tags_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.tags_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.tags_list ? PiecartIcon : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, tags_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.tags_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{
                        color: statsuViews.tags_list ? "#1173FF" : "#fff",
                      }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1">
                {statuses.by_tags.length > 0 ? (
                  !statsuViews.tags_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_tags} />
                    </Box>
                  ) : (
                    <PieChart data={statuses.by_tags} graphHeight="95%" />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      {/* Table */}
      <Collapse in={screens.includes("filter")}>
        <FilterFields
          filterField={CasesFilterFields}
          onSearch={fetchCases}
          _for="cases"
        />
      </Collapse>
      <Box
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={2.5}
        sx={{ borderRadius: 1.5, maxWidth: "100%", overflowX: "auto" }}
        height="100%"
      >
        <CustomTable
          checkboxies
          columns={columns}
          data={data.data}
          CustomTableRow={CustomTableRow}
          loading={data.loading}
          onPageChange={onPageChange}
          refrashFunc={fetchCases}
          updateCase={updateCase}
          page={pagination.page}
          total_count={Math.ceil(data.count / pagination.per_page)}
          SelectRows={setSelectedRows}
          permissions={props.permissions}
        />
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    permissions: state.permissions,
  };
};
export default connect(mapStateToProps, null)(CasesList);
