import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  ButtonBase,
  DialogActions,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ReactScrollbar from "react-scrollbar";
import { useEffect } from "react";
import messagereplyIcon from "../../../static/icons/messagereplyIcon.svg";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";

const RunResponderDialog = ({
  open,
  setOpen,
  parentOpen,
  selectResponder,
  data = {},
}) => {
  const handleClose = () => {
    setOpen(false);
    selectResponder({});
  };
  const RunAlert = async (id) => {
    try {
      const response = await axios.get(`/cms/api/connector/cortex/action`, {
        responderId: data.id,
        objectType: "alert",
        objectId: data.alertId,
      });
      let d = response.data;
      if (d) {
        toast.success("Successfuly start runinng.");
        handleClose();
        parentOpen(false);
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h5" fontWeight={700}>
          Run responders {data.name}
        </Typography>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <Typography variant="subtitle1">
            Are you sure you want to run responser Mailer_1_0?
          </Typography>
        </DialogContent>
      </ReactScrollbar>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={RunAlert}>
            Yes, run it
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default function AlertRespondersDialog({ open, setOpen, data = [] }) {
  const handleClose = () => {
    setOpen(false);
  };
  const [dialog, setdialog] = React.useState(false);
  const [selectedResponder, selectResponder] = React.useState({});

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  useEffect(() => {
    selectResponder({});
  }, []);
  const handleRunResponder = (repo) => () => {
    selectResponder(repo);
  };
  useEffect(() => {
    if (Object.keys(selectedResponder).length > 0) {
      setdialog(true);
    }
  }, [selectedResponder]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "822px",
          borderRadius: "15px",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="self-start">
          <Box>
            <Typography variant="h5" fontWeight={700}>
              Run Responders
            </Typography>
            <Typography variant="caption" color="#A7BBCE">
              Please select the responder you want to run
            </Typography>
          </Box>

          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CancelIcon fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReactScrollbar style={{ maxHeight: "70vh" }}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2.25}>
            <TextField
              fullWidth
              placeholder="Filter Responders"
              sx={{
                color: "primary.light",
                borderRadius: "5px",
                "& input::placeholder": { opacity: 1 },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
            {data &&
              data.map((res) => (
                <ButtonBase onClick={handleRunResponder(res)}>
                  <Box
                    sx={{
                      bgcolor: "#ECF0F4",
                      borderRadius: "6px",
                      width: "100%",
                      p: "17px 20px",
                      "&:hover": {
                        bgcolor: "#D4D8DB",
                      },
                    }}
                    display="flex"
                    gap={2.5}
                  >
                    <Box
                      component="img"
                      src={messagereplyIcon}
                      width={28}
                      height={28}
                    />
                    <Box>
                      <Typography
                        textAlign="left"
                        variant="subtitle1"
                        fontSize={14}
                      >
                        {res.name}
                      </Typography>
                      <Typography variant="caption">
                        {res.description}
                      </Typography>
                    </Box>
                  </Box>
                </ButtonBase>
              ))}
          </Box>
        </DialogContent>
      </ReactScrollbar>
      {/* Nested Dialog */}
      <RunResponderDialog
        parentOpen={setOpen}
        selectResponder={selectResponder}
        open={dialog}
        setOpen={setdialog}
        data={selectedResponder}
      />
    </Dialog>
  );
}
