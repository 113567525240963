import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../static/logos/logo.svg";
import "./FlashScreen.css";
import { connect } from "react-redux";

const FlashScreen = (props) => {
  let brand = localStorage.getItem("brand");
  const [logo, setLogo] = useState(brand ? JSON.parse(brand)?.org_logo : Logo);

  useEffect(() => {
    if (props.brand?.org_logo) {
      setLogo(props.brand?.org_logo);
    }
    else if (brand) {
      brand = JSON.parse(brand);
      setLogo(brand.org_logo);
    }
  }, [props.brand])

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <Box component={"img"} src={logo} alt="Logo" className="logo-animation" />
    </Box>
  );
};

const mapStatestoProps = (state) => {
  return {
    brand: state.brand
  }
}
export default connect(mapStatestoProps, null)(FlashScreen);
