import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SensorsOffIcon from "@mui/icons-material/SensorsOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import Table from "../../components/Display/Table";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import WindowIcon from "@mui/icons-material/Window";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import WindowIcon from "../../static/icons/windows.svg";
import UbuntuIcon from "../../static/icons/ubuntu.svg";
import MacosIcon from "../../static/icons/macos.svg";
import SensorsIcon from "@mui/icons-material/Sensors";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FullScreenDialog from "../../components/Dialog/FullScreenDialog";
import DeployAgents from "./DeployAgents.js";
import LeakRemoveIcon from "@mui/icons-material/LeakRemove";
import {
  FetchAgents,
  FetchAgentsSummary,
} from "../../utils/agents/AgentUtils.js";
import LanguageIcon from "@mui/icons-material/Language";
import { wazuhIndex } from "../../config/wazuh";
import { agent_evolution_query } from "./enums/enums";
import dayjs from "dayjs";
import AutoCompleteFilters from "../../components/Field/AutoComplete/AutoCompleteFilters.js";
import filtersSuggestions from "../../static/agents/filterSugessions.js";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 150,
  },
  {
    field: "ip",
    headerName: "IP address",
    minWidth: 150,
    renderCell: ({ value }) => (
      <Box
        sx={{
          color: "black",
          textDecoration: "none",
          "&:hover": { cursor: "pointer" },
        }}
        href="/"
      >
        {value}
      </Box>
    ),
  },
  {
    field: "group",
    headerName: "Groups",
    width: 150,
    renderCell: ({ value }) => (
      <Box style={{ border: "1px solid #D3DAE6" }} p={"0 8px"}>
        <Box
          sx={{
            color: "black",
            textDecoration: "none",
          }}
        >
          {value?.map((g) => g)}
        </Box>
      </Box>
    ),
  },
  {
    field: "os",
    headerName: "Operating system",
    minWidth: 210,
    flex: 1,
    valueFormatter: ({ value }) => value?.name,
    renderCell: ({ value }) => (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {value?.platform.includes("window") && (
          <Box component={"img"} src={WindowIcon} width="16px" mr={0.5} />
        )}
        {value?.platform.includes("ubuntu") && (
          <Box component={"img"} src={UbuntuIcon} width="16px" mr={0.5} />
        )}
        {value?.platform.includes("darwin") && (
          <Box component={"img"} src={MacosIcon} width="16px" mr={0.5} />
        )}
        {value?.name}
      </Box>
    ),
  },
  {
    field: "node_name",
    headerName: "Cluster node",
    width: 150,
  },
  {
    field: "version",
    headerName: "Version",
    width: 170,
  },
  {
    field: "dateAdd",
    headerName: "Registration Date",
    width: 200,
    type: "dateTime",

    // renderCell: ({ value }) => (value),
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: ({ value }) => (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FiberManualRecordIcon
          sx={{ color: value !== "active" ? "#d32f2f" : "green" }}
          fontSize="small"
        />
        {value}
      </Box>
    ),
  },
  {
    field: "group_config_status",
    headerName: "Sycend",
    width: 150,
    renderCell: ({ value }) => (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FiberManualRecordIcon
          sx={{ color: "success.dark" }}
          fontSize="small"
        />
        {value}
      </Box>
    ),
  },
  // {
  //   field: "actions",
  //   type: "actions",
  //   width: 80,
  //   getActions: (params) => [
  //     <GridActionsCellItem
  //       icon={<TuneIcon sx={{ color: "primary.dark" }} fontSize="small" />}
  //       label="Delete"
  //     />,
  //     <GridActionsCellItem
  //       icon={
  //         <RemoveRedEyeIcon sx={{ color: "primary.dark" }} fontSize="small" />
  //       }
  //       label="Toggle Admin"
  //     />,
  //   ],
  // },
];

const AgentsPreview = (props) => {
  const [agentDialog, openAgentDialog] = useState(false);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialDateRange = {
    from_date: dayjs().subtract(30, "day").toISOString(),
    to_date: dayjs().toISOString(),
  };
  // eslint-disable-next-line
  const [dateRange, setDateRange] = useState(initialDateRange);

  const [data, setData] = useState({
    summary: [],
    lastRegisteredAgent: "",
    evolution: { data: {}, data_keys: [] },
  });

  const FetchEvolution = async (
    from_date = dateRange.from_date,
    to_date = dateRange.to_date,
    repeat = true
  ) => {
    setData((pre) => ({
      ...pre,
      evolution: { data: [], data_keys: [] },
    }));
    await wazuhIndex
      .post(
        `/idx/wazuh-monitoring-*/_search/`,
        agent_evolution_query(from_date, to_date)
      )
      .then((res) => {
        const aggregations = res.data?.aggregations;
        const buckets = Object.values(aggregations)[0].buckets;
        // Create an empty object to hold the transformed data
        const transformedData = {};
        let data_keys = [];
        // Iterate over the API response to format the data
        buckets.forEach((item) => {
          const timestamp = new Date(item.key_as_string);

          // Format the timestamp as "HH:mm"
          const formattedTime = timestamp.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });

          const buckets = item[3].buckets;

          // Create an object with the formatted time as the 'name' property
          const formattedItem = { name: formattedTime };

          // Iterate over the 'buckets' and add each key as a property to the formatted item
          buckets.forEach((bucket) => {
            const d_key = bucket.key.toString();
            if (!data_keys.includes(d_key)) {
              data_keys.push(d_key);
            }
            formattedItem[d_key] = bucket.doc_count;
          });

          // Store the formatted item in the transformedData object
          transformedData[formattedTime] = formattedItem;
        });
        const chartData = Object.values(transformedData);
        setData((pre) => ({
          ...pre,
          evolution: { data: chartData, data_keys: data_keys },
        }));
      })
      .catch((error) => {
        if (repeat) {
          FetchEvolution(from_date, to_date, false);
        }
        console.log(error);
      });
  };
  useEffect(() => {
    FetchEvolution();
    // eslint-disable-next-line
  }, [dateRange]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
        }}
      >
        <GridToolbarExport />
        <Button
          hidden={!props.hasPermissions}
          variant="text"
          size="small"
          onClick={() => openAgentDialog(true)}
          startIcon={<AddCircleOutlineIcon fontSize="small" color="primary" />}
        >
          Deploy new agent
        </Button>
      </GridToolbarContainer>
    );
  };
  const _FetchAgent = async (params) => {
    setLoading(true);
    await FetchAgents("GET", { ...params })
      .then((resp) => {
        const _data = resp;
        for (const row of _data) {
          if (row["version"]?.includes("DefenceFusion")) {
            row["version"] = row["version"].replace("DefenceFusion", props.brand?.org_name);
          }
        }
        setAgents(_data);
        let lastRegisteredAgent = resp[0];

        for (let i = 1; i < resp.length; i++) {
          if (resp[i].dateAdd > lastRegisteredAgent.dateAdd) {
            lastRegisteredAgent = resp[i];
          }
        }
        setData((pre) => ({
          ...pre,
          lastRegisteredAgent: lastRegisteredAgent?.name,
        }));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSearch = (query) => {
    const new_query = query
      .replace(/\s/g, "")
      .replace("OR", ",")
      .replace("AND", ";");
    _FetchAgent({ q: `id!=000${new_query && ";"}${new_query}` });
  };

  useEffect(() => {
    _FetchAgent({ q: "id!=000" });
    FetchEvolution();

    FetchAgentsSummary("GET")
      .then((resp) => {
        const reposne = resp;
        const total = resp?.connection.total;
        const actives = resp?.connection.active;
        reposne["agents_coverage"] = (actives / total) * 100;
        setData((pre) => ({
          ...pre,
          summary: reposne,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justifyContent="center" spacing={2.5}>
      {/* Cards */}
      <Grid container item justifyContent="center" mt={1.5} spacing={2.5}>
        <Grid container item xs>
          <Box
            display="felx"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component={Paper}
            width="100%"
            elevation={0}
            gap={2.5}
            p={2}
            borderRadius={2}
          >
            <Box display="flex" gap={1} justifyContent="space-between">
              <Typography variant="subtitle1">Active</Typography>
              <SensorsIcon color="success" />
            </Box>
            <Typography variant="h5" fontWeight={600} color="primary">
              {data.summary?.connection?.active}
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs>
          <Box
            display="felx"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component={Paper}
            width="100%"
            elevation={0}
            gap={2.5}
            p={2}
            borderRadius={2}
          >
            <Box display="flex" gap={1} justifyContent="space-between">
              <Typography variant="subtitle1">Disconnected</Typography>
              <SensorsOffIcon color="error" />
            </Box>
            <Typography variant="h5" fontWeight={600} color="primary">
              {data.summary?.connection?.disconnected}
            </Typography>
          </Box>
        </Grid>

        <Grid container item xs>
          <Box
            display="felx"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component={Paper}
            width="100%"
            elevation={0}
            gap={2.5}
            p={2}
            borderRadius={2}
          >
            <Box display="flex" gap={1} justifyContent="space-between">
              <Typography variant="subtitle1">Pending</Typography>
              <HourglassBottomIcon color="warning" />
            </Box>
            <Typography variant="h5" fontWeight={600} color="primary">
              {data.summary?.connection?.pending}
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs>
          <Box
            display="felx"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component={Paper}
            width="100%"
            elevation={0}
            gap={2.5}
            p={2}
            borderRadius={2}
          >
            <Box display="flex" gap={1} justifyContent="space-between">
              <Typography variant="subtitle1">Never connected</Typography>
              <LeakRemoveIcon color="primary" />
            </Box>
            <Typography variant="h5" fontWeight={600} color="primary">
              {data.summary?.connection?.never_connected}
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs>
          <Box
            display="felx"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component={Paper}
            width="100%"
            elevation={0}
            gap={2.5}
            p={2}
            borderRadius={2}
          >
            <Box display="flex" gap={1} justifyContent="space-between">
              <Typography variant="subtitle1">Agents coverage</Typography>
              <LanguageIcon color="success" />
            </Box>
            <Typography variant="h5" fontWeight={600} color="primary">
              {parseFloat(data.summary.agents_coverage).toFixed(2)}%
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs>
          <Box
            display="felx"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component={Paper}
            width="100%"
            elevation={0}
            gap={2.5}
            p={2}
            borderRadius={2}
          >
            <Box display="flex" gap={1} justifyContent="space-between">
              <Typography variant="subtitle1">Last registered agent</Typography>
              <HowToRegIcon sx={{ color: "primary.light" }} />
            </Box>
            <Typography variant="h5" fontWeight={600} color="primary">
              {!data.lastRegisteredAgent ? "-" : data.lastRegisteredAgent}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Search Fields */}
      <Grid item xs={12}>
        <Box
          component={Paper}
          display="flex"
          gap={4}
          elevation={3}
          width={"100%"}
          p={2}
        >
          {/* <FilterSelecter onSearch={onSearch} /> */}
          <AutoCompleteFilters
            filtersSuggestions={filtersSuggestions}
            onSearch={onSearch}
          />
        </Box>
      </Grid>
      {/* Data Tables */}
      <Grid container item spacing={2.5}>
        <Grid item xs={12}>
          {/* <DataTable /> */}
          <Table
            columns={columns}
            rows={agents}
            CustomToolbar={CustomToolbar}
            loading={loading}
          />
        </Grid>
      </Grid>
      {/* Dialog */}
      {props.hasPermissions && (
        <FullScreenDialog
          open={agentDialog}
          setOpen={openAgentDialog}
          title={"Deploy new agent"}
        >
          <DeployAgents open={agentDialog} />
        </FullScreenDialog>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    mode: state.mode === "light" ? "black" : "white",
    hasPermissions: state.permissions.mUsers,
    brand: state.brand
  };
};

export default connect(mapStateToProps, null)(AgentsPreview);
