const orderOSAlphabetically = (a, b) => {
  if (a.label.toUpperCase() < b.label.toUpperCase()) {
    return -1;
  }
  if (a.label.toUpperCase() > b.label.toUpperCase()) {
    return 1;
  }
  return 0;
};
// Oprating systems
export const opratingSystems = [
  {
    id: "rpm",
    label: "Red Hat Enterprise Linux",
  },
  {
    id: "cent",
    label: "CentOS",
  },
  {
    id: "ubu",
    label: "Ubuntu",
  },
  {
    id: "win",
    label: "Windows",
  },
  {
    id: "macos",
    label: "macOS",
  },
  {
    id: "deb",
    label: "Debian",
  },
  {
    id: "open",
    label: "openSUSE",
  },
  {
    id: "sol",
    label: "Solaris",
  },
  {
    id: "aix",
    label: "AIX",
  },
  {
    id: "hp",
    label: "HP-UX",
  },
  {
    id: "amazonlinux",
    label: "Amazon Linux",
  },
  {
    id: "fedora",
    label: "Fedora",
  },
  {
    id: "oraclelinux",
    label: "Oracle Linux",
  },
  {
    id: "suse",
    label: "SUSE",
  },
  {
    id: "raspbian",
    label: "Raspbian OS",
  },
  {
    id: "alpine",
    label: "Alpine",
  },
].sort(orderOSAlphabetically);

// Verions
const versionForAmazonLinux = [
  {
    id: "amazonlinux1",
    label: "Amazon Linux 1",
  },
  {
    id: "amazonlinux2",
    label: "Amazon Linux 2",
  },
  {
    id: "amazonlinux2022",
    label: "Amazon Linux 2022",
    default: true,
  },
];
const versionForRedHat = [
  {
    id: "redhat5",
    label: "Red Hat 5",
  },
  {
    id: "redhat6",
    label: "Red Hat 6",
  },
  {
    id: "redhat7",
    label: "Red Hat 7 +",
    default: true,
  },
];
const versionForCentos = [
  {
    id: "centos5",
    label: "CentOS 5",
  },
  {
    id: "centos6",
    label: "CentOS 6",
  },
  {
    id: "centos7",
    label: "CentOS 7 +",
    default: true,
  },
];
const versionForDebian = [
  {
    id: "debian7",
    label: "Debian 7",
  },
  {
    id: "debian8",
    label: "Debian 8",
  },
  {
    id: "debian9",
    label: "Debian 9 +",
    default: true,
  },
];

const versionForFedora = [
  {
    id: "22",
    label: "Fedora 22 +",
  },
];

const versionForUbuntu = [
  {
    id: "ubuntu14",
    label: "Ubuntu 14",
  },
  {
    id: "ubuntu15",
    label: "Ubuntu 15 +",
    default: true,
  },
];

const versionForWindows = [
  {
    id: "windowsxp",
    label: "Windows XP",
  },
  {
    id: "windowsserver2008",
    label: "Windows Server 2008",
  },
  {
    id: "windows7",
    label: "Windows 7 +",
    default: true,
  },
];

const versionForSuse = [
  {
    id: "suse11",
    label: "SUSE 11",
  },
  {
    id: "suse12",
    label: "SUSE 12",
    default: true,
  },
];

const versionForMacOS = [
  {
    id: "sierra",
    label: "macOS Sierra +",
  },
];
const versionForOpenSuse = [
  {
    id: "leap15",
    label: "openSUSE Leap 15 +",
  },
];

const versionForSolaris = [
  {
    id: "solaris10",
    label: "Solaris 10",
  },
  {
    id: "solaris11",
    label: "Solaris 11",
    default: true,
  },
];
const versionForAix = [
  {
    id: "6.1 TL9",
    label: "AIX 6.1 TL9 +",
  },
];

const versionForHPUX = [
  {
    id: "11.31",
    label: "HP-UX 11.31 +",
  },
];

const versionForOracleLinux = [
  {
    id: "oraclelinux5",
    label: "Oracle Linux 5",
  },
  {
    id: "oraclelinux6",
    label: "Oracle Linux 6 +",
    default: true,
  },
];
const versionForRaspbian = [
  {
    id: "busterorgreater",
    label: "Raspbian Buster or greater",
  },
];

const versionForAlpine = [
  {
    id: "3.12.12",
    label: "3.12.12 +",
  },
];

export const versionsForOp = {
  amazonlinux: versionForAmazonLinux,
  rpm: versionForRedHat,
  cent: versionForCentos,
  deb: versionForDebian,
  fedora: versionForFedora,
  ubu: versionForUbuntu,
  win: versionForWindows,
  suse: versionForSuse,
  macos: versionForMacOS,
  open: versionForOpenSuse,
  sol: versionForSolaris,
  aix: versionForAix,
  hp: versionForHPUX,
  oraclelinux: versionForOracleLinux,
  raspbian: versionForRaspbian,
  alpine: versionForAlpine,
};

// Architecture
const architectureCommon = [
  {
    id: "i386",
    label: "i386",
  },
  {
    id: "x86_64",
    label: "x86_64",
    default: true,
  },
  {
    id: "armhf",
    label: "armhf",
  },
  {
    id: "aarch64",
    label: "aarch64",
  },
];

const architectureForWithPPC64LE = [
  {
    id: "i386",
    label: "i386",
  },
  {
    id: "x86_64",
    label: "x86_64",
    default: true,
  },
  {
    id: "armhf",
    label: "armhf",
  },
  {
    id: "aarch64",
    label: "aarch64",
  },
  {
    id: "powerpc",
    label: "PowerPC",
  },
];

const architectureForWithPPC64LEAlpine = [
  {
    id: "i386",
    label: "i386",
  },
  {
    id: "x86",
    label: "x86",
  },
  {
    id: "x86_64",
    label: "x86_64",
    default: true,
  },
  {
    id: "armhf",
    label: "armhf",
  },
  {
    id: "aarch64",
    label: "aarch64",
  },
  {
    id: "powerpc",
    label: "PowerPC",
  },
];

const architectureFori386 = [
  {
    id: "i386/x86_64",
    label: "i386/x86_64",
  },
];

const architecturei386Andx86_64 = [
  {
    id: "i386",
    label: "i386",
  },
  {
    id: "x86_64",
    label: "x86_64",
    default: true,
  },
];

const architectureForSolaris = [
  {
    id: "i386",
    label: "i386",
    default: true,
  },
  {
    id: "sparc",
    label: "SPARC",
  },
];

const architectureForMacos = [
  {
    id: "intel/applesilicon",
    label: "Intel/Apple Silicon",
  },
];

const architectureForAix = [
  {
    id: "powerpc",
    label: "PowerPC",
  },
];

const architectureForHpUx = [
  {
    id: "itanium2",
    label: "Itanium2",
  },
];

export const windowsArchitectures = [
  {
    ids: [
      "centos6",
      "oraclelinux6",
      "amazonlinux1",
      "redhat6",
      "amazonlinux2022",
      "amazonlinux2",
      "debian7",
      "debian8",
      "ubuntu14",
      "ubuntu15",
    ],
    arch: architectureCommon,
  },
  {
    ids: [
      "centos7",
      "redhat7",
      "suse12",
      "22",
      "debian9",
      "busterorgreater",
      "leap15",
    ],
    arch: architectureForWithPPC64LE,
  },
  {
    ids: ["windowsxp", "windowsserver2008", "windows7"],
    arch: architectureFori386,
  },
  {
    ids: ["sierra"],
    arch: architectureForMacos,
  },
  {
    ids: ["solaris10", "solaris11"],
    arch: architectureForSolaris,
  },
  {
    ids: ["6.1 TL9"],
    arch: architectureForAix,
  },
  {
    ids: ["11.31"],
    arch: architectureForHpUx,
  },
  {
    ids: ["3.12.12"],
    arch: architectureForWithPPC64LEAlpine,
  },
  {
    ids: ["centos5", "redhat5", "oraclelinux5", "suse11"],
    arch: architecturei386Andx86_64,
  },
];

export const SystemStartCmd = [
  {
    for: [
      "redhat7",
      "amazonlinux2022",
      "centos7",
      "suse11",
      "suse12",
      "oraclelinux5",
      "22",
      "amazonlinux2",
      "debian8",
      "debian9",
      "debian10",
      "busterorgreater",
      "ubuntu15",
      "leap15",
    ],
    cmd: "sudo systemctl daemon-reload\nsudo systemctl enable df-agent\nsudo systemctl start df-agent",
  },
  {
    for: [
      "redhat5",
      "redhat6",
      "centos5",
      "centos6",
      "oraclelinux6",
      "amazonlinux1",
      "debian7",
      "ubuntu14",
    ],
    cmd: "service df-agent start",
  },
  {
    for: ["windowsxp", "windowsserver2008", "windows7"],
    cmd: "NET START DefenceFusionSvc",
  },
  {
    for: ["windowsxp", "windowsserver2008", "windows7"],
    cmd: "NET START DefenceFusionSvc",
  },
  {
    for: ["sierra"],
    cmd: "sudo /Library/Ossec/bin/wazuh-control start",
  },
  {
    for: ["solaris10", "solaris11", "6.1 TL9", "3.12.12"],
    cmd: "/var/ossec/bin/wazuh-control start",
  },
  {
    for: ["11.31"],
    cmd: "/sbin/init.d/df-agent start",
  },
];

// Pakages
export function RPMPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "redhat5-i386":
      return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}-1.el5.i386.rpm`;
    case "redhat5-x86_64":
      return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}-1.el5.x86_64.rpm`;
    case "redhat6-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "redhat6-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "redhat6-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "redhat6-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "redhat7-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "redhat7-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "redhat7-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "redhat7-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "redhat7-powerpc":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.ppc64le.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
  }
}

export function AlpinePackage(architecture, version) {
  switch (`${version}-${architecture}`) {
    case "3.12.12-i386":
      return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
    case "3.12.12-aarch64":
      return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
    case "3.12.12-x86_64":
      return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
    case "3.12.12-x86":
      return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
    case "3.12.12-armhf":
      return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
    case "3.12.12-powerpc":
      return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
    default:
      return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
  }
}

export function ORACLELINUXPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "oraclelinux5-i386":
      return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}-1.el5.i386.rpm`;
    case "oraclelinux5-x86_64":
      return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}-1.el5.x86_64.rpm`;
    case "oraclelinux6-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "oraclelinux6-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "oraclelinux6-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "oraclelinux6-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
  }
}

export function CENTPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "centos5-i386":
      return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}-1.el5.i386.rpm`;
    case "centos5-x86_64":
      return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}-1.el5.x86_64.rpm`;
    case "centos6-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "centos6-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "centos6-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "centos6-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "centos7-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "centos7-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "centos7-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "centos7-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "centos7-powerpc":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.ppc64le.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
  }
}

export function SUSEPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "suse11-i386":
      return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}-1.el5.i386.rpm`;
    case "suse11-x86_64":
      return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}-1.el5.x86_64.rpm`;
    case "suse12-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "suse12-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "suse12-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "suse12-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "suse12-powerpc":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.ppc64le.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
  }
}

export function FEDORAPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "22-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "22-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "22-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "22-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "22-powerpc":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.ppc64le.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
  }
}

export function AMAZONLPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "amazonlinux1-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "amazonlinux1-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "amazonlinux1-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "amazonlinux1-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "amazonlinux2-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "amazonlinux2-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "amazonlinux2-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "amazonlinux2-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    case "amazonlinux2022-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.i386.rpm`;
    case "amazonlinux2022-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.aarch64.rpm`;
    case "amazonlinux2022-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
    case "amazonlinux2022-armhf":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.armv7hl.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}-1.x86_64.rpm`;
  }
}

export function DEBPackage(architecture, DF_version) {
  const addToVersion = "-1";

  switch (architecture) {
    case "i386":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_i386.deb`;
    case "aarch64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_arm64.deb`;
    case "armhf":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_armhf.deb`;
    case "x86_64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
    case "powerpc":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_ppc64el.deb`;
    default:
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
  }
}
export function RASPBIANPackage(DF_version, architecture) {
  switch (`busterorgreater-${architecture}`) {
    case "busterorgreater-i386":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_i386.deb`;
    case "busterorgreater-aarch64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_arm64.deb`;
    case "busterorgreater-armhf":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_armhf.deb`;
    case "busterorgreater-x86_64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_amd64.deb`;
    case "busterorgreater-powerpc":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_ppc64el.deb`;
    default:
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_amd64.deb`;
  }
}

export function UBUNTUPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "ubuntu14-i386":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_i386.deb`;
    case "ubuntu14-aarch64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_arm64.deb`;
    case "ubuntu14-armhf":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_armhf.deb`;
    case "ubuntu14-x86_64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_amd64.deb`;
    case "ubuntu15-i386":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_i386.deb`;
    case "ubuntu15-aarch64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_arm64.deb`;
    case "ubuntu15-armhf":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_armhf.deb`;
    case "ubuntu15-x86_64":
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_amd64.deb`;
    default:
      return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}_amd64.deb`;
  }
}

export function OPENSUSEPackage(DF_version, architecture) {
  switch (`leap15-${architecture}`) {
    case "leap15-i386":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}.i386.rpm`;
    case "leap15-x86_64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}.x86_64.rpm`;
    case "leap15-aarch64":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}.aarch64.rpm`;
    case "leap15-ppc64le":
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}.ppc64le.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}.x86_64.rpm`;
  }
}

export function SOLARISPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "solaris10-i386":
      return `https://packages.defencefusion.io/4.x/solaris/i386/10/df-agent_v${DF_version}-sol10-i386.pkg`;
    case "solaris10-sparc":
      return `https://packages.defencefusion.io/4.x/solaris/sparc/10/df-agent_v${DF_version}-sol10-sparc.pkg`;
    case "solaris11-i386":
      return `https://packages.defencefusion.io/4.x/solaris/i386/11/df-agent_v${DF_version}-sol11-i386.p5p`;
    case "solaris11-sparc":
      return `https://packages.defencefusion.io/4.x/solaris/sparc/11/df-agent_v${DF_version}-sol11-sparc.p5p`;
    default:
      return `https://packages.defencefusion.io/4.x/solaris/sparc/11/df-agent_v${DF_version}-sol11-sparc.p5p`;
  }
}

export function AIXPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "6.1 TL9-powerpc":
      return `https://packages.defencefusion.io/4.x/aix/df-agent-${DF_version}.aix.ppc.rpm`;
    default:
      return `https://packages.defencefusion.io/4.x/aix/df-agent-${DF_version}.aix.ppc.rpm`;
  }
}

export function HPPackage(DF_version, architecture, version) {
  switch (`${version}-${architecture}`) {
    case "11.31-itanium2":
      return `https://packages.defencefusion.io/4.x/hp-ux/df-agent-${DF_version}-hpux-11v3-ia64.tar`;
    default:
      return `https://packages.defencefusion.io/4.x/hp-ux/df-agent-${DF_version}-hpux-11v3-ia64.tar`;
  }
}
