import React, { useState } from "react";
import {
  Box,
  Link,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

const SortTable = ({
  rows,
  columns,
  pagination,
  setPagination,
  total = undefined,
  onRowClick = () => { },
}) => {
  const startIndex = pagination?.from;
  const endIndex = startIndex + pagination?.size;
  const paginatedRows = rows.slice(startIndex, endIndex);

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const sortedRows = [...rows].sort((a, b) => {
    if (sortBy) {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (sortOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    }
    return 0;
  });

  return (
    <TableContainer
      sx={{
        "& .MuiTableCell-root": {
          fontSize: 13,
        },
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                // align="center"
                key={column.id}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <TableSortLabel
                  active={sortBy === column.id}
                  direction={sortOrder}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((row, index) => (
            <TableRow hover key={index}>
              {/* Rest of your TableRow and TableCell code... */}
            </TableRow>
          ))}
          {sortedRows.map((row, index) => (
            <TableRow hover key={index}>
              {columns.map((column) => (
                <TableCell
                  onClick={() => onRowClick(row.policy_id, row)}
                  // align="center"
                  key={column.id}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    padding: "10px 15px",
                  }}
                >
                  {column.id === "path" ? (
                    <Link
                      href={row[column.id]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box
                        sx={{
                          maxWidth: 120,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {row[column.id]}
                      </Box>
                    </Link>
                  ) : column.id === "time" ||
                    column.id === "ruleDescription" ? (
                    <Box
                      sx={{
                        maxWidth: 200,
                        minWidth: 78,
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {row[column.id]}
                    </Box>
                  ) : (
                    <Box sx={{ maxWidth: 200, width: 50 }}>
                      {row[column.id]}
                    </Box>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {sortedRows.length < 1 && (
            <TableRow>
              <TableCell align="center" colSpan={columns.length}>
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {pagination && (
        <Pagination
          count={Math.ceil((total ? total : rows.length) / pagination.size)}
          page={pagination.from / pagination.size + 1}
          onChange={(event, value) => {
            const newFrom = (value - 1) * pagination.size;
            setPagination((prev) => ({
              ...prev,
              from: newFrom,
            }));
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
          }}
        />
      )}
    </TableContainer>
  );
};

export default SortTable;
