import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateRangeSelector from "../../../../../components/Field/DateRangeSeletor";
import { formatDate } from "../../../../../utils/utils";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import EventsTabs from "../../EventsTabs";
import { mitre_events_query } from "../../../enums/enums";
import { wazuhIndex } from "../../../../../config/wazuh";
import ShowCaseDialoge from "../../../../../components/Dialog/SideCanvas.js";

function SideCanvas({
  agentId,
  mitre_id,
  techniques,
  show,
  setShow,
  name,
  tactics,
  ...props
}) {

  const initialDateRange = {
    from_date: formatDate(dayjs().subtract(7, "day")),
    to_date: formatDate(dayjs()),
  };

  const [events, setEvents] = useState({ loading: false, data: {} });
  const [query, setQuery] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);

  const [pagination, setPagination] = React.useState({
    from: 0,
    size: 10,
  });

  const handleSearch = (e) => {
    const dqlQuery = e.target.value;
    setQuery(dqlQuery);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      FetchEvent();
    }
  };

  const FetchEvent = async (
    _query = query,
    from_date = dateRange.from_date,
    to_date = dateRange.to_date,
    from = pagination.from,
    size = pagination.size
  ) => {
    setEvents((pre) => ({ ...pre, loading: true }));
    await wazuhIndex
      .post(
        `/idx/wazuh-alerts-*/_search/`,
        mitre_events_query(
          agentId,
          mitre_id,
          _query,
          from_date,
          to_date,
          from,
          size
        )
      )
      .then((res) => {
        setEvents({ data: res.data, loading: false });
      })
      .catch(() => {
        setEvents((pre) => ({ ...pre, loading: false }));
      });
  };
  const handlePageChange = (newFrom, newSize) => {
    setPagination({ from: newFrom, size: newSize });
    FetchEvent(undefined, undefined, newFrom, newSize);
  };

  useEffect(() => {
    FetchEvent();
    // eslint-disable-next-line
  }, [mitre_id, agentId, techniques]);

  return (
    <ShowCaseDialoge title={techniques?.name} show={show} setShow={setShow}  {...props} >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Technique details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"space-around"}
            gap={2}
          >
            <Box>
              <Typography variant="subtitle1" fontWeight={700}>
                ID
              </Typography>
              <Typography variant="subtitle1">
                {techniques?.external_id}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight={700}>
                Tactics
              </Typography>
              {tactics.map((t) => (
                <Typography color="primary" variant="subtitle1" key={t.id}>
                  {t.name}
                </Typography>
              ))}
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight={700}>
                Version
              </Typography>
              <Typography variant="subtitle1">V2.2.1</Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Box mt={1} />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Recent Events</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"space-around"}
            gap={2}
          >
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search Event"
                  value={query}
                  onChange={handleSearch}
                  onKeyDown={handleSearchKeyDown}
                />
              </Grid>
              <Grid item xs={5}>
                <DateRangeSelector
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  startIcon={<HistoryIcon fontSize="small" />}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => FetchEvent()}
                >
                  Refresh
                </Button>
              </Grid>
              {events.data?.hits?.hits && (
                <Grid item xs={12}>
                  <EventsTabs
                    loading={events.loading}
                    data={events.data}
                    pagination={pagination}
                    handlePageChange={handlePageChange}
                    setTableData={() => { }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </ShowCaseDialoge >
  );
}

export default SideCanvas;
