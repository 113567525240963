import React from 'react';
import XMLViewer from 'react-xml-viewer'

const ViewXmlPage = () => {
   
    const xmlData = decodeURI(localStorage.getItem('configraton_xml'));

    return (
        <div>
            <h1>XML Data</h1>
            <XMLViewer xml={xmlData} />
        </div>
    );
};

export default ViewXmlPage;
