import {
  Alert,
  Box,
  Typography,
  IconButton,
  SnackbarContent,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Stack } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const InstallAndStart = ({
  startCMD,
  selectedOS,
  DF_version,
  architecture,
  version,
  agent,
  serverAdress,
  selectedGroup,
  needsPassword,
  udpProtocol,
}) => {
  const addToVersion = "-1";
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const optionalDeploymentVariables = () => {
    const escapeQuotes = (value) => value.replace(/'/g, "\\'");
    let deployment =
      serverAdress && `DF_MANAGER='${escapeQuotes(serverAdress)}' `;
    if (selectedOS === "win") {
      deployment += `DF_REGISTRATION_SERVER='${escapeQuotes(serverAdress)}' `;
    }

    if (needsPassword) {
      deployment += `DF_REGISTRATION_PASSWORD='${escapeQuotes(
        this.state.wazuhPassword
      )}' `;
    }

    if (udpProtocol) {
      deployment += "DF_PROTOCOL='UDP' ";
    }

    if (selectedGroup.length) {
      deployment += `DF_AGENT_GROUP='${selectedGroup
        .map((item) => item)
        .join(",")}' `;
    }

    return deployment;
  };

  const agentNameVariable = () => {
    let agentName = `DF_AGENT_NAME='${agent}' `;
    if (architecture && agentName !== "") {
      return agentName;
    } else {
      return "";
    }
  };
  const optionalPackages = () => {
    switch (selectedOS) {
      case "rpm":
        return resolveRPMPackage();
      case "cent":
        return resolveCENTPackage();
      case "deb":
        return resolveDEBPackage();
      case "ubu":
        return resolveUBUNTUPackage();
      case "open":
        return resolveOPENSUSEPackage();
      case "sol":
        return resolveSOLARISPackage();
      case "aix":
        return resolveAIXPackage();
      case "hp":
        return resolveHPPackage();
      case "amazonlinux":
        return resolveAMAZONLPackage();
      case "fedora":
        return resolveFEDORAPachage();
      case "oraclelinux":
        return resolveORACLELINUXPackage();
      case "suse":
        return resolveSUSEPackage();
      case "raspbian":
        return resolveRASPBIANPackage();
      case "alpine":
        return resolveAlpinePackage();
      default:
        return `https://packages.defencefusion.io/4.x/yum/x86_64/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };
  const macOSInstallationOptions =
    // eslint-disable-next-line
    `${optionalDeploymentVariables()}${agentNameVariable()}`
      // eslint-disable-next-line
      .replace(/\' ([a-zA-Z])/g, "' && $1") // Separate environment variables with &&
      // eslint-disable-next-line
      .replace(/\"/g, '\\"') // Escape double quotes
      .trim();

  // If no variables are set, the echo will be empty
  const macOSInstallationSetEnvVariablesScript = macOSInstallationOptions
    ? `sudo echo "${macOSInstallationOptions}" > /tmp/DF_envs && `
    : ``;

  const macOSInstallationScript = `curl -so df-agent.pkg https://packages.defencefusion.io/4.x/macos/df-agent-${DF_version}-1.pkg && ${macOSInstallationSetEnvVariablesScript}sudo installer -pkg ./df-agent.pkg -target /`;

  const resolveRPMPackage = () => {
    switch (`${version}-${architecture}`) {
      case "redhat5-i386":
        return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}${addToVersion}.el5.i386.rpm`;
      case "redhat5-x86_64":
        return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}${addToVersion}.el5.x86_64.rpm`;
      case "redhat6-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "redhat6-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "redhat6-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "redhat6-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "redhat7-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "redhat7-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "redhat7-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "redhat7-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "redhat7-powerpc":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.ppc64le.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };

  const resolveAlpinePackage = () => {
    switch (`${version}-${architecture}`) {
      case "3.12.12-i386":
        return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
      case "3.12.12-aarch64":
        return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
      case "3.12.12-x86_64":
        return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
      case "3.12.12-x86":
        return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
      case "3.12.12-armhf":
        return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
      case "3.12.12-powerpc":
        return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
      default:
        return 'https://packages.defencefusion.io/key/alpine-devel%40wazuh.com-633d7457.rsa.pub && echo "https://packages.defencefusion.io/4.x/alpine/v3.12/main"';
    }
  };

  const resolveORACLELINUXPackage = () => {
    switch (`${version}-${architecture}`) {
      case "oraclelinux5-i386":
        return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}${addToVersion}.el5.i386.rpm`;
      case "oraclelinux5-x86_64":
        return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}${addToVersion}.el5.x86_64.rpm`;
      case "oraclelinux6-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "oraclelinux6-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "oraclelinux6-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "oraclelinux6-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };

  const resolveCENTPackage = () => {
    switch (`${version}-${architecture}`) {
      case "centos5-i386":
        return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}${addToVersion}.el5.i386.rpm`;
      case "centos5-x86_64":
        return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}${addToVersion}.el5.x86_64.rpm`;
      case "centos6-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "centos6-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "centos6-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "centos6-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "centos7-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "centos7-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "centos7-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "centos7-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "centos7-powerpc":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.ppc64le.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };

  const resolveSUSEPackage = () => {
    switch (`${version}-${architecture}`) {
      case "suse11-i386":
        return `https://packages.defencefusion.io/4.x/yum5/i386/df-agent-${DF_version}${addToVersion}.el5.i386.rpm`;
      case "suse11-x86_64":
        return `https://packages.defencefusion.io/4.x/yum5/x86_64/df-agent-${DF_version}${addToVersion}.el5.x86_64.rpm`;
      case "suse12-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "suse12-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "suse12-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "suse12-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "suse12-powerpc":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.ppc64le.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };

  const resolveFEDORAPachage = () => {
    switch (`${version}-${architecture}`) {
      case "22-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "22-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "22-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "22-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "22-powerpc":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.ppc64le.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };

  const resolveAMAZONLPackage = () => {
    switch (`${version}-${architecture}`) {
      case "amazonlinux1-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "amazonlinux1-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "amazonlinux1-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "amazonlinux1-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "amazonlinux2-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "amazonlinux2-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "amazonlinux2-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "amazonlinux2-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "amazonlinux2022-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "amazonlinux2022-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "amazonlinux2022-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "amazonlinux2022-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };

  const resolveDEBPackage = () => {
    switch (`${architecture}`) {
      case "i386":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_i386.deb`;
      case "aarch64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_arm64.deb`;
      case "armhf":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_armhf.deb`;
      case "x86_64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
      case "powerpc":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_ppc64el.deb`;
      default:
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
    }
  };

  const resolveRASPBIANPackage = () => {
    switch (`${version}-${architecture}`) {
      case "busterorgreater-i386":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_i386.deb`;
      case "busterorgreater-aarch64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_arm64.deb`;
      case "busterorgreater-armhf":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_armhf.deb`;
      case "busterorgreater-x86_64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
      case "busterorgreater-powerpc":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_ppc64el.deb`;
      default:
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
    }
  };

  const resolveUBUNTUPackage = () => {
    switch (`${version}-${architecture}`) {
      case "ubuntu14-i386":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_i386.deb`;
      case "ubuntu14-aarch64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_arm64.deb`;
      case "ubuntu14-armhf":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_armhf.deb`;
      case "ubuntu14-x86_64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
      case "ubuntu15-i386":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_i386.deb`;
      case "ubuntu15-aarch64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_arm64.deb`;
      case "ubuntu15-armhf":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_armhf.deb`;
      case "ubuntu15-x86_64":
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
      default:
        return `https://packages.defencefusion.io/4.x/apt/pool/main/w/df-agent/df-agent_${DF_version}${addToVersion}_amd64.deb`;
    }
  };

  const resolveOPENSUSEPackage = () => {
    switch (`${version}-${architecture}`) {
      case "leap15-i386":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.i386.rpm`;
      case "leap15-aarch64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.aarch64.rpm`;
      case "leap15-x86_64":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
      case "leap15-armhf":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.armv7hl.rpm`;
      case "leap15-powerpc":
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.ppc64le.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/yum/df-agent-${DF_version}${addToVersion}.x86_64.rpm`;
    }
  };

  const resolveSOLARISPackage = () => {
    switch (`${version}-${architecture}`) {
      case "solaris10-i386":
        return `https://packages.defencefusion.io/4.x/solaris/i386/10/df-agent_v${DF_version}-sol10-i386.pkg`;
      case "solaris10-sparc":
        return `https://packages.defencefusion.io/4.x/solaris/sparc/10/df-agent_v${DF_version}-sol10-sparc.pkg`;
      case "solaris11-i386":
        return `https://packages.defencefusion.io/4.x/solaris/i386/11/df-agent_v${DF_version}-sol11-i386.p5p`;
      case "solaris11-sparc":
        return `https://packages.defencefusion.io/4.x/solaris/sparc/11/df-agent_v${DF_version}-sol11-sparc.p5p`;
      default:
        return `https://packages.defencefusion.io/4.x/solaris/sparc/11/df-agent_v${DF_version}-sol11-sparc.p5p`;
    }
  };

  const resolveAIXPackage = () => {
    switch (`${version}-${architecture}`) {
      case "6.1 TL9-powerpc":
        return `https://packages.defencefusion.io/4.x/aix/df-agent-${DF_version}${addToVersion}.aix.ppc.rpm`;
      default:
        return `https://packages.defencefusion.io/4.x/aix/df-agent-${DF_version}${addToVersion}.aix.ppc.rpm`;
    }
  };

  const resolveHPPackage = () => {
    switch (`${version}-${architecture}`) {
      case "11.31-itanium2":
        return `https://packages.defencefusion.io/4.x/hp-ux/df-agent-${DF_version}${addToVersion}-hpux-11v3-ia64.tar`;
      default:
        return `https://packages.defencefusion.io/4.x/hp-ux/df-agent-${DF_version}${addToVersion}-hpux-11v3-ia64.tar`;
    }
  };

  const customTexts = {
    rpm: `sudo ${optionalDeploymentVariables()}${agentNameVariable()}yum install -y ${optionalPackages()}`,
    // eslint-disable-next-line
    alpine: `wget -O /etc/apk/keys/alpine-devel@wazuh.com-633d7457.rsa.pub ${optionalPackages()} >> /etc/apk/repositories && \ apk update && \ apk add df-agent=${DF_version}-r1`,
    cent: `sudo ${optionalDeploymentVariables()}${agentNameVariable()}yum install -y ${optionalPackages()}`,
    deb: `curl -so df-agent.deb ${optionalPackages()} && sudo ${optionalDeploymentVariables()}${agentNameVariable()}dpkg -i ./df-agent.deb`,
    ubu: `curl -so df-agent.deb ${optionalPackages()} && sudo ${optionalDeploymentVariables()}${agentNameVariable()}dpkg -i ./df-agent.deb`,
    macos: macOSInstallationScript,
    win:
      version === "windowsxp" || version === "windowsserver2008"
        ? `msiexec.exe /i df-agent-${DF_version}-1.msi /q ${optionalDeploymentVariables()}${agentNameVariable()}`
        : `Invoke-WebRequest -Uri https://packages.defencefusion.io/4.x/windows/df-agent-${DF_version}-1.msi -OutFile \${env:tmp}\\df-agent.msi; msiexec.exe /i \${env:tmp}\\df-agent.msi /q ${optionalDeploymentVariables()} ${agentNameVariable()}`,
    open: `sudo rpm --import https://packages.defencefusion.io/key/GPG-KEY-WAZUH && sudo ${optionalDeploymentVariables()}${agentNameVariable()}zypper install -y ${optionalPackages()}`,
    sol: `sudo curl -so ${
      version === "solaris11" ? "df-agent.p5p" : "df-agent.pkg"
    } ${optionalPackages()} && ${
      version === "solaris11"
        ? "pkg install -g df-agent.p5p df-agent"
        : "pkgadd -d df-agent.pkg"
    }`,
    aix: `sudo ${optionalDeploymentVariables()}${agentNameVariable()}rpm -ivh ${optionalPackages()}`,
    hp: `cd / && sudo curl -so df-agent.tar ${optionalPackages()} && sudo groupadd wazuh && sudo useradd -G wazuh wazuh && sudo tar -xvf df-agent.tar`,
    amazonlinux: `sudo ${optionalDeploymentVariables()}${agentNameVariable()}yum install -y ${optionalPackages()}`,
    fedora: `sudo ${optionalDeploymentVariables()}${agentNameVariable()}yum install -y ${optionalPackages()}`,
    oraclelinux: `sudo ${optionalDeploymentVariables()}${agentNameVariable()}yum install -y ${optionalPackages()}`,
    suse: `sudo ${optionalDeploymentVariables()}${agentNameVariable()}yum install -y ${optionalPackages()}`,
    raspbian: `curl -so df-agent.deb ${optionalPackages()} && sudo ${optionalDeploymentVariables()}${agentNameVariable()}dpkg -i ./df-agent.deb`,
  };
  const startCommand = startCMD;

  useEffect(() => {
    // function GetCommand() {
    //   switch (selectedOS) {
    //     case "rpm":
    //       return WazuhPkages.RPMPackage(DF_version, architecture, version);
    //     case "cent":
    //       return WazuhPkages.CENTPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "deb":
    //       return WazuhPkages.DEBPackage(architecture, DF_version);
    //     case "ubu":
    //       return WazuhPkages.UBUNTUPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "open":
    //       return WazuhPkages.OPENSUSEPackage(
    //         DF_version.api_version,
    //         architecture
    //       );
    //     case "sol":
    //       return WazuhPkages.SOLARISPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "aix":
    //       return WazuhPkages.AIXPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "hp":
    //       return WazuhPkages.HPPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "amazonlinux":
    //       return WazuhPkages.AMAZONLPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "fedora":
    //       return WazuhPkages.FEDORAPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "oraclelinux":
    //       return WazuhPkages.ORACLELINUXPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "suse":
    //       return WazuhPkages.SUSEPackage(
    //         DF_version.api_version,
    //         architecture,
    //         version
    //       );
    //     case "raspbian":
    //       return WazuhPkages.RASPBIANPackage(
    //         DF_version.api_version,
    //         architecture
    //       );
    //     case "alpine":
    //       return WazuhPkages.AlpinePackage(architecture, version);
    //     default:
    //       return `https://packages.defencefusion.io/4.x/yum/x86_64/df-agent-${DF_version}-1.x86_64.rpm`;
    //   }
    // }
    // if (selectedOS && "1.1.1.4" && architecture && version) {
    //   setCommand(GetCommand());
    // }
    console.log(
      // selectedOS,
      DF_version,
      // architecture,
      version,
      // agent,
      serverAdress
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Typography variant="h6">Install the agent</Typography>
      <Typography variant="subtitle1" paragraph>
        You can use this command to install and enroll the defencefusion agent
        in one or more hosts.
      </Typography>

      <Alert severity="warning">
        If the installer finds another defencefusion agent in the system, it
        will upgrade it preserving the configuration.
      </Alert>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <SnackbarContent
          sx={{ fontSize: 12 }}
          message={
            <Typography variant="body2">{customTexts[selectedOS]}</Typography>
          }
          action={
            <CopyToClipboard
              text={customTexts[selectedOS]}
              onCopy={(text) => {
                toast.info("Command copied.");
              }}
            >
              <IconButton aria-label="Copy">
                <FileCopyOutlinedIcon color="secondary" />
              </IconButton>
            </CopyToClipboard>
          }
        />
      </Stack>
      <br />
      <Typography variant="h6">Start the agent</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Systemctl" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SnackbarContent
          sx={{ fontSize: 12, whiteSpace: "pre-line" }}
          message={<Typography variant="body2">{startCommand}</Typography>}
          action={
            <CopyToClipboard
              text={startCommand}
              onCopy={(text) => {
                toast.info("Command copied.");
              }}
            >
              <IconButton aria-label="Copy">
                <FileCopyOutlinedIcon color="secondary" />
              </IconButton>
            </CopyToClipboard>
          }
        />
      </TabPanel>
      <Typography variant="subtitle1">
        To verify the connection with the defencefusion server, please follow
        this document.
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    DF_version: state.DF_version?.api_version,
  };
};
export default connect(mapStateToProps, null)(InstallAndStart);
