import React from "react";
import { Routes, Route } from "react-router-dom";
import AgentsPreview from "./pages/Agents/AgentsPreview.js";
import Index from "./index/Index.js";
import { CssBaseline } from "@mui/material";
import AgentDetail from "./pages/Agents/AgentDetail.js";
import SecurityEventIndex from "./pages/Modules/Security events/SecurityEventIndex.js";
import CasesList from "./pages/Cases/CasesList.js";
import CaseDetail from "./pages/Cases/CaseDetail.js";
import TasksList from "./pages/Tasks/TasksList.js";
import AlertsList from "./pages/Alerts/AlertsList.js";
import Organisations from "./pages/administration/Organizations/Organisations.js";
import OrganizationTabs from "./pages/administration/Organizations/OrganizationTabs.js";
import { connect } from "react-redux";
import { adminName } from "./utils/utils.js";
import Settings from "./pages/administration/Settings.js";
import Profiles from "./pages/administration/Profiles.js";
import AnalysisReportTable from "./pages/Reports/AnalysisReportTable.js";
import Dashboard from "./pages/Dashboards/Dashboard.js";
import ViewXmlPage from "./ViewXmlPage .js";

const Directions = (props) => {
  return (
    <>
      <CssBaseline />
      <Index>
        <Routes>
          <Route path="/view-xml" element={<ViewXmlPage />} />
          {props.user.profile !== adminName && (
            <>
              <Route path="/alerts" element={<AlertsList />} />
              <Route path="/tasks" element={<TasksList />} />
              <Route path="/cases" element={<CasesList />} />
              <Route path="/case-detail/:caseId" element={<CaseDetail />} />
              <Route path="/agents" element={<AgentsPreview />} />
              <Route path="/agents-preview" element={<AgentDetail />} />
              <Route path="/modules-events" element={<SecurityEventIndex />} />
              <Route path="/settings" element={<Settings />} />
              {/* <Route path="/reports" element={<Reports />} /> */}
              <Route path="/reports" element={<AnalysisReportTable />} />
            </>
          )}
          {props.dashboards && (
            <Route path="/dashboards/:tier" element={<Dashboard />} />
          )}
          {props.permissions.mPrf && (
            <Route path="/profiles" element={<Profiles />} />
          )}
          {props.permissions.mUsers && (
            <Route
              path="/organizations/:orgName"
              element={<OrganizationTabs />}
            />
          )}
          {props.permissions.mOrg && (
            <Route path="/organizations" element={<Organisations />} />
          )}
          {/* administration */}
          {props.user.profile === adminName && (
            <>
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/organizations/:orgName"
                element={<OrganizationTabs />}
              />
            </>
          )}
          {props.user.profile === adminName && props.permissions.mOrg ? (
            <Route path="/" element={<Organisations />} />
          ) : (
            <Route path="/" element={<CasesList />} />
          )}
        </Routes>
      </Index>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.cms_user,
    permissions: state.permissions,
    dashboards: state.dashboards
  };
};
export default connect(mapStateToProps, null)(Directions);
