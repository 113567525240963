import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  FetchMeta,
  GetDataCounts,
  SetBrandDetails,
  SetWazuhVerion,
  authCheck,
} from "./store/actions/actions.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Account/Login.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FlashScreen from "./Flasher/FlashScreen.js";
import cms from "./config/axiosConfig.js";
import { useData, DataProvider } from "./DataContext.js";
import Directions from "./Directions.js";
import { NotificationBell } from "./utils/utils.js";
import { Typography } from "@mui/material";

function App(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [streamId, setStreamId] = useState(null);
  const { _data, _setData } = useData();

  const CheckForChange = (__data) => {
    if (__data?.length > 0) {
      const alert_summaries = __data.filter(s => s.summary.hasOwnProperty("alert"))
      if (alert_summaries.length > 0) {
        const alerts = [];
        for (const _alert of alert_summaries) {
          const alert = _alert.base?.object
          alerts.push({ id: _alert.base?.operation === "Delete" ? _alert.base?.objectId : alert?._id, title: alert?.title, operation: _alert.base?.operation, object: _alert.base?.object })
        }
        if (alerts.length > 0) {
          // Check if Creation 
          const created = alerts.filter(a => a.operation === "Creation")
          if (created.length > 0) {
            const new_obj = created.map(obj => obj.object)
            for (const _obj of new_obj) {
              _obj.updatedRow = true;
            }
            NotificationBell();
            toast.info(<Typography
              component={Link}
              to={"/alerts" + "?preview=" + created[0].id}
              variant="subtitle1"
              sx={{ textDecoration: "underline", color: "#fff" }}
              onClick={(e) => {
                e.stopPropagation();
              }} >
              Alert: {created.map(a => a.title + ", ")}
            </Typography>);
          }
        }
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    props.setBrand();
    props.authCheck();

    const fetchData = async () => {
      if (props.authorized) {
        try {
          await Promise.all([
            props.wazuhConfig(),
            props.getDataCount(),
            props.FetchMeta(),
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      setIsLoading(false);
    };

    fetchData();

    const interval = setInterval(() => {
      if (props.authorized) {
        props.getDataCount();
      }
    }, 20 * 1000);
    return () => clearInterval(interval);
  }, [props.authorized]);

  // Set Fave icon and Title 
  useEffect(() => {
    // Set fontIcon and title
    let logo = null;
    let title = null;
    if (!props.brand?.org_logo) {
      let brand = localStorage.getItem("brand");
      if (brand) {
        brand = JSON.parse(brand);
        logo = brand?.org_logo;
        title = brand?.org_name;
      }
    }
    else {
      logo = props.brand?.org_logo
      title = props.brand?.org_name;
    }
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon && props.brand) {
      favicon.href = logo;
      document.title = title;
    }
  }, [props.brand])
  // Polling & Updating
  // Updating
  useEffect(() => {
    if (props.authorized) {
      // Fetch stream ID only if it's not already fetched
      if (!streamId) {
        const fetchStreamId = async () => {
          try {
            const response = await cms.post("/cms/api/stream");
            const streamId = response.data;
            setStreamId(streamId);
          } catch (error) {
            console.error("Error fetching stream ID:", error);
          }
        };

        fetchStreamId();
      }
    }
  }, [props.authorized, streamId]);
  // Polling 
  useEffect(() => {
    const pollUpdates = async () => {
      try {
        cms.get(`/cms/api/stream/${streamId}`, { timeout: 60000 * 1 }).then(response => {
          if (response.status === 200) {
            const newData = response.data;
            console.log(newData)
            _setData(newData);
            CheckForChange(newData);
            pollUpdates();
          } else if (response.status === 504) {
            setStreamId(null);
            console.log("No updates, retrying...");
          }
        }).catch((err => {
          setStreamId(null)
          console.error("Error polling updates:", err);
        }));
      } catch (error) {

      }
    };
    if (streamId && props.authorized) {
      pollUpdates();
    }
    // eslint-disable-next-line 
  }, [streamId, props.authorized]);

  return (
    <Router>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        style={{ width: "auto" }}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      {isLoading ? (
        <FlashScreen />
      ) : props.authorized ? (
        <Directions data={_data} />
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    authorized: state.cms_user !== null && state.ams_user !== null,
    loading: state.loading,
    brand: state.brand
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    wazuhConfig: () => dispatch(SetWazuhVerion()),
    setBrand: () => dispatch(SetBrandDetails()),
    getDataCount: () => dispatch(GetDataCounts()),
    authCheck: () => dispatch(authCheck()),
    FetchMeta: () => dispatch(FetchMeta()),
  };
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

const AppWithProvider = () => (
  <DataProvider>
    <ConnectedApp />
  </DataProvider>
);

export default AppWithProvider;
