import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { opratingSystems } from "../../../static/wazuh-consts/wazuhconsts";

const Operatingsystem = ({ onChange, op }) => {
  const ButtonsGrids = ({ value }) => (
    <Grid item key={value.id} xs={4}>
      <Button
        size="small"
        fullWidth
        onClick={() => {
          onChange(value);
        }}
        variant={op.id === value.id ? "contained" : "outlined"}
      >
        {value.label}
      </Button>
    </Grid>
  );
  const op_sys = opratingSystems;
  const [expand, setExpand] = useState(false);
  return (
    <Grid container spacing={1.5}>
      {op_sys.slice(0, 6).map((value) => (
        <ButtonsGrids value={value} />
      ))}
      <Grid item xs={12}>
        <Box
          display="flex"
          component={ButtonBase}
          onClick={() => {
            setExpand(!expand);
          }}
          justifyContent="space-between"
          width="100%"
          mt={1.5}
        >
          <Typography variant="body1" color="primary">
            See More
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Collapse in={expand} unmountOnExit sx={{ width: "100%" }}>
        <Grid item container spacing={1.5} mt={1} p={"0 40px"}>
          {op_sys.slice(6).map((value) => (
            <ButtonsGrids value={value} />
          ))}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default Operatingsystem;
