import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Alert,
    Box,
    Grid,
    IconButton,
    Snackbar,
    Typography,
} from "@mui/material";
import Btn from "../../../../components/Buttons/BootStrap/Btn.js";
import PlusIcon from "../../../../static/icons/plus-icon.svg";
import { useState } from "react";
// import axios from "axios";
// import { sleep } from "../../../../utils/utils.js";
// import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { AssigneeAutoComplete } from "../../../Cases/components/CustomTasksRow.js";
import RichTextEditor from "../../../../components/Field/RichTextEditor.js";
import { organisation } from "../../../../utils/filters/fields.js";

export default function CaseTemplateTaskDialog({
    open,
    setOpen,
    selectedRow,
    order = 0,
    isUpdate,
    AddFunc,
    UpdateFunc
}) {
    const handleClose = () => {
        setOpen(false);
        setFields(initialFields);
    };
    console.log(selectedRow)
    const initialFields = {
        "order": isUpdate ? selectedRow.order : order,
        "title": isUpdate ? selectedRow.title : "",
        // "group": "default",
        "description": isUpdate ? selectedRow.description : "",
        "owner": isUpdate ? selectedRow.owner : "",
    }
    const [fields, setFields] = useState(initialFields);

    const [alert, setAlert] = useState({
        show: false,
        severity: "success",
        text: "",
    });

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFields((pre) => ({ ...pre, [name]: value }));
    };

    // andle Submit
    const validate = () => {
        let valid = true;
        let message = "";
        if (!fields.owner) {
            message = "Assignee is required";
            valid = false;
        } else if (!fields.title) {
            message = "Title is required";
            valid = false;
        } else if (!fields.description) {
            message = "Description is required";
            valid = false;
        }

        if (!valid) {
            setAlert({ show: true, severity: "error", text: message });
        } else {
            setAlert({ show: false, severity: "error", text: "" });
        }

        return valid;
    };

    const handleSubmit = async (e) => {
        if (validate()) {
            if (isUpdate) {
                UpdateFunc(fields);
            }
            else {
                AddFunc(fields);

            }
        }
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="md"
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "822px",
                    borderRadius: "15px",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" fontWeight={700}>
                        Add Task
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setOpen(false);
                            setFields(initialFields);
                        }}
                    >
                        <CancelIcon fontSize="large" />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Snackbar
                    open={alert.show}
                    autoHideDuration={6000}
                    onClose={() => {
                        setAlert((pre) => ({ ...pre, show: false }));
                    }}
                >
                    <Alert
                        onClose={() => {
                            setAlert((pre) => ({ ...pre, show: false }));
                        }}
                        severity={alert.severity}
                        sx={{ width: "100%" }}
                    >
                        {alert.text}
                    </Alert>
                </Snackbar>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Grid container item spacing={2.5}>
                        <Grid item xs={6}>
                            <Box display="flex" flexDirection="column" gap={1.25}>
                                <Typography variant="body2" fontWeight="bold" color="#000">
                                    Assignee*
                                </Typography>
                                <AssigneeAutoComplete
                                    organisation={organisation()}
                                    onClose={() => { }}
                                    onUpdateAssignee={(value) => {
                                        setFields((pre) => ({ ...pre, owner: value.login }));
                                    }}
                                />
                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column" gap={1.25}>
                                <Typography variant="body2" fontWeight="bold" color="#000">
                                    Task Title*
                                </Typography>
                                <Box
                                    component={"textarea"}
                                    name="title"
                                    sx={{
                                        backgroundColor: "transparent",
                                        minHeight: "80px",
                                        fontSize: "14px",
                                        padding: "8px",
                                        border: "1px solid #ECF0F4",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                    value={fields.title}
                                    onChange={handleFieldChange}
                                    placeholder="Type Task Title..."
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column" gap={1.25}>
                                <Typography variant="body2" fontWeight="bold" color="#000">
                                    Task Description*
                                </Typography>
                                <RichTextEditor
                                    setValue={(value) => {
                                        setFields((pre) => ({ ...pre, description: value }));
                                    }}
                                    defaultValue={fields.description}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Box
                    pl="22px"
                    pr="22px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Typography variant="body2" fontWeight={400} color="#000000">
                        * Required field
                    </Typography>

                    <Btn
                        onClick={handleSubmit}
                        text={isUpdate ? "Update Task" : "Add Task"}
                        startIcon={PlusIcon}
                        height={50}
                        fontSize={20}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
}
