import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomTable from "../../components/Display/CustomTable";
import InputField from "../../components/Field/BootStrap/InputField";
import GetAppIcon from "@mui/icons-material/GetApp";
import PiecartIcon from "../../static/icons/piecart-icon.svg";
import PiecartBlueIcon from "../../static/icons/piechart-blue-icon.svg";
import Btn from "../../components/Buttons/BootStrap/Btn.js";
import PieChart from "../../components/charts/PieChart.js";
import axios from "axios";
import CustomObservablesRow from "./components/CustomObservablesRow";
import { handleExport } from "../../store/utils";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ListView from "../../components/Display/ListView";
import CreateorCaseObservables from "./components/CreateorCaseObservables";
import { ObservableFilterField } from "../../utils/filters/fields.js";
import FilterFields from "../../components/filters/FilterFields.js";
import DeleteObservable from "./components/DeleteObservable.js";
import DeleteIcon from "@mui/icons-material/Delete";
import BulkObservalesDeleteDialog from "./components/BulkObservalesDeleteDialog.js";
import ObservableReportsTemplate from "./components/ObservableReportsTemplate.js";

const ObservablesPage = ({
  data,
  fetchObservables,
  loading,
  caseId,
  pagination,
  onPageChange,
  setPagination,
  count,
  onDetailOpen,
  permissions,
  resetDetailPage,
}) => {
  const columns = [
    { id: "flag", label: "Flags", sortable: false },
    { id: "dataType", label: "Type", sortable: true },
    { id: "data", label: "Value/Filename", sortable: true },
    { id: "_createdAt", label: "Dates", sortable: true },
  ];
  const COLORS = [
    "#1173FF",
    "#000000",
    "#FFAF15",
    "#FF3E3E",
    "#FF6B00",
    "#13D38E",
  ];
  const initialStates = { by_tags: [], by_ioc: [], top_tags: [] };
  const [open, setOpen] = useState(false);
  const [statuses, setStatuses] = useState(initialStates);
  const [statusScreen, showStatuses] = useState(false);
  const [deleteDialog, showDeleteDialoge] = useState(false);
  const [selectedObject, selectObject] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkDeleteDialog, showBulkDeleteDialog] = useState(false);

  const [statsuViews, setStatusView] = useState({
    type_list: false,
    ioc_list: false,
    tags_list: false,
  });
  useEffect(() => {
    fetchObservables(false, true);
    // eslint-disable-next-line
  }, [pagination.per_page, pagination.page]);

  // Fetch Statuses
  const fetchStatusByTags = async () => {
    try {
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "getCase",
              idOrName: "~" + caseId,
            },
            {
              _name: "observables",
            },
            {
              _name: "aggregation",
              _agg: "field",
              _field: "dataType",
              _select: [
                {
                  _agg: "count",
                },
              ],
            },
          ],
        },
        {
          params: { name: "case-by-tags-stats" },
        }
      );

      let d = response.data;
      let _statuses = [];

      let index = 0;
      for (let i in d) {
        _statuses.push({ name: i, value: d[i].count, color: COLORS[index] });
        index += 1;
        console.log({ name: i, value: d[i].count, color: COLORS[index] });
      }
      setStatuses((pre) => ({ ...pre, by_tags: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchStatusTopTags = async () => {
    try {
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "getCase",
              idOrName: "~" + caseId,
            },
            {
              _name: "observables",
            },
            {
              _name: "aggregation",
              _agg: "field",
              _field: "tags",
              _select: [
                {
                  _agg: "count",
                },
              ],
              _order: ["-count"],
              _size: 10,
            },
          ],
        },
        {
          params: { name: "case-by-tags-stats" },
        }
      );

      let d = response.data;
      let _statuses = [];

      let index = 0;
      for (let i in d) {
        _statuses.push({ name: i, value: d[i].count, color: COLORS[index] });
        index += 1;
      }
      setStatuses((pre) => ({ ...pre, top_tags: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchStatusByIoc = async () => {
    try {
      const response = await axios.post(
        "/cms/api/v1/query",
        {
          query: [
            {
              _name: "getCase",
              idOrName: "~" + caseId,
            },
            {
              _name: "observables",
            },
            {
              _name: "aggregation",
              _agg: "field",
              _field: "ioc",
              _select: [
                {
                  _agg: "count",
                },
              ],
            },
          ],
        },
        {
          params: { name: "case-by-tags-stats" },
        }
      );

      let d = response.data;
      let _statuses = [];

      let index = 0;
      for (let i in d) {
        _statuses.push({ name: i, value: d[i].count, color: COLORS[index] });
        index += 1;
      }
      setStatuses((pre) => ({ ...pre, by_ioc: _statuses }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchStatus = () => {
    showStatuses(!statusScreen);
    if (!statusScreen) {
      fetchStatusByTags();
      fetchStatusByIoc();
      fetchStatusTopTags();
    }
  };
  const handleDelete = (obj) => () => {
    selectObject(obj);
    showDeleteDialoge(true);
  };
  const handleActionSelect = (event) => {
    showBulkDeleteDialog(true);
  };
  const BulkDeleteDialogBox = useCallback(() => {
    return (
      <BulkObservalesDeleteDialog
        open={bulkDeleteDialog}
        setOpen={showBulkDeleteDialog}
        observables={data.filter((c) => selectedRows.includes(c._id))}
        refreshFunc={() => {
          fetchObservables(false, true);
          setSelectedRows([]);
        }}
      />
    );
  }, [selectedRows, bulkDeleteDialog]);
  const DeleteObservableDialoge = useCallback(() => {
    return (
      <DeleteObservable
        open={deleteDialog}
        selectedObj={selectedObject}
        setOpen={showDeleteDialoge}
        refrashFunc={fetchObservables}
      />
    );
    // eslint-disable-next-line
  }, [deleteDialog, selectedObject]);

  return (
    <Box display="flex" flexDirection="column" gap={1.25} mt={4.375}>
      <Typography variant="h6" fontWeight={700}>
        List of observables ({data.length} of {count})
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={1.25} height={36}>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="delete-label">
              {selectedRows.length < 1
                ? `No observable selected`
                : `${selectedRows.length} observable selected`}
            </InputLabel>
            <Select
              sx={{ minWidth: 240, height: 36 }}
              color="error"
              labelId="delete-label"
              disabled={selectedRows.length < 1}
              label="Action"
              value=""
              onChange={handleActionSelect}
            >
              <MenuItem value="">
                <em>Select Action</em>
              </MenuItem>
              <MenuItem value={"delete"}>
                <DeleteIcon color="error" />
                Delete
              </MenuItem>
            </Select>
            <BulkDeleteDialogBox />
          </FormControl>
          {permissions.mObs && (
            <Button
              startIcon={<AddCircleIcon fontSize="small" />}
              variant={"contained"}
              color="success"
              onClick={() => setOpen(true)}
              sx={{
                borderRadius: "5px",
                borderColor: "#003272",
                color: "#fff",
                bgcolor: "#13D38E",
                "&:hover": {
                  color: "#fff",
                },
              }}
            >
              Add observables
            </Button>
          )}
          <Button
            startIcon={<GetAppIcon fontSize="small" />}
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "5px",
              borderColor: "#1173FF",
              color: "#fff",
              bgcolor: "#1173FF",
            }}
            onClick={() => handleExport(data, "observables")}
          >
            Export
          </Button>
        </Box>
        <Box>
          <Btn
            text="Stats"
            startIcon={PiecartIcon}
            variant={"primary"}
            onClick={fetchStatus}
            sx={{
              borderColor: "#003272",
              color: "#fff",
              bgcolor: "#003272",
            }}
          />
          <Typography
            mr={1.25}
            ml={1}
            variant="caption"
            fontWeight={500}
            color="textPrimary"
          >
            Per Pages
          </Typography>

          <InputField
            type="number"
            value={pagination.per_page}
            onChange={(e) =>
              setPagination((pre) => ({ ...pre, per_page: e.target.value }))
            }
            sx={{ maxWidth: 121 }}
          />
        </Box>
      </Box>
      <Collapse in={statusScreen} unmountOnExit>
        <Grid container spacing={2.5}>
          <Grid item xs={12} lg={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={"12px"}
              sx={{ borderRadius: "10px", border: "1px solid #ECF0F4" }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Observables by type</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, type_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.type_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.type_list ? PiecartIcon : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, type_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.type_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{ color: statsuViews.type_list ? "#1173FF" : "#fff" }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1">
                {statuses.by_tags.length > 0 ? (
                  !statsuViews.type_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_tags} />
                    </Box>
                  ) : (
                    <PieChart data={statuses.by_tags} graphHeight="95%" />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={"12px"}
              sx={{ borderRadius: "10px", border: "1px solid #ECF0F4" }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Observables as IOC</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, ioc_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.ioc_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={statsuViews.ioc_list ? PiecartIcon : PiecartBlueIcon}
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, ioc_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.ioc_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{ color: statsuViews.ioc_list ? "#1173FF" : "#fff" }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1">
                {statuses.by_ioc.length > 0 ? (
                  !statsuViews.ioc_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.by_ioc} />
                    </Box>
                  ) : (
                    <PieChart data={statuses.by_ioc} graphHeight="95%" />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              component={Paper}
              elevation={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={"12px"}
              sx={{ borderRadius: "10px", border: "1px solid #ECF0F4" }}
              height={272}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Top 5 tags</Typography>
                <ToggleButtonGroup size="small" value="bold" color="primary">
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, tags_list: true }))
                    }
                    sx={{
                      bgcolor: statsuViews.tags_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="bold"
                    aria-label="bold"
                  >
                    <Box
                      component="img"
                      src={
                        statsuViews.tags_list ? PiecartIcon : PiecartBlueIcon
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      setStatusView((pre) => ({ ...pre, tags_list: false }))
                    }
                    sx={{
                      bgcolor: !statsuViews.tags_list
                        ? "#1173FF !important"
                        : "transparent !important",
                    }}
                    value="italic"
                  >
                    <FactCheckIcon
                      sx={{ color: statsuViews.tags_list ? "#1173FF" : "#fff" }}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box flex="1">
                {statuses.top_tags.length > 0 ? (
                  !statsuViews.tags_list ? (
                    <Box
                      mt={2}
                      p="5px"
                      sx={{ border: "1px dashed #ECF0F4" }}
                      height="90%"
                    >
                      <ListView data={statuses.top_tags} />
                    </Box>
                  ) : (
                    <PieChart
                      data={statuses.top_tags}
                      graphHeight="95%"
                      sx={{
                        "& .recharts-legend-wrapper": {
                          right: "-15px ",
                        },
                      }}
                    />
                  )
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={1}
                  >
                    <Typography variant="subtitle1" textAlign="center">
                      No Data
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      {/* <FiltersObservablesFields onSearch={fetchObservables} /> */}
      <FilterFields
        filterField={ObservableFilterField}
        onSearch={fetchObservables}
        _for="observables"
      />
      <Box>
        <CustomTable
          columns={columns}
          data={data}
          CustomTableRow={CustomObservablesRow}
          loading={loading}
          handleDelete={permissions.mObs ? handleDelete : false}
          onPageChange={onPageChange}
          page={pagination.page}
          total_count={Math.ceil(count / pagination.per_page)}
          onDetailOpen={onDetailOpen}
          checkboxies
          SelectRows={setSelectedRows}
          _selectedRows={selectedRows}
          resetDetailPage={resetDetailPage}
        />
      </Box>
      {/* Delete Box */}
      <DeleteObservableDialoge />
      {/* Dialog Box */}
      <CreateorCaseObservables
        open={open} 
        setOpen={setOpen}
        caseId={caseId}
        refreshFunc={fetchObservables}
      />
      {/* Report Dialog  */}
      <ObservableReportsTemplate />
    </Box>
  );
};
export default ObservablesPage;
